import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import CamperListing from './campersListing';
import {bookingCamperList} from '../../services/authrisedPickupServeice';
import { pickupTabAction } from '../../store/pickup';

const UserListing = (props) => {

    const [showCamperlist, setShowCamperList]                   = useState(false);
    const [camperList, setCamperList]                           = useState([])
    const [selectedCamper, setSelectedCamper]                   = useState();


    useEffect(()=>{
        bookingCamperList().then((res)=>{
            setCamperList(res.data.responseData.result)
        })
    },[])

    const handelCampers = (camerId) =>{
        setShowCamperList(true);
        setSelectedCamper(camerId);  
    }
    const showCamperList =() => {
        setShowCamperList(false);
    } 



    const handleBack = () => {
        props.pickupTabAction(0);
        window.scroll(0,0)
    }

    return(
        <React.Fragment>
            <button onClick={handleBack} className="btn btn-dark btn_back rounded-0" >Back</button>
        {
            showCamperlist ? 
            <CamperListing CamperList={camperList} SelectedCamper={selectedCamper} ShowCamperList={showCamperList}/>
            : 
        <ul className="campers_list">
        {
            props.CampsezUsers.map((campers, index) => (
                <li key={campers._id}>
                    <div className="d-flex align-items-center">
                        <img src={campers.profileImage ? campers.profileImage : require('../../assets/img/user.png')} alt=''/>
                        <h5 className="mb-0 ms-3">{campers.name}</h5>
                    </div>                   
                    <div>
                        <button onClick={()=>{handelCampers(campers._id)}} className="btn btn-primary ps-5 pe-5 rounded-0"> Add Authorized Pickup</button>
                    </div>
               </li>            
            ))
        }

        
        </ul>
    }

        </React.Fragment>
    )
}

const mapDispatchToProps = dispatch => ({
    pickupTabAction: params => dispatch(pickupTabAction(params)),
});
  
export default connect(null, mapDispatchToProps)(UserListing);
