import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { convertTimeToCurrentTimeZone, resHandle } from '../../../helper'
import { getUsersDetailById } from '../../../services/directorServices'
const tableHeaders = ["Value", "Updated At",'Updated By',  "Reason", ]

const CommuterHistory = ({ show, setShow, history, timezone }) => {
    const [historyData, setHistoryData] = useState(history ?? []);
    const fetchUserdata = async () => {
        const params ={userIds: [...new Set(historyData.map(item => item.updatedBy))]};
        try{
             const res = await getUsersDetailById(params);
            let { status, data : {result}  } = resHandle(res);
            if(status){
                const nameMap = new Map(result.map(item => [item._id, `${item.name} ${item?.lastName ?? ''}`]))
                const mappedName = historyData.map(item => ({
                    ...item,
                    name: nameMap.get(item.updatedBy) || '-/-'
                  }));
                  setHistoryData(mappedName)
            }
        }catch(error){
            console.log(error);
        }
        
    }
    return (
        <Modal
            dialogClassName="h_auto"
            show={show}
            size='md'
            animation={false}
            centered
        >
            <Modal.Header>
                <Modal.Title>Commuter History Audits</Modal.Title>
                <button className='btn btn-sm btn-primary ms-5' onClick={fetchUserdata}>
                    <small>Show users</small>
                </button>
                <span className="modal_close" onClick={() => setShow(false)}><i className="icon-cross" /></span>
            </Modal.Header>
            <Modal.Body>
              
                <div className="overflow-x-auto justify-content-center">
                    <table className="min-w-full border border-gray-300">
                        <thead>
                            <tr className="bg-gray-100">
                                {tableHeaders.map((key) => (
                                    <th key={key} className="px-4 py-2 border"><small><b>{key}</b></small></th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {historyData.map((row, index) => (
                                <tr key={row.updated_at} className="border">
                                    <td className="px-4 py-2 border"><small>{row.currentValue ? "YES" : "NO"}</small></td>
                                    <td className="px-4 py-2 border"><small>{convertTimeToCurrentTimeZone(row.updated_at,timezone,'MM/DD/YY hh:mm a')}</small></td>
                                    <td className="px-4 py-2 border"><small>{row?.name ? row?.name : "-/-"}</small></td>
                                    <td className="px-4 py-2 border"><small>{row.reasonForChange}</small></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CommuterHistory
