import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactSelect from 'react-select';

const CustomDropDown = (props) => {
    const { label, value, onChange, options, className, error, placement = "left", divClass="form-group", classNamePrefix='', isDisabled =false, placeholder } = props
    return (
        <div className={divClass}>
            <label>{label}</label>
            <ReactSelect
                isDisabled={isDisabled}
                className={`${className} ${error ? 'is-invalid' : ''}`}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                options={options}
                classNamePrefix={classNamePrefix}
            />

            {error ? <OverlayTrigger placement={placement} overlay={<Tooltip>{error}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
        </div>
    )
}

export default CustomDropDown
