import React, { Fragment, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { removeItemByKeyMatch } from '../bookings/bookingHelper'
import { removeCamperFromCart } from '../../services/campersServices'
import ConfirmModal from '../modals/ConfirmModal'
import { notificationMsg } from '../../helper'
import { MdPersonAddAlt } from "react-icons/md";
import AddParticipantModal from '../modals/AddParticipantModal'

const CamperList = ({ setActiveCamper, cartitem, getCartItem}) => {
    const [cofirmModal, setCofirmModal] = useState(false)
    const [addParticipantModal, setAddParticipantModal] = useState(false)
    const [activeDetails, setActiveDetails] = useState({})
    const removeCamper = (camperId, cartId) => {
        setActiveDetails({ camperId, cartId })
        setCofirmModal(true)
    }
    const handleRemoveCamper = async () => {
        const params = { ...activeDetails };
        const res = await removeCamperFromCart(params);
        if (res?.data?.statusCode) {
            notificationMsg(res?.statusCode, "Camper removed successfuly,");
            setCofirmModal(false)
            setActiveDetails({})
            getCartItem(cartitem._id);
        }
    }
    const handleCancelModal = async () => {
        setCofirmModal(false)
        setActiveDetails({})
    }
    return (
        <Fragment>
            <ConfirmModal
                show={cofirmModal}
                hideModal={handleCancelModal}
                msg={"Are you sure you want to remove the camper ?"}
                action={handleRemoveCamper}
            />
            <AddParticipantModal
                show={addParticipantModal} 
                hideModal={() => setAddParticipantModal(false)}
                cartId={cartitem._id}
                getCartItem={getCartItem}
            />
            <Row>
                    <Card.Title className='show_sm'><h6><b>{cartitem?.campers?.length ? "Add More ?(Optional)" : "Choose"}</b></h6></Card.Title>
                <Col className='mb-1'>
                    <Card  className='rounded' style={{ backgroundColor: "#c2c2c2" }}>
                        <div>
                            {cartitem.camperList?.length > 0 &&
                                removeItemByKeyMatch(cartitem.camperList, '_id', cartitem.campers, 'camperId')
                                    .map(item =>
                                            <span
                                                key={item._id}
                                                className="btn btn-booking cm_tag"
                                                onClick={() => setActiveCamper(item)}
                                            >
                                                <MdPersonAddAlt
                                                    className=" me-2"
                                                    size={20}
                                                />
                                                {`${item.firstName} ${item?.lastName}`}
                                            </span>
                                    )}
                            <span
                                className="btn btn-booking cm_tag"
                                onClick={() => setAddParticipantModal(true)} ><b> + Add New Participant </b></span>
                        </div>
                    </Card>
                </Col>
                <Col className='mb-1'>
                    <Card className='rounded' style={{ backgroundColor: "#c2c2c2" }}>
                            {
                                cartitem?.campers.length ?
                                    <div >
                                        {
                                            cartitem.campers.map(item =>
                                                <span key={item.camperId} className="btn btn-success cm_tag">
                                                    <i onClick={() => removeCamper(item.camperId, cartitem.cartId)} className="icon-cross me-2" />

                                                    {item.camperName}
                                                </span>
                                            )
                                        }
                                        <p className='ms-3'><small className='text-success'>Details Confirmed</small></p>
                                    </div> :
                                    <small><b>No participant selected</b></small>
                            }
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default CamperList
