import React, {useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import PhoneInput from 'react-phone-number-input/input';
import CamperListing from './campersListing';
import {bookingCamperList} from '../../services/authrisedPickupServeice';
import { pickupTabAction } from '../../store/pickup';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isValidEmail, checkStringContainsNumbers } from '../../helper';
import  flagImg from '../../assets/img/united-states-flag-icon.svg';

const NonUser = props => {

    const [addUserSuccess, setAddUserSuccess]               = useState(false);
    const [name, setFirstName]                              = useState('');
    const [firstNameError, setFirstNameError]               = useState(false);
    const [lastName, setLastName]                           = useState('');
    const [lastNameError, setlastNameError]                 = useState(false);  
    const [phone, setPhone]                                 = useState('');
    const [phoneError, setPhoneError]                       = useState('');
    const [email, setEmail]                                 = useState('');      
    const [emailError, setEmailError]                       = useState(false);
    const [sendNotification, setSendNotification]           = useState(true) 
    const [camperList, setCamperList]                       = useState([])     
    const [nonCampsezUserDetails, setNonCampsezUserDetails] = useState({})

    useEffect(()=>{
        bookingCamperList().then((res)=>{
            setCamperList(res.data.responseData.result)
        })
    },[])


    const addRelationShip = (camperId, relationvalue) =>{
        let camperlistpre = [...camperList];
        let camperIndex = camperlistpre.findIndex(camper=> camper._id === camperId)
        camperlistpre[camperIndex].relation = relationvalue; 
        setCamperList(camperlistpre)
    }



    const handleValidate = () => {
        let validate = true;

        if(name === '' || name.trim() === ''){
            validate = false;
            setFirstNameError('First name is required');
        }else if(checkStringContainsNumbers(name)){
            setFirstNameError('Invalid name');
            validate = false 
        }

        if(lastName === '' || lastName.trim() === ''){
            validate = false;
            setlastNameError('Last name is required');
        }else if(checkStringContainsNumbers(lastName)){
            setFirstNameError('Invalid name');
            validate = false 
        }

        if (email === '') {
            setEmailError('Email is required');
            validate = false;
        }else if (!isValidEmail(email)) {
            setEmailError('Email is not valid');
            validate = false
        }else if(email.trim() === ''){
            setEmailError('Email is required');
            validate = false;
        }

        if (phone === '' || phone === null) {
            setPhoneError('Phone is required');
            validate = false
        }else if(phone.length > 15 || phone.length < 8){
            setPhoneError('Invalid Phone Number');
            validate = false
        }

        return validate;
    }

    const handelSubmit =(e) =>{
        e.preventDefault();
        if(handleValidate()) {
         let userDetails = {name, lastName, phone, email, sendNotification} 
         setNonCampsezUserDetails(userDetails);  
         setAddUserSuccess(true)
    }
    }

    const handelNotification = () =>{
        if(sendNotification){setSendNotification(false)}else{setSendNotification(true)}
    }


    return(
        <div className="body_container input_info_pos_change">
            <div className="container">
            <div className="pickup_user_wrapper">
            <h3 className="page_title">Authorized Pickup</h3>
            {
                addUserSuccess ? 
                <CamperListing CamperList={camperList} UserDetails={nonCampsezUserDetails} AddtionalShip={ addRelationShip }/> 
           :
            <form onSubmit={handelSubmit}>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label>First Name</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e)=>{setFirstName(e.target.value); setFirstNameError(false)}}
                            className={`form-control ${firstNameError ? 'is-invalid': ''}`}
                            placeholder="Enter First Name" /> 

                        {firstNameError ? <OverlayTrigger placement="left" overlay={<Tooltip>{firstNameError}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                    </div>

                    <div className="col-md-6 form-group">
                        <label>Last Name</label>
                        <input
                            type="text"
                            value={lastName}
                            onChange={(e)=>{setLastName(e.target.value); setlastNameError(false)}}
                            className= {`form-control ${lastNameError ? 'is-invalid': ''}`}
                            placeholder="Enter Last Name" /> 

                        {lastNameError ? <OverlayTrigger placement="left" overlay={<Tooltip>{lastNameError}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-6 form-group">
                        <label>Phone Number</label>
                        <div className="cm_phone_field">
                        <img className="us_flag" src={flagImg} />
                            <PhoneInput
                                country="US"
                                className= {`form-control ${phoneError ? 'is-invalid': ''} ` }
                                onChange={e => (setPhone(e), setPhoneError(''))}
                                maxLength={16} />

                            {phoneError ? <OverlayTrigger placement="left" overlay={<Tooltip>{phoneError}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    </div>
                    <div className="col-md-6 form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            className= {`form-control ${emailError ? 'is-invalid': ''}`}
                            value={email}
                            onChange={(e)=>{setEmail(e.target.value); setEmailError(false)}}
                            placeholder="Enter Email Address" /> 

                        {emailError ? <OverlayTrigger placement="left" overlay={<Tooltip>{emailError}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                        <div className="m-0 mt-2">
                            <div className="check_box_wrapper cm_dark_check">
                                <input type="checkbox" onChange={handelNotification} checked={ sendNotification ? 'checked' : '' } value="male" />
                                <span className="check_box" ><span></span><small>Send notification that they have been authorized for pickup.</small></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center mt-5">
                    <Link onClick={()=>{props.pickupTabAction(0)}} className="btn btn-info ps-5 pe-5 me-1 rounded-0" to="/pickup">Cancel</Link>
                    <button className="btn btn-primary ps-5 pe-5 ms-1 rounded-0">Submit</button>
                </div>

            </form>
    }

        </div>
        </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    pickupTabAction: params => dispatch(pickupTabAction(params)),
});
  
export default connect(null, mapDispatchToProps)(NonUser);