import React, { useReducer, useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import Cookies from 'js-cookie';

import { changeAuthModal, toggleForgotModal, resendotpEmailModal, changeRoleAction } from '../../store/ui';
import { loginAction, clearErrorMsgAction, verifyFastEmailotpAction } from '../../store/user';
import { isValidEmail, resHandle } from '../../helper';
import {getCartItems } from '../../services/programService';
import {getitemsincart} from '../../store/cart'
import { fastLogin } from '../../services/authService';
import StaffSignIn from '../../assets/img/staffSignIn.png';
import LoginBg from '../../assets/img/login-bg.jpg';



const Login = props => {
    let logedUser = Cookies.get("user2") ? JSON.parse(Cookies.get("user2")) : {};
    const [isOtp, setIsOtp] = useState(false);
    const [otp, setOtp] = useState('');
    const [userId, setUserId] = useState('');
    const [otpError, setOtpError] = useState('');
    const [loader, setLoader] = useState(false);
    const [resErr, setResErr] = useState('');
    const [userInput, setUserInput] = useReducer((state, newState) => ({...state, ...newState}),
        {
            email: '',
            password: '',
            campCode: '',
        }
    );

    const [error, setError] = useReducer((state, newState) => ({...state, ...newState}),
        {
            email: '',
            password: '',
            campCode: ''
        }
    );


    const handleChange = e => {
        setUserInput({[e.target.name]: e.target.value});
        setError({[e.target.name]: ''});
        setResErr('');
    }



    const handleValidate = () => {
        let validate = true;
        let { email, password } = userInput;
        if(email === ''){
            setError({email: 'Email is required'});
            validate = false
        }else if(!isValidEmail(email)){
            setError({email: 'Email is not valid'});
            validate = false
        }

        return validate;
    }


    const handleSubmit = e => {
        setResErr('');
        setOtp('')
        e.preventDefault();
        let nonUserId = localStorage.getItem('NonLoginUserID');

        if(handleValidate()){
            setLoader(true);
            let { email, password } = userInput;
            let params = '';
            if(props.isDirecterAuth){
                params = { email }
            }else{
                if(nonUserId!==''){
                    params = { email, password, nonUserId }
                }else{
                    params = { email, password }                  
                }
            }


            fastLogin(params).then(res => {
                let { status, data } = resHandle(res);
                if(status){
                    if(email ===logedUser.email){
                        localStorage.setItem('accessToken', Cookies.get("accessToken2"));
                        localStorage.setItem('user', Cookies.get("user2"));
                        window.location.reload();
                    }else{
                        setUserId(data?.userProfile?._id)
                        setLoader(false);
                        setIsOtp(true);
                    }
                    
                }else{
                    setResErr(data);
                    setLoader(false);
                }
            });

            // if(email ===logedUser.email){
            //     localStorage.setItem('accessToken', Cookies.get("accessToken2"));
            //     localStorage.setItem('user', Cookies.get("user2"));
            //     window.location.reload();
            // }else{
            //     fastLogin(params).then(res => {
            //         let { status, data } = resHandle(res);
            //         if(status){
            //             setUserId(data?.userProfile?._id)
            //             setLoader(false);
            //             setIsOtp(true);
            //         }else{
            //             setResErr(data);
            //             setLoader(false);
            //         }
            //     });
            // }
           

        }
    }



    useEffect(() => props.clearErrorMsgAction, [])

    useEffect(()=>{

        return () => {
            getCartItems().then(res=>{
                if(res?.data?.statusCode)
                {
                    props.getitemsincart(res.data.responseData.result.length)
                }
            })
        }
    },[])


    const handleChane = otp => {
        setOtp(otp);
        setOtpError('');
    };


    const handleValidate2 = () => {
        let validate = true;


        if(otp === ''){
            setOtpError('otp is required');
            validate = false
        }
        if(otp.toString().length !== 4){
            setOtpError('otp is require length');
            validate = false
        }

        return validate;
    }



    const handleSubmit2 = e => {
        e.preventDefault();

        if(handleValidate2()){
            setLoader(true);
            let params = {
                userId,
                otp
            }

            props.verifyFastEmailotpAction(params).then(res => {
                setLoader(false);
            });

        }
    }

    return(
        
        <div className="row g-0 d-flex align-items-center">
            <div className="col-lg-6 hide_in_mob">
                <img src={ props.isDirecterAuth ? StaffSignIn : LoginBg} />
                </div>

            <div className="col-lg-6 pb-5 d-flex justify-content-center align-items-center">
                <form onSubmit={handleSubmit}>
                    <h3 className="text-center mb-4">Sign In</h3>

                    {isOtp ? <Fragment>
                            <div className="otp_inputs">
                                <OtpInput
                                    onChange={handleChane}
                                    isInputNum={true}
                                    shouldAutoFocus={true}
                                    name="otp"
                                    value={otp}
                                    numInputs={4}
                                    separator={''}
                                />
                                {otpError ? <div className="invalid-feedback">{otpError}</div> : ''}

                                <p className='m-0 mt-3 text-center'>Didn’t receive the OTP? <span onClick={handleSubmit} className="pointer color-primary">Resend</span></p>
                                
                            </div>
                    </Fragment> : <div className="form-group">
                        <input type="email" name="email" value="" style={{display: 'none'}} />
                        <input
                            type="email"
                            placeholder="Email"
                            className={`form-control ${error.email ? 'is-invalid' : ''}`}
                            name="email"
                            autocomplete="nope"
                            value={userInput.email}
                            onChange={handleChange} />

                        {error.email ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.email}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                    </div>}

                    

                    {/* <div className="form-group eye_wrapper">
                        <input type="password" value="" style={{display: 'none'}} />
                        <input
                            type={showPass ? 'text' : 'password'}
                            placeholder="Password"
                            className={`form-control ${error.password ? 'is-invalid' : ''}`}
                            name="password"
                            autocomplete="new-password"
                            value={userInput.password}
                            onChange={handleChange} />

                            <i className={`icon-eye ${showPass ? 'slash' : ''}`} onClick={() => setShowPass(!showPass)} />

                        {error.password ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.password}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
 
                    </div> */}
{/* 
                    {
                        props.isDirecterAuth ? <div className="form-group mt-3">
                        <input
                            type="text"
                            placeholder="Org Code"
                            className={`form-control ${error.campCode ? 'is-invalid' : ''}`}
                            name="campCode"
                            value={userInput.campCode}
                            onChange={handleChange} />

                        {error.campCode ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.campCode}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        
                    </div> : ''
                    } */}

                    {/* <div className="d-flex justify-content-between mb-3">
                    <div className="check_box_wrapper cm_dark_check pt-1">
                            <input type="checkbox" name="gender" value="male" />
                            <span className="check_box"><span></span> Remember me</span>
                        </div>
                        <span className="cm_link" onClick={props.toggleForgotModal}>Forgot Password?</span>
                    </div> */}

                    
                        {
                            isOtp ? <span
                            onClick={handleSubmit2}
                            className={`btn btn-primary rounded-0 w-100 mt-3 btn_anim ${loader ? 'show_anim' : ''}`}>{loader ? 'Loading...' : 'Confirm OTP'}</span> : 
                            <button
                            type="submit"
                            disabled={loader}
                            className={`btn btn-primary rounded-0 w-100 mt-3 btn_anim ${loader ? 'show_anim' : ''}`}>{loader ? 'Loading...' : 'Sign In'}</button>
                        }
               
                            {/* <span onClick={props.changeRoleAction} className="btn btn-primary rounded-0 w-100 mt-3">{props.isDirecterAuth ? 'Sign in as Attendee?' : 'Sign in as Staff Personnel?'}</span> */}
                    
                    
                    {resErr ? <p className="error_msg">{resErr}</p> : ''}
                    {props.error && <p className="error_msg">{props.error}</p>}
                    

                </form>

                <p className="change_modal">Don't have an account? <span className="cm_link_red" onClick={() => props.changeAuthModal(0)}>Sign Up</span></p>
            </div>

        </div>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { loading, error, loggedIn } = state.user;
    let { isDirecterAuth } = state.ui;
    return {
        loading,
        error,
        isDirecterAuth,
        loggedIn
    };
}
  
const mapDispatchToProps = dispatch => ({
    changeAuthModal: params => dispatch(changeAuthModal(params)),
    toggleForgotModal: params => dispatch(toggleForgotModal(params)),
    resendotpEmailModal: params => dispatch(resendotpEmailModal(params)),
    loginAction: params => dispatch(loginAction(params)),
    clearErrorMsgAction: () => dispatch(clearErrorMsgAction()),
    changeRoleAction: () => dispatch(changeRoleAction()),
    getitemsincart: (cartitems) => dispatch(getitemsincart(cartitems)),
    verifyFastEmailotpAction: params => dispatch(verifyFastEmailotpAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Login);