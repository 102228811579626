import React from 'react'
import { getHtml, hasMatchingAnswers, isSubset, trimWhiteSpacesFromArrayElements } from '../../../helper'
import Select from 'react-select';



const Questions = ({questions, handleAnswerChange, handleMultipleAnswerSelect, handleIsOtherOptionClickAnswer, handleAnswerCheckboxChange}) => {
    
    const shouldRenderQuestion = (question, index) => {
        const _isRequired = question.isRequred;
        if (question.conditional === 1 && question.conditionalOn.length > 0) {
            const condition = question.conditionalOn[0];
            const conditionalQuestion = questions.find(q => q.questionId === condition.questionId);
            const conditionalIndex = questions.findIndex(q => q.questionId === condition.questionId);
            const selectedAnswers =Array.isArray(questions[conditionalIndex]?.answer) ?  questions[conditionalIndex]?.answer: [questions[conditionalIndex]?.answer];
            const options = condition.optionVals;
            const optionOperator = condition.optionValOperator;
            const trimmedOptionsArr = trimWhiteSpacesFromArrayElements(options)
            const trimmedAnsArr = trimWhiteSpacesFromArrayElements(selectedAnswers?.length ? selectedAnswers[0].split(','): []);
            const isMatching = hasMatchingAnswers(options, selectedAnswers, optionOperator );
            if (conditionalQuestion && isMatching) {
                if(_isRequired ===1 ) question.isSkipped = false;
                return true;               
            }else if(isSubset(trimmedOptionsArr,trimmedAnsArr)){
                return true;
            }else{
                question.answer = '';
                if(_isRequired ===1 ) question.isSkipped = true;
                return false;
            }
        } else {
            return true;
        }
    };

  
    return (
    <>
     <label className="ms-3"><b>Responses Required</b></label>
    {
         questions.map((item, index) => (
            shouldRenderQuestion(item, index) && 
            <div id={item._id} className="col-sm-12 mb-3 form-group mb-5" key={item.questionId}>

                <p className='m-0'>Q.{index + 1} {getHtml(item.title)}</p>
                {item.subTitle ? <p><small>{getHtml(item.subTitle)}</small></p> : ''}
                
                {
                    item.questionType === 1 ? (
                        < >
                            <textarea
                                value={item?.answer}
                                onChange={e => handleAnswerChange(e.target.value, index)}
                                className="form-control" name="" />

                            {item?.answerErr ? <h6 className='cm_err'>{item.answerErr}</h6> : ''}
                        </ >
                    ) : ''
                }



                {
                    item.questionType === 2 ? (
                        < >
                            <Select
                                className="form-control"
                                placeholder="Select"
                                classNamePrefix="cm_select"
                                onChange={e => handleAnswerChange(e.value, index)}
                                value={{label:item?.answer, value:item?.answer}}
                                options={item.options.map(im => ({label: im.opt, value: im.opt}))} />

                            {item?.answerErr ? <h6 className='cm_err'>{item.answerErr}</h6> : ''}
                        </ >
                    ) : ''
                }



                {(item.questionType === 3 ) ? (
                    <>
                        {item.options.length ? item.options.map(im => (
                            <div className="check_box_wrapper cm_dark_check">
                                <input
                                    checked={item?.answer?.includes(im.opt) ? true : false}
                                    onChange={() => handleMultipleAnswerSelect(im.opt, index)}
                                    type="checkbox" />
                                <span className="check_box"><span></span></span>
                                <p>{im.opt}</p>
                            </div>
                        )) : ''}

                        {
                            item.isOtherOption === 1 ? (
                                <div className="check_box_wrapper cm_dark_check">
                                    <input
                                        checked={item?.isOtherOptionSelected ? true : false}
                                        onChange={() => handleIsOtherOptionClickAnswer(index)}
                                        type="radio" />
                                   <span className="check_box"><span></span></span>
                                    <p>Other</p>
                                </div>
                            ) : ''
                        }


                        {
                            item?.isOtherOptionSelected ? (
                            <div className='pt-3 ps-4'>
                                <textarea
                                    value={item?.answer}
                                    onChange={e => handleAnswerChange(e.target.value, index)}
                                    className="form-control"  />
                            </div>
                            ) : '' 
                        }

                        {item?.answerErr ? <h6 className='cm_err'>{item.answerErr}</h6> : ''}


                    </>
                ) : ''}





                {item.questionType === 4 ? (
                    < >
                        {item.options.length ? item.options.map(im => (
                            <div className="check_box_wrapper cm_dark_check">
                                <input
                                    checked={im.opt === item.answer ? true : false}
                                    onChange={() => handleAnswerCheckboxChange(im.opt, index)}
                                    type="checkbox" />
                                <span className="check_box"><span></span></span>
                                <p>{im.opt}</p>
                            </div>
                        )) : ''}

{
                            item.isOtherOption === 1 ? (
                                <div className="check_box_wrapper cm_dark_check">
                                    <input
                                        checked={item?.isOtherOptionSelected ? true : false}
                                        onChange={() => handleIsOtherOptionClickAnswer(index)}
                                        type="radio" />
                                   <span className="check_box"><span></span></span>
                                    <p>Other</p>
                                </div>
                            ) : ''
                        }


                        {
                            item?.isOtherOptionSelected ? (
                            <div className='pt-3 ps-4'>
                                <textarea
                                    value={item?.answer}
                                    onChange={e => handleAnswerChange(e.target.value, index)}
                                    className="form-control"  />
                            </div>
                            ) : '' 
                        }

                        {item?.answerErr ? <h6 className='cm_err'>{item.answerErr}</h6> : ''}
                    </ >
                ) : ''}




                {
                    item.questionType === 5 ? (
                        <>
                            {
                                item.options.length ? item.options.map(im => (
                                    <div className="check_box_wrapper cm_dark_check">
                                        <input
                                            checked={im.opt === item.answer ? true : false}
                                            onChange={() => handleAnswerCheckboxChange(im.opt, index)}
                                            type="radio" />
                                        <span className="check_box"><span></span></span>
                                        <p>{im.opt}</p>
                                    </div>
                                )) : ''
                            }

                            {item?.answerErr ? <h6 className='cm_err'>{item.answerErr}</h6> : ''}
                        </>
                    ) : ''
                }



                {
                    item.questionType === 6 ? (
                        <>
                            <Select
                                className="form-control"
                                placeholder="Select"
                                classNamePrefix="cm_select"
                                onChange={e => handleAnswerChange(e.value, index)}
                                options={item.options.map(im => ({label: im.opt, value: im.opt}))} />

                            {item?.answerErr ? <h6 className='cm_err'>{item.answerErr}</h6> : ''}
                        </>
                    ) : ''
                }





            </div>
        ))

    }
      
    </>
  )
}

export default Questions
