import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { viewLoginModalAction, toggleAuthModal } from '../../store/ui';

const LoginAlertModal = props => (
    <Modal show={props.viewLoginModal} centered>
        <Modal.Body>
            <span className="modal_close" onClick={props.viewLoginModalAction}><i className="icon-cross" /></span>
            <h5 className="text-center mt-4">
            Please login or register first 
            </h5>
            <div className="d-flex justify-content-center mt-5 mb-4">
                <button className="btn btn-primary ps-5 pe-5" onClick={() => (props.viewLoginModalAction, props.toggleAuthModal(1))}>Sign In</button>
            </div>
        </Modal.Body>
    </Modal>
)


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { viewLoginModal } = state.ui;
    return {
        viewLoginModal
    };
}

const mapDispatchToProps = dispatch => ({
    viewLoginModalAction: () => dispatch(viewLoginModalAction()),
    toggleAuthModal: params => dispatch(toggleAuthModal(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(LoginAlertModal);