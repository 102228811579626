import React,{useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import {getgendersdisplay} from '../../helper';
import {editauthpickup} from '../../services/authrisedPickupServeice';
import {pickupTabAction} from '../../store/pickup';
import { LogoWhite, CardLoader} from '../reuse/SVG';
import {bookingCamperList} from '../../services/authrisedPickupServeice';
import { ProgramLoader } from '../reuse/SVG';

 const EditPickup =(props) =>{
 
 
 const [addrelationPopup, setAddrelationPopup]               = useState(false);
 const [realtionvalue, setRelationValue]                     = useState('');
 const [realtionError, setRelationError]                     = useState(false);
 const [selectedCamer, setSelectedCamer]                     = useState('');
 const [camperList, setCamperList]                           = useState([]);
 const [authrisedCamperListing, setAuthrisedCamperListing]   = useState([]);
 const [realtionWarning, setRelationWarning]                 = useState(false);    
 const [programWarning, setProgramWarning]                   = useState(false);
 const [pickupWarning, setPickupWarning]                     = useState(false); 
 const [success, setSuccess]                                 = useState(false); 
 const [btnloder, setBtnloder]                               = useState(false);
 const [relationtext, setRelationText]                       = useState('Add Relation'); 
 const [loading, setLoading]                                 = useState(true);

useEffect(() => {
    let authlist = [...authrisedCamperListing];
    bookingCamperList().then((res)=>{
        if(res?.data?.statusCode)
        {
        let camperlistdata  =  res.data.responseData.result;
        camperlistdata.map((item,i)=>{
            let precamperdata = props.pickupUser.campers.filter(camper => camper.camperId === item._id)
            if(precamperdata.length)
            {
                camperlistdata[i].relation = precamperdata[0].relationship;
                if(precamperdata[0].programId.length===0)
                {
                    let programarr =[];
                    camperlistdata[i].programs.map(pro =>{
                        programarr.push(pro._id)
                    })
                    programarr.push('All');
                    camperlistdata[i].selectedprograms = programarr;   
                }
                else
                {
                    camperlistdata[i].selectedprograms = precamperdata[0].programId;   
                }
                
                authlist.push(item._id);
            }
        })
        setAuthrisedCamperListing(authlist);
        setCamperList(camperlistdata)
         setLoading(false);

        }
        else{
            setLoading(false)
        }
    })
},[])


const handelRelationship =() =>{
    if(realtionvalue.trim() ==='' || realtionvalue ===undefined)
    {
        setRelationError(true)
    }
    else
    {
        addRelationShip(selectedCamer,realtionvalue)
        setAddrelationPopup(false)
    }
}


const addRelationShip = (camperId, relationvalue) =>{
    let camperlistpre = [...camperList];
    let camperIndex = camperlistpre.findIndex(camper=> camper._id === camperId)
    camperlistpre[camperIndex].relation = relationvalue; 
    setCamperList(camperlistpre)
}

const addauthrizePickup =(camerId) =>{
    let authrisedusers = [...authrisedCamperListing];
    if(authrisedCamperListing.includes(camerId))
    {
       let newauthriselist =  authrisedusers.filter(camper => camper !== camerId);
       setAuthrisedCamperListing(newauthriselist);
    //    let list = [...camperList];
    //    let camperIndex =  list.findIndex(item => item.camperId!==camerId);
    //    delete list[camperIndex].relation;
    //    delete list[camperIndex].selectedprograms
    //    setCamperList(list);
    }
    else
    {
        let camperdetails =  camperList.filter(item => item._id === camerId)
        if(camperdetails[0].relation === undefined){setRelationWarning(true)}
        else if(camperdetails[0].selectedprograms === undefined){setProgramWarning(true)}
        else if(camperdetails[0].selectedprograms !== undefined && camperdetails[0].selectedprograms.length<1){setProgramWarning(true)}
        else
        {
           authrisedusers.push(camerId);
        }
        setAuthrisedCamperListing(authrisedusers);
    }
}

const selectAllPrograms =(camperId)=>{
    let camperlistpre = [...camperList];
    let camperindex = camperlistpre.findIndex(item => item._id === camperId);
    let programlist = camperlistpre[camperindex].programs;
    let proarr = [];
    let authrisedusers = [...authrisedCamperListing];
    if(camperlistpre[camperindex].selectedprograms!==undefined && camperlistpre[camperindex].selectedprograms.includes('All'))
    {
        camperlistpre[camperindex].selectedprograms =[];
        if(authrisedusers.includes(camperlistpre[camperindex]._id))
        {
            let newlist = authrisedusers.filter(item => item!==camperlistpre[camperindex]._id)
            setAuthrisedCamperListing(newlist);
        }

    }
    else
    {
    if(programlist.length >= 0 )
    {
        programlist.map(item=>{
            proarr.push(item._id)
        })
        proarr.push('All')
    } 
    camperlistpre[camperindex].selectedprograms =  proarr;
    }
    setCamperList(camperlistpre);

}

const selectSingleProgram = (camperId, programID) => {
    let camperlistpre = [...camperList];
    let camperindex = camperlistpre.findIndex(item => item._id === camperId);
    let proarr = [];
    let authrisedusers = [...authrisedCamperListing];
    if(camperlistpre[camperindex].selectedprograms!==undefined && camperlistpre[camperindex].selectedprograms.includes(programID))
    {
       let allindex = camperlistpre[camperindex].selectedprograms.findIndex(item => item === 'All')
       let programIndex =  camperlistpre[camperindex].selectedprograms.findIndex(item => item === programID)
       if(allindex !==-1){camperlistpre[camperindex].selectedprograms.splice(allindex,1)}
       camperlistpre[camperindex].selectedprograms.splice(programIndex,1);
       if(camperlistpre[camperindex].selectedprograms.length===0)
       {
        let newlist = authrisedusers.filter(item => item!==camperlistpre[camperindex]._id)
        setAuthrisedCamperListing(newlist);
       }
    }
    else
    {
        if(camperlistpre[camperindex].selectedprograms===undefined)
        {
            
            camperlistpre[camperindex].selectedprograms = [programID];
        }
        else
        {
            camperlistpre[camperindex].selectedprograms.push(programID);   
        }
    }    
    setCamperList(camperlistpre);
}


const handelSubmit = (e) =>{
    e.preventDefault();
    setBtnloder(true)
    if(authrisedCamperListing.length > 0)
    {
        let params ={}
        let campers=[] 
        let selectedcamperlist =  camperList.filter(item => authrisedCamperListing.includes(item._id));
        selectedcamperlist.map(item=>{
            if(item.selectedprograms.includes('All'))
            {
                let obj ={"camperId":item._id, "programId":[], "relationship":  item.relation}
                campers.push(obj);
            }
            else
            {
                let obj ={"camperId":item._id, "programId": item.selectedprograms, "relationship":  item.relation}
                campers.push(obj);
            }
        })
        params.campers = JSON.stringify(campers);
        params.pickupId = props.pickupUser._id
        editauthpickup(params).then((res)=>{
            if(res?.data?.statusCode)
            {
                setSuccess(true)
                setBtnloder(false)
            }
        })
        
    }
    else
    {
        setPickupWarning(true)
        setBtnloder(false)
    }
}


    return(
    <React.Fragment>


{ loading ?  <div className="program_loader"><ProgramLoader /></div> : 
       <form onSubmit={handelSubmit}>
         
       <Modal
            dialogClassName="h_auto"
            show={realtionWarning}
            animation={false}
            centered
            >
            <Modal.Body>
            <span className="modal_close" onClick={()=> {setRelationWarning(false); }}><i className="icon-cross" /></span>
                <div className="p-5">
                    <h3 className="text-center mb-4">Error</h3>
                    <p>You Need to Add Relation First</p>                
                    <span onClick={()=> {setRelationWarning(false)}} className="btn btn-primary w-100 mt-2">Ok</span>
                </div>
            </Modal.Body>
        </Modal>

        <Modal
            dialogClassName="h_auto"
            show={programWarning}
            animation={false}
            centered
            >
            <Modal.Body>
            <span className="modal_close" onClick={()=> {setProgramWarning(false)}}><i className="icon-cross" /></span>
                <div className="p-5">
                    <h3 className="text-center mb-4">Error</h3>
                    <p>You Need to Select Program First </p>                
                    <span onClick={ () => {setProgramWarning(false)}} className="btn btn-primary w-100 mt-2">Ok</span>
                </div>
            </Modal.Body>
        </Modal>

          <Modal
            dialogClassName="h_auto"
            show={addrelationPopup}
            animation={false}
            centered
            >
            <Modal.Body>
            <span className="modal_close" onClick={()=> {setAddrelationPopup(false); setRelationError(false)}}><i className="icon-cross" /></span>
                <div className="p-5">
                    <h3 className="text-center mb-4">{relationtext}</h3>
                    <p>Add the relationship of the person you are authorizing for pickup to the camper that has been chosen here. (i.e. Is the person you are adding for pickup the campers father, mother, stepmother, stepfather, grandpa, family friend? Etc.)</p>
                    <div className="form-group">
                        <input type="text" onChange={(e)=>{setRelationValue(e.target.value)}} value={realtionvalue} className="form-control" />    
                    </div> 
                       {realtionError && <span>Add Relation is required</span>}                   
                        <span onClick={handelRelationship} className="btn btn-primary w-100 mt-2">Save</span>
                </div>
            </Modal.Body>
          </Modal>

          <Modal
            dialogClassName="h_auto"
            show={pickupWarning}
            animation={false}
            centered
            >
            <Modal.Body>
            <span className="modal_close" onClick={()=> {setPickupWarning(false)}}><i className="icon-cross" /></span>
                <div className="p-5">
                    <h3 className="text-center mb-4">Error</h3>
                    <p>Please select the users first</p>                
                    <span onClick={()=> {setPickupWarning(false)}} className="btn btn-primary w-100 mt-2">Ok</span>
                </div>
            </Modal.Body>
        </Modal>

        <Modal
            dialogClassName="h_auto"
            show={success}
            animation={false}
            centered
            >
            <Modal.Body>
            <span className="modal_close" onClick={()=> {setPickupWarning(false)}}><i className="icon-cross" /></span>
                <div className="p-5">
                    <h3 className="text-center mb-4">Success</h3>
                    <p>Pickup edit successfully </p>
                    <a onClick={()=>{props.pickupTabAction(0)}} className="btn btn-primary w-100 mt-2" href="/pickup">Ok</a>                
                </div>
            </Modal.Body>
        </Modal>        
        <h3 className="text-center mt-4">Edit Authorized Pickup</h3>
        <ul className="campers_list mt-5">
         {
         camperList.map((camper)=>(   
            <li key={camper._id}>
            <div className="d-flex align-items-center">
                    <div className="text-center">
                                <div className="check_box_wrapper cm_dark_check">
                                    <input type="checkbox" onChange={()=>{addauthrizePickup(camper._id)}} checked={authrisedCamperListing.includes(camper._id) ? 'checked' : ''}/>
                                    <span className="check_box"><span></span></span>
                                </div>
                            </div> 
                        <img meta_key={camper._id} src={require('../../assets/img/user.png')} />
                        <div className="mb-0 ms-3">
                        <p>{camper.firstName}</p>
                        <p><b>Age: </b>{camper.age} year old</p>
                        <p><b>Sex: </b>{getgendersdisplay(camper.gender)}</p> 
                        {
                            camper.relation && <p><b>Relation: </b> { camper.relation  } </p>
                        }

            </div>
            </div>
            <div className="d-flex align-items-center">
            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">Choose Program</Dropdown.Toggle>
                <Dropdown.Menu>
                <ul>
                    <li onClick={()=>{selectAllPrograms(camper._id)}}>
                             <div className="check_box_wrapper cm_dark_check">
                                <input type="checkbox"  checked={camper.selectedprograms!==undefined &&  camper.selectedprograms.includes('All') ? 'checked' : ''} /> All Programs 
                                    <span className="check_box"><span></span></span>
                            </div>
                    </li>
                    {
                      camper.programs !== undefined &&  
                      camper.programs.map(camp=>(
                        <li key={camp._id} onClick={()=>{ selectSingleProgram(camper._id, camp._id)  }}> 
                            <div className="check_box_wrapper cm_dark_check">
                            <input type="checkbox" checked={camper.selectedprograms!==undefined &&  camper.selectedprograms.includes(camp._id) ? 'checked' : '' } value={camp._id} /> 
                             {camp.programName}
                                    <span className="check_box"><span></span></span>
                            </div> 
                       </li>
                      ))                         
                    }
                </ul>
                </Dropdown.Menu>
            </Dropdown> 
                <span onClick={()=>{setAddrelationPopup(true); setSelectedCamer(camper._id); setRelationValue(); setRelationText(camper.relation ? 'Edit Relation' : 'Add Relation'); setRelationValue(camper.relation ? camper.relation : '' )}}   className="btn btn-primary ms-2"> <i className="icon-plus" style={{color: '#fff'}} />{ camper.relation ? 'Edit Relation' : 'Add Relation' } </span>
            </div>
        </li>
    ))
    }
     </ul> 

     <div className="col-sm-12 text-center mb-5">
                <a href="/pickup" onClick={()=>{props.pickupTabAction(0)}}  className="btn btn-light rounded-0 me-2" style={{width: '130px'}}>Cancel</a>
                <button style={{minWidth: '130px'}} className={`btn btn-primary rounded-0 ${btnloder ? 'btnLoader' : ''}`}>Save</button>
    </div>

    </form>
 }
  </React.Fragment>   
    )
}
const mapDispatchToProps = dispatch => ({
    pickupTabAction: params => dispatch(pickupTabAction(params)),
});
  
export default connect(null, mapDispatchToProps)(EditPickup);
