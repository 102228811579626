import React, {useState, useEffect} from 'react'
import { confirmedlist, getCartItems, removeFromCart} from '../../services/programService';
import { ProgramLoader } from '../reuse/SVG';
import Cartitems from './cartItems';
import { resHandle } from '../../helper';


const LoginUserConfirmedCartItems =props => {

const [cartItems, setCartItems] = useState([]);
const[loader, setloader] = useState(true);
const[erroscode, setErroscode] = useState(false);

useEffect(() => {
    confirmedlist().then((res)=>{
        if(res.data.statusCode===0)
        {
            setCartItems([]);
            setloader(false); 
            setErroscode(true)           
        }
        else
        {
        setCartItems(res.data.responseData.result);
        setloader(false);
        }
    })
},[])  

const removeCartItem = async (cartId) =>{
    const removeItem = await removeFromCart(`/?cartId=${cartId}`);
    try {
        // setDetailsConfirmed(isDetailsConfirmed - 1);
        const res = await getCartItems()
        const {status, data : {result}} = resHandle(res)
        if (status) {
            props.getitemsincart(result.length);
            setCartItems(result);
            // setMiniLoader({ [cartId]: false });
        }
    }
    // try{
    //     let cartprducts = [...cartItems];
    //     let newcat = cartprducts.filter(cartitem => cartitem._id !== cartId);
    //     setCartItems(newcat);
    // }
    catch(err){} 
}


return(
    <React.Fragment>
        {
            loader ?  <div className="program_loader"><ProgramLoader /></div> :
            cartItems.map((cartitem, i) => (
                    <Cartitems key={i} Item={cartitem} RemoveFromCart ={removeCartItem} ConfirmedList={"Yes"} />  
        ))
        }       
    </React.Fragment>
)

}

export default LoginUserConfirmedCartItems;