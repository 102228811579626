import React, { useState, useRef, Fragment, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { TimePicker, notification } from 'antd'
import { Modal, Tooltip, OverlayTrigger, Container, Row, Col } from 'react-bootstrap';
import {
    CompositeDecorator,
    Editor,
    EditorState,
    RichUtils,
    convertToRaw
} from 'draft-js';
import { CustomDatePicker } from '../reuse/FormControls';
import { stateToHTML } from "draft-js-export-html";

import { messageProgramService, isMessageProgramExistService } from '../../services/programService';
import { getprogramdatesinCart } from '../../helper';
import { programDatesAction } from '../../store/ui';
import RewardPointsImg from '../../assets/img/rewardPoints.png';
import { getProgramBookingDetail } from '../../services/directorServices';



const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
}


const MyLink = props => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={url}>
            {props.children}
        </a>
    );
};

const CampCard = props => {
    const [msgModal, setMsgModal] = useState(false);

    const myRef = useRef();
    const [showURLInput, setShowURLInput] = useState(false);
    const [urlValue, setUrlValue] = useState('');
    const [messageErr, setMessageErr] = useState('');
    const [subject, setSubject] = useState('');
    const [messageType, setMessageType] = useState([]);
    const [messageTypeErr, setMessageTypeErr] = useState('');
    const [saveAsDefault, setSaveAsDefault] = useState(false);
    const [msgDate, setMsgDate] = useState();
    const [msgTime, setMsgTime] = useState();
    const [msgDateErr, setMsgDateErr] = useState('');
    const [msgTimeErr, setMsgTimeErr] = useState('');
    const [loader, setLoader] = useState(false);
    const decorator = new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: MyLink,
        },
    ]);

    const [editorState, setEditorState] = useState(EditorState.createEmpty(decorator));


    const {
        _id,
        programName,
        availableSlots,
        programCode,
        programImage,
        startDate,
        endDate,
        checkInTime,
        checkOutTime,
        noOfSlots,
        byDates,
        additionalDates
    } = props.CampDetails;




    const promptForLink = e => {
        e.preventDefault();
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = editorState.getSelection().getStartKey();
            const startOffset = editorState.getSelection().getStartOffset();
            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
            let url = '';
            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey);
                url = linkInstance.getData().url;
            }
            setShowURLInput(true);
            setUrlValue(url);
        }
    }



    const removeLink = e => {
        e.preventDefault();
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            setEditorState(RichUtils.toggleLink(editorState, selection, null))
        }
    }




    const confirmLink = e => {
        e.preventDefault();
        const contentState = editorState.getCurrentContent();

        const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            { url: urlValue }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        // Apply entity
        let nextEditorState = EditorState.set(editorState,
            { currentContent: contentStateWithEntity }
        );

        // Apply selection
        nextEditorState = RichUtils.toggleLink(nextEditorState,
            nextEditorState.getSelection(), entityKey
        );

        setEditorState(nextEditorState);
        setShowURLInput(false);
        setUrlValue('');

    }


    const onLinkInputKeyDown = e => { if (e.which === 13) { confirmLink(e); } }


    const handleTypeChange = e => {
        let tempVal = parseInt(e.target.value)
        setMessageTypeErr('');
        let tempMessageType = [...messageType];

        if(tempMessageType.includes(tempVal)){
            let index = tempMessageType.indexOf(tempVal);
            tempMessageType.splice(index, 1);
            setMessageType(tempMessageType);
        }else{
            tempMessageType.push(tempVal);
            setMessageType(tempMessageType);
        }
    }


    const handleScheduleDate = e => {
        setMsgDateErr('');
        setMsgDate(moment.utc(e._d).format('MM/DD/YYYY'))
    }


    const handleScheduleTime = e => {
        setMsgTimeErr('');
        setMsgTime(moment(e._d).format('h:mm a'))
    }



    const handleValidate = () => {
        let validate = true;

        if(messageType.length === 0){
            validate = false;
            setMessageTypeErr('Message type is required');
        }

        // if(subject === ''){
        //     validate = false;
        //     setSubjectErr('Subject is required');
        // }


        const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
        const rowValue = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

        if (rowValue.length <= 1) {
            setMessageErr('Message is required');
            validate = false
        }


        // if (msgDate === '' || msgDate === null || msgDate === "undefined") {
        //     setMsgDateErr('Date is required');
        //     validate = false
        // }


        // if (msgTime === '' || msgTime === null || msgTime === "undefined") {
        //     setMsgTimeErr('Time is required');
        //     validate = false
        // }

        return validate
    }
    

    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            setLoader(true);
            let params = {
                programId: _id,
                messageType: JSON.stringify(messageType),
                saveAsDefault: saveAsDefault ? 1 : 0,
                subject,
                message: stateToHTML(editorState.getCurrentContent()),
                scheduleDateTime: msgDate && msgTime ? new Date(`${msgDate} ${msgTime}`).getTime() : msgDate ? new Date(`${msgDate}`).getTime() : new Date(`${msgTime}`).getTime(),
            }

            messageProgramService(params).then(res => {
                if(res?.data?.statusCode){
                    closeModal();
                    notification.open({
                        description:
                            'Message Save Successfully',
                    });
                }

                setLoader(false);
            })
        }
        

    }


    const closeModal = () => {
        setMsgModal(false);
        setSubject('');
        setMsgDate('');
        setMsgTime('');
        setLoader(false);
    }


    const sendMessage = () => {
        setMsgModal(true);
        let query = `?programId=${_id}`;
        isMessageProgramExistService(query).then(res => {
            if(res?.data?.statusCode){
               setMessageType(res?.data?.responseData?.messageType || []);
               setSaveAsDefault(res?.data?.responseData?.saveAsDefault || false)
            }
        })
    }

    return(
        <div className="d-flex align-items-center justify-content-between dir_camp_card">

            <Modal
                show={msgModal}
                animation={false}
                dialogClassName="msg_modal"
                size="lg"
                centered
            >
                <Modal.Body>
                    <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                    
                    <form className="p-4 pt-5" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6 _border_right">
                                <div className="form-group mt-3">
                                    <p className="mt-3">Message Type</p>
                                    <div className="check_box_wrapper cm_dark_check mb-3">
                                        <input
                                            type="checkbox"
                                            checked={messageType.includes(1)}
                                            name="1"
                                            onChange={handleTypeChange}
                                            value="1" />
                                        <span className="check_box"><span></span> Phone Text</span>
                                    </div>
                                    <div className="check_box_wrapper cm_dark_check mb-3">
                                    <input
                                            type="checkbox"
                                            name="2"
                                            checked={messageType.includes(2)}
                                            onChange={handleTypeChange}
                                            value="2" />
                                        <span className="check_box"><span></span> App Message</span>
                                    </div>
                                    <div className="check_box_wrapper cm_dark_check mb-3">
                                    <input
                                            type="checkbox"
                                            name="3"
                                            checked={messageType.includes(3)}
                                            onChange={handleTypeChange}
                                            value="3" />
                                        <span className="check_box"><span></span> Email</span>
                                    </div>
                                    <div className="check_box_wrapper cm_dark_check mb-3">
                                        <input
                                            type="checkbox"
                                            name="saveAsDefault"
                                            checked={saveAsDefault} onChange={e => setSaveAsDefault(e.target.checked)} />
                                        <span className="check_box"><span></span> Save above as default</span>
                                    </div>


                                    {messageTypeErr ? <span className="err_msg">{messageTypeErr}</span> : ''}
                                    
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mt-3">Schedule Send for Later</p>


                                <div className="form-group">
                                    <label>Date</label>
                                    <CustomDatePicker
                                        error={msgDateErr}
                                        disabledDate={current => current && current < moment.utc(new Date().getTime() - 86400000 )}
                                        change={handleScheduleDate} />
                                </div>

                                <div className="form-group">
                                    <label>Time</label>
                                    <TimePicker
                                        use12Hours
                                        format="h:mm a"
                                        onChange={handleScheduleTime}
                                        className={`form-control ${msgTimeErr ? 'is-invalid' : ''}`}/>
                                    {msgTimeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{msgTimeErr}</Tooltip>}><span className="error_tootltip_label" /></OverlayTrigger> : ''}
                                </div>
                            </div>
                        </div>


                        {
                            messageType.includes(3) ? <div className="form-group mt-4">
                            <label>Subject</label>
                            <textarea value={subject} onChange={e => setSubject(e.target.value)} className="form-control"></textarea>
                        </div> : ''
                        }
                        



                        <div className={`form-group editer_wrapper`}>

                            <label>Message</label>

                            <div className="editer_btns">
                                <button className="btn btn-sm btn-secondary ps-4 pe-4" type="button" onMouseDown={promptForLink}>Add Link</button>
                                <button className="btn btn-sm btn-info ms-3 ps-4 pe-4" type="button" onMouseDown={removeLink}>Remove Link</button>
                            </div>

                            <Editor
                                editorState={editorState}
                                onChange={e => (setEditorState(e), setMessageErr(''))}
                                placeholder="Write Description here…"
                                ref={myRef}
                            />

                            {messageErr ? <span className="err_msg">{messageErr}</span> : ''}


                            <Modal
                                centered
                                dialogClassName="remove_header_line"
                                show={showURLInput}
                                onHide={() => setShowURLInput(false)}>
                                <Modal.Body>

                                    <span className="modal_close" onClick={() => setShowURLInput(false)}><i className="icon-cross" /></span>
                                    <div className="p-5">
                                        <label>Type or paste your link in the text box</label>
                                        <input
                                            onChange={e => setUrlValue(e.target.value)}
                                            type="text"
                                            className="form-control"
                                            value={urlValue}
                                            placeholder="https://www.example.com/"
                                            onKeyDown={onLinkInputKeyDown}
                                        />
                                        <button disabled={urlValue ? false : true} className="btn btn-primary mt-4 btn-block" onMouseDown={confirmLink}> Confirm </button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>

                        <div className="text-center">
                            <button disabled={loader} type="submit" className="btn btn-primary">Send</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>



            <div className="d-flex align-items-center">
             <Link to={`/bookings/${_id}`} className="d-flex align-items-center">
                <div className="">
                    <img src={programImage ? programImage : RewardPointsImg} alt='reward' loading="lazy" />
                </div> 
                <div className="">
                    <h3>{programName}</h3>
                    <p>Timing : {`${checkInTime} - ${checkOutTime}` }</p>
                    <p>Date : {moment.utc(startDate).format('DD MMMM, YYYY')} <br/> {moment.utc(endDate).format('DD MMMM, YYYY')} </p>
                    <p>Program Code: {programCode}</p>
                </div>
            </Link>  
            </div> 

            <div className="d-flex align-items-center">
                {byDates === 1 ? (
                    <Col className='ps-3 pe-3 participant_count'>
                        {additionalDates.slice(0, 3).map(item => (
                            <Fragment>
                                <span className={`cm_selected_date`} key={item._id}>
                                    {getprogramdatesinCart(item.startDate, item.endDate)}
                                    <b title='Number of available slots'>{item.slots}</b>
                                </span>
                                <br />
                            </Fragment>
                        ))}
                        {additionalDates.length > 3 ? <span className='my_view_all' onClick={() => props.programDatesAction(additionalDates)}>View All Dates</span> : ''}
                    </Col>
                ) : (
                <Col className="cm_slots">
                    <p>Available Slots: <h4>{availableSlots}</h4></p>
                </Col>
                )}
                
            </div > 

            <div className="d-flex flex-row flex-lg-column">
                <small>Participants: {noOfSlots}</small>
                {!(props.UserType === 4 && !props.messageCamper ) && 
                <button onClick={sendMessage} className="btn btn-secondary btn_sm m-2 ">Message</button>
                }
            </div> 
        </div>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { UserType } = state.user.userProfile;
    return {
        state,
        UserType
    };
}

const mapDispatchToProps = dispatch => ({
    programDatesAction: (params) => dispatch(programDatesAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampCard);

  
