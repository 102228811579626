import React, { useEffect, useState, useReducer, Fragment } from 'react';
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input/input';
import { InputNumber,} from 'antd';
import { connect } from 'react-redux';
import { AES } from 'crypto-js';
import { Link } from 'react-router-dom';
import settings from '../../config/settings';

import {
    viewProgramDetail,
    getCustomerCardsService,
    donateService,
    deleteCardService,
    markPrimaryCard
} from '../../services/programService';
import { accountTypes, isValidEmail } from '../../helper';
import SecureInput from '../payment/SecureInput';
import { getTaxForPrice } from '../bookings/bookingHelper';
import { getCampDetailService } from '../../services/userServices';
import AddNewCard from '../payment/AddNewCard';
import CreditCard from '../payment/CreditCard';
import ConfirmationModal from '../payment/ConfirmationModal';
import { ProgramLoader } from '../reuse/SVG';
import InfoModal from '../common/Modals/InfoModal';
import CustomDropDown from '../reuse/UIComponents/CustomDropDown';


const addZero = num => {
    if( num.toString().length < 2 )
    return "0" + num;
    return num.toString();
}


const Donate = props => {
    const programId = props.match.params.programId;
    const [programName, setProgramName] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [sucessModal, setSucessModal] = useState(false);
    const [campCode, setCampCode] = useState('');
    const [campId, setCampId] = useState('');
    const [customerProfileId, setCustomerProfileId] = useState('');
    const [cardList, setCardList] = useState([]);  
    const [paymentMethod, setPaymentMethod] = useState(1); 
    const [customerPaymentProfileId, setCustomerPaymentProfileId] = useState('');
    const [customerPaymentProfileIdError, setCustomerPaymentProfileIdError] = useState('');  
    const [customerPaymentProfileIdErr, setCustomerPaymentProfileIdErr] = useState('');
    const [isNewCard, setIsNewCard] = useState(false);
    const [nextPage, setNextPage] = useState(1);

    const [creditCardRate, setCreditCardRate] = useState({});
    const [achTransferRate, setAchTransferRate] = useState({});
    const [holdPrimaryId, setHoldPrimaryId] = useState('');
    const [isSwitchPrimaryCard,setIsSwitchPrimaryCard] = useState(false);
    const [isDeletePopup,setIsDeletePopup] = useState(false);
    const [deleteCardToken,setDeleteCardToken] = useState('');
    const [primaryCardId,setPrimaryCardId] = useState('');
    const [cardLoader, setCardLoader] = useState(false);


    const [userInput, setUserInput] = useReducer((state, newState) => ({...state, ...newState}),
        {
            firstName: props?.userProfile?.name || "",
            lastName: props?.userProfile?.lastName || "",
            donerEmail: props?.userProfile?.email || "",
            phoneNo: props?.userProfile?.phoneNumber || "",
            amount: '',
            message: '',
            bankAccountType: '',
            bankAccountNum: '',
            routingNumber: '',
            nameOnAccount: '',

            address: props?.userProfile?.address || "",
            city: '',
            state: '',
            country: '',
            zip: ''
        }
    );


    

    const [error, setError] = useReducer((state, newState) => ({...state, ...newState}),
        {
            name: '',
            donerEmail: '',
            phoneNo: '',
            amount: '',
            bankAccountNum: '',
            routingNumber: '',
            nameOnAccount: '',
            bankAccountType: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            state: '',
            country: '',
            zip: ''
        }
    );



    const handleChange = e => {
        setUserInput({[e.target.name]: e.target.value});
        setError({[e.target.name]: ''});
    }


    const handlePhoneNoChange = e => {
        setUserInput({phoneNo: e});
        setError({phoneNo: ''});
    }


    const handleNumberChange = (e, name) => {
        setUserInput({[name]: e});
        setError({[name]: ''});
    }


    const handleNextPage = () => {
        if(handleValidate()){
            setNextPage(2);
        }
    }


    const handleValidate = () => {
        let validate = true;
        let { 
            donerEmail,
            phoneNo,
            amount,
            bankAccountNum,
            routingNumber,
            nameOnAccount,
            bankAccountType,
            firstName,
            lastName,
            address,
            city,
            state,
            zip,
            country,
        } = userInput;



        if(donerEmail ===''){
            setError({donerEmail: 'Email is required'});
            validate = false
        }else if(!isValidEmail(donerEmail)){
            setError({donerEmail: 'Email is not valid'});
            validate = false
        }

        if(phoneNo === ''){
            setError({phoneNo: 'Phone number is required'});
            validate = false
        }

        if(amount === ''){
            setError({amount: 'Amount is required'});
            validate = false
        }

        if(firstName === ''){
            setError({firstName: 'First name is required'});
            validate = false
        }else if(firstName.length > 50){
            setError({firstName: 'First name is to large max limit 50'});
            validate = false;
        }

        if(lastName === ''){
            setError({lastName: 'Last name is required'});
            validate = false
        }

        if(address === ''){
            setError({address: 'Address is required'});
            validate = false
        }
        if(city === ''){
            setError({city: 'City is required'});
            validate = false
        }
        if(state === ''){
            setError({state: 'State is required'});
            validate = false
        }
        if(zip === ''){
            setError({zip: 'Zip is required'});
            validate = false
        }
        if(country === ''){
            setError({country: 'Country is required'});
            validate = false
        }

        if(paymentMethod === 4){
            if(bankAccountType === ''){
                setError({bankAccountType: 'Account type is required'});
                validate = false
            }
            if(bankAccountNum === ''){
                setError({bankAccountNum: 'Account number is required'});
                validate = false
            }
            if(routingNumber === ''){
                setError({routingNumber: 'Routing number is required'});
                validate = false
            }
            if(nameOnAccount === ''){
                setError({nameOnAccount: 'name is required'});
                validate = false
            }
        }
    

        return validate;
    }


    useEffect(() => {
       let params = `?programId=${programId}`; 
        viewProgramDetail(params).then(res => {
            if(res?.data?.statusCode){
                setProgramName(res.data.responseData.result.programData.campName);
                setCampCode(res.data.responseData.result.programData.campCode);
                setCampId(res.data.responseData.result.programData.campId);
                getTaxs(res.data.responseData.result.programData.campCode); 
            }
        }) 
    }, [])

    const getTaxs = (campCode) => {
        getCampDetailService(`?campCode=${campCode}`).then(res => {
            if(res?.data?.statusCode){
              let _data = res.data.responseData.result;
              setCreditCardRate(_data.creditCardRate || {});
              setAchTransferRate(_data.achTransferRate || {});
            }
        })
    }


    const getCards = () => {
        setCardLoader(true)
        let query = `?isWeb=1&campCode=${campCode}`;
        getCustomerCardsService(query).then(res => {
            if(res?.data?.statusCode){
                setCardList(res.data.responseData?.result || []);
                setCardLoader(false)
            }else{
                setCardList([]); 
                setCardLoader(false)
            }
        })
    }
    
    
    useEffect(() => {
        if(campCode){
            getCards();
        }
       
    }, [props.isNewCardAdd, campCode])



    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            setBtnLoader(true);
            let {
                firstName,
                lastName,
                donerEmail,
                phoneNo,
                amount,
                bankAccountNum,
                routingNumber,
                nameOnAccount,
                bankAccountType,
            } = userInput;

            let params = '';
            const name = `${firstName} ${lastName}`

            if(paymentMethod === 1){
                params = {
                    name,
                    isWeb: 1,
                    campId,
                    email: donerEmail,
                    phoneNo,
                    message: userInput.message,
                    amount,
                    applicationFeeAmount : getTaxForPrice(creditCardRate,amount),
                    authPayment: JSON.stringify({
                        paymentType: paymentMethod,
                        customerProfileId,
                        token: customerPaymentProfileId
                    })
                    
                }
            }


            if(paymentMethod === 4){
                const __routing_number = AES.encrypt(routingNumber, settings.api.mySecretKey).toString();
                const __bankAccountNum = AES.encrypt(bankAccountNum, settings.api.mySecretKey).toString();
                params = {
                    name,
                    isWeb: 1,
                    campId,
                    email: donerEmail,
                    phoneNo,
                    message: userInput.message,
                    amount,
                    applicationFeeAmount : getTaxForPrice(achTransferRate,amount),
                    authPayment: JSON.stringify({
                        paymentType: paymentMethod,
                        account_number: __bankAccountNum,
                        routing_number: __routing_number,
                        account_owner_name: nameOnAccount,
                        account_type:bankAccountType.value,
                    })
                    
                }
            }

            donateService(params).then(res => {
                setBtnLoader(false);
                if (res.data.statusCode === 1) {
                    setSucessModal(true);
                }
            })


        }
    }


    const handlePrimaryCard = (id) => {
        setHoldPrimaryId(id)
        setIsSwitchPrimaryCard(true)
    }
    const handleDeleteCard = (cardId) => {
        setIsDeletePopup(true); 
        setDeleteCardToken(cardId)
    }
    const deleteCardAction = () => {
        const params = {
        userId:props.userProfile._id,
        cardToken :deleteCardToken,
    }
    //added api call to remove the card using card id. 
    deleteCardService(params).then( res => {
        if(res.status){
            getCards()
            setCardList(cardList.filter(card =>card.card.id !== deleteCardToken));
            setIsDeletePopup(false)
        }
    }).catch(error => {
        console.log(error)
    })
    .finally(() => {
        setIsDeletePopup(false)
    }
    )
    
  }
    const handlePrimaryCardAction = () => {
        
        const params = {
            cardToken: holdPrimaryId,
            userId: props.userProfile._id,
        }
        markPrimaryCard(params).then( res => {
            if(res.status){
                setIsSwitchPrimaryCard(false)
                getCards();
                setPrimaryCardId(holdPrimaryId);
            }
        }).catch(error => {
            console.log(error)
        })
        .finally(() => {
            setIsSwitchPrimaryCard(false)
        }
        )
      }
    return(
        <div className="body_container donation_container">
        <ConfirmationModal
            show={isDeletePopup}
            onClose={() => setIsDeletePopup(false)} 
            dialougeMessage={"Are you sure you want to remove this card ?"}
            onConfirm={deleteCardAction}
        />
        <ConfirmationModal
            show={isSwitchPrimaryCard}
            onClose={() => setIsSwitchPrimaryCard(false)} 
            dialougeMessage={"Are you sure you want to change your primary card ?"}
            onConfirm={handlePrimaryCardAction}
        />

        <Modal
            show={sucessModal}
            animation={false}
            dialogClassName="folder_model"
            size="lg"
            centered
        >
            <Modal.Body>
                <div className="text-center p-5 pickup_modal_body">
                    <p>Thanks for donation</p>
                </div>
                <div className="text-center p-5">
                    <Link to={`/camps/${programId}`} style={{ width: '220px' }} className="btn btn-primary ms-3">Back to Program Detail</Link>
                </div>
            </Modal.Body>
        </Modal>




            <div className="cm_banner_wrapper">
                <div className="cm_banner_text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h2>Donate Now</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        
            <div className="container mt-5 pb-5 donate_wrap">
                <div className="row donation_form  text-white">
                    <div className="col-lg-2" />

                    <div className="col-lg-8">
                        <h3 className="text-center">Make a difference <span>Donate Today!</span></h3>
                        <p className="text-center mb-5">This donation will go to the
                            camp/organization listed below. Charitable donations are an awesome way
                            to give back to the community and allow more people to enjoy the camp
                            experience. Donations are tax deductible and tax documents will be sent to
                            the email below. The camp providers want to thank you for your gracious
                            donations. Feel free to leave a note of encouragement for them below as
                            well!
                        </p>
                    
                    
                        <h4 className="mt-3 mb-4">Camp Name : <span>{programName}</span></h4>

                        <h4 className="mb-4 color_red">Fill your details</h4>

                        <form onSubmit={handleSubmit}>


                            {
                                nextPage === 1 ? <Fragment>
                                    <div className="row">
                                        <div className="col-md-6 form-group cm_err_toltip">
                                            <label>First Name</label>
                                            <input
                                                type="text"
                                                name="firstName"
                                                 placeholder='Please enter your first name.....'
                                                value={userInput.firstName}
                                                className={`form-control ${error.firstName ? 'is-invalid' : ''}`}
                                                onChange={handleChange} />

                                            {error.firstName ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.firstName}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>

                                        <div className="col-md-6 form-group cm_err_toltip">
                                            <label>Last Name</label>
                                            <input
                                                type="text"
                                                name="lastName"
                                                 placeholder='Please enter your last name.....'
                                                value={userInput.lastName}
                                                className={`form-control ${error.lastName ? 'is-invalid' : ''}`}
                                                onChange={handleChange} />

                                            {error.lastName ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.lastName}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div> 
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <label>Email</label>
                                            <input
                                                type="email"
                                                placeholder='Please enter your e-mail.....'
                                                className={`form-control ${error.donerEmail ? 'is-invalid' : ''}`}
                                                name="donerEmail"
                                                value={userInput.donerEmail}
                                                onChange={handleChange} />

                                            {error.donerEmail ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.donerEmail}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>

                                        <div className="col-md-6 form-group">
                                            <label>Phone Number</label>
                                            <PhoneInput
                                                country="US"
                                                placeholder='Please enter your phone number.....'
                                                className={`form-control ${error.phoneNo ? 'is-invalid' : ''}`}
                                                onChange={handlePhoneNoChange}
                                                value={userInput.phoneNo}
                                                maxLength={16}
                                                />
                                        
                                            {error.phoneNo ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.phoneNo}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-6 form-group">
                                                <label>Donation Amount</label>
                                                <InputNumber
                                                    placeholder='Please enter donation amount.....'
                                                    className={`form-control ${error.amount ? 'is-invalid' : ''}`}
                                                    value ={userInput.amount}
                                                    onChange={e => handleNumberChange(e, 'amount')}
                                                />


                                                {error.amount ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.amount}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>

                                    </div>


                                        <div className="form-group">
                                        <label>Message [Optional]</label>
                                        <textarea
                                            placeholder="Write a message........"
                                            className="form-control"
                                            name="message"
                                            value={userInput.message}
                                            onChange={handleChange} />
                                        </div>


                                <div className="row">
                                    
                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>Address</label>
                                        <input
                                            placeholder='Please enter your Address.....'
                                            type="text"
                                            name="address"
                                            value={userInput.address}
                                            className={`form-control ${error.address ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.address ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.address}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>

                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>City</label>
                                        <input
                                            placeholder='Please enter your city.....'
                                            type="text"
                                            name="city"
                                            value={userInput.city}
                                            className={`form-control ${error.city ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.city ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.city}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>
                                    
                                </div>



                                <div className="row">
                                    
                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>STATE / PROVINCE</label>
                                        <input
                                            placeholder='Please enter your state.....'
                                            type="text"
                                            name="state"
                                            value={userInput.state}
                                            className={`form-control ${error.state ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.state ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.state}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>

                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>ZIP / POSTAL CODE</label>
                                        <input
                                            placeholder='Please enter your postal code.....'
                                            type="text"
                                            name="zip"
                                            value={userInput.zip}
                                            className={`form-control ${error.zip ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.zip ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.zip}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>
                                    
                                </div>



                                <div className="row">
                                    
                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>Country</label>
                                        <input
                                            type="text"
                                            placeholder='Please enter your country name.....'
                                            name="country"
                                            value={userInput.country}
                                            className={`form-control ${error.country ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.country ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.country}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>
                                    
                                </div>
                                </Fragment>
                                :
                                
                                <Fragment>
                                     <h4 className="mb-4">Select Payment Method</h4>
                                        <p>
                                            <span className="cm_radio_wrap ms-0">
                                                <input type="radio" onChange={() => setPaymentMethod(1)} checked={paymentMethod === 1} name="paymentMethod" />
                                                <span />
                                                Credit Card / Debit Card
                                            </span>
                                        </p>
                                        {cardLoader ?  <div className="program_loader"><ProgramLoader /></div> :
                                            paymentMethod === 1 ? <Fragment>

                                            <ul className="card_list">
                                                {cardList.length ? cardList.map(item => (
                                                    <CreditCard
                                                        item={item}
                                                        customerPaymentProfileId={customerPaymentProfileId}
                                                        setCustomerPaymentProfileId={setCustomerPaymentProfileId}
                                                        setCustomerPaymentProfileIdErr={setCustomerPaymentProfileIdErr}
                                                        handlePrimaryCard={handlePrimaryCard}
                                                        handleDeleteCard={handleDeleteCard}
                                                />

                                                    )) : ''
                                            }
                                        </ul>

                                                        {customerPaymentProfileIdError ? <span className="cm_card_error">{customerPaymentProfileIdError}</span> : ''}

                                                        {
                                                            isNewCard ? (
                                                                <AddNewCard
                                                                    setIsNewCard={setIsNewCard}
                                                                    email={props.userProfile.email}
                                                                    userId={props.userProfile._id}
                                                                    getCards={getCards}
                                                                    setResErr={() => ""}
                                                                    campCode={campCode}
                                                                    eyeColor={'#842014'}
                                                                />
                                                            ) : <button className="btn btn-primary" onClick={() => setIsNewCard(true)}>Add New Card</button>
                                                        }
                                            </Fragment> : ''
                                        }


                                        <p>
                                            <span className="cm_radio_wrap my-2 ms-0">
                                                <input type="radio" onChange={() => setPaymentMethod(4)} checked={paymentMethod === 4} name="paymentMethod" />
                                                <span />
                                                Internet Banking
                                            </span>
                                        </p>



                                        {
                                            paymentMethod === 4 ? <Fragment>

                                                <div className="card_inputs">
                                                <CustomDropDown
                                                        className="form-group"
                                                        label={'Select Account Type'}
                                                        error={error.bankAccountType }
                                                        onChange={e => handleNumberChange(e, 'bankAccountType')}
                                                        options={accountTypes}
                                                        divClass="form-group"
                                                    />

                                                <div className="form-group">
                                                        <label>Routing Number</label>
                                                        <input
                                                            type="number"
                                                            name='routingNumber'
                                                            className={`form-control ${error.routingNumber ? 'is-invalid' : ''}`}
                                                            value ={userInput.routingNumber}
                                                            placeholder="Account Number"
                                                            onChange={handleChange}
                                                        />

                                                        {error.routingNumber ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.routingNumber}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                    
                                                    </div>
                                            
                                                    <div className="form-group">
                                                        <label>Account Number</label>
                                                        <SecureInput
                                                            eyeColor={'#842014'}
                                                            type='number'
                                                            error={error.bankAccountNum }
                                                            value={userInput.bankAccountNum}
                                                            name={"bankAccountNum"}
                                                            placeholder={"Account Number"}
                                                            maxLength={30}
                                                            onChange={handleChange}
                                                        />                                             

                                                        {error.bankAccountNum ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.bankAccountNum}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                    
                                                    </div>


                                                    <div className="form-group">
                                                        <label>Account Holder Name</label>
                                                    
                                                            <input
                                                                type="text"
                                                                className={`form-control ${error.nameOnAccount ? 'is-invalid' : ''}`}
                                                                name="nameOnAccount"
                                                                value={userInput.nameOnAccount}
                                                                onChange={handleChange}
                                                                placeholder="Account Holder Name" />

                                                            {error.nameOnAccount ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.nameOnAccount}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                    </div>




                                                </div>



                                            </Fragment> : ''
                                        }
                                </Fragment>
                            }



                            <div className="text-center">
                                {nextPage === 1 ? <span className="btn btn-primary rounded-0 ps-5 pe-5" onClick={handleNextPage}>Next step</span> : ''}
                                {nextPage === 2 ? 
                                    <Fragment>
                                        <span className="btn btn-dark rounded-0 ps-5 pe-5 me-3" onClick={() => setNextPage(1)}>Back</span>
                                        <button disabled={btnLoader} type="submit" className={`btn btn-primary rounded-0 ${btnLoader ? 'btnLoader' : ''}`} style={{width: '160px'}}>Proceed to Pay</button>
                                    </Fragment>
                                     : ''}
                            </div>

                        </form>

                    </div>
                </div>
            </div>



    </div>
    )
}

const mapStateToProps = state => {
    let { loggedIn, userProfile } = state.user;    
    let { isNewCardAdd } = state.ui;    
    return{
        userProfile,
        loggedIn,
        isNewCardAdd
    }
}

export default connect(mapStateToProps, null)(Donate);