import React, { useState, useReducer, useEffect, Fragment } from 'react';
import { InputNumber, notification, Select, DatePicker } from 'antd';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input/input';
import isEmail from 'validator/lib/isEmail';


import { purchaseGiftCard, giftCardDetailService } from '../../services/giftCardService';
import {  userguest } from '../../services/programService';
import { billingDetailService } from '../../services/otherService'
import { checkStringContainsNumbers,  specialCharactersCheck } from '../../helper';

import SecureInput from '../payment/SecureInput';
import  flagImg from '../../assets/img/united-states-flag-icon.svg';

const { Option } = Select;

const addZero = num => {
    if( num.toString().length < 2 )
    return "0" + num;
    return num.toString();
}

const BuyGiftCard = props => {
    const oneYearFromNow = new Date();
    let history = useHistory();
    const giftCardId = props.match.params.id;
    const [giftCardValue, setGiftCardValue] = useState('');
    const [giftCardData, setGiftCardData] = useState({});
    const [token, setTokan] = useState('');
    const [paymentMethod, setPaymentMethod] = useState(0);  
    const [nextPage, setNextPage] = useState(1);
    const [btnLoader, setBtnLoader] = useState(false);

    
    const [userInput, setUserInput] = useReducer((state, newState) => ({...state, ...newState}),
        {
            message: '',
            noOfCards: '',
            recieverName: '',
            recieverEmail: '',
            bankAccountType: '',
            bankAccountNum: '',
            routingNumber: '',
            nameOnAccount: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            country: '',
        }
    );

    const [error, setError] = useReducer((state, newState) => ({...state, ...newState}),
        {
            noOfCards: '',
            recieverName: '',
            recieverEmail: '',
            bankAccountType: '',
            bankAccountNum: '',
            routingNumber: '',
            nameOnAccount: '',
            giftCardValue: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            country: '',
        }
    );

    const [cardNo, setCardNo] = useState('');
    const [cardNoErr, setCardNoErr] = useState('');
    const [expDate, setExpDate] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [expDateErr, setExpDateErr] = useState('');
    const [cvc, setCvc] = useState('');
    const [cvcErr, setCvcErr] = useState('');
    const [firstName, setFirstName] = useState('');
    const [firstNameErr, setFirstNameErr] = useState('');
    const [lastName, setLastName] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [zipCodeErr, setZipCodeErr] = useState('');



    useEffect(() => {
        giftCardDetailService(giftCardId).then(res => {
            if(res?.data?.statusCode){
                setGiftCardValue(res.data.responseData.result.giftcardValue)
                setGiftCardData(res.data.responseData.result)
            }
        })

        userguest().then(res => {
            setTokan(res.data.responseData.accessToken);
            localStorage.setItem('NonLoginaccessToken', res.data.responseData.accessToken);
            localStorage.setItem('NonLoginUserID', res.data.responseData.userProfile._id);
        })


        billingDetailService().then(res => {
            if(res?.data?.statusCode){
                setUserInput(res.data.responseData.billingDetail)
            }
        })
        
    }, [])


    

    const handleChange = e => {
        setUserInput({[e.target.name]: e.target.value});
        setError({[e.target.name]: ''});
    }


    const handleNumberChange = (e, name) => {
        setUserInput({[name]: e});
        setError({[name]: ''});
    }



    const handleValidate = () => {
        let {
            noOfCards,
            recieverName,
            recieverEmail,
            firstName,
            lastName,
            email,
            phone,
            address,
            city,
            state,
            zip,
            country,
        } = userInput;

        let validate = true;

        if(recieverEmail.trim() === ''){
            setError({recieverEmail: 'Email is required'});
            validate = false
        }else if(!isEmail(recieverEmail)){
            setError({recieverEmail: 'Email is not valid'});
            validate = false
        }


        if(noOfCards === ''){
            setError({noOfCards: 'Number of cards is required'});
            validate = false
        }

        if(recieverName.trim() === ''){
            setError({recieverName: 'Reciever name is required'});
            validate = false
        }else if(recieverName.length > 50){
            setError({recieverName: 'Reciever name is to large max limit 50'});
            validate = false
        }else if(checkStringContainsNumbers(recieverName)){
            setError({recieverName: 'Invalid reciever name'});
            validate = false
        }


        if(giftCardValue === '' || giftCardValue === 'undefined' || giftCardValue === null){
            setError({giftCardValue: 'Gift card value  is required'});
            validate = false
        }

        if(email.trim() === ''){
            setError({email: 'Email is required'});
            validate = false
        }else if(!isEmail(email)){
            setError({email: 'Email is not valid'});
            validate = false
        }

        if(firstName.trim() === ''){
            setError({firstName: 'First name is required'});
            validate = false
        }else if(firstName.length > 50){
            setError({firstName: 'First name is to large max limit 50'});
            validate = false;
        }else if(checkStringContainsNumbers(firstName)){
            setError({firstName: 'Invalid first name'});
            validate = false
        }

        if(lastName.trim() === ''){
            setError({lastName: 'Last name is required'});
            validate = false
        }else if(lastName.length > 50){
            setError({lastName: 'Last name is to large max limit 50'});
            validate = false;
        }else if(checkStringContainsNumbers(lastName)){
            setError({lastName: 'Invalid last name'});
            validate = false
        }

        if(phone.trim() === ''){
            setError({phone: 'Phone is required'});
            validate = false
        }

        if(address.trim() === ''){
            setError({address: 'Address is required'});
            validate = false
        }

        if(city.trim() === ''){
            setError({city: 'City is required'});
            validate = false
        }

        if(state.trim() === ''){
            setError({state: 'State is required'});
            validate = false
        }

        if(zip.trim() === ''){
            setError({zip: 'Zip is required'});
            validate = false
        }else if(specialCharactersCheck(zip)){
            setError({zip: 'Zip is invalid'});
            validate = false
        }

        if(country.trim() === ''){
            setError({country: 'Country is required'});
            validate = false
        }
        return validate;
    }





    const handleValidate2 = () => {
        let {
            bankAccountNum,
            routingNumber,
            nameOnAccount,
            bankAccountType,
        } = userInput;

        let validate = true;
       
        if(paymentMethod === 1){
            if(cardNo === ''){
                setCardNoErr('card number error');
                validate = false;
            }else if (!(cardNo.length === 18 || cardNo.length === 19)) {
                setCardNoErr('card number lenth error');
                validate = false;    
            }else if(cardNo.includes('_')){
                setCardNoErr('card number lenth error');
                validate = false;    
            }
    
            if(expDate === ''){
                setExpDateErr('card expired date error');
                validate = false;
            }
    
            if(zipCode === ''){
                setZipCodeErr('zip code error');
                validate = false;
            }
    
            if(cvc === ''){
                setCvcErr('cvc error');
                validate = false;
            }else if(cvc.includes('_')){
                setCvcErr('cvc lenth error');
                validate = false;    
            }
    
            if(firstName === ''){
                setFirstNameErr('first name error');
                validate = false;
            }else if(firstName.length > 50){
                setFirstNameErr('First name is to large max limit 50')
                validate = false;
            }
        }



        if(paymentMethod === 4){
            if(bankAccountType === ''){
                setError({bankAccountType: 'Account type is required'});
                validate = false
            }
            if(bankAccountNum === ''){
                setError({bankAccountNum: 'Account number is required'});
                validate = false
            }
            if(routingNumber === ''){
                setError({routingNumber: 'Routing number is required'});
                validate = false
            }
            if(nameOnAccount === ''){
                setError({nameOnAccount: 'name is required'});
                validate = false
            }
        }

        return validate;
    }




    const handleNextPage = () => {
        if(handleValidate()){
            setNextPage(2);
        }
    }



    const handleSubmit = e => {
        e.preventDefault();


        if(handleValidate2()){
            setBtnLoader(true);
            let {
                message,
                noOfCards,
                recieverName,
                recieverEmail,
                bankAccountNum,
                routingNumber,
                nameOnAccount,
                bankAccountType
            } = userInput;

            let params = {}

            if(paymentMethod === 1){

                let _cardNo = cardNo.replace(/\s/g,'');
                // const encrypted_cardNo = AES.encrypt(_cardNo, settings.api.mySecretKey).toString();
                // const encrypted_cvc = AES.encrypt(cvc, settings.api.mySecretKey).toString();
                const encrypted_cardNo = _cardNo;
                const encrypted_cvc = cvc;

                params = {
                    message,
                    isWeb: 1,
                    giftCardValue,
                    noOfCards,
                    recieverName,
                    recieverEmail,
                    giftCardId,
                    authPayment: JSON.stringify({
                        paymentType: 2,
                        cardno: encrypted_cardNo,
                        expdate: expDate,
                        month,
                        year,
                        cvc: encrypted_cvc,
                        firstName,
                        lastname: lastName,
                        email: userInput.email,
                        isWeb: 1,
                        zipCode
                    })
                    
                }
            }

            if(paymentMethod === 4){
                params = {
                    message,
                    isWeb: 1,
                    giftCardValue,
                    noOfCards,
                    recieverName,
                    recieverEmail,
                    giftCardId,
                    authPayment: JSON.stringify({
                        paymentType: paymentMethod,
                        bankAccountNum: parseInt(bankAccountNum),
                        routingNumber: parseInt(routingNumber),
                        nameOnAccount,
                        bankAccountType
                    })
                    
                }
            }


        
            purchaseGiftCard(params).then(res => {
                setBtnLoader(false);
                if(res?.data?.statusCode){
                    history.push('/gift-cards');
                    notification.open({
                        message: 'Gift Card',
                        description: res.data.responseData.message
                    });
                }
            })
        }
    }



    const handlePrimarynumber =(e) =>{
        if(e!==undefined){
            if(e.length < 12 || e.length > 12 )
            {}else if(e.length === 12){setUserInput({phone: e}); setError({phone: ''})}
        }
    }

    const handleCardNumberChange = (e) => {
        const inputNumber = e.target.value.replace(/\D/g, '');
        const formattedNumber = inputNumber.replace(/(\d{4})/g, '$1 ').trim();
        setCardNo(formattedNumber);
        setCardNoErr('');
    };

    const handleCvcChange = (e) => {
        const inputNumber = e.target.value.replace(/\D/g, '');
        const formattedNumber = inputNumber.replace(/(\d{4})/g, '$1 ').trim();
        setCvc(formattedNumber);
        setCvcErr('');
      };


    return(
        <div className="body_container">
        <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-lg-2" />

                    <div className="col-lg-8">

                        <h3 className="dark_heading">Buy a Gift Card</h3>

                        <p>This Gift card expires on {moment(giftCardData.toPurchaseDate).format('MM/DD/YYYY')}</p>
                        <form onSubmit={handleSubmit}>
                            

                            <div className="row">

                            {
                                nextPage === 1 ? (
                                    <Fragment>
                                    <div className="col-md-6 form-group cm_err_toltip">
                                    <label>Receiver’s Email ID</label>
                                    <input
                                        onChange={handleChange}
                                        type="email"
                                        name="recieverEmail"
                                        value ={userInput.recieverEmail}
                                        className={`form-control ${error.recieverEmail ? 'is-invalid' : ''}`} />

                                        {error.recieverEmail ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.recieverEmail}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                      
                                </div>

                                <div className="col-md-6 form-group cm_err_toltip">
                                    <label>Receiver Name</label>
                                    <input
                                        onChange={handleChange}
                                        type="text"
                                        name="recieverName"
                                        value ={userInput.recieverName}
                                        className={`form-control ${error.recieverName ? 'is-invalid' : ''}`} />

                                    {error.recieverName ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.recieverName}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>Card Value in $</label>
                                    <InputNumber
                                        min={0}
                                        precision={2}
                                        placeholder="Card Value"
                                        onChange={e => setGiftCardValue(e)}
                                        value ={giftCardValue}
                                        className={`form-control ${error.giftCardValue ? 'is-invalid' : ''}`}
                                    />
                                      {error.giftCardValue ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.giftCardValue}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                </div>

                                <div className="col-md-6 form-group cm_err_toltip">
                                    <label>Number of Cards</label>
                                    <InputNumber
                                        min={0}
                                        precision={0}
                                        step={1}
                                        placeholder="Number of Cards"
                                        className={`form-control ${error.noOfCards ? 'is-invalid' : ''}`}
                                        value ={userInput.noOfCards}
                                        onChange={e => handleNumberChange(e, 'noOfCards')}
                                    />

                                    {error.noOfCards ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.noOfCards}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>

                                <div className="col-md-12 form-group">
                                    <label>Message [Optional]</label>
                                    <textarea
                                        value={userInput.message}
                                        name="message"
                                        onChange={handleChange}
                                        className={`form-control ${error.message ? 'is-invalid' : ''}`} />
                                </div>




                                <div className="col-md-12 form-group">
                                
                                <h4 className="mb-4">Billing Information</h4>


                                <div className="row">
                                    
                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>Billing First Name</label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            value={userInput.firstName}
                                            className={`form-control ${error.firstName ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.firstName ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.firstName}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>

                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>Billing Last Name</label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            value={userInput.lastName}
                                            className={`form-control ${error.lastName ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.lastName ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.lastName}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>
                                    
                                </div>



                                <div className="row">
                                    
                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={userInput.email}
                                            className={`form-control ${error.email ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.email ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.email}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>

                                    <div className="col-md-6 form-group cm_err_toltip">
                                    <label>Phone</label>
                                    <div className="cm_phone_field">
                                        <img className="us_flag" src={flagImg} />
                                        <PhoneInput
                                            country="US"
                                            value={userInput.phone}
                                            className={`form-control ${error.phone ? 'is-invalid' : ''}`}
                                            onChange={handlePrimarynumber}
                                            maxLength={16}
                                            />
                                    </div>

                                        {error.phone ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.phone}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>
                                    
                                </div>



                                <div className="row">
                                    
                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>Address</label>
                                        <input
                                            type="text"
                                            name="address"
                                            value={userInput.address}
                                            className={`form-control ${error.address ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.address ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.address}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>

                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>City</label>
                                        <input
                                            type="text"
                                            name="city"
                                            value={userInput.city}
                                            className={`form-control ${error.city ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.city ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.city}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>
                                    
                                </div>



                                <div className="row">
                                    
                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>STATE / PROVINCE</label>
                                        <input
                                            type="text"
                                            name="state"
                                            value={userInput.state}
                                            className={`form-control ${error.state ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.state ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.state}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>

                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>ZIP / POSTAL CODE</label>
                                        <input
                                            type="text"
                                            name="zip"
                                            value={userInput.zip}
                                            className={`form-control ${error.zip ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.zip ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.zip}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>
                                    
                                </div>



                                <div className="row">
                                    
                                    <div className="col-md-6 form-group cm_err_toltip">
                                        <label>Country</label>
                                        <input
                                            type="text"
                                            name="country"
                                            value={userInput.country}
                                            className={`form-control ${error.country ? 'is-invalid' : ''}`}
                                            onChange={handleChange} />

                                        {error.country ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.country}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>
                                    
                                </div>
                                </div>
                                </Fragment>
                                )

                                :

                                (
                                    <div className="col">
                                         <h4 className="mb-4">Select Payment Method</h4>



<p>
    <span className="cm_radio_wrap ms-0">
        <input type="radio" onChange={() => setPaymentMethod(1)} checked={paymentMethod === 1} name="paymentMethod" />
        <span />
        Credit Card / Debit Card
    </span>
</p>





{
       paymentMethod === 1 ? <Fragment>

                <div className="card_inputs add_card_form">
                    <div className="form-group">
                        <label>Card Number</label>
                            <input
                                type="text"
                                className={`form-control ${cardNoErr ? 'is-invalid' : ''}`}
                                name="cardNumber"
                                placeholder="XXXX XXXX XXXX XXXX"
                                value={cardNo}
                                maxLength={19}
                                onChange={handleCardNumberChange} />

                            {cardNoErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{cardNoErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                    </div>

                    <div className="row">
                        <div className="col form-group">
                            <label>Expiry Date</label>
                            <DatePicker
                                className={`form-control ${expDateErr ? 'is-invalid' : ''}`}
                                format="MM/YY"
                                picker="month"
                                placeholder="MM/YY"
                                onKeyPress={() => setExpDateErr('')}
                                onChange={e => (setMonth(`${addZero(e._d.getMonth() + 1)}`), setYear(`${e._d.getFullYear().toString()}`), setExpDate(`${addZero(e._d.getMonth() + 1)}${e._d.getFullYear().toString()}`), setExpDateErr(''))} />

                            {expDateErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{expDateErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                        <div className="col form-group">
                            <label>CVC</label>
                            {/* <InputMask
                                className={`form-control ${cvcErr ? 'is-invalid' : ''}`}
                                placeholder="XXX"
                                mask="999"
                                onKeyPress={() => setCvcErr('')}
                                onChange={e => setCvc(e.target.value)} /> */}

                            <input
                                type="text"
                                className={`form-control ${cvcErr ? 'is-invalid' : ''}`}
                                name="cvc"
                                placeholder="XXX"
                                value={cvc}
                                maxLength={4}
                                onChange={handleCvcChange} />

                                {cvcErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{cvcErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Cardholder First Name</label>
                        <input
                            type="text"
                            className={`form-control ${firstNameErr ? 'is-invalid' : ''}`}
                            placeholder="Cardholder First Name"
                            onKeyPress={() => setFirstNameErr('')}
                            onChange={e => setFirstName(e.target.value)} />

                            {firstNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{firstNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                    </div>

                    <div className="form-group">
                        <label>Cardholder Last Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Cardholder Last Name"
                            onChange={e => setLastName(e.target.value)} />
                    </div>

                    <div className="form-group">
                        <label>Zip Code</label>
                        <input
                            type="text"
                            className={`form-control ${zipCodeErr ? 'is-invalid' : ''}`}
                            placeholder="Zip Code"
                            onChange={e => (setZipCode(e.target.value), setZipCodeErr(''))} />

                            {zipCodeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{zipCodeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                    </div>
                    </div>
                
       </Fragment> : ''
   }




   <p>
        <span className="cm_radio_wrap ms-0">
            <input type="radio" onChange={() => setPaymentMethod(4)} checked={paymentMethod === 4} name="paymentMethod" />
            <span />
            Internet Banking
        </span>
   </p>



   {
       paymentMethod === 4 ? <Fragment>

        <div className="card_inputs">

        <div className="form-group">
            <label>Select Account Type</label>
            
            <Select
                className={`form-control ${error.bankAccountType ? 'is-invalid' : ''}`}
                placeholder="Select Account Type"
                onChange={e => handleNumberChange(e, 'bankAccountType')}
            >    
                <Option value="checking">Checking</Option>
                <Option value="savings">Savings</Option>
                <Option value="businessChecking">business Checking</Option>
            </Select>

            {error.bankAccountType ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.bankAccountType}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
        </div>

        <div className="form-group">
                <label>Routing Number</label>
                <input
                    type="number"
                    name="routingNumber"
                    className={`form-control ${error.routingNumber ? 'is-invalid' : ''}`}
                    value ={userInput.routingNumber}
                    placeholder="Account Number"
                    onChange={handleChange}
                    //onChange={e => handleNumberChange(e, 'routingNumber')}
                />

                {error.routingNumber ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.routingNumber}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
               
            </div>
    
            <div className="form-group">
                <label>Account Number</label>
                <SecureInput
                    type='number'
                    error={error.bankAccountNum }
                    value={userInput.bankAccountNum}
                    name={"bankAccountNum"}
                    placeholder={"Account Number"}
                    maxLength={30}
                    onChange={handleChange}
                />

                {error.bankAccountNum ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.bankAccountNum}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
               
            </div>





            <div className="form-group">
                <label>Account Holder Name</label>
               
                     <input
                        type="text"
                        className={`form-control ${error.nameOnAccount ? 'is-invalid' : ''}`}
                        name="nameOnAccount"
                        value={userInput.nameOnAccount}
                        onChange={handleChange}
                        placeholder="Account Holder Name" />

                    {error.nameOnAccount ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.nameOnAccount}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
            </div>




        </div>



       </Fragment> : ''
   }
                                    </div>
                                )
                            }

                                

                                <div className="col-md-12 text-center">
                                    {nextPage === 1 ? <span className="btn btn-primary rounded-0 ps-5 pe-5" onClick={handleNextPage}>Next step</span> : ''}
                                    {nextPage === 2 ? 
                                    <Fragment>
                                        <span className="btn btn-dark rounded-0 ps-5 pe-5 me-3 mb-3" onClick={() => setNextPage(1)}>Back</span>
                                        <button disabled={btnLoader} type="submit" className={`btn btn-primary rounded-0 ps-5 pe-5 mb-3 ${btnLoader ? 'btnLoader' : ''}`}>Buy Gift Card</button>
                                    </Fragment>
                                     : ''}
                                    
                                    
                                </div>

                            </div>
                        </form>

                    </div>
                </div>
                </div>
        </div>
    )
}


const mapStateToProps = state => {
    let { loggedIn } = state.user;    
    let { isNewCardAdd } = state.ui;    
    return{
        loggedIn,
        isNewCardAdd
    }
}

export default connect(mapStateToProps, null)(BuyGiftCard);