import React, {useState, useEffect} from 'react';
import {getProgramFolderDetail,updatefolderbooking, getbookingfolderlist} from '../../services/directorServices';
import { Link} from 'react-router-dom';
import { ProgramLoader } from '../reuse/SVG';
import { Modal } from 'react-bootstrap';
import { notification } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import RewardPointsImg from '../../assets/img/rewardPoints.png';

const BookingFolderDetails = (props) => {
    
    const [programsList, setProgramsList]             = useState([]); 
    const [loading, setLoading]                       = useState(true);
    const [programID, setProgramID]                   = useState();
    const [removefrmfolder, setremovefrmfolder]       = useState(false);  
    const [addmore, setAddmore]                       = useState(false);
    const [selectAll, setSelectAll]                   = useState(false);

    const [campersWarning, setCampersWarning]        = useState(false);  
    const [programsIds, setProgramsIds]               = useState([]);  
    const [bookings, setBookings]                     = useState([]);
    const [btnloder, setBtnLoader]                    = useState(false);
    const [folderName, setFolderName]                 = useState('');

    useEffect(()=>{
        let params = `/?folderId=${props.match.params.id}`;
        getProgramFolderDetail(params).then((res)=>{
            if(res?.data?.statusCode)
            {
                
                setProgramsList(res.data.responseData.result)
                setFolderName(res.data.responseData.folderName)
                setLoading(false)
            }
            else
            {
                setLoading(true)
            }
        })
    },[]);

    const removeFromFolderAction = () => {
        let params ={"folderId":props.match.params.id, "programIds": JSON.stringify([programID]), "type": 2}
        updatefolderbooking(params).then(res=>{
            if(res?.data?.statusCode)
            {
                let programs = programsList.filter(item => item._id !==programID);
                setProgramsList(programs);
                notification.open({
                    description:
                      'Program successfully Removed from folder',
                  });
                  setremovefrmfolder(false);
            }
            else
            {

            }
        })
    }



    /***********add more functions */
  
    const selectAllPrograms = () =>{
        let listprograms = [...programsIds];
        listprograms.length = 0;
        bookings.map(item=>{
            listprograms.push(item._id);
        })
        setProgramsIds(listprograms)
    }

    const deselectAllPrograms = () =>{
        let listprograms = [...programsIds];
        listprograms.length = 0;
        setProgramsIds(listprograms)
    } 

    const addMoreAction = () =>{
        setAddmore(true);
        setLoading(true);
        let params = `/?folderId=${props.match.params.id}&campCode=${props.userProfile.campCode}`;
        getbookingfolderlist(params).then((res)=>{
            if(res?.data?.statusCode)
            {
                
                setBookings(res.data.responseData.result)
                setLoading(false)
            }
            else
            {
                setLoading(true)
            }
        })

    }
    const handelPrograms = (e) =>{
        let listprograms = [...programsIds];
        setCampersWarning(false);
        
        if(listprograms.includes(e.target.value))
        {
            setSelectAll(false)
            let newProgramslist = listprograms.filter(item => item!==e.target.value) 
            setProgramsIds(newProgramslist)
        }
        else
        {
            listprograms.push(e.target.value);
            if(listprograms.length === bookings.length){setSelectAll(true)}
            setProgramsIds(listprograms)
        }
    }   

    const addMoreToFolder = () => {
        setLoading(true);
        setBtnLoader(true);
        if(programsIds.length > 0)
        {
        let params ={"folderId":props.match.params.id, "programIds": JSON.stringify(programsIds), "type": 1}
        updatefolderbooking(params).then(res=>{
            if(res?.data?.statusCode)
            {
                let params2 = `/?folderId=${props.match.params.id}`;
                getProgramFolderDetail(params2).then((res)=>{
                    if(res?.data?.statusCode)
                    {
                        
                        setProgramsList(res.data.responseData.result)
                        setLoading(false);
                        setSelectAll(false); setProgramsIds([]); setCampersWarning(false); setAddmore(false)
                        setBtnLoader(false);
                    }
                    else
                    {
                        setLoading(true)
                    }
                })
                notification.open({
                    description:
                      'Program successfully Added to folder',
                  });
                  setremovefrmfolder(false);
            }
            else
            {

            }
        })

    }
    else
    {
        setLoading(false);
        setCampersWarning(true);
        setBtnLoader(false);
    }
    }





    return(
        <div className="body_container pb-4 director_wrapper">    

            
            <Modal
            show={removefrmfolder}
            animation={false}
            dialogClassName="folder_model"
            size="lg"
            centered
            >
            <Modal.Body>
                <span className="modal_close" onClick={()=>{setProgramID(''); setremovefrmfolder(false)}}><i className="icon-cross" /></span>
                <div className="text-center p-5 pickup_modal_body">
                    <p>Are you sure you want to remove this program from folder</p>
                </div>
                <div className="text-center p-5"> 
                <button  onClick={()=>{setProgramID(''); setremovefrmfolder(false)}} style={{width: '120px'}} className="btn btn-light rounded-0">No</button>
                <button  onClick={removeFromFolderAction} style={{width: '120px'}} className="btn btn-primary ms-3">Yes</button>
                </div>
            </Modal.Body>
            </Modal> 

            <div className="container">
                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Folder : {folderName}</h4>

                    {
                            addmore ? 
                             selectAll ?
                        <button className="btn btn-primary" onClick={()=>{deselectAllPrograms(); setSelectAll(false)}}> Deselect All </button>
                        :
                        <button className="btn btn-primary" onClick={()=>{selectAllPrograms(); setSelectAll(true)}}> Select All </button> 
                          :
                          <div className="btn_group">
                            <span onClick={()=>{addMoreAction()}} className="btn btn-primary me-3">Add More</span>
                            <Link to="/bookings" className="btn btn-primary">Back</Link>
                         </div>    
                        }

                </div>

                <div className="row">
                 <div className="col-lg-1" />
                     
                     {
                         !addmore ?  
                     <div className="col-lg-10">
                    {
                        loading ? <div className="program_loader"><ProgramLoader /></div> : 
                        programsList.length > 0 ? 
                        programsList.map(program=>(
                            <div className="d-flex align-items-center justify-content-between dir_camp_card">
                            <div className="d-flex align-items-center">
                              <Link to={`/director/camp/${program._id}`} className="d-flex align-items-center">
                                <div className="">
                                    <img src={program.programImage || RewardPointsImg} />
                                </div> 
                                <div className="">
                                    
                                    <h3>{program.programName}</h3>
                                    <p>Timing : {`${program.checkInTime} - ${program.checkOutTime}` }</p>
                                    <p>Date : {moment.utc(program.startDate).format('DD MMMM, YYYY')} <br/> {moment.utc(program.endDate).format('DD MMMM, YYYY')} </p>
                                    <p>Program Code: {program.programCode}</p>
                                </div>
                               </Link>  
                            </div> 
                    
                    
                            <div className="d-flex align-items-center">
                                  <div className="cm_slots">
                                    <p>Available Slots</p>
                                    <h4>{program.availableSlots}</h4>
                                  </div>
                            </div> 

                            <div className="d-flex align-items-center">
                                <div className="text-center">
                                    <b>Bookings <br/> {program.noOfSlots}</b> <br/>
                                    <Link to={`/bookings/${program._id}`} className="btn btn-primary btn_sm">View</Link>
                                </div> 
                            </div> 
                    
                            <div className="d-flex align-items-center">
                                <div className="text-center">
                                <button className="btn btn-primary" onClick={()=>{ setremovefrmfolder(true); setProgramID(program._id) }}> Remove from folder</button>   
                                </div> 
                            </div>                     
                    
                        </div>
                        )) 
                        :
                        <div className="d-flex align-items-center counselor_card">
                            No Programs Found
                        </div>    
                    }
                    </div>

                    :
                    <div className="col-lg-10">
                     

                     {
                    campersWarning ? <p className="waring_text">Please select the Programs First</p> : ''
                    }
                     {
                        loading ? <div className="program_loader"><ProgramLoader /></div> : 
                        bookings.length > 0 ? 
                        bookings.map(program=>(
                            <div className="d-flex align-items-center justify-content-between dir_camp_card">
                            <div className="d-flex align-items-center">
                              <Link to={`/director/camp/${program._id}`} className="d-flex align-items-center">
                                <div className="">
                                    <img src={program.programImage || RewardPointsImg} />
                                </div> 
                                <div className="">
                                    
                                    <h3>{program.programName}</h3>
                                    <p>Timing : {`${program.checkInTime} - ${program.checkOutTime}` }</p>
                                    <p>Date : {moment.utc(program.startDate).format('DD MMMM, YYYY')} <br/> {moment.utc(program.endDate).format('DD MMMM, YYYY')} </p>
                                    <p>Program Code: {program.programCode}</p>
                                </div>
                               </Link>  
                            </div> 
                    
                    
                            <div className="d-flex align-items-center">
                                {/* <div className="cm_slots">
                                    <p>Balance Outstanding</p>
                                    <h4>5</h4>
                                </div>
                                <div className="cm_slots">
                                    <p>Grouped Campers</p>
                                    <h4>5</h4>
                                </div> */}
                                  <div className="cm_slots">
                                    <p>Available Slots</p>
                                    <h4>{program.availableSlots}</h4>
                                  </div>
                            </div> 
                    
                    
                            <div className="d-flex align-items-center">
                                <div className="text-center">
                                    <div className="check_box_wrapper cm_dark_check">
                                        <input type="checkbox" onChange={(e)=>{handelPrograms(e)}} value={program._id} checked={programsIds.includes(program._id)}  />
                                        <span className="check_box"><span></span></span>
                                    </div>
                                </div> 
                            </div> 
                    
                    
              
                        </div>
                        )) 
                        :
                        <div className="d-flex align-items-center counselor_card">
                            No Programs Found
                        </div>
                    }

                <div className="col-sm-12 text-center mb-5">
                        <Link className="btn btn-light rounded-0 me-2" onClick={()=>{setSelectAll(false); setProgramsIds([]); setCampersWarning(false); setAddmore(false)}} style={{width: '130px'}}>Cancel</Link>
                        <button  onClick={()=>{setBtnLoader(true); addMoreToFolder()}} className={`btn btn-primary rounded-0 ${btnloder ? 'btnLoader' : ''}`} style={{width: '130px'}}>Submit</button>
                       </div>

                    </div>      
                    
                    }



                </div>            

            </div>
        </div>
    )
}


const mapStateToProps = state => {
    let { userProfile } = state.user;
    return {
        userProfile
    };
}
  
export default connect(mapStateToProps)(BookingFolderDetails);
