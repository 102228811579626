export const MAIN_LOADER = 'MAIN_LOADER'
export const MINI_LOADER = 'MINI_LOADER'


export const AUTH_MODAL = 'AUTH_MODAL'
export const IS_LOGIN_MODAL = 'IS_LOGIN_MODAL'
export const IS_OTP_MODAL = 'IS_OTP_MODAL'
export const FORGOT_MODAL = 'FORGOT_MODAL'
export const RESET_PASSWORD_MODAL = 'RESET_PASSWORD_MODAL'
export const CLOSE_RESET_PASSWORD_MODAL = 'CLOSE_RESET_PASSWORD_MODAL'
export const SUCCESS_MODAL = 'SUCCESS_MODAL'
export const PASSWORD_SUCCESS_MODAL = 'PASSWORD_SUCCESS_MODAL'
export const MAP_VIEW_MODAL = 'MAP_VIEW_MODAL'
export const CLEAR_LOADER = 'CLEAR_LOADER'


export const SIGNUP_REQIEST = 'SIGNUP_REQIEST'
export const LOGIN_REQIEST = 'LOGIN_REQIEST'

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const RESEND_OTP_EMAIL = 'RESEND_OTP_EMAIL'
export const RESEND_OTP_EMAIL_LOADER = 'RESEND_OTP_EMAIL_LOADER'
export const CLEAR_ERROR_MSG = 'CLEAR_ERROR_MSG'
export const CHANGE_AUTH_ROLE = 'CHANGE_AUTH_ROLE'
export const SAVE_CABIN_ID = 'SAVE_CABIN_ID'
export const CHECK_IS_TEAMS_EZ = 'CHECK_IS_TEAMS_EZ'
export const WAIVER_MODAL_AND_TYPE = 'WAIVER_MODAL_AND_TYPE'
export const WAIVER_URL = 'WAIVER_URL'
export const WAIVER2_URL = 'WAIVER2_URL'
export const WAIVER3_URL = 'WAIVER3_URL'
export const WAIVER4_URL = 'WAIVER4_URL'
export const CLEAR_WAIVERS = 'CLEAR_WAIVERS'
export const PROGRAM_DATES = 'PROGRAM_DATES'

export const LOGOUT = 'LOGOUT'



export const FEED_MODAL = 'FEED_MODAL'
export const TEMP_EMAIL = 'TEMP_EMAIL'
export const ACTIVITY_MODAL = 'ACTIVITY_MODAL'


export const PICKUP_USER_MODAL = 'PICKUP_USER_MODAL'
export const PICKUP_TAB = 'PICKUP_TAB'


export const PRE_LIST = 'PRE_LIST'
export const ACTIVE_PROGRAM_LOADER = 'ACTIVE_PROGRAM_LOADER'
export const PROGRAM_ERROR = 'PROGRAM_ERROR'
export const GET_ALL_PROGRAM_LIST = 'GET_ALL_PROGRAM_LIST'
export const FILTER_PROGRAMS = 'FILTER_PROGRAMS'
export const VIEW_PROGRAM_DETAIL = 'VIEW_PROGRAM_DETAIL'
export const PROGRAMS_TOTAL_COUNT = 'PROGRAMS_TOTAL_COUNT'
export const SET_SUB_CATEGORY = 'SET_SUB_CATEGORY'
export const CAMPER_LIST = 'CAMPER_LIST'
export const CHILD_ACTION = 'CHILD_ACTION'
export const S_PROGRAM_LIST = 'S_PROGRAM_LIST'


export const F_KEYWORDSEARCH = 'F_KEYWORDSEARCH'
export const F_GENDER = 'F_GENDER'
export const F_AGE_GROUP = 'F_AGE_GROUP'
export const F_GRADE = 'F_GRADE'
export const F_DATES = 'F_DATES'
export const F_PROGRAM_TYPE = 'F_PROGRAM_TYPE'
export const F_WEEKDAY = 'F_WEEKDAY'
export const F_PRICE_RANGE = 'F_PRICE_RANGE'
export const F_EZ_PRO_RATING = 'F_EZ_PRO_RATING'
export const F_MAX_DISTANCE = 'F_MAX_DISTANCE'
export const F_CATEGORIES = 'F_CATEGORIES'
export const F_SUB_CATEGORIES = 'F_SUB_CATEGORIES'
export const F_ADVANCE_SEARCH = 'F_ADVANCE_SEARCH'
export const F_CITY = 'F_CITY'
export const F_RADIUS = 'F_RADIUS'
export const F_FILTER_LAT_LONG= 'F_FILTER_LAT_LONG'
export const F_FILTER_TYPE = 'F_FILTER_TYPE'
export const F_WEEK_DAY = 'F_WEEK_DAY'
export const F_LAT_LONG = 'F_LAT_LONG'
export const F_PAGE_NO = 'F_PAGE_NO'
export const F_CAMPERS = 'F_CAMPERS'
export const F_CAMPERS_LIST = 'F_CAMPERS_LIST'
export const F_CAMPER_DISPLAY_LIST = 'F_CAMPER_DISPLAY_LIST'
export const CLEAR_SINGLE_FILTER = 'CLEAR_SINGLE_FILTER'
export const CLEAR_DATE_FILTER = 'CLEAR_DATE_FILTER'
export const CLEAR_ALL_FILTER = 'CLEAR_ALL_FILTER'
export const IS_ONLINE = 'IS_ONLINE';
export const IS_DISTRICT_CAMP = "IS_DISTRICT_CAMP";

/****** Auto Scheduler actions*******/
export const AS_PROGRAM_LIST = 'AS_PROGRAM_LIST'
export const AS_PROGRAM_ERROR = 'AS_PROGRAM_ERROR'
export const AS_WEEKDATA_LIST = 'AS_WEEKDATA_LIST'


/********Cart Actions*********************/
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const GET_ITEMS_IN_CART = 'GET_ITEMS_IN_CART'
export const PRODUCT_CART_COUNT = 'PRODUCT_CART_COUNT'
export const ACTIVE_STEP_COUNT ='ACTIVE_STEP_COUNT'
export const DETAILS_CONFIRMED_COUNT= 'DETAILS_CONFIRMED_COUNT'
export const IS_ALL_FREE= 'IS_ALL_FREE'

export const NEW_FEED = 'NEW_FEED'
export const VIEW_LOGIN_MODAL = 'VIEW_LOGIN_MODAL'
export const OPEN_ACTIVITY = 'OPEN_ACTIVITY'
export const IS_NEW_CARD = 'IS_NEW_CARD'
export const ADD_FOLDER_MODAL = 'ADD_FOLDER_MODAL'
export const D_PROGRAM_MODAL = 'D_PROGRAM_MODAL'


/***************Chat Actions**************************/
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const GET_MESSAGE = 'GET_MESSAGE'
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const FILTER_CABIN_DATA = 'FILTER_CABIN_DATA'
export const CHECK_IS_COUNSELOR = 'CHECK_IS_COUNSELOR'
export const GET_UNREAD_COUNT = 'GET_UNREAD_COUNT'
export const BOOKING_USER_CHAT = 'BOOKING_USER_CHAT'


export const F_MEDICAL_BOOKING_FILTER = 'F_MEDICAL_BOOKING_FILTER'
export const F_TSIZE_BOOKING_FILTER = 'F_TSIZE_BOOKING_FILTER'
export const F_SSIZE_BOOKING_FILTER = 'F_SSIZE_BOOKING_FILTER'
export const F_FALLERGY_BOOKING_FILTER = 'F_FALLERGY_BOOKING_FILTER'
export const F_FENDER_BOOKING_FILTER = 'F_FENDER_BOOKING_FILTER'
export const F_AGE_BOOKING_FILTER = 'F_AGE_BOOKING_FILTER'

export const INBOX_TAB = 'INBOX_TAB'
export const NEW_MSG_MODAL = 'NEW_MSG_MODAL'
export const GET_CHAT_USER_LIST_CALBACK = 'GET_CHAT_USER_LIST_CALBACK'
export const ACTIVE_USER_CHAT_WINDOW = 'ACTIVE_USER_CHAT_WINDOW'
export const CAMP_CONTACT = 'CAMP_CONTACT'

