import React, { useEffect, useState } from 'react';
import moment from 'moment';
import parse from 'html-react-parser';

import { newsDetailService } from '../../services/otherService';
import { ProgramLoader } from '../reuse/SVG';



const removeHtmlTags = e => {
    return e.replace(/(<([^>]+)>)/gi, " ");
}

const NewsDetail = props => {
    const [loader, setLoader] = useState(true);
    const [news, setNews] = useState('');
    const [list, setList] = useState([]);


    const getNewsDetail = () => {
        let query = `?newsId=${props.match.params.id}`;

        newsDetailService(query).then(res => {
            setLoader(false);
            if(res?.data?.statusCode){
                setNews(res.data.responseData.result);
                setList(res.data.responseData.news);
            }
        })
    }



    useEffect(() => {
        getNewsDetail();
    }, [])

    return(
        <div className="body_container">
            {loader ? <div className="page_loader"><ProgramLoader /></div> : ''}
            <div className="container">
                <div className="section_header mb-3">
                    <h3>Recent News & Events</h3>
                </div>
                
                <div className="row g-0">
                {
                    list.map(item => (
                        <div className="col-lg-4 mb-4" key={item._id}>
                            <div className="cm_card">
                                <div className="card_img_wrap">
                                    <img src={item.image} />

                                    <div className="d-flex justify-content-between align-items-center camp_card_name">
                                        <small><i className="white">{moment(item.created).format('MMM,DD YYYY')}</i></small>
                                    </div>
                                </div>

                                <div className="card_body cm_min_height">
                                <p><strong className="cm_no_breck">{item.title}</strong></p>
                                    <p><small><span className="three_dots">{removeHtmlTags(item.desc)}</span> <a href={`/news-and-events/${item._id}`}>[Read more]</a></small></p>

                                </div>
                            </div>
                            
                        </div>
                    ))
                }
                </div>

                <div className="mt-5 news_detail">
                    <h4 className="mb-4">{news?.title}</h4>

                    <img className="img-fluid mb-3" src={news?.image} />
                    <p>{parse(`<span>${news?.desc}</span>`)}</p>
                </div>
            </div>
        </div>
    )
}

export default NewsDetail;

