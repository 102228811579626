import React, {useState, useEffect} from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { camperinformation } from '../../../services/campersServices';
import {shortsAndTshirtSizes, gradeArr, provinceList, medicalList, allergyList} from '../../../helper';
import {uploadMedia, editCamper} from '../../../services/campersServices';
import {PDFIcon, ProgramLoader} from '../../reuse/SVG';
import CustomMultiSelectDropdownfrom from '../../reuse/CustomMultiSelectDropdown';
import DateSelector from '../../date/DateSelector';
import {  InputTextError } from '../../reuse/FormControls';


const retunDataType = (value) => {
    if(value){
        if(value === 'None'){
            return ['None']
        }else{
            let temp_val = JSON.parse(value)
                if(temp_val.includes('None')){
                    return ['None']
                }else{
                    if(temp_val.length){
                        let isObj = temp_val[0]?.label;
                        if(isObj){
                            return temp_val.map(i => i.temp_val);
                        }else{
                            return temp_val
                        }
                    }
                }
        }
        
    }
}

const EditCamper = (props) =>{
    const[loader, setLoader]                                    = useState(true);
    const[profileimageurl, setProfileImageUrl]                  = useState();
    const[firstName, setFirstName]                              = useState('');
    const[lastName, setLastName]                                = useState('');
    const[dob, setDob]                                          = useState();
    const[gender, setGender]                                    = useState();
    const[address, setAddress]                                  = useState('');
    const[apartment, setAppartment]                             = useState('');
    const[city, setCity]                                        = useState('');
    const[state, setState]                                      = useState('');
    const[zipCode, setZipCode]                                  = useState('');
    const[currentGrade, setCurrentGrade]                        = useState();
    const[tshirtSize, setTshirtSize]                            = useState();
    const[shortsSize, setShortSize]                             = useState();
    const[medicalvalues, setMedical]                            = useState([]);
    const[dietaryGuidelines, setDietaryGuidelines]              = useState();
    const[dietReligious, setDietReligious]                      = useState();
    const[allergyvalues, setAllergy]                            = useState([]);
    const[allergyDeadly, setAllergyDeadly]                      = useState();
    const[medicalInsuranceCompany, setMedicalInsuranceCompany]  = useState('');
    const[policyNumber, setPolicyNumber]                        = useState('');
    const[group, setGroup]                                      = useState('');
    const[groupcode, setGroupcode]                              = useState('');
    const[primaryPhysician, setPrimaryPhysician]                = useState('');
    const[physicianAddress, setPhysicianAddress]                = useState('');
    const[physicianPhone, setPhysicianPhone]                    = useState('');
    const[medicationsTaken, setMedicationsTaken]                = useState('');
    const[allowDocumentCamper, setAllowDocumentCamper]          = useState();
    const[hippaDocument, setHippaForm]                          = useState();
    const[medicalDocument, setMedicalForm]                      = useState([]);
    const[activityInterest, setActivityInterest]                = useState([]);
    const[errormodal, setErrorModel]                            = useState(false);
    const[errors, setfiledErrors]                               = useState([]);
    const[success, setSuccessModel]                             = useState(false);
    const dateFormatNew                                         = 'MM/DD/YYYY';
    const [permissionEmergencyMedication, setPermissionEmergencyMedication]                 = useState('');
    const [participantReceiveCounterMedication, setParticipantReceiveCounterMedication]     = useState('');
    const [participantBringingOwnMedication, setParticipantBringingOwnMedication]           = useState('');
    
    

    const[firstNameErr, setFirstNameErr]                              = useState('');
    const[lastNameErr, setLastNameErr]                                = useState('');
    const[dobErr, setDobErr]                                          = useState();
    const[genderErr, setGenderErr]                                    = useState();
    const[addressErr, setAddressErr]                                  = useState('');
    const[apartmentErr, setAppartmentErr]                             = useState('');
    const[cityErr, setCityErr]                                        = useState('');
    const[stateErr, setStateErr]                                      = useState('');
    const[zipCodeErr, setZipCodeErr]                                  = useState('');
    const[currentGradeErr, setCurrentGradeErr]                        = useState();
    const[tshirtSizeErr, setTshirtSizeErr]                            = useState();
    const[shortsSizeErr, setShortSizeErr]                             = useState();
    const[medicalvaluesErr, setMedicalErr]                            = useState();
    const[allergyvaluesErr, setAllergyErr]                            = useState();
    const[allergyDeadlyErr, setAllergyDeadlyErr]                      = useState();
    const[medicalInsuranceCompanyErr, setMedicalInsuranceCompanyErr]  = useState('');
    const[policyNumberErr, setPolicyNumberErr]                        = useState('');
    const[groupErr, setGroupErr]                                      = useState('');
    const[groupcodeErr, setGroupcodeErr]                                 = useState('');
    const[primaryPhysicianErr, setPrimaryPhysicianErr]                = useState('');
    const[physicianAddressErr, setPhysicianAddressErr]                = useState('');
    const[physicianPhoneErr, setPhysicianPhoneErr]                    = useState('');
    const[medicationsTakenErr, setMedicationsTakenErr]                = useState('');


  


useEffect(() => {
    let query = `?camperId=${props.match.params.id}`;

    camperinformation(query).then(res => {
        if(res.data.statusCode){
            let camperInfo = res.data.responseData.result;
            setFirstName(camperInfo.firstName);
            setProfileImageUrl(camperInfo.profileImage);
            setLastName(camperInfo.lastName);
            setDob(camperInfo.dob);
            setGender(camperInfo.gender);
            setAddress(camperInfo.address);
            setAppartment(camperInfo.apartment);
            setCity(camperInfo.city);
            setState(camperInfo.state);
            setZipCode(camperInfo.zipCode);
            setCurrentGrade(camperInfo.currentGrade);
            setTshirtSize(camperInfo.tshirtSize);
            setShortSize(camperInfo.shortsSize);
            setDietaryGuidelines(camperInfo.dietaryGuidelines);
            setDietReligious(camperInfo.dietReligious);
            setAllergy(retunDataType(camperInfo.allergy));
            setMedical(retunDataType(camperInfo.medical));
            setAllergyDeadly(camperInfo.allergyDeadly);
            setMedicalInsuranceCompany(camperInfo.medicalInsuranceCompany);
            setPolicyNumber(camperInfo.policyNumber);
            setGroup(camperInfo.group);
            setGroupcode(camperInfo.groupcode);
            setPrimaryPhysician(camperInfo.primaryPhysician);
            setPhysicianAddress(camperInfo.physicianAddress);
            setPhysicianPhone(camperInfo.physicianPhone);
            setMedicationsTaken(camperInfo.medicationsTaken);
            setAllowDocumentCamper(camperInfo.allowDocumentCamper);
            setHippaForm(camperInfo.hippaDocument);
            setMedicalForm(camperInfo.medicalDocument || []);
            setActivityInterest(camperInfo.activityInterest || []);
            setPermissionEmergencyMedication(camperInfo.permissionEmergencyMedication);
            setParticipantReceiveCounterMedication(camperInfo.participantReceiveCounterMedication);
            setParticipantBringingOwnMedication(camperInfo.participantBringingOwnMedication);
            setLoader(false);
        }
    })
},[])


const fieldName = `participants[0]`;


const disabledDate = (current) => {
    return current && (current > moment().startOf('day') || current < moment(`${new Date().getFullYear() - 100}-10-23`).startOf('day'));
}

const uploadHippaCompliance = (e, type) => {
    const data = new FormData();
    data.append('file', e.target.files[0]);
    uploadMedia(data).then((res)=>{
        if(res.data.responseData.result.fileUrl !== undefined){
            if(type === 'hippa'){
                setHippaForm(res.data.responseData.result.fileUrl)
            }
            if(type === 'medical'){
                let _medicalDocument = [...medicalDocument];
                _medicalDocument.push(res.data.responseData.result.fileUrl)
                setMedicalForm(_medicalDocument);
            }
        }
    })
}  


const handleRemoveMedicalForm = url => {
    let _medicalDocument = [...medicalDocument].filter(i => i !== url);
    setMedicalForm(_medicalDocument);
}


const upload_image = (e) =>{
    if (e.target.files[0]) {
        const data = new FormData();
        data.append('file', e.target.files[0]);
        uploadMedia(data).then((res)=>{
            if(res.data.responseData.result.fileUrl !== undefined){
                setProfileImageUrl(res.data.responseData.result.fileUrl);
             }
        })
    }
}


const handleValidate = () => {
    let validate = true;

    if(groupcode === ''){
        setGroupcodeErr('Group code is required');
        validate = false;
    }

    if(firstName === ''){
        setFirstNameErr('First name is required');
        validate = false;
    }

    if(lastName === ''){
        setLastNameErr('Last name is required');   
        validate = false;
    }

    if(dobErr){ 
        validate = false;
    }

    if(currentGrade === ''){
        setCurrentGradeErr('Grade is required');   
        validate = false;
    }

    if(gender === ''){
        setGenderErr('Gender is required');   
        validate = false;
    }

    if(address === ''){
        setAddressErr('Address is required');   
        validate = false;
    }

    if(city === ''){
        setCityErr('City is required');   
        validate = false;
    }

    if(zipCode === ''){
        setZipCodeErr('Zip code is required');   
        validate = false;
    }
    if(zipCode.length < 5){
        setZipCodeErr('The zip code must be at least 5 digits long.');   
        validate = false;
    }

    if(state === ''){
        setStateErr('State is required');   
        validate = false;
    }

    if(shortsSize === ''){
        setShortSizeErr('Shorts size is required');   
        validate = false;
    }

    if(tshirtSize === ''){
        setTshirtSizeErr('T-shirt size is required');   
        validate = false;
    }

    if(medicalvalues === ''){
        setMedicalErr('Medical is required');   
        validate = false;
    }

    if(allergyvalues === ''){
        setAllergyErr('Allergy is required');   
        validate = false;
    }
    if(!isAtLeastFiveYearsAgo(dob)){
        setDobErr("Not a valid date of birth.")
        validate = false;
    }

    
    return validate;

}  
const  isAtLeastFiveYearsAgo = (timestamp) => {
    const currentDate = new Date();
    const targetDate = new Date(timestamp);
    const differenceMs = currentDate - targetDate;
    const differenceYears = differenceMs / (1000 * 60 * 60 * 24 * 365);
    return differenceYears >= 0;
  }



const handleSubmit = e => {
    e.preventDefault();
    if (handleValidate()) {
        let _participantlist = {
            _id: props.match.params.id, 
            profileImage: profileimageurl,
            firstName,
            lastName,
            dob,
            gender,
            address,
            apartment,
            city,
            state,
            zipCode,
            currentGrade,
            tshirtSize,
            shortsSize,
            medical: JSON.stringify(medicalvalues),
            allergy: JSON.stringify(allergyvalues),
            dietaryGuidelines,
            dietReligious,
            medicalInsuranceCompany,
            policyNumber,
            group,
            primaryPhysician,
            physicianAddress,
            physicianPhone,
            medicationsTaken,
            allowDocumentCamper,
            hippaDocument,
            medicalDocument,
            activityInterest: '',
            permissionEmergencyMedication,
            participantReceiveCounterMedication,
            participantBringingOwnMedication

        }

        let params = {
            deviceTypeID: 3,
            participants: JSON.stringify([_participantlist])
        }
        editCamper(params).then(res => {
            if(res?.data?.statusCode){
                setSuccessModel(true);
            }
        }) 
    }else{
        setErrorModel(true)
    }
    
}
const handleDateChange = (timestamp) => {

    const date = new Date(timestamp);

    var timestamps = date.getTime();
    setDob(timestamps);
  };




return(

    <div className="card mb-4">
        <form onSubmit={handleSubmit}> 

            <Modal
                dialogClassName="h_auto"
                show={errormodal}
                animation={false}
                centered>
                <Modal.Body>
                    <div className="p-5">
                        <h3 className="text-center mb-4">Error</h3>
                        Please Fill in Required Fields
                        <button  onClick={() => {setErrorModel(false)}} className="btn btn-primary w-100 mt-2">Ok</button>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                dialogClassName="h_auto"
                show={success}
                animation={false}
                centered>
                <Modal.Body>
                    <div className="p-5">
                        <h3 className="text-center mb-4">Success</h3>
                        Participant Updated Successfully
                        <Link to="/profile/participant" className="btn btn-primary w-100 mt-2">ok</Link>
                    </div>
                </Modal.Body>
            </Modal>

            
            {loader ? <div className="page_loader"><ProgramLoader /></div> : ''}
            

            <div className="row pro_wrapper pro_wrapper2">

                <div className="col-lg-11">
                    <div className="profile_upload">
                        <img src={profileimageurl ? profileimageurl : require('../../../assets/img/camps-user.png')} />
                        <input onChange={upload_image} type="file" accept="image/x-png,image/gif,image/jpeg"/>
                        <i className="icon-camera-plus" />
                        {profileimageurl ? <input type="hidden" name={`${fieldName}.profileImage`} value={profileimageurl} /> : '' } 
                    </div>
                </div>

                <div className="col-lg-11">
                    <div className="d-flex justify-content-between">
                        <div>
                            <p className="color_primary">{<span className="group_code">Group Code</span>}
                            <input
                                type="text"
                                onChange={(e) => {setGroupcode(e.target.value); setGroupcodeErr('')}}
                                value={groupcode}
                                className={`group_code ${groupcodeErr ? 'is-invalid' : ''}`}
                                placeholder="Group Code" />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-11">
                    <div className="row">

                        <div className="col-md-6 form-group">
                            <label>First Name</label>
                            <input
                                type="text"
                                onChange={(e) => {setFirstName(e.target.value); setFirstNameErr('')}}
                                value={firstName}
                                className={`form-control ${firstNameErr ? 'is-invalid' : ''}`}
                                placeholder="First Name" />

                            {firstNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{firstNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>


                        <div className="col-md-6 form-group">
                            <label>Last Name</label>
                            <input
                                type="text"
                                onChange={e => {setLastName(e.target.value); setLastNameErr('')}}
                                value={lastName}
                                className={`form-control ${lastNameErr ? 'is-invalid' : ''}`}
                                placeholder="Last Name" />

                            {lastNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{lastNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>


                        <div className="col-md-6 form-group">
                            <label>Date of Birth</label>

                            <DateSelector
                                dateValue={dob ? moment.utc(parseInt(dob)) : ''}
                                disabledDate={disabledDate}
                                className={`form-control ${dobErr ? 'is-invalid' : ''}`}
                                placeholder="Date of Birth"
                                setDobErr={setDobErr}
                                dobErr={dobErr}
                                onDateChange={handleDateChange}
                            />

                            {dobErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{dobErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            
                        </div>


                        <div className="col-md-6 form-group">
                            <label>Select Current Grade As of Today</label>
                            <select
                                value={currentGrade}
                                onChange={e => {setCurrentGrade(e.target.value); setCurrentGradeErr('')}}
                                className={`form-control ${currentGradeErr ? 'is-invalid' : ''}`}>
                                {gradeArr.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>

                            {currentGradeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{currentGradeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>

                        
                        <div className="col-md-12 mb-3 d-flex cm_gendr">
                        
                            <label>Sex</label>
                            <ul className="d-flex">
                                <li className="me-2 check_box_wrapper">
                                    <input
                                        type="radio"
                                        onChange={e => {setGender(1); setGenderErr('')}}
                                        checked={gender === 1 ? 'checked' : ''} />
                                    <span className="check_box"><span /> Male</span> 
                                </li>
                                <li className="me-2 check_box_wrapper">
                                    <input
                                        type="radio"
                                        onChange={e => {setGender(2); setGenderErr('')}}
                                        checked={gender === 2 ? 'checked' : ''} />
                                    <span className="check_box"><span /> Female</span> 
                                </li>
                            </ul>

                            {genderErr ? <span className="cm_err_msg">{genderErr}</span> :''}
                            
                        </div>

                        <div className="col-md-6 form-group">
                            <label>Street Address</label>
                            <input
                                type="text"
                                onChange={e => {setAddress(e.target.value); setAddressErr('')}}
                                value={address}
                                className={`form-control ${addressErr ? 'is-invalid' : ''}`}
                                placeholder="Street Address" />

                            {addressErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{addressErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>


                        <div className="col-md-6 form-group">
                            <label>Apt./Suite/Unit [optional]</label>
                            <input
                                type="text"
                                onChange={e => {setAppartment(e.target.value); setAppartmentErr('')}}
                                value={apartment}
                                className={`form-control ${apartmentErr ? 'is-invalid' : ''}`}
                                placeholder="Apt./Suite/Unit [optional]" />

                            {apartmentErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{apartmentErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>


                        <div className="col-md-6 form-group">
                            <label>City</label>
                            <input
                                type="text"
                                onChange={e => {setCity(e.target.value); setCityErr('')}}
                                value={city}
                                className={`form-control ${cityErr ? 'is-invalid' : ''}`}
                                placeholder="City" />

                            {cityErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{cityErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>


                        <div className="col-md-6 form-group">
                            <label>Zip Code</label>
                            <input
                                type='number'
                                onChange={e => {setZipCode(e.target.value); setZipCodeErr('')}}
                                value={zipCode}
                                className={`form-control ${zipCodeErr ? 'is-invalid' : ''}`}
                                placeholder="Zip Code" 
                                />
                                {zipCodeErr && <InputTextError error={zipCodeErr}/>}
                        </div>


                        <div className="col-md-6 form-group">
                            <label>State/Province</label>
                            <select
                                onChange={e => {setState(e.target.value); setStateErr('')}}
                                className={`form-control ${stateErr ? 'is-invalid' : ''}`}
                                value={state}
                                placeholder="State/Province">
                            
                                <option value="">Select State/Province</option>   
                                {provinceList.map((item, i) => <option key={i} value={item.value}>{item.value} - {item.label}</option>)}
                            </select>

                            {stateErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{stateErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>


                        <div className="col-sm-12" />
                        

                        <div className="col-md-6 form-group">
                            <label>T-shirt Size</label>
                            <select
                                value={tshirtSize}
                                defaultValue={tshirtSize}
                                onChange={e => {setTshirtSize(e.target.value); setTshirtSizeErr('')}}
                                className={`form-control ${tshirtSizeErr ? 'is-invalid' : ''}`}>
                                
                                {shortsAndTshirtSizes.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                        
                            {tshirtSizeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{tshirtSizeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            
                        </div>


                        <div className="col-md-6 form-group">
                            <label>Shorts Size</label>
                            <select
                                onChange={e => {setShortSize(e.target.value); setShortSizeErr('')}}
                                value={shortsSize}
                                defaultValue={shortsSize}
                                className={`form-control ${shortsSizeErr ? 'is-invalid' : ''}`}>
                                
                                {shortsAndTshirtSizes.map(value => <option key={value} value={value}>{value}</option>)}
                            </select> 

                            {shortsSizeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{shortsSizeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>


                        <div className="col-md-6 form-group">
                            <label>Medical</label>
                            <CustomMultiSelectDropdownfrom
                                list={medicalList}
                                placeHolder={'Select Medical'}
                                err={medicalvaluesErr}
                                calback={val => (setMedical(val), setMedicalErr(''))}
                                value={medicalvalues}/>

                            {medicalvaluesErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{medicalvaluesErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>


                        <div className="col-md-6 form-group">
                            <label>Allergy</label>
                            <CustomMultiSelectDropdownfrom
                                list={allergyList}
                                placeHolder={'Select Allergy'}
                                err={allergyvaluesErr}
                                calback={val => (setAllergy(val), setAllergyErr(''))}
                                value={allergyvalues}/>

                            {allergyvaluesErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{allergyvaluesErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>


                        <div className="col-md-12 mb-3 d-flex cm_gendr">
                            {allergyDeadlyErr ? <span className="cm_err_msg">{allergyDeadlyErr}</span> :''}
                            <label>Allergy is deadly</label>
                            <ul className="d-flex">
                                <li className="me-2 check_box_wrapper">
                                    <input
                                        type="radio"
                                        onChange={e => {setAllergyDeadly(1); setAllergyDeadlyErr('')}}
                                        checked={allergyDeadly === 1 ? 'checked' : ''} />
                                    <span className="check_box"><span /> Yes</span> 
                                </li>
                                <li className="me-2 check_box_wrapper">
                                    <input
                                        type="radio"
                                        onChange={e => {setAllergyDeadly(2); setAllergyDeadlyErr('')}}
                                        checked={allergyDeadly === 2 ? 'checked' : ''} />
                                    <span className="check_box"><span /> No</span> 
                                </li>
                            </ul>

                        </div>
                        
                        
                        <div className="col-sm-12 mb-3">
                            <h6>Insurance Information:</h6>
                        </div>


                        <div className="col-md-6 form-group">
                            <label>Medical Insurance Company</label>
                            <input
                                type="text"
                                onChange={e => {setMedicalInsuranceCompany(e.target.value); setMedicalInsuranceCompanyErr('')}}
                                className={`form-control ${medicalInsuranceCompanyErr ? 'is-invalid' : ''}`}
                                value={medicalInsuranceCompany}
                                placeholder="Medical Insurance Company" />

                            {medicalInsuranceCompanyErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{medicalInsuranceCompanyErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>


                        <div className="col-md-6 form-group">
                            <label>Policy Number</label>
                            <input
                                type="text"
                                onChange={e => {setPolicyNumber(e.target.value); setPolicyNumberErr('')}}
                                className={`form-control ${policyNumberErr ? 'is-invalid' : ''}`}
                                value={policyNumber}
                                placeholder="Policy Number" />

                            {policyNumberErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{policyNumberErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>


                        <div className="col-md-6 form-group">
                            <label>Group #</label>
                            <input
                                type="text"
                                onChange={e => {setGroup(e.target.value); setGroupErr('')}}
                                className={`form-control ${groupErr ? 'is-invalid' : ''}`}
                                value={group}
                                placeholder="Group #" />

                            {groupErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{groupErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>


                        <div className="col-md-6 form-group">
                            <label>Primary Physician</label>
                            <input
                                type="text"
                                onChange={e => {setPrimaryPhysician(e.target.value); setPrimaryPhysicianErr('')}}
                                className={`form-control ${primaryPhysicianErr ? 'is-invalid' : ''}`}
                                value={primaryPhysician}
                                placeholder="Primary Physician" />

                            {primaryPhysicianErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{primaryPhysicianErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>


                        <div className="col-md-6 form-group">
                            <label>Physician Address</label>
                            <input
                                type="text"
                                onChange={e => {setPhysicianAddress(e.target.value); setPhysicianAddressErr('')}}
                                className={`form-control ${physicianAddressErr ? 'is-invalid' : ''}`}
                                value={physicianAddress}
                                placeholder="Physician Address" />

                            {physicianAddressErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{physicianAddressErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>



                        <div className="col-md-6 form-group">
                            <label>Physician Phone #</label>
                            <div className="cm_phone_field">
                                <img className="us_flag" src={require('../../../assets/img/united-states-flag-icon.svg')} />
                                <input
                                    type="number"
                                    className={`form-control ${physicianPhoneErr ? 'is-invalid' : ''}`}
                                    onChange={e => {setPhysicianPhone(e.target.value); setPhysicianPhoneErr('')}}
                                    value={physicianPhone}
                                    maxLength={16} />
                            </div>
                            {physicianPhoneErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{physicianPhoneErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>


                        <div className="col-md-12 form-group">
                            <label>Please list any medications taken(please include dosage & timing) or any other special assistance necessary to attend a program. (Leave blank if none)</label>
                            <textarea
                                 className={`form-control ${medicationsTakenErr ? 'is-invalid' : ''}`}
                                value={medicationsTaken}
                                onChange={e => {setMedicationsTaken(e.target.value); setMedicationsTakenErr('')}}
                                placeholder="Write something here…" />

                            {medicationsTakenErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{medicationsTakenErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                        </div>


                        <div className="col-md-12 form-group">
                            <label>Parent Permission for EMERGENCY MEDICATIONS: (rescue inhaler, glucagon, epinephrine)</label>
                        </div>



                        <div className="col-md-12 mb-3 cm_gendr">
                            <label>Participant has permission to carry his/her own emergency medication, or inhaler:</label>
                            <ul className="d-flex">
                                <li className="me-2 check_box_wrapper">
                                    <input
                                        type="radio"
                                        onChange={() => setPermissionEmergencyMedication(1)}
                                        checked={permissionEmergencyMedication === 1 ? 'checked' : ''} />
                                    <span className="check_box"><span /> Yes</span> 
                                </li>
                                <li className="me-2 check_box_wrapper">
                                    <input
                                        type="radio"
                                        onChange={() => setPermissionEmergencyMedication(2)}
                                        checked={permissionEmergencyMedication === 2 ? 'checked' : ''} />
                                    <span className="check_box"><span /> No</span> 
                                </li>
                            </ul>

                        </div>



                                <div className="col-md-12 mb-3 cm_gendr">
                                    <label>May the participant receive over-the counter medication:</label>
                                    <ul className="d-flex">
                                        <li className="me-2 check_box_wrapper">
                                            <input
                                                type="radio"
                                                onChange={() => setParticipantReceiveCounterMedication(1)}
                                                checked={participantReceiveCounterMedication === 1 ? 'checked' : ''}/>
                                            <span className="check_box"><span /> Yes</span> 
                                        </li>
                                        <li className="me-2 check_box_wrapper">
                                            <input
                                                type="radio"
                                                onChange={() => setParticipantReceiveCounterMedication(2)}
                                                checked={participantReceiveCounterMedication === 2 ? 'checked' : ''}/>
                                            <span className="check_box"><span /> No</span> 
                                        </li>
                                    </ul>

                                </div>

                                <div className="col-md-12 mb-3 cm_gendr">
                                    <label>Will the participant be bringing their own medication, or an inhaler to a program: </label>
                                    <ul className="d-flex">
                                        <li className="me-2 check_box_wrapper">
                                            <input
                                                type="radio"
                                                onChange={() => setParticipantBringingOwnMedication(1)}
                                                checked={participantBringingOwnMedication === 1 ? 'checked' : ''} />
                                            <span className="check_box"><span /> Yes</span> 
                                        </li>
                                        <li className="me-2 check_box_wrapper">
                                            <input
                                                type="radio"
                                                onChange={() => setParticipantBringingOwnMedication(2)}
                                                checked={participantBringingOwnMedication === 2 ? 'checked' : ''} />
                                            <span className="check_box"><span /> No</span> 
                                        </li>
                                    </ul>

                                </div>                            




                                <div className="col-md-12 form-group">
                                    <label>If the Participant is bringing medication please note:</label>
                                    <label>All Medication must be sent in the original container and must state the current instructions for dispensing.  All medications must be turned in when attendee is dropped off, if permission is not granted above for them to carry it.</label>
                                </div>                            


                        <div className="col-md-12 mb-3 cm_gendr">
                            <label>Service Provider Staff may document participant with digital and film photography and/or video.</label>
                            <ul className="d-flex">
                                <li className="me-2 check_box_wrapper">
                                    <input
                                        type="radio"
                                        onChange={e => setAllowDocumentCamper(1)}
                                        checked={allowDocumentCamper === 1 ? 'checked' : ''} />
                                    <span className="check_box"><span /> Yes</span> 
                                </li>
                                <li className="me-2 check_box_wrapper">
                                    <input
                                        type="radio"
                                        onChange={e => setAllowDocumentCamper(2)}
                                        checked={allowDocumentCamper === 2 ? 'checked' : ''} />
                                    <span className="check_box"><span /> No</span> 
                                </li>
                            </ul>
                        </div>



                        <div className="col-md-12 form-group">
                            <label>Please attach any accompanying documents that you feel are necessary for attendance (Additional Medical document etc).</label>
                            <div  className="attach_file_btn">Attach PDF <input type="file" value="" accept="application/pdf" onChange={(e)=>{uploadHippaCompliance(e,'medical')}} /></div>


                            {
                                medicalDocument.length ? medicalDocument.map((m, i) => <div key={i} className="cm_pdf_icon"><PDFIcon /><i className="icon-cross" onClick={() => handleRemoveMedicalForm(m)} /> <p>{m ? m.slice(m.lastIndexOf('_') + 1) : ''}</p></div>) : ''  
                            }

                            <input value={medicalDocument} type="hidden" name={`${fieldName}.medicalDocument`} />                                
                        </div>



                    </div>
            </div>

                <input type="hidden" name={`${fieldName}._id`} value={props.match.params.id}/>                
    
            </div>
            <div className="col-sm-12 text-center">
                <Link to="/profile/information" className="btn btn-light rounded-0 me-2" style={{width: '130px'}}>Cancel</Link>
                <button style={{minWidth: '130px'}} className="btn btn-primary rounded-0">Save</button>
            </div>


    </form>

</div>



)

}


const mapStateToProps = state => {
    let { preList, fSubcategory} = state.program;
    let { loggedIn } = state.user;
    let userID =false;

    if(state.user.userProfile!== undefined)
    {
        userID = state.user.userProfile._id;
    }
    
    return{
        preList,
        fSubcategory,
        loggedIn,
        userID
    }
}
export default connect(mapStateToProps, null)(EditCamper);