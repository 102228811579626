import React, {  useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import { changeInboxTabAction } from '../../store/inbox';
import { uploadMedia, createTemplateService, getTemplatesService, deleteTemplateService, editTemplateService, sendEmailTestService, sendAppMessageTestService, sendPhoneTextTestService } from '../../services/campersServices';
import { getHtml, removeHtmlTags, resHandle } from '../../helper';
import { notification } from 'antd';
import moment from 'moment';
import ConfirmDiloag from '../modals/ConfirmDiloag';
import { counselorlist, getPersonnelByCampCode } from '../../services/directorServices';
import PaginatedComponent from '../common/Pagination/PaginationComponent';


const Templates = props => {
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const [deleteItem, setDeleteItem] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [activeTemplate, setActiveTemplate] = useState();
    const [uploadLoader, setUploadLoader] = useState(false);

    const [counselorList, setCounselorList] = useState([]);
    const [counselorId, setCounselorId] = useState();
    const [messageTypes, setMesageTypes] = useState("1,2,3")
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState();
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [messageText, setMessageText] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [templateType, setTemplateType] = useState(3);
    
    const [nameErr, setNameErr] = useState('');
    const [subjectErr, setSubjectErr] = useState('');
    const [bodyErr, setBodyErr] = useState('');
    const [templateTypeErr, setTemplateTypeErr] = useState('');


    const limit = 10;

    const closeModal = () => {
        setShowModal(false);
        setName('');
        setSubject('');
        setBody('');
        setAttachments([]);
        setIsEditMode(false);
        setActiveTemplate('');
    }


    const getTemplates = () => {
        let query = `?page=${page - 1}&limit=${limit}&campCode=${props.campCode}`;

        getTemplatesService(query).then(res => {
            let { status, data } = resHandle(res);
            if(status){
                setList(data.result);
                setTotalCount(data.count);
            }
        })
    }


    useEffect(() => {
        getTemplates()
    }, [page])

    // useEffect(() => {
    //     setMessageText(extractTextFromHTML(body))
    // getCounselorlist
    // }, [body])

     useEffect(() => {
        (counselorList.length === 0)  && getCounselorlist();
    }, [])

    const handleFileChange = (e) => {
        const data = new FormData();
        let _file = e.target.files[0];
        data.append('file', _file);
        setUploadLoader(true);
        uploadMedia(data).then(res => {
            setUploadLoader(false);
            if(res.data.responseData.result.fileUrl !== undefined){
                let attachment = {
                    attchmentType: _file.type,
                    url: res.data.responseData.result.fileUrl
                }

                let _attachments = [...attachments];
                _attachments.push(attachment);
                setAttachments(_attachments);
            }
        })
    } 



    const handleValidate = () => {
        let validate = true;
        
        if(name === ''){
            setNameErr('Name is required');
            validate = false
        }

        if(subject === ''){
            setSubjectErr('Subject is required');
            validate = false
        }

        if(body === '' || removeHtmlTags(body) === '' || removeHtmlTags(body).trim() === ''){
            setBodyErr('Body is required');
            validate = false
        }

        return validate;
    }


    const handleSubmit = () => {

        if(handleValidate()){
            setBtnLoader(true);
           
            let params = {
                name,
                subject,
                body,
                attachments,
                campCode: props.campCode,
                bodyText:messageText,
                messageTypes:messageTypes
            }

            if(isEditMode){
                params.templateId = activeTemplate;
            }

            setBtnLoader(true);

            let _service = isEditMode ? editTemplateService : createTemplateService;

            _service(params).then(res => {
                setBtnLoader(false);
                let { status, data } = resHandle(res);
                if(status){
                    closeModal();
           
                    getTemplates();
                    notification.open({
                        message: 'Template',
                        description:
                          'Template create successfully',
                      });
                   }
            })
        }
    }



    const openEditModal = item => {
        setIsEditMode(true);
        setShowModal(true);
        setActiveTemplate(item._id)
        setName(item.name);
        setSubject(item.subject);
        setBody(item.body);
        setMessageText(item.bodyText)
        setAttachments(item.attachments);
    }


    const handleDeleteItem = () => {
        let params = {
            templateId: deleteItem._id
        }
        deleteTemplateService(params).then(res => {
            let { status, data } = resHandle(res);
            if(status){
                setDeleteItem({});
                getTemplates();
                notification.open({
                    message: 'Template',
                    description:
                      'Template deleted successfully',
                  });
               }
        })

    }

    const getCounselorlist = () => {
    
            getPersonnelByCampCode(props.campCode).then(res => {
                if(res?.data?.statusCode){   
                    const listOfTester = res.data.responseData.counselors.concat(res.data.responseData.directors);
                    setCounselorList(listOfTester);
                }else{
                let params = `/`
                counselorlist(params).then(res => {
                    if(res?.data?.statusCode){   
                        setCounselorList(res.data.responseData.result.counselor)
                    }
            })
            }
            })
    }

    const handleDeleteAttachments = index => {
        let _attachments = [...attachments];
        _attachments.splice(index, 1);
        setAttachments(_attachments); 
    }


  const handleTextChange = (event) => {
    setMessageText(event.target.value);
  };

  const  extractTextFromHTML = (htmlString) => {
    var temp = document.createElement("div");
    temp.innerHTML = htmlString;
    return temp.textContent || temp.innerText || "";
  }

  const handleSendTest = () => {
    let _sendTestService;
    let params; 
    let item; 
    if(templateType ===3 ){
        _sendTestService = sendEmailTestService;
        params =  {
            email,
            body, 
            subject,
            attachments,
        }
        item = 'Email';
    }else if(templateType===2){
        _sendTestService = sendAppMessageTestService;
        params =   {
            receiverUserId:counselorId._id, 
            body,
        }
        item = 'App Message';
    }else if (templateType ===1){
        _sendTestService = sendPhoneTextTestService;
        params =  {
            phone:phoneNumber, 
            bodyText: messageText, 
        }
        item = 'Phone text';
        
    }else{
        notification.open({
            message: 'Template',
            description:
              `Someting went wrong, please try again`,
          });
        return;
    }
    
    _sendTestService(params).then(res => {
        let { status} = resHandle(res);
        if(status){
            notification.open({
                message: 'Template',
                description:
                  `Test ${item} sent successfully`,
              });
           }
    })
    
  }

    return(
        <div className='inner_inbox_page'>

            <ConfirmDiloag
                show={deleteItem._id}
                hideModal={() => setDeleteItem({})}
                action={handleDeleteItem}
                Message={["Are You Sure you want to delete this template?"]} /> 

            <div className="d-flex align-items-center justify-content-between mb-3">
                <h6>Templates</h6>
                <span onClick={() => setShowModal(true)} className="btn btn-primary ms-3 btn-sm rounded"> Add Template</span>
            </div>

            <div className='inbox_data_list'>
                {
                    list.map((item, i) => (
                        <div key={item._id} className='inbox_data_item'>
                            <div className='inbox_data_item_left'>
                                <h5>{((i + 1) + ((page - 1) * limit))}</h5>
                                <h3>{item.name}</h3>
                                <div className='three_dots'>
                                    {getHtml(item.body)}
                                </div>
                            </div>

                            <div className='inbox_data_item_right'>
                                <p>Attachments: <b>{item.attachments.length || 0}</b></p>
                                {/* <p>Type: <span className='inbox_type'>SMS</span><span className='inbox_type'>Email</span></p> */}
                                <p><b>Created At:</b> <span>{moment(item.created).format('DD MMM, YYYY')}</span></p>
                            </div>

                            <div className='inbox_data_action'>
                                <span className='btn btn-dark btn-sm' onClick={() => openEditModal(item)}><i className='icon-pancil' /> Edit</span>
                                <span className='btn btn-danger btn-sm' onClick={() => setDeleteItem(item)}><i className='icon-delete' /> Delete</span>
                            </div>
                        </div>
                    ))
                }
            </div>


            {
                list.length ? 
                <div className="d-flex justify-content-center mt-5">
                    <PaginatedComponent
                        activePage={page}
                        itemsCountPerPage={limit}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={4}
                        onPageChange={setPage}/>
                </div>  : ''

            } 





            <Modal
                size="lg"
                show={showModal}
                dialogClassName="remove_header_line"
                animation={false}
                centered>

                <Modal.Header>
                    <Modal.Title>{isEditMode ? 'Edit' : 'Create'} Template</Modal.Title>
                    <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>
                <div className="form-group">
                        <input
                            value={name}
                            onChange={e => (setName(e.target.value), setNameErr(''))}
                            type="text"
                            className={`form-control ${ nameErr ? 'is-invalid' : '' }`}
                            placeholder="Template Name" />
                        
                        {nameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{nameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                    </div>
                                <div className="form-group">
                                    <p className="a_label">Template Type</p>
                                    <div className='inbox_tabs'>
                                    <span className={templateType === 1 ? 'active' : ''} onClick={() => setTemplateType(1)}>Phone Text</span>
                                    <span className={templateType === 2 ? 'active' : ''} onClick={() => setTemplateType(2)}>App Message</span>
                                    <span className={templateType === 3 ? 'active' : ''} onClick={() => setTemplateType(3)}>Email</span>
                                    </div>
                                    {templateTypeErr ? <h6 className='cm_err'>{templateTypeErr}</h6> : ''}
                                </div>             
           
                    {templateType === 3 &&
                        
                        <div className="form-group">
                            <p className='a_label mb-2'>Subject</p>
                        <input
                            value={subject}
                            onChange={e => (setSubject(e.target.value), setSubjectErr(''))}
                            type="text"
                            className={`form-control ${ subjectErr ? 'is-invalid' : '' }`}
                            placeholder="Email Subject" />

                        {subjectErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{subjectErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                    </div>
                    
                    }
                    
                    {
                    (templateType === 3) ||(templateType === 2)   ? 
                    
                    <div className="form-group">
                        <p className='a_label mb-2'>Body</p>
                        <ReactQuill
                            className={`inbox_editer ${bodyErr ? 'is-invalid' : ''}`}
                            theme="snow"
                            value={body}
                            onChange={e => (setBody(e), setBodyErr)} />

                        {bodyErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{bodyErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                    </div>
                    : 
                    <div className="form-group">
                        <p className='a_label mb-2'>Message Content</p>
                        <textarea
                        value={messageText}
                        onChange={handleTextChange}
                        style={{ width: '100%', minHeight:"200px"}}
                      />
                    </div>
                    }
                    {templateType === 3 &&
                        <div className="form-group">
                        <p className='a_label mb-2'>Attach File</p>
                        <input
                            type="file"
                            value=""
                            style={{lineHeight: 1}}
                            onChange={handleFileChange}
                            className='form-control' />
                    </div>}

                    <div className='attachments_list'>
                        {
                            attachments.map((item , i) => (
                                <div className='d-flex justify-content-between align-items-center'>
                                    <a target='_blank' href={item.url}>
                                        <svg viewBox="0 0 280.067 280.067">
                                                <path fill="#000" d="M149.823,257.142c-31.398,30.698-81.882,30.576-113.105-0.429
                                                    c-31.214-30.987-31.337-81.129-0.42-112.308l-0.026-0.018L149.841,31.615l14.203-14.098c23.522-23.356,61.65-23.356,85.172,0
                                                    s23.522,61.221,0,84.586l-125.19,123.02l-0.044-0.035c-15.428,14.771-40.018,14.666-55.262-0.394
                                                    c-15.244-15.069-15.34-39.361-0.394-54.588l-0.044-0.053l13.94-13.756l69.701-68.843l13.931,13.774l-83.632,82.599
                                                    c-7.701,7.596-7.701,19.926,0,27.53s20.188,7.604,27.88,0L235.02,87.987l-0.035-0.026l0.473-0.403
                                                    c15.682-15.568,15.682-40.823,0-56.39s-41.094-15.568-56.776,0l-0.42,0.473l-0.026-0.018l-14.194,14.089L50.466,158.485
                                                    c-23.522,23.356-23.522,61.221,0,84.577s61.659,23.356,85.163,0l99.375-98.675l14.194-14.089l14.194,14.089l-14.194,14.098
                                                    l-99.357,98.675C149.841,257.159,149.823,257.142,149.823,257.142z"/>
                                            </svg>
                                            {item.url}
                                    </a>

                                    <i className="icon-delete pointer" onClick={() => handleDeleteAttachments(i)} />
                                </div>
                            ))
                        }
                    </div>

                    




                    <div className="text-right">
                        {(templateType === 1 || templateType ===2 ) ? 
                          <button onClick={() => setTemplateType(prev => prev + 1)} style={{minWidth: '130px'}} disabled={btnLoader || uploadLoader} className={`btn btn-primary ps-5 pe-5 ${btnLoader ? 'btnLoader' : ''}`}>Next</button>
                            :
                        <button onClick={handleSubmit} style={{minWidth: '130px'}} disabled={btnLoader || uploadLoader} className={`btn btn-primary ps-5 pe-5 ${btnLoader ? 'btnLoader' : ''}`}>{isEditMode ? 'Save' : 'Create'}</button>
                        }
                    </div>


                    <div className='send_test'>
                        <div>
                            <h6>Send Test</h6>
                        {
                            templateType === 3 && 
                            <div className="form-group">
                                <input
                                 type="email"
                                className='form-control'
                                 placeholder={"To Address"} 
                                 onChange={e => (setEmail(e.target.value))}
                                 />
                            </div>
                        }
                        {
                             templateType === 2 && 
                             <div className="form-group">
                                <Select
                                    placeholder="To User"
                                    value={counselorId}
                                    classNamePrefix="cm_select"
                                    onChange={e => (setCounselorId(e))}
                                    className={`form-control`}
                                    options={counselorList.map(i => ({...i, label: i.name, value: i._id}))} 
                                />
                             </div>
                        }
                         {
                             templateType === 1 && 
                             <div className="form-group">
                                 <input
                                 className='form-control'
                                  placeholder={"To Phone"} 
                                  onChange={(e) => setPhoneNumber(e.target.value)}
                                  />
                             </div>
                        }
                            <div className="text-right">
                                <button onClick={handleSendTest} className='btn btn-primary ps-5 pe-5'>Send</button>
                            </div>
                        </div>
                        
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}


const mapStateToProps = state => {
    let { inboxTab } = state.inbox;
    let campCode = state.user.userProfile.campCode;
    return {
        inboxTab,
        campCode
    };
}
  
const mapDispatchToProps = dispatch => ({
    changeInboxTabAction: params => dispatch(changeInboxTabAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Templates);