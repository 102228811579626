import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { AES } from 'crypto-js';

import { getCustomerCardsService, productBookingCreate,  markPrimaryCard,  deleteCardService,  } from '../../services/programService';

import { getitemsincart } from '../../store/cart';
import { billingDetailService } from '../../services/otherService';
import settings from '../../config/settings';
import { ProgramLoader } from '../reuse/SVG';
import { accountTypes, isNonEmptyArray } from '../../helper';
import { getCampDetailService } from '../../services/userServices';
import { getTaxForPrice, getWithTaxPrice } from '../bookings/bookingHelper';
import AddNewCard from './AddNewCard';
import SecureInput from './SecureInput';
import CreditCard from './CreditCard';
import CustomDropDown from '../reuse/UIComponents/CustomDropDown';






const ProductPaymentGateway = props => {
    const [btnloder, setBtnloder] = useState(false);


    const [paymentMethod, setPaymentMethod] = useState(0);
    const [paymentMethodErr, setPaymentMethodErr] = useState('');
    const [isNewCard, setIsNewCard] = useState(false);
    const [cardList, setCardList] = useState([]);

    const [resErr, setResErr] = useState('');

    const [customerPaymentProfileId, setCustomerPaymentProfileId] = useState('');
    const [customerPaymentProfileIdErr, setCustomerPaymentProfileIdErr] = useState('');


    const [bankAccountType, setBankAccountType] = useState('');
    const [bankAccountNum, setBankAccountNum] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [nameOnAccount, setNameOnAccount] = useState('');
    const [bankAccountTypeErr, setBankAccountTypeErr] = useState('');
    const [bankAccountNumErr, setBankAccountNumErr] = useState('');
    const [routingNumberErr, setRoutingNumberErr] = useState('');
    const [nameOnAccountErr, setNameOnAccountErr] = useState('');
    const [totalAmount, setTotalAmount] = useState(0);
    const [billingDetail, setBillingDetail] = useState({});
    const [primaryCardId,setPrimaryCardId] = useState('');
    const [isDeletePopup,setIsDeletePopup] = useState(false);
    const [deleteCardToken,setDeleteCardToken] = useState('');
    const [isSwitchPrimaryCard,setIsSwitchPrimaryCard] = useState(false);
    const [holdPrimaryId, setHoldPrimaryId] = useState('');
    const [loader, setLoader] = useState(true);
    const [paymentLoader, setPaymentLoader] = useState(true);

    const [creditCardRate, setCreditCardRate] = useState({});
    const [achTransferRate, setAchTransferRate] = useState({});


    useEffect(() => {
        if(props?.products?.length){
            let _total = 0;

            for (let i = 0; i < props.products.length; i++) {
                _total += (!isNonEmptyArray(props.products[i]?.subItems)) ? 
                (props.products[i]?.price * props.products[i].count) : 

                props.products[i].subItems?.reduce((total, addon) =>(
                    (addon.price *addon.count) + total), props.products[i]?.price * props.products[i].count)
            }

            setTotalAmount(_total);
        }else{
            if(props?.passData?.length){
                let _totalAmount = 0;

                for (let i = 0; i < props?.passData.length; i++) {
                    _totalAmount += props?.passData[i].price;
                }
                setTotalAmount(_totalAmount)
            }

        }

        billingDetailService().then(res => {
            if (res.data.statusCode) {
                setBillingDetail(res.data.responseData.billingDetail)
            }
        })

    }, [props.products, props.passData])

    const getTaxs = () => {
        setPaymentLoader(true)
        let query =`?campCode=${props.userProfile.campCode}`;
        getCampDetailService(query).then(res => {
            if(res?.data?.statusCode){
              let _data = res.data.responseData.result;
              setCreditCardRate(_data.creditCardRate || {});
              setAchTransferRate(_data.achTransferRate || {});
            }
        }).catch(e => {
            console.log(e)
            setPaymentLoader(false)
        }).finally(() => {
            setPaymentLoader(false)
        })
    }

    useEffect(() => {
        if(props.userProfile.campCode){
            getTaxs();
        }
    }, [props.userProfile.campCode])


    const getCards = () => {
        setLoader(true)
        setCardList([]);
        setHoldPrimaryId('');
        setPrimaryCardId('');
        let query = ``;
        if(props?.passData?.length){
            query = `?isWeb=1&userId=${props.userId}&campCode=${props.userProfile.campCode}`
        }else{
            query = `?isWeb=1&campCode=${props?.products[0].campCode}`;
        }

        

        getCustomerCardsService(query).then(res => {
            if (res.data.statusCode) {
                if(res.data.responseData.result?.length){
                    const cardList = res.data.responseData.result
                    setCardList(cardList);
                    let primaryCard = ''
                    for(let i =0; i < cardList.length ; i++){
                        if(cardList[i]?.card?.metadata?.primary){
                            primaryCard= cardList[i].card.token;
                        }else{
                            continue;
                        }
                    }
                    setPrimaryCardId(primaryCard)
                    setLoader(false)
                }else{
                    setLoader(false)
                    setIsNewCard(true);
                }
            } else {
                setLoader(false)
                setCardList([]);
            }
        }).catch((e) => {
            console.log(e)
        }).finally(() => {
            setLoader(false)
        })
    }

    useEffect(() => {
       
        if(props?.products?.length){
            getCards();
        }

        if(props?.passData?.length){
            getCards();
        }
      
    }, [props.isNewCardAdd, props.products, props?.passData?.length])

    const handleValidate = () => {
        let validate = true;

        if(paymentMethod === 0){
            validate = false;
            setPaymentMethodErr('Please select payment method');
        }

        if(paymentMethod === 1) {
            if(customerPaymentProfileId === ''){
                validate = false;
                setCustomerPaymentProfileIdErr('Please select a card')
            }
        }

        if(paymentMethod === 3) {

            if(bankAccountType === '' || bankAccountType === 'undefined' || bankAccountType === null){
                validate = false;
                setBankAccountTypeErr('Account type is required')
            }

            if(bankAccountNum === '' || bankAccountNum === 'undefined' || bankAccountNum === null){
                validate = false;
                setBankAccountNumErr('Account number is required')
            }

            if(routingNumber === '' || routingNumber === 'undefined' || routingNumber === null){
                validate = false;
                setRoutingNumberErr('Routing number is required')
            }

            if(nameOnAccount === '' || nameOnAccount === 'undefined' || nameOnAccount === null){
                validate = false;
                setNameOnAccountErr('Account holder name is required')
            }

        }
       

        return validate;
    }





    const submitRegistration = () => {
        setResErr('');
        if (handleValidate()){
            setBtnloder(true);
            let payment = {};

            let params = {
                isWeb: 1,
                price: totalAmount,
                userId: props.userId,
                billingDetail: JSON.stringify(billingDetail),
            };

            if(props?.passData?.length){
                let _itemIds = props?.passData.map(j => j.itemId);
                let _cartIds = props?.passData.map(j => j.cartId);
                params.itemIds = JSON.stringify(_itemIds);
                params.cartIds = JSON.stringify(_cartIds);
            }else{
                params.itemIds = JSON.stringify(props.products.map(i => i.itemId))
                params.cartIds = JSON.stringify(props.products.map(i => i.cartId))
            }

            


            if(paymentMethod === 1) {
                params.payment = JSON.stringify({
                    method: 'Credit Card',
                    amount: getWithTaxPrice(creditCardRate, totalAmount),
                    applicationFeeAmount: getTaxForPrice(creditCardRate, totalAmount)
                });
                params.authPayment = JSON.stringify({
                    paymentType: 1,
                    token: customerPaymentProfileId
                });
            }


            if (paymentMethod === 6) {
                params.payment = JSON.stringify({
                    method: 'Pay Later',
                    amount: 0,
                    status: 2
                });
           
                params.partialPayment = true;
                params.remainingAmount = totalAmount;
                params.totalAmount = totalAmount;
     
            }



            if(paymentMethod === 3) {
                params.payment = JSON.stringify({
                    method: 'Internet Banking',
                    amount: getWithTaxPrice(achTransferRate, totalAmount),
                    applicationFeeAmount: getTaxForPrice(achTransferRate, totalAmount)
                });
                params.authPayment = JSON.stringify({
                    paymentType: 1,
                    token: customerPaymentProfileId
                });


                const __routing_number = AES.encrypt(routingNumber, settings.api.mySecretKey).toString();
                const __bankAccountNum = AES.encrypt(bankAccountNum, settings.api.mySecretKey).toString();


                params.internetBanking = 1;
                params.authPayment = JSON.stringify({
                    account_owner_name: nameOnAccount,
                    routing_number: __routing_number,
                    account_number: __bankAccountNum,
                    account_type: bankAccountType.value,
                    account_owner_type: 'individual',
                    country: 'US',
                    currency: 'usd',
                    paymentType: 4
                });
            }

           
    
            productBookingCreate(params).then(res => {
                setBtnloder(false);
                if (res.data.statusCode === 1) {
                    props.callback()
                }else{
                    setResErr(res.data.error.responseMessage)
                }
            })

        } else {
            setBtnloder(false);
   
        }
    }


      const handleDeleteCard = (cardId) => {
        setIsDeletePopup(true); 
        setDeleteCardToken(cardId)
      }
      const deleteCardAction = () => {
        const params = {
            userId: props?.passData?.length ? props.userId : props.userProfile._id,
            cardToken :deleteCardToken,
        }
        //added api call to remove the card using card id. 
        deleteCardService(params).then( res => {
            if(res.status){
                getCards()
                setCardList(cardList.filter(card =>card.card.id !== deleteCardToken));
                setIsDeletePopup(false)
            }
        }).catch(error => {
            console.log(error)
        })
        .finally(() => {
            setIsDeletePopup(false)
        }
        )
        
      }
      const handlePrimaryCardAction = () => {
        
        const params = {
            cardToken: holdPrimaryId,
            userId: props?.passData?.length ? props.userId : props.userProfile._id,
        }
        markPrimaryCard(params).then( res => {
            if(res.status){
                setIsSwitchPrimaryCard(false)
                getCards();
                setPrimaryCardId(holdPrimaryId);
            }
        }).catch(error => {
            console.log(error)
        })
        .finally(() => {
            setIsSwitchPrimaryCard(false)
        }
        )
      }
      const handlePrimaryCard = (id) => {
            setHoldPrimaryId(id)
            setIsSwitchPrimaryCard(true)
      }

   const handleBankAccountNumberChange = (e) => {
        setBankAccountNum(e.target.value);
        setBankAccountNumErr('');
   }
    return (
        <div className="payment-gateway ps-4 pe-4">
            <Modal
                    show={isDeletePopup || isSwitchPrimaryCard}
                    animation={false}
                    dialogClassName="folder_model"
                    size="lg"
                    centered
                >
                {isSwitchPrimaryCard && 
                    <Modal.Body>
                    <span className="modal_close" onClick={() => { setIsSwitchPrimaryCard(false) }}><i className="icon-cross" /></span>
                    <div className="text-center p-5 pickup_modal_body">
                        <p>Are you sure you want to change your primary card ?</p>
                    </div>
                    <div className="text-center p-5">
                        <button onClick={() => { setPrimaryCardId(''); setIsSwitchPrimaryCard(false) }} style={{ width: '120px' }} className="btn btn-light rounded-0">No</button>
                        <button onClick={handlePrimaryCardAction} style={{ width: '120px' }} className="btn btn-primary ms-3">Yes</button>
                    </div>
                    </Modal.Body>
                
                }
                  { isDeletePopup && 
                    <Modal.Body>
                    <span className="modal_close" onClick={() => { setIsDeletePopup(false) }}><i className="icon-cross" /></span>
                    <div className="text-center p-5 pickup_modal_body">
                        <p>Are you sure you want to remove this card ?</p>
                    </div>
                    <div className="text-center p-5">
                        <button onClick={() => { setDeleteCardToken(''); setIsDeletePopup(false) }} style={{ width: '120px' }} className="btn btn-light rounded-0">No</button>
                        <button onClick={deleteCardAction} style={{ width: '120px' }} className="btn btn-primary ms-3">Yes</button>
                    </div>
                    </Modal.Body>
                
                }
                </Modal>


                    <Fragment>
                        <h3>Select a Payment Option</h3>

                        {paymentLoader ? <div className="program_loader"><ProgramLoader/></div>:
                            <div className="row">

                            <div className="col-md-6">
                                <div
                                    className={`payment-gateway-card ${paymentMethod === 1 ? 'active' : ''}`}
                                    onClick={() => (setPaymentMethod(1), setPaymentMethodErr(''))}>
                                    <h4>Credit Card / Debit Card</h4>
                                    {totalAmount > 0 &&
                                        <>
                                        <h2>${getWithTaxPrice(creditCardRate, totalAmount)}</h2>
                                        <p>Payment Includes {creditCardRate.percentage}% + {`$${creditCardRate.taxRate}`} Processing Charge</p>
                                        </>
                                    }
                                </div>
                            </div>


                            <div className="col-md-6">
                                <div
                                    className={`payment-gateway-card ${paymentMethod === 3 ? 'active' : ''}`}
                                    onClick={() => (setPaymentMethod(3), setPaymentMethodErr(''))}>
                                    <h4>Internet Banking</h4>
                                    {totalAmount > 0 &&
                                        <>
                                            <h2>${getWithTaxPrice(achTransferRate, totalAmount)}</h2>
                                            <p>Payment Includes {achTransferRate.percentage}% + {`$${achTransferRate.taxRate}`} Processing Charge</p>
                                        </>
                                    }
                                </div>
                            </div>


                            {
                                props.userProfile.UserType !== 1 ? totalAmount ? <div className="col-md-6">
                                    <div className={`payment-gateway-card ${paymentMethod === 6 ? 'active' : ''}`}
                                        onClick={() => (setPaymentMethod(6), setPaymentMethodErr(''))}>
                                        <h4>Pay Later</h4>
                                        {totalAmount > 0 &&
                                        <>
                                        <h2>${getWithTaxPrice(creditCardRate, totalAmount)}</h2>
                                        <p>Payment Includes {creditCardRate.percentage}% + {`$${creditCardRate.taxRate}`} Processing Charge</p>
                                        </>
                                    }
                                    </div>
                                </div> : '' : ''
                            }
        
                        </div>
                        }


                        {paymentMethodErr ? <p className="color_red">{paymentMethodErr}</p> : ''}
                        {
                            paymentMethod === 1 ? (
                                <Fragment>
                                    <ul className="card_list">
                                        {loader ? 
                                            <div className="program_loader"><ProgramLoader /></div>
                                            :
                                          cardList.length ? cardList.map(item => (
                                                <li key={item.signature} className={customerPaymentProfileId === item.card.token ? 'active' : ''} style={{justifyContent: 'space-between'}}>
                                                <CreditCard
                                                    item={item}
                                                    customerPaymentProfileId={customerPaymentProfileId}
                                                    setCustomerPaymentProfileId={setCustomerPaymentProfileId}
                                                    setCustomerPaymentProfileIdErr={setCustomerPaymentProfileIdErr}
                                                    handlePrimaryCard={handlePrimaryCard}
                                                    handleDeleteCard={handleDeleteCard}
                                                />
   
                                               </li>

                                            )) : ''
                                        }
                                    </ul>

                                    {customerPaymentProfileIdErr ? <p className="color_red text-center">{customerPaymentProfileIdErr}</p> : ''}



                                { //TODO: Re-factor this component more.
                                    isNewCard ? (
                                          <AddNewCard
                                            setIsNewCard={setIsNewCard}
                                            email={props.userEmail || props.userProfile.email}
                                            userId={props.userId}
                                            getCards={getCards}
                                            setResErr={setResErr}
                                            campCode={(props?.passData?.length) ?
                                                props?.userProfile?.campCode : props.products.map(i => i.campCode)
                                                }
                                          />
                                      
                                    ) : <button className="btn btn-primary mb-3" onClick={() => setIsNewCard(true)}>Add New Card</button>
                                } 
                            </Fragment>
                            ) : ''
                        }


    


                        {    
                            paymentMethod === 3 ? (
                                <Fragment>
                                    <div className="card_inputs add_card_form">
                                        <CustomDropDown
                                            className="form-group"
                                            label={'Select Account Type'}
                                            error={bankAccountTypeErr}
                                            onChange={e =>{setBankAccountType(e);setBankAccountTypeErr('')}}
                                            options={accountTypes}
                                        />

                                        <div className="form-group">
                                            <label>Routing Number</label>
                                            <input
                                                type='number'
                                                className={`form-control ${routingNumberErr ? 'is-invalid' : ''}`}
                                                value={routingNumber}
                                                placeholder="Routing Number"
                                                name="routingNumber"
                                                onChange={e => (setRoutingNumber(e.target.value), setRoutingNumberErr(''))} />

                                            {routingNumberErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{routingNumberErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                        </div>

                                        <div className="form-group">
                                            <label>Account Number</label>
                                            <SecureInput
                                                type='number'
                                                error={bankAccountNumErr}
                                                value={bankAccountNum}
                                                name={"bankAccountNum"}
                                                placeholder={"Account Number"}
                                                maxLength={30}
                                                onChange={handleBankAccountNumberChange}
                                            />

                                            {bankAccountNumErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{bankAccountNumErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                        </div>





                                        <div className="form-group">
                                            <label>Account Holder Name</label>
                                            <input
                                                type="text"
                                                className={`form-control ${nameOnAccountErr ? 'is-invalid' : ''}`}
                                                name="nameOnAccount"
                                                value={nameOnAccount}
                                                onChange={e => (setNameOnAccount(e.target.value), setNameOnAccountErr(''))}
                                                placeholder="Account Holder Name" />

                                            {nameOnAccountErr? <OverlayTrigger placement="left" overlay={<Tooltip>{nameOnAccountErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>




                                        </div>


                                        
                            </Fragment>
                            ) : ''
                        }
                    </Fragment>


                    {resErr ? <p className="error_msg">{resErr}</p> : ''}



            { !(paymentMethod ===0)  && 
                <div className="text-center mb-4 mt-4">
                <span onClick={() => btnloder ? null : submitRegistration()} className={`btn btn-primary  ${btnloder ? 'btnLoader' : ''}`} >Buy now</span> 
            </div>
            }

        </div>
    )
}


const mapStateToProps = state => {
    let { loggedIn, userProfile } = state.user;
    let { isNewCardAdd } = state.ui;
    let itemsincart = state.cartReducer.cartitems;
    return {
        loggedIn,
        isNewCardAdd,
        itemsincart,
        userProfile
    }
}

const mapDispatchToProps = dispatch => ({
    getitemsincart: params => dispatch(getitemsincart(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductPaymentGateway);

