import React, {useState, useEffect, useRef} from 'react';

import { Modal } from 'react-bootstrap';
const MultiselectDropdownCommon = (props) => {

    const refprop                                                   = props.refprops;
    const[isfielddisable, setFieldDisable]                          = useState(false);
    const[Multiselectvalues, setMultiselectvalues]                  = useState([]);
    const[otherValueModal, setotherValueModal]                      = useState(false);
    const[fieldVisiblity, setFieldVisiblity]                        = useState(false);
    const[MultiselectPrevalues, setMultiselectPrevalues]            = useState(props.Multiselectvalues);
    const[othervalueterr, setOthervalueterr]                        = useState(false);
    const[checkVaues, setCheckedValues]                             = useState([]);
    const dietRef                                                   = useRef();
    const myRef                                                     = useRef();
   
     useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
      }); 
      
      
     useEffect(()=>{

        if(props.PreSelectedValue!==undefined)
        {
            let prevalues = JSON.parse(props.PreSelectedValue);
            let checkedvalues = [...checkVaues];
            if(Array.isArray(prevalues))
            {
                let multiselects = [...Multiselectvalues];
                prevalues.map(item=>{
                    let newval = {value: item, label: item, type: 'prev'}
                    multiselects.push(newval);
                    checkedvalues.push(item);
                })
                setMultiselectvalues(multiselects); 
               
                setCheckedValues(checkedvalues); 
            }
            else{
                if(prevalues === 'None')
                {
                    let newval = [{value: 'None', label: 'None', type: 'prev'}]
                    setMultiselectvalues(newval);
                    setFieldDisable(true);
                    setCheckedValues([...checkVaues, 'None']);  
                }else
                {
                    let newval = [{value: prevalues, label: prevalues, type: 'prev'}]
                    setMultiselectvalues(newval);
                    setCheckedValues([...checkVaues, prevalues]); 
                }
            } 
        }
     },[props.PreSelectedValue]) 



      const handleClickOutside = e => {
         
          if(typeof myRef.current !=='undefined' && myRef.current !==null ) 
          {
            if (!myRef.current.contains(e.target)) {
              setFieldVisiblity(false);
             }
          }
      };


    const handelchange = (e) => {


        let fieldValue = e.target.value;
        let checkedvalues = [...checkVaues];
        let multiselects = [...Multiselectvalues];
        if(e.target.checked){
            if(fieldValue === 'None'){
                if(props.handelAllergydeadly){props.handelAllergydeadly()}
                if(props.handelDietReligious){props.handelDietReligious()}
                let newval = {
                    value: fieldValue,
                    label: fieldValue,
                    type: 'prev'
                }
                setMultiselectvalues([newval]);
                setFieldDisable(true);
                setCheckedValues(['None']);  
            }
            else if(fieldValue ==='Other')
            {
                setotherValueModal(true);
            }
            else
            {
                
                let newval = {value: fieldValue, label: fieldValue, type: 'prev'}
                multiselects.push(newval);
                setMultiselectvalues(multiselects);
                checkedvalues.push(fieldValue);
                setCheckedValues(checkedvalues);  
            }
        }
        else
        {
            let newchecklist = checkedvalues.filter(item=> item!==fieldValue);
            let newnumtivalue = multiselects.filter(item => item.value!==fieldValue);
            setMultiselectvalues(newnumtivalue);
            setCheckedValues(newchecklist); 
            setFieldDisable(false); 
        } 
     }
  
     const addOtherValue = () => {
         let otherval = dietRef.current.value;
         if(otherval==='')
         {
            setOthervalueterr(true);
         }
         else
         {
         let diet = {value: otherval, label: 'None', type: 'Other'}
         setMultiselectvalues([...Multiselectvalues, diet])
         setotherValueModal(false);
         setOthervalueterr(false);
         }
     }  

    const removeChecked = (fieldValue, index) => {
        let checkedvalues = [...checkVaues];
        let multiselects = [...Multiselectvalues];
        let newchecklist = checkedvalues.filter(item=> item!==fieldValue);
        let newnumtivalue = multiselects.filter(item => item.value!==fieldValue);
        setMultiselectvalues(newnumtivalue);
        setCheckedValues(newchecklist); 
        if(fieldValue==='None'){setFieldDisable(false)}
    } 



    return(
        <React.Fragment>
          <Modal
            dialogClassName="h_auto"
            show={otherValueModal}
            animation={false}
            centered
            >
            <Modal.Body>
            <span className="modal_close" onClick={()=> {setotherValueModal(false)}}><i className="icon-cross" /></span>
                <div className="p-5">
                    <h3 className="text-center mb-4">Add</h3>
                    <div className="form-group">
                        <input type="text" className="form-control" ref={dietRef} />    
                    </div> 
                    {othervalueterr && <span>Need to fill the value</span>}
                    <button  onClick={addOtherValue}className="btn btn-primary w-100 mt-2">Ok</button>
                </div>
            </Modal.Body>
          </Modal>


                        <div className={`multiselectvaluesdiv ${props.RequiredError ? 'is-invalid' : ''}`} onClick={(e) => {setFieldVisiblity(true)}}>
                                { Multiselectvalues.length ? '' : props.PlaceHolder}
                                {
                                    Multiselectvalues.map((item,index) =>(
                                    <span key={item.value}>{item.value} <i onClick={()=>{removeChecked(item.value, index)}} className="icon-cross" /> </span>
                                    )) 
                                }
                                {
                                Multiselectvalues.map((item) =>(
                                    <input key={item.value} type="checkbox" ref={props.Ref} name={props.Fieldname}  value={item.value} checked="true" style={{display:"none"}}/>
                                    ))   
                                } 
                                </div>
                            {
                            fieldVisiblity &&(
                                <div className="multi_select_options" ref={myRef}>
                                <ul>
                                    {
                                        MultiselectPrevalues.map((item) => {
                                        if(item.value ==='None')
                                        {
                                            return (<li key={item.value}> <label><input onChange={handelchange} type="checkbox" checked={checkVaues.includes(item.value)? 'checked':''} value={item.value} /> {item.label}</label></li>)
                                        }
                                        return(<li key={item.value}> <label> <input onChange={handelchange} disabled={isfielddisable} type="checkbox" checked={checkVaues.includes(item.value)? 'checked': ''}  value={item.value}  /> {item.label}</label></li>
                                        )
                                        })
                                    }
                                </ul>        
                                </div>
                            )} 


        </React.Fragment>
    )
}

export default MultiselectDropdownCommon;