import React, { useCallback, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { addAdditionalExp } from '../../../services/directorServices'
import AdditionalExpComp from '../AdditionalExpComp';
import { notificationMsg } from '../../../helper';

const AddExp = ({ show, setShow, detail, data, refresh }) => {
    const [additionalExp, setAdditionalExp] = useState(data ?? [])
    const [error, setError] = useState('')

    const { programId, bookingId, camperId } = detail;
    const handleClickCross = () => {
        setShow(false)
    }
    const handleAddAdditionalExp = useCallback((index) => {
        if (additionalExp[index].numberOfBooking === 0){ 
            setError('Please increase quantity.')
            return;
        }
        const {quantity, question, ...rest} = additionalExp[index]
        const params = {
            programId,
            bookingId,
            camperId,
            actionType: 1,
            additionalExp: {
                answer: question,
                ...rest
            }
        }
        addAdditionalExp(params).then((res) => {
            const status = res.data.statusCode
            setShow(false)
            if (status) {
                notificationMsg(status, `Additional experience added successfuly.`)
                refresh()
                setAdditionalExp(data)
            } else {
                notificationMsg(0)
            }

        })
    }, [])
    return (
        <Modal
            dialogClassName="h_auto"
            show={show}
            animation={false}
            centered
        >
            <Modal.Header>
                <Modal.Title>Add Additional Experience</Modal.Title>
                <span className="modal_close" onClick={handleClickCross}><i className="icon-cross" /></span>
            </Modal.Header>
            <Modal.Body>
                <div className="p-2">
                    {error && <p className='text-danger'>{error}</p>}
                    <AdditionalExpComp
                        additionalExperience={additionalExp}
                        setAdditionalExperience={setAdditionalExp}
                        handleAddAdditionalExp={handleAddAdditionalExp}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddExp


const ConfirmAddExp = ({ show, setShow, detail, refresh, activeAddExp }) => {

    const { programId, bookingId, camperId } = detail;
    const handleClickCross = () => {
        setShow(false)
    }
    const handleAddAdditionalExp = () => {
        const params = {
            programId,
            bookingId,
            camperId,
            actionType: 2,
            additionalExp: {
                additionalExperienceId: activeAddExp?.additionalExperienceId,
                _id: activeAddExp?._id
            }
        }


        addAdditionalExp(params).then((res) => {
            const status = res.data.statusCode
            setShow(false)
            if (status) {
                notificationMsg(status, `Additional experience removed successfully`)
                refresh()
            } else {
                notificationMsg(0)
            }
        })
    }
    return (
        <Modal
            dialogClassName="h_auto"
            show={show}
            animation={false}
            centered
        >
            <Modal.Header>
                <Modal.Title>Remove Additional Experience</Modal.Title>
                <span className="modal_close" onClick={handleClickCross}><i className="icon-cross" /></span>
            </Modal.Header>
            <Modal.Body>
                <div className="p-2">
                    <p>Are you sure you want to remove it ?</p>
                    <div className='d-flex justify-content-end col-12'>
                        <span className='btn-sm btn-primary px-4' onClick={handleAddAdditionalExp}>Remove</span>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export { ConfirmAddExp }