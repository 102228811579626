import * as actionType from './actionsTypes';

const initialState = {
    authModal: false,
    isLoginModal: 0,
    otpModal: false,
    forgotModal: false,
    resetPasswordModal: false,
    mainLoader: false,
    miniLoader: false,
    successModal: false,
    passwordSuccessModal: false,
    mapViewModal: false,
    resendotpEmail: '',
    resendotpEmailLoader: false,
    tempEmail: '',
    activityModal: false,
    viewLoginModal: false,
    isNewCardAdd: false,
    addFolderModal: false,
    folderType: '',
    dProgramModal: false,
    dProgramId: '',
    isDirecterAuth: false,
    saveCabinId: '',
    waiverModal: false,
    waiverType: '',
    waiverURL: '',
    waiver2URL: '',
    waiver3URL: '',
    waiver4URL: '',
    porgramDates: '',
    campPhone: '',
    campEmail: '',

}


/************************************************************************************/
// UI Reducer
/************************************************************************************/


const uiReducer = (state = initialState, action) => {

    switch (action.type) {


        case actionType.MAIN_LOADER:
            return {
            ...state,
            mainLoader: !state.mainLoader
        }


        case actionType.MINI_LOADER:
            return {
            ...state,
            miniLoader: !state.miniLoader
        }


        case actionType.SUCCESS_MODAL:
            return {
            ...state,
            passwordSuccessModal: false,
            successModal: !state.successModal
        }

        case actionType.PASSWORD_SUCCESS_MODAL:
            return {
            ...state,
            successModal: false,
            passwordSuccessModal: !state.passwordSuccessModal,
        }


        case actionType.AUTH_MODAL:
            return {
            ...state,
            forgotModal: false,
            authModal: !state.authModal,
            isLoginModal: action.payload,
            viewLoginModal: false
        }


        case actionType.MAP_VIEW_MODAL:
            return {
            ...state,
            mapViewModal: !state.mapViewModal,
        }


        case actionType.IS_LOGIN_MODAL:
            return {
            ...state,
            forgotModal: false,
            authModal: true,
            isLoginModal: action.payload
        }


        case actionType.IS_OTP_MODAL:
            return {
            ...state,
            authModal: false,
            isLoginModal: 0,
            otpModal: !state.otpModal,
            isDirecterAuth: false
        }


        case actionType.FORGOT_MODAL:
            return {
            ...state,
            authModal: false,
            isLoginModal: 0,
            forgotModal: !state.forgotModal
        }


        case actionType.RESET_PASSWORD_MODAL:
            return {
            ...state,
            resetPasswordModal: !state.resetPasswordModal
        }


        case actionType.CLOSE_RESET_PASSWORD_MODAL:
            return {
            ...state,
            resetPasswordModal: false
        }


        case actionType.RESEND_OTP_EMAIL:
            return {
            ...state,
            resendotpEmail: action.payload
        }


        case actionType.RESEND_OTP_EMAIL_LOADER:
            return {
            ...state,
            resendotpEmailLoader: !state.resendotpEmailLoader
        }
        

        case actionType.TEMP_EMAIL:
            return {
            ...state,
            tempEmail: action.payload
        }

        case actionType.ACTIVITY_MODAL:
            return {
            ...state,
            activityModal: !state.activityModal
        }


        case actionType.VIEW_LOGIN_MODAL:
            return {
            ...state,
            isLoginModal: 1,
            authModal: !state.authModal,
        }


        case actionType.IS_NEW_CARD:
            return {
            ...state,
            isNewCardAdd: !state.isNewCardAdd
        }


        case actionType.ADD_FOLDER_MODAL:
            return {
            ...state,
            addFolderModal: !state.addFolderModal,
            folderType: action.payload
        }


        case actionType.D_PROGRAM_MODAL:
            return {
            ...state,
            dProgramModal: !state.dProgramModal,
            dProgramId: action.payload
        }


        case actionType.CHANGE_AUTH_ROLE:
            return {
            ...state,
            isDirecterAuth: !state.isDirecterAuth
        }


        case actionType.SAVE_CABIN_ID:
            return {
            ...state,
            saveCabinId: action.payload
        }

        case actionType.CHECK_IS_TEAMS_EZ:
            return {
            ...state,
            isTeamsEZ: !state.isTeamsEZ
        }


        case actionType.WAIVER_MODAL_AND_TYPE:
            return {
            ...state,
            waiverModal: action.payload.waiverModal,
            waiverType: action.payload.waiverType,
        }

        case actionType.WAIVER_URL:
            return {
            ...state,
            waiverURL: action.payload,
            waiverModal: false,
            waiverType: ''
        }

        case actionType.WAIVER2_URL:
            return {
            ...state,
            waiver2URL: action.payload,
            waiverModal: false,
            waiverType: ''
        }

        case actionType.WAIVER3_URL:
            return {
            ...state,
            waiver3URL: action.payload,
            waiverModal: false,
            waiverType: ''
        }

        case actionType.WAIVER4_URL:
            return {
            ...state,
            waiver4URL: action.payload,
            waiverModal: false,
            waiverType: ''
        }

        case actionType.CLEAR_WAIVERS:
            return {
            ...state,
            waiverURL: '',
            waiver2URL: '',
            waiverType: ''
        }
        case actionType.PROGRAM_DATES:
            return {
            ...state,
            porgramDates: action.payload,
        }
        case actionType.CAMP_CONTACT:
            return {
            ...state,
            campPhone: action.payload.campPhone,
            campEmail: action.payload.campEmail,
        }

        

        default: return state;
    }
}




/************************************************************************************/
// All UI Actions
/************************************************************************************/


export const mainLoaderAction = () => (dispatch => dispatch({ type: actionType.MAIN_LOADER }))

export const viewLoginModalAction = () => (dispatch => dispatch({ type: actionType.VIEW_LOGIN_MODAL }))

export const toggleAuthModal = payload => (dispatch => dispatch({ type: actionType.AUTH_MODAL, payload }))

export const toggleActivityModal = () => (dispatch => dispatch({ type: actionType.ACTIVITY_MODAL }))

export const changeAuthModal = payload => (dispatch => dispatch({ type: actionType.IS_LOGIN_MODAL, payload }))

export const toggleOtpModal = () => (dispatch => dispatch({ type: actionType.IS_OTP_MODAL}))

export const toggleForgotModal = () => (dispatch => dispatch({ type: actionType.FORGOT_MODAL}))

export const isNewCardAction = () => (dispatch => dispatch({ type: actionType.IS_NEW_CARD}))

export const dProgramModalAction = payload => (dispatch => dispatch({ type: actionType.D_PROGRAM_MODAL, payload}))

export const successModalAction = () => (dispatch => {
    dispatch({ type: actionType.SUCCESS_MODAL})
    dispatch({ type: actionType.CLEAR_LOADER})
})

export const passwordSuccessModalAction = () => (dispatch => dispatch({ type: actionType.PASSWORD_SUCCESS_MODAL}))

export const toggleResetPasswordModal = () => (dispatch => dispatch({ type: actionType.RESET_PASSWORD_MODAL}))

export const closeResetPasswordModal = () => (dispatch => dispatch({ type: actionType.CLOSE_RESET_PASSWORD_MODAL}))

export const toggleMapViewModal = () => (dispatch => dispatch({ type: actionType.MAP_VIEW_MODAL}))

export const toggleAddFolderModal = payload => (dispatch => dispatch({ type: actionType.ADD_FOLDER_MODAL, payload}))

export const resendotpEmailModal = payload => (dispatch => dispatch({ type: actionType.RESEND_OTP_EMAIL, payload}))

export const clearTempEmailAction = () => (dispatch => dispatch({ type: actionType.TEMP_EMAIL, payload: '' }))

export const changeRoleAction = () => (dispatch => dispatch({ type: actionType.CHANGE_AUTH_ROLE }))

export const checkIsTeamsEZAction = () => (dispatch => dispatch({ type: actionType.CHECK_IS_TEAMS_EZ }))

export const saveCabinIdAction = payload => (dispatch => dispatch({ type: actionType.SAVE_CABIN_ID, payload}))

export const waiverAction = payload => (dispatch => dispatch({ type: actionType.WAIVER_MODAL_AND_TYPE, payload}))

export const waiverURLAction = payload => (dispatch => dispatch({ type: actionType.WAIVER_URL, payload}))

export const waiver2URLAction = payload => (dispatch => dispatch({ type: actionType.WAIVER2_URL, payload}))

export const waiver3URLAction = payload => (dispatch => dispatch({ type: actionType.WAIVER3_URL, payload}))

export const waiver4URLAction = payload => (dispatch => dispatch({ type: actionType.WAIVER4_URL, payload}))

export const programDatesAction = payload => (dispatch => dispatch({ type: actionType.PROGRAM_DATES, payload}))

export const clearWaiversAction = () => (dispatch => dispatch({ type: actionType.CLEAR_WAIVERS}))

export const campContectAction = payload => (dispatch => dispatch({ type: actionType.CAMP_CONTACT, payload}))


export default uiReducer;