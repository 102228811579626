import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DatePicker, Select, InputNumber } from 'antd';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Redirect, Link } from 'react-router-dom';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import {
    fSearchAction,
    fGenderAction,
    fCategoriesAction,
    fDatesAction,
    fAdvanceSearchAction,
    fProgramTypeAction,
    fCityAction,
    fRadiusAction,
    fLatLongAction,
    filterLatLongAction,
    fAgeGroupAction,
    fGrageAction,
    fPriceRangeAction,
    fWeekDayAction,
    fEzProEatingAction,
    fCampersAction,
    clearAllFitlerAction,
    fCamperDisplayAction,
    fIsonlineOffline,
    clearSingleFitlerAction,
    clearDateFitlerAction,
    fPageNoAction
} from '../../store/pFilter';

import { filterProgramsAction, setSubCatAction } from '../../store/program';

import { dateFormat, programTypes, daysArr, PriceArr, gradeArr, ageArr, removeTime2, genders3 } from '../../helper';
import settings from '../../config/settings';



const { RangePicker } = DatePicker;
const { Option } = Select;


const MainFilter = props => {
    const [isRedirect, setIsRedirect] = useState(false);

    const handleCategory = e => {

        if(props.categories.includes(e._id)){
            let catArr = props.categories.filter(item => item !== e._id);
            props.fCategoriesAction(catArr);

            const subCatArr = props.fSubcategory.filter(el => el.categoryname  !== e.name);
            
            props.setSubCatAction(subCatArr);

        }else{
            
            let catArr = [...props.categories, e._id];
            props.fCategoriesAction(catArr);
            if(e.subCategory.length >0 )
            {
            let subcatlistwithname = { "categoryname": e.name, "catlist": e.subCategory }
            let subCatArr = [...props.fSubcategory, subcatlistwithname];
            props.setSubCatAction(subCatArr);
            }
        }  

    }


    const handleWeekDay = e => {
        if(props.weekDays.includes(e)){
            let weekArr = props.weekDays.filter(item => item !== e);
            props.fWeekDayAction(weekArr)
        }else{
            let weekArr = [...props.weekDays, e];
            props.fWeekDayAction(weekArr)
        }  
    }



    const handleCheckbox = e => {
        if(e.target.checked){
            props.fGenderAction(parseInt(e.target.value))
        }else{
            props.fGenderAction('')
        }
    }

    const handleRadio = e => {
        if(e.target.checked){
             props.fIsonlineOffline(parseInt(e.target.value))
        }else{
            props.fIsonlineOffline(null)
        }
    }    




    const handleCamperSelect = e => {
        let localCamper = props.camperList.filter(el => e.find(i => el._id === i) && el).map(n => n._id);
        props.fCamperDisplayAction(localCamper);
        if(e.length === 1){
            const singleCamper = props.camperList.filter(el => el._id === e)[0];
            props.fCityAction(singleCamper.city);
            props.fGenderAction(singleCamper.gender);
            props.fAgeGroupAction(`${singleCamper.age}-${singleCamper.age}`);
            props.fGrageAction(singleCamper.grade);
        }else{

            const multipleCamper = props.camperList.filter(el => e.find(i => el._id === i) && el).map(camper => ({
                camperId: camper._id,
                gender: camper.gender,
                age: camper.age,
                grade: camper.grade,
                city: camper.city
            }));

            props.fCityAction('');
            props.fGenderAction('');
            props.fAgeGroupAction('');
            props.fGrageAction('');
            
           props.fCampersAction(multipleCamper);
        }  
    }


    const handleFiterSearch = e => {
        props.fPageNoAction(1)
        e.preventDefault();
        if(props.isHome){
            setIsRedirect(true);
        }else{

            let { 
                keywordSearch,
                gender,
                ageGroup,
                grade,
                startDate,
                endDate,
                programType,
                city,
                radius,
                priceRange,
                ezProRating,
                categories,
                flat,
                flong,
                lat,
                long,
                pageNo,
                limit,
                weekDays,
                subCategories,
                campers,
                isOnline
            } = props;
        
            let params = { 
                keywordSearch,
                gender,
                ageGroup,
                grade,
                startDate,
                endDate,
                programType,
                city,
                radius,
                priceRange,
                ezProRating,
                flat,
                flong,                
                lat,
                long,
                pageNo: 1,
                limit,
                weekDays: weekDays.length ? JSON.stringify(weekDays) : '',
                categories: categories.length ? JSON.stringify(categories) : '',
                subCategories: subCategories.length ? JSON.stringify(subCategories) : '',
                campers: campers.length ? JSON.stringify(campers) : '',
                specialDeals: window.location.pathname.includes('special-offers'),
                isOnline,
            }

            if(window.location.href.includes('sports-international-camps')){
                params.campId = settings.api.sportInternationalCamp
            }else{
                params.campId = props.isDistrictCamps ? settings.api.districtCamp : ''
            }

            

            

            
            props.filterProgramsAction(params);


            const element = document.getElementById("camps_lists");
            element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }
    }


   
const getLatLong =(city) =>{
    geocodeByAddress(city)
    .then(results => {
      let city
      for (let i = 0; i < results.length; i++) {
          for (let j = 0; j < results[i].address_components.length; j++) {
              for (let k = 0; k < results[i].address_components[j].types.length; k++) {
                  if (results[i].address_components[j].types[k] === "locality") {
                      city = results[i].address_components[j].long_name;
                      if(city){
                        props.fCityAction(city);
                      }else{
                        props.fCityAction();
                      }
                  
                  }
              }
          }
      }
      return results;
    })
    geocodeByAddress(city)
    .then(results => getLatLng(results[0]))
    .then(latLng => props.filterLatLongAction(latLng))
    .catch(error => console.error('Error', error));
}
const  disabledDate = (current) => {
    return current && current < moment().startOf('day');
  }
const checkClity =(e) =>{
   if(e==='')
   {
    props.filterLatLongAction('',''); 
   }
}


    let { preList } = props;
    
    return(
        <div className={`main_filter ${props.hideCategory ? 'cm_hide_cat' : ''}`}>
            {/* <div className="clear_filter">
                <svg viewBox="0 0 56 56"><path  d="m0 3v50c.00483299 1.6548482 1.34515177 2.995167 3 3h44c1.6548482-.004833 2.995167-1.3451518 3-3v-50c-.004833-1.65484823-1.3451518-2.99516701-3-3h-44c-1.65484823.00483299-2.99516701 1.34515177-3 3zm18.78 24.11-11.56-11.55c-.23349074-.2378518-.36605376-.5567196-.37-.89.00015409-.3340494.13328959-.6542942.37-.89l3.56-3.56c.4921592-.49002032 1.2878408-.49002032 1.78 0l11.55 11.56c.4921592.4900203 1.2878408.4900203 1.78 0l11.55-11.56c.4921592-.49002032 1.2878408-.49002032 1.78 0l3.56 3.56c.2367104.2357058.3698459.5559506.37.89-.0039462.3332804-.1365093.6521482-.37.89l-11.56 11.55c-.4900203.4921592-.4900203 1.2878408 0 1.78l11.56 11.55c.2334907.2378518.3660538.5567196.37.89-.0001541.3340494-.1332896.6542942-.37.89l-3.56 3.56c-.4921592.4900203-1.2878408.4900203-1.78 0l-11.55-11.56c-.4921592-.4900203-1.2878408-.4900203-1.78 0l-11.55 11.56c-.4921592.4900203-1.2878408.4900203-1.78 0l-3.56-3.56c-.23671041-.2357058-.36984591-.5559506-.37-.89.00394624-.3332804.13650926-.6521482.37-.89l11.56-11.55c.4900203-.4921592.4900203-1.2878408 0-1.78z"/><path d="m56 3c-.004833-1.65484823-1.3451518-2.99516701-3-3h-2.03c.6648098.8590309 1.0269367 1.91376946 1.03 3v50c-.0030633 1.0862305-.3651902 2.1409691-1.03 3h2.03c1.6548482-.004833 2.995167-1.3451518 3-3z"/></svg>
            </div> */}
            {isRedirect ? <Redirect to="/camps" /> : ''}
            <form className="cm_radius_0" onSubmit={handleFiterSearch}>


                <div className="form-group cat_wrap_wrapper">
                    {props.hideCategory ? <Fragment><span className="clear_filters" onClick={props.clearAllFitlerAction}>Clear All</span><h3>Search</h3></Fragment> : ''}

                    {props.isDistrictFilter ? '' : preList.category.map(item => (
                                                    <OverlayTrigger
                                                        key={item._id}
                                                        delay={{ show: 10, hide: 200 }}
                                                        overlay={<Tooltip className="dark_tooltip">{item.name}</Tooltip>}>
                                                        <div className="cat_wrap_inner">
                                                            <div className="cat_wrap">
                                                                <input
                                                                    type="checkbox"
                                                                    value={item._id}
                                                                    checked={props.categories.includes(item._id)}
                                                                    onChange={() => handleCategory(item)}
                                                                />
                                                                
                                                                    <div className="cat_bg">
                                                                        <img src={item.icon} alt={"icon"} width={25} height={25}/>
                                                                    </div>
                                                            </div>
                                                            
                                                            
                                                        </div>
                                                    </OverlayTrigger>
                                                ))
                    }
                </div>

                

                
                
                <div className={`form-group ${props.loggedIn ? 'auto_scheduler' : ''}`}>
                    {props.loggedIn ? <Link to="/auto-scheduler" className="btn btn-secondary">Auto Scheduler</Link> : ''}
                    <label>Keyword Search</label>
                    <input
                        type="text"
                        value={props.keywordSearch}
                        className="form-control"
                        placeholder="Enter in Keyword or Program Name"
                        onChange={e => props.fSearchAction(e.target.value)}
                    />
                </div>


                {
                    props.loggedIn ? <div className="form-group">
                                        <label>Search by User</label>
              
                                        <Select
                                            onChange={handleCamperSelect}
                                            mode="multiple"
                                            disabled={!props.camperList.length}
                                            className="form-control"
                                            placeholder="Select based on Participant Profile"
                                            value={props.camperDisplay}
                                            
                                        >      
                                            {props.camperList.map(item => <Option key={item._id} value={item._id}>{item.firstName} {item.lastName ? item.lastName : '' } </Option>)}
                                        </Select>
                                    </div> : ''
                }





            <div className="row" style={{position: 'relative'}}>
                <div className="col-sm-8" style={{position: 'static'}}>
                    <div className="form-group" style={{position: 'static'}}>
                        <label>City</label>
                        <PlacesAutocomplete
                            className="form-control"
                            value={props.city ? props.city : ''}
                            onChange={(e) => {props.fCityAction(e); checkClity(e)}} 
                            onSelect={getLatLong} 
                            
                        >
                            
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input
                                
                                {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input form-control',   
                                })}
                                disabled={props.isOnline!=='' && props.isOnline === 1 ? 'disabled' : ''} />
                                <div className="autocomplete-dropdown-container cm_locations">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                    return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                        className,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                    );
                                })}

                                    
                                </div>
                            </div>
                            )}
                        </PlacesAutocomplete>

                        {
                      /*      
                        <Select
                            disabled={props.campers.length > 1}
                            className="form-control"
                            placeholder="Select City"
                            onChange={e => props.fCityAction(e)} 
                            value={props.city ? props.city : null}
                        >    
                            <Option key="cityselect" value="">Select City</Option> 
                            {preList.city && preList.city.map(item => <Option key={item} value={item}>{item}</Option>)}
                        </Select>
                       */ 
                        }
                    </div>
                </div>    

                     <div className="col-sm-4 form-group">
                        <label>Radius</label>
                        <InputNumber
                            placeholder="Radius"
                            className="form-control"
                            value ={props.radius}
                            onChange={e => props.fRadiusAction(e)} 
                            min={1}
                            max={500}
                            disabled={props.isOnline!=='' && props.isOnline === 1 ? 'disabled' : ''} />
                        {/* <small className="Mile_label">Miles</small> */}
                    </div> 
            </div>        
         


                


                <div className="d-flex cm_gendr">
                    {/* <label>Gender</label> */}
                    <ul className="d-flex">
                        {
                            genders3.map(item => (
                                <li className="me-2 check_box_wrapper" key={item.value}>
                                    <input
                                        disabled={props.campers.length > 1}
                                        type="checkbox"
                                        checked={props.gender === item.value}
                                        onChange={handleCheckbox}
                                        value={item.value}
                                        name={item.label}
                                    />
                                    <span className="check_box"><span /> {item.label}</span> 
                                </li>
                            ))
                        }

                    </ul>
                    
                </div>

               

                <div className="row">

                    <div className="form-group col-md-6">
                        <label>Age</label>
                        <Select
                            disabled={props.campers.length > 1}
                            className="form-control"
                            placeholder="Select Age"
                            onChange={e => props.fAgeGroupAction(e)}
                            value={props.ageGroup ? props.ageGroup : null}
                        >    
                            <Option key="selectAge" value="">Select Age</Option> 
                            {ageArr.map(item => <Option key={item} value={item}>{item}</Option>)}
                        </Select>
                        {props.ageGroup ? <i className="icon-cross field_clear" title="Clear age" onClick={() => props.clearSingleFitlerAction('ageGroup')} /> : ''}
                    </div>

                    <div className="form-group col-md-6">
                        <label>Grade</label>
                        <Select
                            disabled={props.campers.length > 1}
                            className="form-control"
                            placeholder="Select Grade"
                            onChange={e => props.fGrageAction(e)}
                            value={props.grade ? props.grade : null}
                        >    
                            <Option key="selectGrade" value="">Select Grade</Option> 
                            {gradeArr.map(item => <Option key={item} value={item}>{item}</Option>)}
                        </Select>
                        {props.grade ? <i className="icon-cross field_clear" title="Clear grade" onClick={() => props.clearSingleFitlerAction('grade')} /> : ''}
                    </div>

                </div>


                <div className="form-group">
                    <label>Select Dates</label>
                    <div>
                        <RangePicker
                            className="form-control"
                            disabledDate={disabledDate}
                            onChange={e => props.fDatesAction([new Date(removeTime2(e[0]._d)).getTime(), new Date(removeTime2(e[1]._d)).getTime()])}
                            value={props.startDate ? [
                                moment.utc(parseInt(props.startDate)),
                                moment.utc(parseInt(props.endDate))
                            ] : ''}
                        />
                        {props.startDate ? <i className="icon-cross field_clear" title="Clear Dates" onClick={props.clearDateFitlerAction} /> : ''}
                        
                    </div>
                </div>



                <div className="check_box_wrapper">
                    <input
                        type="checkbox"
                        checked={props.advanceSearch}
                        onChange={props.fAdvanceSearchAction} />

                    <span className="check_box">
                        <span />
                        Advance Search
                    </span>
                </div>


                {
                    props.advanceSearch ? (
                        <Fragment>
                            <div className="d-flex cm_gendr mt-3">
                    <label>Remove Online Programs <div style={{
                        display: 'inline-block',
                        verticalAlign: 'text-top',
                        top: '2px',
                        marginLeft: '10px'
                    }} className="check_box_wrapper"><input
                                        type="checkbox"
                                        value="0"
                                        checked={props.isOnline!=='' && props.isOnline === 0}
                                        onChange={handleRadio}
                                        //checked={props.gender === item.value}
                                    /><span className="check_box"><span /></span></div></label>
                    <ul className="d-flex">
                    {/* <li className="me-2 check_box_wrapper">
                                    <input
                                        type="checkbox"
                                        value="1"
                                        checked={props.isOnline === 1}
                                        onChange={handleRadio}
//checked={props.gender === item.value}
                                    />
                                    <span className="check_box"><span /> Online</span> 
                                </li> */}

                                {/* <li className="me-2 check_box_wrapper">
                                    
                                    <span className="check_box"><span /> Offline</span> 
                                </li> */}

                    </ul>
                    
                </div> 

                            <div className="form-group">
                                <label>Program Type</label>
                                <Select
                                    className="form-control"
                                    onChange={e => props.fProgramTypeAction(e)}
                                    value={props.programType ? props.programType : null}
                                    placeholder="Select Program Type"
                                >     
                                    {programTypes.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                </Select>
                                {props.programType ? <i className="icon-cross field_clear" title="Clear program type" onClick={() => props.clearSingleFitlerAction('programType')} /> : ''}
                            </div>


                            <div className="form-group">
                                <label>Choose start day of program</label>
                                <div className="day_wrapper">
                                    {
                                        daysArr.map((day, i) => (
                                            <div className="day_wrap" key={i}>
                                                <input
                                                    type="checkbox"
                                                    value={i}
                                                    checked={props.weekDays.includes(i)}
                                                    onChange={() => handleWeekDay(i)}
                                                />
                                                <div className="day_bg">{day}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>


                            <div className="row">

                                <div className="form-group col-md-6">
                                    <label>Choose Price Range</label>
                                    <Select
                                        className="form-control"
                                        placeholder="Select Price Range"
                                        onChange={e => props.fPriceRangeAction(e)}
                                        value={props.priceRange ? props.priceRange : null}
                                    >  
                                        <Option key="selectPriceRange" value="">Select Price Range</Option>  
                                        {PriceArr.map(item => <Option key={item} value={item}>{item}</Option>)}
                                    </Select>
                                    {props.priceRange ? <i className="icon-cross field_clear" title="Clear price range" onClick={() => props.clearSingleFitlerAction('priceRange')} /> : ''}
                                </div>

                                <div className="form-group col-md-6">
                                    <label>EZPRO Rating</label>
                                    <Select
                                        className="form-control"
                                        onChange={e => props.fEzProEatingAction(e)}
                                        placeholder="Select Rating"
                                        value={props.ezProRating ? props.ezProRating : null}> 
                                        <Option key="selectRating" value="">Select Rating</Option>  
                                        <Option value={1}>1</Option>
                                        <Option value={2}>2</Option>
                                        <Option value={3}>3</Option>
                                        <Option value={4}>4</Option>
                                        <Option value={5}>5</Option>
                                    </Select>
                                    {props.ezProRating ? <i className="icon-cross field_clear" title="Clear rating" onClick={() => props.clearSingleFitlerAction('ezProRating')} /> : ''}
                                </div>

                            </div>
                        </Fragment>
                    ) : ''
                }


                <div className="text-center mt-3">
                    <button type="submit" className="btn btn-secondary ps-5 pe-5">Search</button>
                </div>

            </form>
        </div>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {

    let { loggedIn } = state.user;

    let { preList, fSubcategory, camperList } = state.program;

    let { 
        keywordSearch,
        gender,
        ageGroup,
        grade,
        startDate,
        endDate,
        programType,
        weekDay,
        city,
        radius,
        priceRange,
        ezProRating,
        categories,
        flat,
        flong,
        lat,
        long,
        pageNo,
        limit,
        weekDays,
        subCategories,
        advanceSearch,
        campers,
        camperDisplay,
        isOnline,
        isDistrictCamps
    } = state.pFilter;

    return {
        fSubcategory,
        loggedIn,
        preList,
        keywordSearch,
        gender,
        ageGroup,
        grade,
        startDate,
        endDate,
        programType,
        weekDay,
        city,
        radius,
        priceRange,
        ezProRating,
        categories,
        flat,
        flong,
        lat,
        long,
        pageNo,
        limit,
        weekDays,
        subCategories,
        advanceSearch,
        campers,
        camperList,
        camperDisplay,
        isOnline,
        isDistrictCamps
    };
}
  
const mapDispatchToProps = dispatch => ({
    fSearchAction:               params => dispatch(fSearchAction(params)),
    fGenderAction:               params => dispatch(fGenderAction(params)),
    fCategoriesAction:           params => dispatch(fCategoriesAction(params)),
    fDatesAction:                params => dispatch(fDatesAction(params)),
    fProgramTypeAction:          params => dispatch(fProgramTypeAction(params)),
    fCityAction:                 params => dispatch(fCityAction(params)),
    fRadiusAction:               params => dispatch(fRadiusAction(params)),
    filterLatLongAction:         params => dispatch(filterLatLongAction(params)),
    fAgeGroupAction:             params => dispatch(fAgeGroupAction(params)),
    fGrageAction:                params => dispatch(fGrageAction(params)),
    fPriceRangeAction:           params => dispatch(fPriceRangeAction(params)),
    fWeekDayAction:              params => dispatch(fWeekDayAction(params)),
    filterProgramsAction:        params => dispatch(filterProgramsAction(params)),
    fEzProEatingAction:          params => dispatch(fEzProEatingAction(params)),
    setSubCatAction:             params => dispatch(setSubCatAction(params)),
    fCampersAction:              params => dispatch(fCampersAction(params)),
    fCamperDisplayAction:        params => dispatch(fCamperDisplayAction(params)),
    clearSingleFitlerAction:     params => dispatch(clearSingleFitlerAction(params)),
    fIsonlineOffline:            params => dispatch(fIsonlineOffline(params)),
    fPageNoAction:               params => dispatch(fPageNoAction(params)),
    fAdvanceSearchAction:            () => dispatch(fAdvanceSearchAction()),
    clearDateFitlerAction:           () => dispatch(clearDateFitlerAction()),
    clearAllFitlerAction:            () => dispatch(clearAllFitlerAction()),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(MainFilter);
