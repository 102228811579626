import React, { Fragment, useEffect, useReducer, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { InputNumber, Select, notification } from 'antd';
import CustomSelect from 'react-select';
import { connect } from 'react-redux';
import isURL from 'validator/lib/isURL';
import {
    CompositeDecorator,
    Editor,
    EditorState,
    RichUtils,
    convertToRaw,
    convertFromHTML,
    ContentState,
} from 'draft-js';
import { stateToHTML } from "draft-js-export-html";

import { getDirectorActivityService, editDirectorActivityService, directorActivityListService } from '../../services/activityService';
import { counselorlist } from '../../services/directorServices';
import { getHtml } from '../../helper';
import CustomDropDown from '../reuse/UIComponents/CustomDropDown';



const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
}


const MyLink = props => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={url} target='_blank'>
            {props.children}
        </a>
    );
};


const EditActivity = props => {
    let history = useHistory();
    const myRef = useRef();
    const [showURLInput, setShowURLInput] = useState(false);
    const [urlValue, setUrlValue] = useState('');
    const [urlValueErr, setUrlValueErr] = useState('');
    const [isAllSelect, setIsAllSelect] = useState(2);
    const [personnels, setPersonnels] = useState([]);
    const [counselors, setCounselors] = useState([]);
    const [addActivityLoader, setAddActivityLoader] = useState(false);

    const decorator = new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: MyLink,
        },
    ]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty(decorator));
    const [folders, setFolders] = useState([]);
    const [mode, setMode] = useState(1);
    const [foldername, setFoldernames] = useState([]);
    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            activityName: '',
            timeNeeded: '',
            equipment: '',
            equipmentSingle: '',
            cost: 0,
            category: '',
            oldCategory: '',
            folder: [],
            description: '',
        }
    );

    const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            activityName: '',
            timeNeeded: '',
            equipment: '',
            cost: '',
            category: '',
            folder: '',
            description: ''
        }
    );


    useEffect(() => {
        getActivities();

        let params = `/`
        counselorlist(params).then((res) => {
            if (res.data.statusCode) {
                setCounselors(res.data.responseData.result.counselor)
            }
        })
    }, [])



    const getActivities = () => {
        directorActivityListService('').then(res => {
            if (res.data.statusCode) {
                setFolders(res.data.responseData.result.folder);
            }
        })
    }

    useEffect(() => {
        getActivities();
    }, [])


    useEffect(() => {
        let query = `?activityId=${props.match.params.id}`;
        getDirectorActivityService(query).then(res => {
            if (res.data.statusCode) {
                setFoldernames(res.data.responseData.result.folder);

                const blocksFromHTML = convertFromHTML(res.data.responseData.result.description);
                const state = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap,
                );

                setEditorState(EditorState.createWithContent(
                    state,
                    decorator,
                ));

                let _equipment = [];

                if(res.data.responseData.result.equipment.length){
                    _equipment = res.data.responseData.result.equipment.split(',')
                }

                setUserInput({
                    activityName: res.data.responseData.result.activityName,
                    timeNeeded: res.data.responseData.result.timeNeeded,
                    equipment: _equipment,
                    cost: res.data.responseData.result.cost,
                    category: res.data.responseData.result.category,
                    oldCategory: res.data.responseData.result.category,
                    folder: res.data.responseData.result.folderIds,
                    description: res.data.responseData.result.description
                })

                let tempArr = res.data.responseData.result.personnels || [];
                let fiterArr = counselors.filter(el => tempArr.find(i => el._id === i) && el).map(n => ({ value: n._id, label: `${n.name} ${n.email}` }))
                setPersonnels(fiterArr);

                if (tempArr.length === counselors.length) {
                    setIsAllSelect(1)
                } else {
                    setIsAllSelect(2)
                }
            }
        })
    }, [counselors])






    const handleChange = e => {
        setUserInput({ [e.target.name]: e.target.value });
        setError({ [e.target.name]: '' });
    }


    const handleNumberChange = (e, name) => {
        setUserInput({ [name]: e });
        setError({ [name]: '' });
    }



    const addEquipment = () => {
        if (userInput.equipmentSingle) {
            setUserInput({
                equipment: userInput.equipment ? [...userInput.equipment, userInput.equipmentSingle] : [userInput.equipmentSingle],
                equipmentSingle: '',
                cost: 0
            })
        }

    }


    const removeEquipment = i => {
        let tempEquipment = userInput.equipment;
        tempEquipment.splice(i, 1);
        setUserInput({ equipment: tempEquipment })
    }



    const handleValidate = () => {
        let validate = true;
        let {
            activityName,
        } = userInput;

        if (activityName.trim() === '') {
            setError({ activityName: 'Name is required' });
            validate = false
        }else if(activityName.length > 150){
            setError({ activityName: 'Activity name max length 150 characters'});
            validate = false
        }


        const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
        const rowValue = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

        if (rowValue.length <= 1) {
            setError({ description: 'Description is required' });
            validate = false
        }

        return validate;
    }


    const handleSubmit = e => {
        e.preventDefault();

        if (handleValidate()) {
            setAddActivityLoader(true);
            
            let {
                activityName,
                timeNeeded,
                equipment,
                cost,
                category,
                oldCategory,
                folder,
            } = userInput;

            let params = '';


            if (folder) {
                params = {
                    activityId: props.match.params.id,
                    activityName,
                    timeNeeded,
                    equipment: equipment ? equipment.join(', ') : '',
                    cost,
                    folder: folder ? JSON.stringify(folder) : null,
                    description: stateToHTML(editorState.getCurrentContent()),
                }
                if (oldCategory !== category) {
                    params.category = category
                }
            } else {
                params = {
                    activityId: props.match.params.id,
                    activityName,
                    timeNeeded,
                    equipment: equipment ? equipment.join(', ') : '',
                    cost,
                    description: stateToHTML(editorState.getCurrentContent()),
                }
                if (oldCategory !== category) {
                    params.category = category
                }
            }

            if (isAllSelect === 1) {
                params.personnels = JSON.stringify(counselors.map(item => item._id));

            } else {
                if (personnels !== null && personnels?.length) {
                    params.personnels = JSON.stringify(personnels.map(item => item.value));
                } else {
                    params.personnels = JSON.stringify([]);
                }
            }

            editDirectorActivityService(params).then(res => {
                setAddActivityLoader(false);
                if (res.data.statusCode) {
                    history.push('/activity')
                    notification.open({
                        message: 'Activity',
                        description:
                            'Activity Edit Successfull',
                    });
                }
            })
        }

    }


    const handelFolderAction = (e) => {

        let tempVal = e ? e.map(i => i.value) : '';
        if (tempVal.length === 0) {
            setUserInput({ folder: [] })
        }
        else {
            setUserInput({ folder: tempVal })
        }
    }

    let prevalus = []
    folders.map(item => {
        if (userInput.folder.includes(item._id)) {
            let obj = { "value": item._id, "label": item.folderName }
            prevalus.push(obj);
        }
    })







    const promptForLink = e => {
        e.preventDefault();
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = editorState.getSelection().getStartKey();
            const startOffset = editorState.getSelection().getStartOffset();
            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
            let url = '';
            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey);
                url = linkInstance.getData().url;
            }
            setShowURLInput(true);
            setUrlValue(url);
        }
    }



    const removeLink = e => {
        e.preventDefault();
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            setEditorState(RichUtils.toggleLink(editorState, selection, null))
        }
    }




    const confirmLink = e => {
        e.preventDefault();

        let validate = true;
        if(urlValue.trim() === ''){
            validate = false;
            setUrlValueErr('URL is  required')
        }else if(!isURL(urlValue)){
            validate = false;
            setUrlValueErr('URL is not valid')
        }else if(!urlValue.startsWith('http')){
            validate = false;
            setUrlValueErr('URL is not valid please add http:// or https:// Before your URL')
        }

        if(validate){
            const contentState = editorState.getCurrentContent();

            const contentStateWithEntity = contentState.createEntity(
                'LINK',
                'MUTABLE',
                {url: urlValue, target: '_blank'},
            );
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    
            let nextEditorState = EditorState.set(editorState,
                { currentContent: contentStateWithEntity }
            );
    
            nextEditorState = RichUtils.toggleLink(nextEditorState,
                nextEditorState.getSelection(), entityKey
            );
    
            setEditorState(nextEditorState);
            setShowURLInput(false);
            setUrlValue('');
        }

    }


    const onLinkInputKeyDown = e => { if (e.which === 13) { confirmLink(e); } }

    const handleAllSelect = e => {
        setIsAllSelect(isAllSelect === 1 ? 2 : 1);
        if (isAllSelect === 1) {
            setPersonnels([]);
        }


    }

    return (
        <div className="body_container pb-4 director_wrapper">
            <div className="container mt-5">

                <div className="row">
                    <div className="col-lg-2" />

                    <div className="col-lg-8">


                        <form onSubmit={handleSubmit} className="donate_wrap dark_labal">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label>Activity Name</label>
                                    {
                                        (mode === 1) ?
                                            <p><b>{userInput.activityName}</b></p>
                                            :
                                            <Fragment>
                                                <input
                                                    type="text"
                                                    name="activityName"
                                                    value={userInput.activityName}
                                                    className={`form-control ${error.activityName ? 'is-invalid' : ''}`}
                                                    placeholder="Enter Activity Name"
                                                    onChange={handleChange} />

                                                {error.activityName ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.activityName}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </Fragment>
                                    }

                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Time Needed</label>
                                    {
                                        (mode === 1) ?
                                            <p><b>{userInput.timeNeeded || 0} minutes</b></p>
                                            :
                                            <Fragment>
                                                <InputNumber
                                                    min={0}
                                                    precision={0}
                                                    step={1}
                                                    className={`form-control ${error.timeNeeded ? 'is-invalid' : ''}`}
                                                    value={userInput.timeNeeded}
                                                    placeholder="Enter Time Needed"
                                                    onChange={e => handleNumberChange(e, 'timeNeeded')}
                                                />

                                                {error.timeNeeded ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.timeNeeded}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </Fragment>
                                    }


                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label>Equipment</label>
                                    {
                                        (mode === 1) ?
                                            <div className="equipment_row">
                                                <div className="equipment_chip">{userInput.equipment ? userInput.equipment.length ? userInput.equipment.map((item, i) => <span key={i}>{item}</span>) : <b>N/A</b> : <b>N/A</b>}</div>
                                            </div>
                                            :
                                            <Fragment>
                                                <input
                                                    type="text"
                                                    name="equipmentSingle"
                                                    value={userInput.equipmentSingle}
                                                    onChange={handleChange}
                                                    className={`form-control ${error.equipment ? 'is-invalid' : ''}`} />

                                                {error.equipment ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.equipment}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}


                                                <div className="equipment_row">
                                                    <span className="" onClick={addEquipment}><i className="icon-plus" /> Add Equipment</span>

                                                    <div className="equipment_chip">{userInput.equipment ? userInput.equipment.map((item, i) => <span key={i}>{item} <i onClick={() => removeEquipment(i)} className="icon-cross" /></span>) : ''}</div>
                                                </div>
                                            </Fragment>
                                    }



                                </div>


                                <div className="col-md-6 form-group">
                                    <label>Cost</label>
                                    {
                                        (mode === 1) ?
                                            <p><b>{userInput.cost ? `$${userInput.cost?.toFixed(2)}` : 'N/A'}</b></p>
                                            :
                                            <Fragment>
                                                <InputNumber
                                                    min={0}
                                                    precision={2}
                                                    className={`form-control ${error.cost ? 'is-invalid' : ''}`}
                                                    value={userInput.cost}
                                                    placeholder="Enter Cost"
                                                    onChange={e => handleNumberChange(e, 'cost')}
                                                />

                                                {error.cost ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.cost}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </Fragment>
                                    }
                                </div>
                            </div>



                            <div className="row">
                          
                                    {
                                        (mode === 1) ?
                                        <div className="col-md-6 form-group">
                                            <label>Category</label>
                                            <p><b>{userInput.category || 'N/A'}</b></p>
                                        </div>
                                            :
                                            <CustomDropDown
                                                disabled={!props.category.length}
                                                value={userInput.category}
                                                className="form-control"
                                                label={'Category'}
                                                error={error.category}
                                                placeholder="Select Category"
                                                onChange={e => handleNumberChange(e, 'category')}
                                                options={props.category ? props.category.map(item => ({value: item._id,lable:item.name})) : [{value: undefined , label: " Not Availabe"}]}
                                                divClass={'col-md-6 form-group'}
                                            />
                                    }


                                



                                <div className="col-md-6 form-group" style={{position: 'relative', zIndex: 99}}>
                                    <label>Assign to Folder</label>
                                    {
                                        (mode === 1) ?
                                            <p>
                                                {
                                                    foldername.length ? foldername.map((item, i) => (
                                                        <b>{item}{i === foldername.length - 1 ? "" : ", "}</b>
                                                    )) : <b>N/A</b>
                                                }
                                            </p>
                                            :
                                            <CustomSelect
                                                isMulti
                                                placeholder="Select"
                                                onChange={e => handelFolderAction(e)}
                                                value={prevalus}
                                                options={folders.map(item => ({ value: item._id, label: item.folderName }))} />
                                    }

                                </div>


                            </div>


                            <div className="form-group" style={{position: 'relative', zIndex: 9}}>
                                <label>Send ‘activity’/alert personnel of activity</label>
                                {
                                    mode === 1 ?

                                        <div className="row">
                                            <div className="col-md-12">
                                                {isAllSelect === 1 ? <p><b>Send to All</b></p> : <p>{personnels?.length ? personnels.map((item, i) => <span className="cm__chip">{item.label}</span>) : <b>None</b>}
                                                </p>}

                                            </div>

                                        </div>

                                        :

                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="check_box_wrapper cm_dark_check">
                                                    <input
                                                        type="checkbox"
                                                        onChange={handleAllSelect}
                                                        checked={isAllSelect === 1 ? 'checked' : ''} />
                                                    <span className="check_box"><span />Send to All</span>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                {isAllSelect === 1 ? '' : <CustomSelect
                                                    isMulti
                                                    placeholder="Select Personnel"
                                                    value={personnels}
                                                    onChange={e => setPersonnels(e)}
                                                    options={counselors.map(item => ({ value: item._id, label: `${item.name} ${item.email}` }))} />
                                                }
                                            </div>
                                        </div>
                                }

                            </div>




                            {mode === 1 ?
                                <div className="form-group">
                                    <label>Description</label>
                                    <p><b>{getHtml(userInput.description)}</b></p>
                                </div>
                                :
                                <div className={`form-group editer_wrapper ${error.description ? 'is__invalid' : ''}`}>

                                    <label>Description</label>

                                    <div className="editer_btns">
                                        <button className="btn btn-sm btn-secondary ps-4 pe-4" type="button" onMouseDown={promptForLink}>Add Link</button>
                                        <button className="btn btn-sm btn-info ms-3 ps-4 pe-4" type="button" onMouseDown={removeLink}>Remove Link</button>
                                    </div>

                                    <Editor
                                        editorState={editorState}
                                        onChange={e => (setEditorState(e), setError({ description: '' }))}
                                        placeholder="Write Description here…"
                                        ref={myRef}
                                    />

                                    {error.description ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.description}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}


                                    <Modal
                                        centered
                                        dialogClassName="remove_header_line"
                                        show={showURLInput}
                                        onHide={() => setShowURLInput(false)}>
                                        <Modal.Body>

                                            <span className="modal_close" onClick={() => setShowURLInput(false)}><i className="icon-cross" /></span>
                                            {/* <div className="p-5">
                                                <label>Type or paste your link in the text box</label>
                                                <input
                                                    onChange={e => setUrlValue(e.target.value)}
                                                    type="text"
                                                    className="form-control"
                                                    value={urlValue}
                                                    placeholder="https://www.example.com/"
                                                    onKeyDown={onLinkInputKeyDown}
                                                />
                                                <button disabled={urlValue ? false : true} className="btn btn-primary mt-4 w-100" onMouseDown={confirmLink}> Confirm </button>
                                            </div> */}

                                            <div className="p-5">
                                                <label>Type or paste your link in the text box</label>
                                                <input
                                                    onChange={e => (setUrlValue(e.target.value), setUrlValueErr(''))}
                                                    type="text"
                                                    className={`form-control ${urlValueErr ? 'is-invalid' : ''}`}
                                                    value={urlValue}
                                                    placeholder="https://www.example.com/"
                                                    onKeyDown={onLinkInputKeyDown}
                                                />
                                                {urlValueErr ? <div className='res_err'>{urlValueErr}</div> : ''}
                                                <button className="btn btn-primary mt-4 w-100" onMouseDown={confirmLink}> Confirm </button>
                                            </div>
                                        </Modal.Body>
                                    </Modal>

                                </div>}


                            <div className="d-flex justify-content-center mt-5">
                                {
                                    (mode === 1) ?
                                        <span onClick={() => setMode(0)} className="btn btn-primary ps-5 pe-5 ms-1 rounded-0">Edit</span>
                                        :
                                        <Fragment>



                                            <span onClick={() => setMode(1)} className="btn btn-dark ps-5 pe-5 me-1 rounded-0">Cancel</span>
                                           
                                            <button
                                                type="submit"
                                                disabled={addActivityLoader}
                                                className={`btn btn-primary rounded-0  ps-5 pe-5 btn_anim ${addActivityLoader ? 'show_anim' : ''}`}>{addActivityLoader ? 'Loading...' : 'Submit'}</button>
                                        </Fragment>
                                }


                            </div>


                        </form>




                    </div>
                </div>

            </div>
        </div>
    )
}


const mapStateToProps = state => {
    let { preList } = state.program;
    let { category } = preList;
    return {
        category
    }
}

export default connect(mapStateToProps, null)(EditActivity);