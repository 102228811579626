import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

const NoteModal = ({ show, handleClose, handleUpdate }) => {
    const [note, setNote] = useState('')
    const [noteErr, setNoteErr] = useState('')

    const handleClickUpdate = () => {
        if(!note){
            setNoteErr("please enter a reason")
            return; 
        }
        handleUpdate(note);
        setNote('')
        setNoteErr('')
    }
    const handleClickCross = () => {
        setNote('')
        setNoteErr('')
        handleClose(false)
    }
    return (
        <Modal
            dialogClassName="h_auto"
            show={show}
            animation={false}
            centered
        >
            <Modal.Header>
                <Modal.Title>Update Commuter Status</Modal.Title>
                <span className="modal_close" onClick={handleClickCross}><i className="icon-cross" /></span>
            </Modal.Header>
            <Modal.Body>
                <div className="p-2">
                    <div className={`form-group`}>
                        <label>Reason</label>
                        <input
                            type='text'
                            className='form-control'
                            value={note}
                            onChange={e => (setNote(e.target.value), setNoteErr(''))}
                        />
                        {noteErr ? <h6 className='cm_err'>{noteErr}</h6> : ''}
                    </div>
                    <span onClick={handleClickUpdate} className='btn btn-primary'>Update</span>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default NoteModal
