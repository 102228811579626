import React from 'react'
import { Modal } from 'react-bootstrap'
import ReactSelect from 'react-select';
import ChargedPaymentGateway from '../../payment/ChargedPaymentGateway';

const ChargeCamperModal = ({ chargeModal, handleCloseChargeModal, modalState, handleCamperChange, handleChargeAmountReason, handleChargeAmountChange, userId }) => {
    return (
        <Modal
            dialogClassName="h_auto"
            show={chargeModal}
            animation={false}
            onHide={() => handleCloseChargeModal(0)}
            centered>
            <Modal.Header
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Modal.Title >Additional Charge Info.</Modal.Title>
                <span className="modal_close" onClick={() => handleCloseChargeModal(0)}><i className="icon-cross" /></span>
            </Modal.Header>
            <Modal.Body>

                <div className='text-center px-4'>
                    {
                        (modalState.camperList?.length > 0) &&
                        <div className="form-group">
                            <label>Select Camper</label>
                            <ReactSelect
                                placeholder='Select camper'
                                className="form-control ms-3"
                                classNamePrefix="cm_select"
                                value={modalState?.camperList.find(li => li.value === modalState.selectedCamper)}
                                onChange={e => (handleCamperChange(e.value))}
                                options={modalState.camperList.length > 0 ? modalState.camperList : [{ label: 'No Camper', value: "No Camper" }]}
                            />
                        </div>
                    }
                    {modalState.selectedCamper &&
                        <div className="form-group">
                            <label>Enter the reason to charge this Amount.</label>
                            <textarea
                                placeholder='Enter the reason to charge this amount........'
                                className="form-control ms-3 mt-2"
                                onChange={(e) => handleChargeAmountReason(e.target.value)}
                            />
                            {modalState?.reasonErr && <span className='text-danger mt-2 ms-3'>{modalState.reasonErr}</span>}
                        </div>
                    }
                    {modalState.description &&
                        <div className="form-group">
                            <label>Enter Amount to be charged.</label>
                            <input
                                placeholder='Enter Amount to be charged......'
                                className="form-control ms-3 "
                                type='number'
                                onChange={(e) => handleChargeAmountChange(Number(e.target.value))}
                            />
                        </div>
                    }
                </div>
                {modalState.amount > 0 ? <ChargedPaymentGateway AllData={modalState} userId={userId} callback={handleCloseChargeModal}/> : ''}

            </Modal.Body>
        </Modal>
    )
}

export default ChargeCamperModal
