import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Campers from '../profile/campers'
import { profileInformation } from '../../services/campersServices'
import { ProgramLoader } from '../reuse/SVG'
// import Campers from '../cart/addcampers/'

const AddParticipantModal = ({ show, hideModal, cartId, getCartItem }) => {
    const [UserCamperList, setUserCamperList] = useState([])
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        loadProfileInfo()
    }, [])

    const loadProfileInfo = async () => {
        const res = await profileInformation()
        if (res.data.statusCode === 1) {
            let profileInfo = res.data.responseData.result;
            setUserCamperList(profileInfo.campersData);
            setLoader(false);
        }
        else {
            setLoader(false);
        }

    }
    const handleCancel = () => {
        hideModal()
        getCartItem(cartId)
    }
    return (
        <Modal show={show} centered dialogClassName="remove_header_line" size="lg">
            <Modal.Header>
                <span className="modal_close" onClick={hideModal}><i className="icon-cross" /></span>
            </Modal.Header>
            <Modal.Body className='mx-2'>
                {
                    loader ? <div className='program_loader'><ProgramLoader /></div> :
                        <Campers
                            addcampersfromcart={true}
                            numberOfCampers={1}
                            cartId={cartId}
                            handleCancel={handleCancel}
                            UserCamperList={UserCamperList}
                        />
                }

            </Modal.Body>
        </Modal>
    )
}

export default AddParticipantModal
