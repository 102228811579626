import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { DatePicker, TimePicker, Select } from 'antd';
import moment from 'moment';

import { toggleActivityModal } from '../../store/ui';
import { addActivityService, editActivityService } from '../../services/activityService';

const { RangePicker } = DatePicker;
const TimeRangePicker = TimePicker.RangePicker;
const { Option } = Select;


const ActivityModal = props => {
    const [categories, setCategories] = useState([]);
    const [activityId, setActivityId] = useState('');
    const [loader, setLoader] = useState(false);
    const [userInput, setUserInput] = useReducer((state, newState) => ({...state, ...newState}),
        {
            name: '',
            date: new Date().getTime(),
            startTime: '',
            endTime: '',
            desc: '',
            cat: '',
        }
    );

    const [error, setError] = useReducer((state, newState) => ({...state, ...newState}),
        {
            name: '',
            date: '',
            startTime: '',
        }
    );


    useEffect(() => {
        if(props.openActivity){
            setUserInput({
                name: props.openActivity.name,
                date: props.openActivity.date,
                startTime: props.openActivity.startTime,
                endTime: props.openActivity.endTime,
                desc: props.openActivity.desc,
            });

            setCategories(props.openActivity.categories);
            setActivityId(props.openActivity._id);
        }else{
            setUserInput({
                name: '',
                date: new Date().getTime(),
                startTime: '',
                endTime: '',
                desc: '',
                cat: '',
            });
            setCategories([]);
            setActivityId('');
        }

    }, [props.openActivity])



    const handleChange = e => {
        setUserInput({[e.target.name]: e.target.value});
        setError({[e.target.name]: ''});
    }

    const handleDateChange = e => {
        setUserInput({date: e._d.getTime()});
        setError({date: ''});
    }

    const handleTimeChange = e => {
        setUserInput({
            startTime: moment(e[0]._d.getTime()).format('hh:mm:ss'),
            endTime: moment(e[1]._d.getTime()).format('hh:mm:ss')
        });
        setError({startTime: ''});
    }


    const AddCat = () => {
        if(userInput.cat !== ''){
            setCategories([...categories, userInput.cat]);
            setUserInput({cat: ''});
        }
    }



    const handleValidate = () => {
        let validate = true;
        let { name, date, startTime } = userInput;

        if(name === ''){
            setError({name: 'Name is required'});
            validate = false
        }
        if(date === ''){
            setError({date: 'Date is required'});
            validate = false
        }
        if(startTime === ''){
            setError({startTime: 'Time is required'});
            validate = false
        }

        return validate;
    }


    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            let { name, date, startTime, endTime, desc } = userInput;
            let params = {activityId, name, date, startTime, endTime, desc, categories: JSON.stringify(categories)};
            setLoader(true);

           if(props.openActivity){
            editActivityService(params).then(res => {
                setLoader(false);
                if(res?.data?.statusCode){
                    setUserInput({
                        date: new Date().getTime(),
                        date: '',
                        startTime: '',
                        endTime: '',
                        desc: '',
                        cat: '',
                    })
                   
                    props.toggleActivityModal();

                }
            });
           }else{
            addActivityService(params).then(res => {
                setLoader(false);
                if(res?.data?.statusCode){
                    setUserInput({
                        name: '',
                        date: new Date().getTime(),
                        startTime: '',
                        endTime: '',
                        desc: '',
                        cat: '',
                    })
                    props.toggleActivityModal();

                }
            });
           }
            
        }
    }



    const handleRemove = index => {
        let tempArr = categories.filter((val, i) => i !== index) ;
        setCategories(tempArr)
    }


    return(
        <Modal
            show={props.activityModal}
            animation={false}
            dialogClassName="pickup_modal"
            centered
        >
            <Modal.Body>
                
                <span className="modal_close" onClick={props.toggleActivityModal}><i className="icon-cross" /></span>
                <h3 className="text-center mb-4">{props.openActivity ? 'Edit' : 'Add'} Activity</h3>

                <form onSubmit={handleSubmit}>
                    <div className="form-group cm_err_toltip">
                        <label>Name</label>
                        <input
                            type="text"
                            className={`form-control ${error.name ? 'is-invalid' : ''}`}
                            name="name"
                            value={userInput.name}
                            onChange={handleChange} />

                        {error.name ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.name}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                    </div>


                    <div className="form-group cm_err_toltip">
                        <label>Date</label>
                        <DatePicker
                            onChange={handleDateChange}
                            value={moment.utc(userInput.date)}
                            className={`form-control ${error.date ? 'is-invalid' : ''}`}
                        />

                        {error.date ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.date}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                    </div>

                    <div className="form-group cm_err_toltip">
                        <label>Time</label>
                        <TimeRangePicker
                            onChange={handleTimeChange}
                            use12Hours
                            format="h:mm a"
                            value={userInput.startTime ? [moment(userInput.startTime, 'h:mm: a'), moment(userInput.endTime, 'h:mm a')] : ''}
                            className={`form-control ${error.startTime ? 'is-invalid' : ''}`}
                        />

                        {error.startTime ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.startTime}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                    </div>


                    <div className="form-group">
                        <label>Category</label>
                        <input
                            type="text"
                            className="form-control"
                            name="cat"
                            value={userInput.cat}
                            onChange={handleChange} />

                        <span className="btn btn-primary add_cat_btn" onClick={AddCat}>add</span>

                        {categories.map((item, i) => <span className="cm_tag"><i className="icon-close" onClick={() => handleRemove(i)} />{item}</span>)}
                    </div>

                    <div className="form-group">
                        <label>Description</label>
                        <textarea
                            type="text"
                            className="form-control"
                            name="desc"
                            value={userInput.desc}
                            onChange={handleChange} />
                    </div>


                    <button disabled={loader} type="submit" className="btn btn-primary w-100">{props.openActivity ? 'Save' : 'Create'}</button>


                </form>

            </Modal.Body>
        </Modal>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { activityModal } = state.ui;
    let { openActivity } = state.calendar;
    return {
        activityModal,
        openActivity
    };
}
  
const mapDispatchToProps = dispatch => ({
    toggleActivityModal: () => dispatch(toggleActivityModal()),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(ActivityModal);