import ApiInstance from './intercepter';
import API from '../config/api';

export const directorprograms = params => ApiInstance.get(API.DIRECTOR_PROGRAMS+params);
export const directorprogramstatus = params => ApiInstance.post(API.DIRECTOR_PROGRAMS_STATUS,params);
export const campprograms = params => ApiInstance.get(API.CAMP_PROGRAMS+params);
export const addcounselor = params => ApiInstance.post(API.ADD_COUNSELOR, params);
export const counselorlist = params => ApiInstance.get(API.COUNSELOR_LIST+params);
export const getDistrictsService = () => ApiInstance.get(API.DISTRICT_LIST);
export const counselorAllList = () => ApiInstance.get(API.COUNSELOR_ALL_LIST);
export const createcounselorfolder = params => ApiInstance.post(API.CREATE_COUNSELOR_FOLDER, params);
export const editcounselor = params => ApiInstance.post(API.EDIT_COUNSELOR, params);
export const userStatusChangeService = params => ApiInstance.put(API.USER_STATUS_CHANGE, params);
export const deletecounselorFolder = params => ApiInstance.delete(API.DELETE_COUNSELOR_FOLDER+params);
export const counselordetail = params => ApiInstance.get(API.COUNSELOR_DETAILS+params);
export const getPersonnelActivityListService = params => ApiInstance.get(API.GET_PERSONNEL_ACTIVITY_LIST+params);
export const counselorfoders = params => ApiInstance.get(API.COUNSELOR_FOLDERS);
export const counselorfoderdetails = params => ApiInstance.get(API.COUNSELOR_FOLDERS_DETAILS+params);
export const dProgramDetail = params => ApiInstance.get(API.D_PROGRAM_DETAIL+params);
export const dProgramEdit = params => ApiInstance.post(API.D_PROGRAM_EDIT, params);
export const dProgramCreate = params => ApiInstance.post(API.D_PROGRAM_CREATE, params);
export const updateProgramImg = params => ApiInstance.post(API.UPDATE_PROGRAM_IMG, params);
export const editProgram = params => ApiInstance.post(API.EDIT_PROGRAM, params);
export const bookingCamperDetailService = params => ApiInstance.get(API.BOOKING_CAMPER_DETAIL + params);
export const getAdditionalExperienceService = params => ApiInstance.post(API.GET_ADDITIONAL_EXP, params);
export const updateAdditionalExp = params => ApiInstance.post(API.UPDATE_ADDITIONAL_EXP, params);
export const programDelete = params => ApiInstance.delete(API.PROGRAM_DELETE, {data: params});
export const directorbookinglist = params => ApiInstance.get(API.DIRECTOR_BOOKING_LIST+params);
export const directorCamperlist = params => ApiInstance.get(API.DIRECTOR_CAMPER_LIST+params);
export const createBookingfolder = params => ApiInstance.post(API.CREATE_BOOKING_FOLDER, params);
export const deleteBookingfolder = params => ApiInstance.delete(API.DELETE_BOOKING_FOLDER+params);
export const getProgramBookingDetail = params => ApiInstance.get(API.GET_PROGRAM_BOOKING_DETAIL+params);
export const getProgramFolderDetail = params => ApiInstance.get(API.BOOKING_FOLDERS_DETAILS+params);
export const createbookingcamperfolder = params => ApiInstance.post(API.CREATE_BOOKING_CAMPER_FOLDER,params);
export const deleteBookingCamperFolder = params => ApiInstance.delete(API.DELETE_BOOKING_CAMPER_FOLDER+params);
export const bookingCamperFolderDetails = params => ApiInstance.get(API.BOOKING_CAMPER_FOLDER_DETAILS+params);
export const campersignin = params => ApiInstance.post(API.CAMPERS_SIGN_IN,params);
export const campersignout = params => ApiInstance.post(API.CAMPERS_SIGN_OUT,params);
export const updatefoldercounselor = params => ApiInstance.post(API.ADD_REMOVE_COUNSELOR_FROM_FOLDER,params);
export const deletecounselor = params => ApiInstance.delete(API.DELETE_COUNSELOR+params);
export const updatefolderbooking = params => ApiInstance.post(API.ADD_REMOVE_BOOKING_FROM_FOLDER,params);
export const allowAllCounselorFeeds = params => ApiInstance.post(API.ALLOW_DISALLOW_PERSONNEL_FEEDS, params);
export const addremovecamperfromfolder = params => ApiInstance.post(API.ADD_REMOVE_CAMPERS_FROM_FOLDER,params);
export const getpersonnelfolderlist = params => ApiInstance.get(API.GET_PERSONNEL_FOLDER_LIST+params);
export const getcamperfolderlist = params => ApiInstance.get(API.GET_CAMPER_LIST_CAMPER_FOLDER+params);
export const getbookingfolderlist = params => ApiInstance.get(API.GET_BOOKING_LIST_CAMPER_FOLDER+params);
export const deleteGalleryImgService = params => ApiInstance.post(API.DELETE_GALLERY_IMG, params);
export const uploadGardianPdfService = params => ApiInstance.post(API.UPLOAD_GARDIAN_PDF, params);
export const addScheduleService = params => ApiInstance.post(API.ADD_SCHEDULE, params);
export const getScheduleService = params => ApiInstance.get(API.GET_SCHEDULE + params);
export const singleScheduleDeleteService = params => ApiInstance.delete(API.SINGLE_DELETE_SCHEDULE, {data: params});
export const scheduleDeleteService = params => ApiInstance.delete(API.DELETE_SCHEDULE, {data: params});
export const scheduleCommitService = params => ApiInstance.post(API.SCHEDULE_COMMIT, params);
export const scheduleEditService = params => ApiInstance.post(API.SCHEDULE_EDIT, params);
export const removeStaffService = params => ApiInstance.post(API.REMOVE_STAFF, params);
export const signupStaffService = params => ApiInstance.post(API.SIGNUP_FOR_STAFF, params);
export const updateRemainingAmountService = params => ApiInstance.post(API.UPDATE_REMAINING_AMOUNT, params);
export const updateBookingDateService = params => ApiInstance.post(API.UPDATE_BOOKING_DATE, params);
export const editAnsByDirService = params => ApiInstance.post(API.EDIT_ANS_BY_DIR, params);
export const editAllAnsByDirService = params => ApiInstance.post(API.EDIT_All_QUES_BY_DIR, params);
export const productListByBookingSerivce = params => ApiInstance.get(API.LIST_PRODUCT_BY_BOOKING + params);
export const cancelProductByBookingSerivce = params => ApiInstance.post(API.CANCEL_PRODUCT_BY_BOOKING, params);
export const refundProductByBookingSerivce = params => ApiInstance.post(API.REFUND_PRODUCT_BY_BOOKING, params);
export const medicalDocumentStatusChangeSerivce = params => ApiInstance.put(API.MEDICAL_DOCUMENT_STATUS, params);
export const getPersonnelByCampCode = params => ApiInstance.get(API.GET_PERSONNEL_BY_CAMP_CODE + params);
export const getBookingCampers = params => ApiInstance.post(API.PARTICIPANTS_CAMPER_BOOKING, params);
export const getPaymentMethod = params => ApiInstance.get(API.PAYMENT_METHOD_BY_TRANSACTON + params);
export const updateCommuterService = (prams) => ApiInstance.post(API.UPDATE_COMMUTER, prams);
export const addAdditionalExp = params => ApiInstance.post(API.ADD_ADDITIONAL_EXP, params);
export const getUsersDetailById = params => ApiInstance.post(API.GET_USERS_DETAIL,  params)
export const addAdditionalEmi = params => ApiInstance.post(API.ADD_ADDITIONAL_EMI, params)
export const removeAdditionalEmi = params => ApiInstance.post(API.REMOVE_ADDITIONAL_EMI, params)