import React, {useState, useEffect} from 'react';
import { connect } from "react-redux";
import LoginUserCart from './loginUserCart';
import NotloginUserCart from './notloginUserCart';
import LoginUserConfirmedCartItems from './loginUserConfirmedCartItems';

const LoginuserConfirmedCart = (props) => {

    return(
        <div className="body_container">
            <div className="container">
                <div className="d-flex align-items-center justify-content-between section_header">
                    <h2>Your Programs</h2>
                </div>
            </div>

            <div className="container cart_staps">
                <p><span>Step 1: </span>Click the "Confirm Details" Button and Enter Participant Information for Each Program Below or if you have participant details already saved, simply add the participant without having to spend time re-entering the information. </p>
                
                <p><span>Step 2: </span>Repeat this process once for each Participant as needed</p>
                
                <p><span>Step 3: </span>Click "PROCEED TO PAYMENT" -&gt; Once you've Finished Adding Participants to the selected programs</p>
            </div>  

            <div className="container p-0 mt-4 mb-5">
                <div className="row g-0">
                    <div className="col-lg-10">
                                {
                                    props.loggedIn ? <LoginUserConfirmedCartItems/> : <NotloginUserCart/>
                                }              
                    </div>
                </div>
            </div>    


        </div>   
    )
}

const mapStateToProps = state => {
    let {cartitems} = state.cartReducer.cartitems;
    let { loggedIn } = state.user;
    return {
        cartitems:cartitems,
        loggedIn
    };
}
  
const mapDispatchToProps = dispatch => ({
    
    
});

export default connect(mapStateToProps,mapDispatchToProps)(LoginuserConfirmedCart);
