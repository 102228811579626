import React, { useState } from 'react'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import NumericInput from 'react-numeric-input'
import { getTaxForPrice, getWithTaxPrice } from '../bookings/bookingHelper';
import AddNewCard from './AddNewCard';
import CreditCard from './CreditCard';
import { notification, } from 'antd';

import SecureInput from './SecureInput';
import { ProgramLoader } from '../reuse/SVG';
import { deductPaylaterAmountService } from '../../services/onlineStoreService';
import { accountTypes, resHandle } from '../../helper';
import { AES } from 'crypto-js';
import settings from '../../config/settings';
import CustomDropDown from '../reuse/UIComponents/CustomDropDown';

const PaymentModal = ({ showModal,
    handleCloseModal, amount,purchaseId,bookingId,
    creditCardRate, achTransferRate,
    cardList, userEmail, handleDeleteCard,
    userId, getCards, campCode,customerProfileId, handlePrimaryCard, payNowCallback
    }) => {
    const [errMsg, setErrorMsg] = useState('');
    const handleValidate = () => {
        let validate = true;

        if(amount > 0){
            if(paymentMethod === 0){
                validate = false;
                setPaymentMethodErr('Please select payment method');
            }
    
            if(paymentMethod === 1){
                if(customerPaymentProfileId === '' || customerPaymentProfileId === 'undefined' || customerPaymentProfileId === null){
                    validate = false;
                    setCustomerPaymentProfileIdError('Please select card')
    
                }
            }


            if(paymentMethod === 3) {

                if(bankAccountType === '' || bankAccountType === 'undefined' || bankAccountType === null){
                    validate = false;
                    setBankAccountTypeErr('Account type is required')
                }

                if(bankAccountNum === '' || bankAccountNum === 'undefined' || bankAccountNum === null){
                    validate = false;
                    setBankAccountNumErr('Account number is required')
                }

                if(routingNumber === '' || routingNumber === 'undefined' || routingNumber === null){
                    validate = false;
                    setRoutingNumberErr('Routing number is required')
                }

                if(nameOnAccount === '' || nameOnAccount === 'undefined' || nameOnAccount === null){
                    validate = false;
                    setNameOnAccountErr('Account holder name is required')
                }

            }
        }

        

        return validate;
    }
    
    

    
        const handlePayment = () => {
    
            if (handleValidate()) {
                setBtnLoader(true);
                let params = {};

                if (paymentMethod === 1) {
                    params = {
                        bookingId: bookingId, 
                        authPayment: {
                            paymentType: paymentMethod,
                            customerProfileId,
                            token: customerPaymentProfileId
                        },
                        payment: {
                            method: "Credit Card",
                            amount:getWithTaxPrice(creditCardRate, amount),
                            applicationFeeAmount : getTaxForPrice(creditCardRate, amount),
                        },
                    }
    
                    params.remainingAmount = 0;
                }
    
    
                if(paymentMethod === 3) {
    
                    const __routing_number = AES.encrypt(routingNumber, settings.api.mySecretKey).toString();
                    const __bankAccountNum = AES.encrypt(bankAccountNum, settings.api.mySecretKey).toString();
    
                    params = {
                        isWeb: 1,
                        bookingId:bookingId, //ProducId
                        authPayment: {
                            account_owner_name: nameOnAccount,
                            routing_number: __routing_number,
                            account_number: __bankAccountNum,
                            account_type: bankAccountType.value,
                            account_owner_type: 'individual',
                            country: 'US',
                            currency: 'usd',
                            paymentType: 4
                        },
                        payment: {
                            method: 'Internet Banking',
                            amount: getWithTaxPrice(achTransferRate,amount),
                            applicationFeeAmount : getTaxForPrice(achTransferRate,amount), 
                        },
                    }
    
            
                }

                params.purchaseId = purchaseId; //product id
                params.campCode = campCode;
                params.remainingAmount = 0;
                params.type = 2;
                params.actionSource = 0;
    
    
                
        
                deductPaylaterAmountService(params).then(res => {
                    let { status, data } = resHandle(res);
                    setBtnLoader(false);
                    if(status){
                        handleCloseModal();//
                        notification.open({
                            message: 'Payments',
                            description:
                                'Payment create successfully',
                        });
                        
                    } else {
                        setErrorMsg(res.data.error.errors.message || res.data.error.responseMessage);
                    }
                })
            }  
            
        }

    const [paymentMethod, setPaymentMethod] = useState(1);
    const [paymentMethodErr, setPaymentMethodErr] = useState('');
    const [loader, setLoader] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const [isNewCard, setIsNewCard] = useState(false);

    const [resErr, setResErr] = useState('');

    const [bankAccountType, setBankAccountType] = useState('');
    const [bankAccountNum, setBankAccountNum] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [nameOnAccount, setNameOnAccount] = useState('');
    const [bankAccountTypeErr, setBankAccountTypeErr] = useState('');
    const [bankAccountNumErr, setBankAccountNumErr] = useState('');
    const [routingNumberErr, setRoutingNumberErr] = useState('');
    const [nameOnAccountErr, setNameOnAccountErr] = useState('');


    const [customerPaymentProfileId, setCustomerPaymentProfileId] = useState('');
    const [customerPaymentProfileIdErr, setCustomerPaymentProfileIdErr] = useState('');
    const [customerPaymentProfileIdError, setCustomerPaymentProfileIdError] = useState('');

    const handleBankAccountNumberChange = (e) => {
        setBankAccountNum(e.target.value);
        setBankAccountNumErr('');
   }

   

    return (
        <Modal
            size="lg"
            show={showModal}
            animation={false}
            centered>

            <Modal.Header>
                <Modal.Title>Payments</Modal.Title>
                <span className="modal_close" onClick={handleCloseModal}><i className="icon-cross" /></span>
            </Modal.Header>

            <Modal.Body>
                <h4>Remaining Amount: ${amount.toFixed(2)}</h4>
                <div className={`form-group`}>
                    <label>Enter Amount to Pay</label>
                    <NumericInput
                        step={0.1}
                        disabled
                        precision={2}
                        value={amount}
                        className="form-control"
                        min={0}
                        max={100}
                        size={2}
                        onChange={e => (e)}
                        pattern="[0-9].[0-9][0-9]" />
                </div>

                <h5>Select a Payment Option</h5>

                <div className="row">

                    {
                        creditCardRate.status === 1 ? (
                            <div className="col-md-6">
                                <div
                                    className={`payment-gateway-card ${paymentMethod === 1 ? 'active' : ''}`}
                                    onClick={() => (setPaymentMethod(1), setPaymentMethodErr(''))}>
                                    <h4>Credit Card / Debit Card</h4>
                                    <h2>${getWithTaxPrice(creditCardRate, amount)}</h2>
                                    <p>Payment Includes {creditCardRate.percentage}% + {`$${creditCardRate.taxRate}`} Processing Charge</p>
                                </div>
                            </div>
                        ) : ''
                    }



                    {
                        achTransferRate.status === 1 ? (
                            <div className="col-md-6">
                                <div
                                    className={`payment-gateway-card ${paymentMethod === 3 ? 'active' : ''}`}
                                    onClick={() => (setPaymentMethod(3), setPaymentMethodErr(''))}>

                                    <h4>Internet Banking</h4>
                                    <h2>${getWithTaxPrice(achTransferRate, amount)}</h2>
                                    <p>Payment Includes {achTransferRate.percentage}% + {`$${achTransferRate.taxRate}`} Processing Charge</p>
                                </div>
                            </div>
                        ) : ''
                    }



                </div>


                {paymentMethodErr ? <p className="color_red">{paymentMethodErr}</p> : ''}






                {
                    (paymentMethod === 1) ? (
                        <>
                            <ul className="card_list">
                                {loader ?
                                    <div className="program_loader"><ProgramLoader /></div>
                                    :
                                    (cardList.length ? cardList.map(item => (
                                        <li key={item.signature} className={customerPaymentProfileId === item.card.token ? 'active' : ''} style={{ justifyContent: 'space-between' }}>
                                            <CreditCard
                                                item={item}
                                                customerPaymentProfileId={customerPaymentProfileId}
                                                setCustomerPaymentProfileId={setCustomerPaymentProfileId}
                                                setCustomerPaymentProfileIdErr={setCustomerPaymentProfileIdErr}
                                                handlePrimaryCard={handlePrimaryCard}
                                                handleDeleteCard={handleDeleteCard}
                                            />
                                        </li>

                                    )) : '')
                                }
                            </ul>

                            {customerPaymentProfileIdError ? <p className="color_red text-center">{customerPaymentProfileIdError}</p> : ''}


                            {
                                isNewCard ? (
                                    <AddNewCard
                                        setIsNewCard={setIsNewCard}
                                        email={userEmail}
                                        userId={userId}
                                        getCards={getCards}
                                        setResErr={setResErr}
                                        campCode={campCode}
                                    />
                                ) : <button className="btn btn-primary mb-3" onClick={() => setIsNewCard(true)}>Add New Card</button>
                            }
                        </>
                    ) : ''
                }





                {
                    paymentMethod === 3 ? (
                        <>
                            <div className="card_inputs add_card_form">

                                        <CustomDropDown
                                            className="form-group"
                                            label={'Select Account Type'}
                                            error={bankAccountTypeErr}
                                            onChange={e =>{setBankAccountType(e);setBankAccountTypeErr('')}}
                                            options={accountTypes}
                                        />

                                <div className="form-group">
                                    <label>Routing Number</label>
                                    <input
                                        type='number'
                                        className={`form-control ${routingNumberErr ? 'is-invalid' : ''}`}
                                        value={routingNumber}
                                        placeholder="Routing Number"
                                        name="routingNumber"
                                        onChange={e => (setRoutingNumber(e.target.value), setRoutingNumberErr(''))} />

                                    {routingNumberErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{routingNumberErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                </div>

                                <div className="form-group">
                                    <label>Account Number</label>
                                    <SecureInput
                                        type='number'
                                        error={bankAccountNumErr}
                                        value={bankAccountNum}
                                        name={"bankAccountNum"}
                                        placeholder={"Account Number"}
                                        maxLength={30}
                                        onChange={handleBankAccountNumberChange}
                                    />

                                    {bankAccountNumErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{bankAccountNumErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                </div>





                                <div className="form-group">
                                    <label>Account Holder Name</label>
                                    <input
                                        type="text"
                                        className={`form-control ${nameOnAccountErr ? 'is-invalid' : ''}`}
                                        name="nameOnAccount"
                                        value={nameOnAccount}
                                        onChange={e => (setNameOnAccount(e.target.value), setNameOnAccountErr(''))}
                                        placeholder="Account Holder Name" />

                                    {nameOnAccountErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{nameOnAccountErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>

                            </div>
                        </>
                    ) : ''
                }

                {resErr ? <p className="color_red">{resErr}</p> : ''}
                <div className="d-flex justify-content-between mt-5">
                    <span onClick={handleCloseModal} className="btn btn-info rounded-0 w-100 me-2">Cancel</span>
                    <span onClick={() => btnLoader ? null : handlePayment()} className={`btn btn-primary rounded-0 w-100  ${btnLoader ? 'btnLoader' : ''}`}>Pay</span>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default PaymentModal
