import ApiInstance from './intercepter';
import API from '../config/api';
export const participantAdd = params => ApiInstance.post(API.PARTICIPANT_ADD, params);
export const uploadMedia = params => ApiInstance.post(API.UPLOAD_MEDIA, params);
export const userPDFCreate = params => ApiInstance.post(API.USER_PDF_CREATE, params);
export const profileInformation = () => ApiInstance.get(API.PROFILE_INFORMATION);
export const changePassword = (params) => ApiInstance.put(API.CHANGE_PASSWORD, params);
export const updateProfile = (params) => ApiInstance.put(API.UPDATE_PROFILE, params);
export const camperinformation = (params) => ApiInstance.get(API.CAMPER_INFORMATION + params);
export const deleteCamper = (params) => ApiInstance.delete(API.DELETE_CAMPER + params);
export const editCamper = (params) => ApiInstance.post(API.EDIT_CAMPER, params);
export const singleEditCamperService = (params) => ApiInstance.post(API.SINGLE_CAMPER_EDIT, params);
export const getSingleEditCamperService = (params) => ApiInstance.get(API.SINGLE_CAMPER_EDIT + params);
export const couponlist = params => ApiInstance.get(API.COUPON_LIST + params);
export const usLacrosseService = (params) => ApiInstance.post(API.US_LACROSSE, params);
export const createTemplateService = (params) => ApiInstance.post(API.CREATE_TEMPLATE, params);
export const getTemplatesService = (params) => ApiInstance.get(API.GET_TEMPLATES + params);
export const createScheduleService = (params) => ApiInstance.post(API.CREATE_SCHEDULE, params);
export const getcheduleListService = (params) => ApiInstance.get(API.GET_SCHEDULE_LIST + params)
export const getcheduleConversationsListService = () => ApiInstance.get(API.GET_SCHEDULE_CONVERSATIONS_LIST);
export const deleteTemplateService = (params) => ApiInstance.post(API.DELETE_TEMPLATE, params);
export const editTemplateService = (params) => ApiInstance.post(API.EDIT_TEMPLATE, params);
export const createNoteService = (params) => ApiInstance.post(API.CREATE_NOTE, params);
export const deleteNoteService = (params) => ApiInstance.post(API.DELETE_NOTE, params);
export const editNoteService = (params) => ApiInstance.post(API.EDIT_NOTE, params);
export const editScheduleService = (params) => ApiInstance.post(API.EDIT_SCHEDULE, params);
export const deleteScheduleService = (params) => ApiInstance.post(API.DELETE_SCHEDULE, params);
export const getNotesService = (params) => ApiInstance.get(API.GET_NOTES + params);
export const getUserHistroyService = (params) => ApiInstance.get(API.GET_USER_ACTIVITY + params);
export const resendBookingEmailService = (params) => ApiInstance.post(API.RESEND_BOOKING_EMAIL, params);
export const sendEmailSmsService = (params) => ApiInstance.post(API.SEND_EMAIL_SMS, params);
export const sendEmailTestService = (params) => ApiInstance.post(API.SEND_EMAIL_TEST, params);
export const sendAppMessageTestService = (params) => ApiInstance.post(API.SEND_APP_MESSAGE_TEST, params);
export const sendPhoneTextTestService = (params) => ApiInstance.post(API.SEND_PHONE_TEXT_TEST, params);
export const updateCamperToCart = params => ApiInstance.post(API.UPDATE_CART_CAMPER, params);
export const removeCamperFromCart = params => ApiInstance.post(API.REMOVE_CART_CAMPER, params);
export const getCartItemService = params => ApiInstance.get(API.GET_CART_ITEM + params)