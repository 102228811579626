import React, { useReducer, useState, useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { InputNumber, notification } from 'antd';
import { connect } from 'react-redux';
import CustomSelect from 'react-select';
import isURL from 'validator/lib/isURL';
import {
    CompositeDecorator,
    Editor,
    EditorState,
    RichUtils,
    convertToRaw
} from 'draft-js';
import { stateToHTML } from "draft-js-export-html";


import { directorAddActivityService, directorActivityListService } from '../../services/activityService';
import { counselorlist } from '../../services/directorServices';
import CustomDropDown from '../reuse/UIComponents/CustomDropDown';



const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
}


const MyLink = props => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={url}>
            {props.children}
        </a>
    );
};

const AddActivity = props => {
    var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    const [isRedirect, setIsRedirect] = useState(false);
    const myRef = useRef();
    const [folders, setFolders] = useState([]);
    const [showURLInput, setShowURLInput] = useState(false);
    const [urlValue, setUrlValue] = useState('');
    const [urlValueErr, setUrlValueErr] = useState('');
    const [isAllSelect, setIsAllSelect] = useState(2);
    const [personnels, setPersonnels] = useState([]);
    const [addActivityLoader, setAddActivityLoader] = useState(false);
    const decorator = new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: MyLink,
        },
    ]);

    const [counselors, setCounselors] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty(decorator));
    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            activityName: '',
            timeNeeded: '',
            equipment: '',
            equipmentSingle: '',
            cost: '',
            category: '',
            folder: [],
            description: '',
        }
    );

    const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            activityName: '',
            timeNeeded: '',
            equipment: '',
            cost: '',
            category: '',
            folder: '',
            description: ''
        }
    );


    const getActivities = () => {
        directorActivityListService('').then(res => {
            if (res.data.statusCode) {
                setFolders(res.data.responseData.result.folder);
            }
        })
    }

    useEffect(() => {
        getActivities();

        let params = `/`
        counselorlist(params).then((res) => {
            if (res.data.statusCode) {
                setCounselors(res.data.responseData.result.counselor)
            }
        })
    }, [])




    let prevalus = []
    folders.map(item => {
        let obj = { "value": item._id, "label": item.folderName }
        prevalus.push(obj);
    })


    const handleChange = e => {
        setUserInput({ [e.target.name]: e.target.value });
        setError({ [e.target.name]: '' });
    }


    const handleNumberChange = (e, name) => {
        setUserInput({ [name]: e });
        setError({ [name]: '' });
    }



    const addEquipment = () => {
        if (userInput.equipmentSingle.trim()) {
            setUserInput({
                equipment: userInput.equipment ? [...userInput.equipment, userInput.equipmentSingle.trim()] : [userInput.equipmentSingle.trim()],
                equipmentSingle: '',
                cost: 0
            })
        }

    }


    const removeEquipment = i => {
        let tempEquipment = userInput.equipment;
        tempEquipment.splice(i, 1);
        setUserInput({ equipment: tempEquipment })
    }



    const handleValidate = () => {
        let validate = true;
        let {
            activityName,
        } = userInput;

        if (activityName.trim() === '') {
            setError({ activityName: 'Name is required' });
            validate = false
        }else if(activityName.length > 150){
            setError({ activityName: 'Activity name max length 150 characters'});
            validate = false
        }

        const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
        const rowValue = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

        if (rowValue.length <= 1) {
            setError({ description: 'Description is required' });
            validate = false
        }


        return validate;
    }




    const handleSubmit = e => {
        e.preventDefault();

        if (handleValidate()) {
            setAddActivityLoader(true);
            let {
                activityName,
                timeNeeded,
                equipment,
                cost,
                category,
                folder,
                description,
            } = userInput;

            let params = '';

            if (folder) {
                params = {
                    activityName,
                    timeNeeded,
                    equipment: equipment ? equipment.join(', ') : '',
                    cost,
                    campCode: props.userProfile.campCode,
                    "folder": JSON.stringify(folder),
                    description: stateToHTML(editorState.getCurrentContent()),
                }
                if (category) {
                    params.category = category
                }
            } else {
                params = {
                    activityName,
                    timeNeeded,
                    equipment: equipment ? equipment.join(', ') : '',
                    cost,
                    campCode: props.userProfile.campCode,
                    description: stateToHTML(editorState.getCurrentContent()),
                }
                if (category) {
                    params.category = category
                }
            }


            if (isAllSelect === 1) {
                params.personnels = JSON.stringify(counselors.map(item => item._id));

            } else {
                if (personnels !== null && personnels?.length) {
                    params.personnels = JSON.stringify(personnels.map(item => item.value));
                }
            }


            directorAddActivityService(params).then(res => {
                setAddActivityLoader(false);
                if (res.data.statusCode) {
                    setIsRedirect(true);
                    notification.open({
                        message: 'Activity',
                        description: 'Activity added successfully',
                    });
                }
            })
        }

    }

    const handelFolderAction = (e) => {
        let tempVal = e ? e.map(i => i.value) : '';
        if (tempVal.length === 0) {
            setUserInput({ folder: [] })
        }
        else {
            setUserInput({ folder: tempVal })
        }
    }



    const promptForLink = e => {
        e.preventDefault();
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = editorState.getSelection().getStartKey();
            const startOffset = editorState.getSelection().getStartOffset();
            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
            let url = '';
            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey);
                url = linkInstance.getData().url;
            }
            setShowURLInput(true);
            setUrlValue(url);
        }
    }



    const removeLink = e => {
        e.preventDefault();
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            setEditorState(RichUtils.toggleLink(editorState, selection, null))
        }
    }




    const confirmLink = e => {
        e.preventDefault();
        let validate = true;
        if(urlValue.trim() === ''){
            validate = false;
            setUrlValueErr('URL is  required')
        }else if(!isURL(urlValue)){
            validate = false;
            setUrlValueErr('URL is not valid')
        }else if(!urlValue.startsWith('http')){
            validate = false;
            setUrlValueErr('URL is not valid please add http:// or https:// Before your URL')
        }

        if(validate){
            const contentState = editorState.getCurrentContent();

            const contentStateWithEntity = contentState.createEntity(
                'LINK',
                'MUTABLE',
                {url: urlValue, target: '_blank'},
            );
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    
    
            let nextEditorState = EditorState.set(editorState,
                { currentContent: contentStateWithEntity }
            );
    
    
            nextEditorState = RichUtils.toggleLink(nextEditorState,
                nextEditorState.getSelection(), entityKey
            );
    
            setEditorState(nextEditorState);
            setShowURLInput(false);
            setUrlValue('');
        }

    }


    const onLinkInputKeyDown = e => { if (e.which === 13) { confirmLink(e); } }


    const handleAllSelect = e => {
        setIsAllSelect(isAllSelect === 1 ? 2 : 1);
        if (isAllSelect === 1) {
            setPersonnels([]);
        }
    }


    return (
        <div className="body_container pb-4 director_wrapper">
            {isRedirect ? <Redirect to="/activity" /> : ''}
            <div className="container">
                <div className="row">
                    <div className="col-lg-2" />

                    <div className="col-lg-8">

                        <div className="page_card_header cm_border_0 mb-0">
                            <h4 className="color_red">New Activity</h4>
                        </div>

                        <form onSubmit={handleSubmit} className="donate_wrap dark_labal">
                            <div className="row">

                                <div className="col-md-6 form-group">
                                    <label>Activity Name</label>
                                    <input
                                        type="text"
                                        name="activityName"
                                        value={userInput.activityName}
                                        className={`form-control ${error.activityName ? 'is-invalid' : ''}`}
                                        placeholder="Enter Activity Name"
                                        onChange={handleChange} />

                                    {error.activityName ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.activityName}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Time Needed <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 50, hide: 250 }}
                                        overlay={<Tooltip>Enter time needed in minutes</Tooltip>}>
                                        <i style={{ verticalAlign: 'middle' }} className="icon-information" />
                                    </OverlayTrigger></label>

                                    <InputNumber
                                        className={`form-control ${error.timeNeeded ? 'is-invalid' : ''}`}
                                        value={userInput.timeNeeded}
                                        min={0}
                                        precision={0}
                                        step={1}
                                        placeholder="Enter Time Needed"
                                        onChange={e => handleNumberChange(e, 'timeNeeded')}
                                    />

                                    {error.timeNeeded ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.timeNeeded}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label>Equipment</label>
                                    <input
                                        type="text"
                                        name="equipmentSingle"
                                        value={userInput.equipmentSingle}
                                        onChange={handleChange}
                                        className={`form-control ${error.equipment ? 'is-invalid' : ''}`} />

                                    {error.equipment ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.equipment}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}


                                    <div className="equipment_row">
                                        <span className="" onClick={addEquipment}><i className="icon-plus" /> Add Equipment</span>

                                        <div className="equipment_chip">{userInput.equipment ? userInput.equipment.map((item, i) => <span key={i}>{item} <i onClick={() => removeEquipment(i)} className="icon-cross" /></span>) : ''}</div>
                                    </div>


                                </div>


                                <div className="col-md-6 form-group">
                                    <label>Cost</label>
                                    <InputNumber
                                        className={`form-control ${error.cost ? 'is-invalid' : ''}`}
                                        value={userInput.cost}
                                        min={0}
                                        precision={2}
                                        placeholder="Enter Cost"
                                        onChange={e => handleNumberChange(e, 'cost')}
                                    />

                                    {error.cost ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.cost}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                </div>
                            </div>



                            <div className="row">
                               
                                <CustomDropDown
                                    className="form-control"
                                    label={'Category'}
                                    error={error.category}
                                    placeholder="Select Category"
                                    onChange={e => handleNumberChange(e, 'category')}
                                    options={props.category ? props.category.map(item => ({value: item._id,lable:item.name})) : [{value: undefined , label: " Not Availabe"}]}
                                />

                                <div className="col-md-6 form-group" style={{position: 'relative', zIndex: 99}}>
                                    <label>Assign to Folder</label>

                                    <CustomSelect
                                        isMulti
                                        placeholder="Select"
                                        onChange={e => handelFolderAction(e)}
                                        //value={prevalus}
                                        options={folders.map(item => ({ value: item._id, label: item.folderName }))} />
                                </div>

                            </div>

                            <div className="form-group" style={{position: 'relative', zIndex: 9}}>
                                <label>Send ‘activity’/alert personnel of activity</label>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="check_box_wrapper cm_dark_check">
                                            <input
                                                type="checkbox"
                                                onChange={handleAllSelect}
                                                checked={isAllSelect === 1 ? 'checked' : ''} />
                                            <span className="check_box"><span />Send to All</span>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        {isAllSelect === 1 ? '' : <CustomSelect
                                            isMulti
                                            placeholder="Select Personnel"
                                            value={personnels}
                                            onChange={e => setPersonnels(e)}
                                            options={counselors.map(item => ({ value: item._id, label: `${item.name} ${item.email}` }))} />
                                        }
                                    </div>
                                </div>
                            </div>



                            <div className={`form-group editer_wrapper ${error.description ? 'is__invalid' : ''}`}>

                                <label>Description</label>

                                <div className="editer_btns">
                                    <button className="btn btn-sm btn-secondary ps-4 pe-4" type="button" onMouseDown={promptForLink}>Add Link</button>
                                    <button className="btn btn-sm btn-info ms-3 ps-4 pe-4" type="button" onMouseDown={removeLink}>Remove Link</button>
                                </div>

                                <Editor
                                    editorState={editorState}
                                    onChange={e => (setEditorState(e), setError({ description: '' }))}
                                    placeholder="Write Description here…"
                                    ref={myRef} />

                                {error.description ? <span className="cm_error">{error.description}</span> : ''}


                                <Modal
                                    centered
                                    dialogClassName="remove_header_line"
                                    show={showURLInput}
                                    onHide={() => setShowURLInput(false)}>
                                    <Modal.Body>

                                        <span className="modal_close" onClick={() => setShowURLInput(false)}><i className="icon-cross" /></span>
                                        <div className="p-5">
                                            <label>Type or paste your link in the text box</label>
                                            <input
                                                onChange={e => (setUrlValue(e.target.value), setUrlValueErr(''))}
                                                type="text"
                                                className={`form-control ${urlValueErr ? 'is-invalid' : ''}`}
                                                value={urlValue}
                                                placeholder="https://www.example.com/"
                                                onKeyDown={onLinkInputKeyDown}
                                            />
                                            {urlValueErr ? <div className='res_err'>{urlValueErr}</div> : ''}
                                            <button className="btn btn-primary mt-4 w-100" onMouseDown={confirmLink}> Confirm </button>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>

                            <div className="d-flex justify-content-center mt-5">
                                <Link className="btn btn-info ps-5 pe-5 me-1 rounded-0" to="/activity">Cancel</Link>
                                <button
                                    type="submit"
                                    disabled={addActivityLoader}
                                    className={`btn btn-primary rounded-0  ps-5 pe-5 btn_anim ${addActivityLoader ? 'show_anim' : ''}`}>{addActivityLoader ? 'Loading...' : 'Submit'}</button>
                                
                            </div>


                        </form>
                    </div>
                </div>

            </div>
        </div>
    )
}


const mapStateToProps = state => {
    let { preList } = state.program;
    let { userProfile } = state.user;
    let { category } = preList;
    return {
        category,
        userProfile
    }
}

export default connect(mapStateToProps, null)(AddActivity);