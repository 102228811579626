import React, {useState, useEffect} from 'react';
import {counselorfoderdetails, updatefoldercounselor, getpersonnelfolderlist} from '../../services/directorServices';
import CounselorCard from './CounselorCard';
import { ProgramLoader } from '../reuse/SVG';
import { Link} from 'react-router-dom';
import { notification } from 'antd';

const FolderDetails = (props) => {
    
    const [counselorList, setCounsolersList]          = useState([]); 
    const [loading, setLoading]                       = useState(true);
    const [addmore, setAddmore]                       = useState(false);
    const [counselorWarning, setCounselorWarning]     = useState(false);
    const [selectAll, setSelectAll]                   = useState(false);
    const [counselorIds, setCounselorIds]             = useState([]);
    const [btnloder, setBtnLoader]                    = useState(false);
    const [newCounsolerlist, setNewCounsolerList]     = useState([]);
    const [folderName, setFolderName]                 = useState('');

    useEffect(()=>{
        let params = `/?folderId=${props.match.params.id}`;
        counselorfoderdetails(params).then((res)=>{
            if(res?.data?.statusCode)
            {
                
                setCounsolersList(res.data.responseData.result)
                setFolderName(res.data.responseData.folderName)
                setLoading(false)
            }
            else
            {
                setLoading(true)
            }
        })
    },[])



    const removeFromFolderAction =(personnelID)=>{
        let params ={"folderId":props.match.params.id, "counselorIds": JSON.stringify([personnelID]), "type": 2}
        updatefoldercounselor(params).then(res=>{
            if(res?.data?.statusCode)
            {
                let counsollist = counselorList.filter(item => item._id !==personnelID);
                setCounsolersList(counsollist);
                notification.open({
                    description:
                      'Personnel successfully remove from folder',
                  });
            }
            else
            {

            }
        })
    }


    const addMoreAction = () =>{
        setAddmore(true);
        setLoading(true);
        let params = `/?folderId=${props.match.params.id}`;
        getpersonnelfolderlist(params).then(res=>{
            setLoading(false);
            if(res?.data?.statusCode)
            {
                setNewCounsolerList(res.data.responseData.result)
            }
            else
            {
                notification.open({
                    description:
                      'Please try after some time',
                  });               
            }

        })
    }




    const handelCounslor = (e) =>{
        let listcounslor = [...counselorIds];
        setCounselorWarning(false);
        if(listcounslor.includes(e.target.value))
        {
            setSelectAll(false)
            let newcousolerlist = listcounslor.filter(item => item!==e.target.value) 
            setCounselorIds(newcousolerlist)
        }
        else
        {
            listcounslor.push(e.target.value);
            if(listcounslor.length===counselorList.length){setSelectAll(true)}
            setCounselorIds(listcounslor)
        }
    }



    const selectAllCounslors = () =>{
        let listcounslor = [...counselorIds];
        listcounslor.length = 0;
        newCounsolerlist.map(item=>{
            listcounslor.push(item._id);
        })
        setCounselorIds(listcounslor)
    }


    const deselectAllCounslors = () =>{
        let listcounslor = [...counselorIds];
        listcounslor.length = 0;
        setCounselorIds(listcounslor)
    }  


    const addMoreToFolder = () =>{
        setBtnLoader(true);
        if(counselorIds.length > 0)
        {
            let params ={"folderId":props.match.params.id, "counselorIds": JSON.stringify(counselorIds), "type": 1}
            updatefoldercounselor(params).then(res=>{
                if(res?.data?.statusCode)
                {
                    let params2 = `/?folderId=${props.match.params.id}`;
                    counselorfoderdetails(params2).then((res)=>{
                        if(res?.data?.statusCode)
                        {      
                            setCounsolersList(res.data.responseData.result)
                            setBtnLoader(false);
                            setAddmore(false);
                            setSelectAll(false);

                        }
                        else
                        {
                            setLoading(true)
                        }
                    })
                   
                    notification.open({
                        description:
                          'Personnel successfully Added in folder',
                      });
                }
                else
                {
                    notification.open({
                        description:
                          'Please try after some time',
                      });    
                }
            })
            
        }
        else
        {
            setCounselorWarning(true);
            setBtnLoader(false);
        }   
    }






    return(
        <div className="body_container pb-4 director_wrapper">    
            <div className="container">
                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Folder : {folderName}</h4>
                   
                        {
                            addmore? 

                            selectAll ?
                            <button className="btn btn-primary" onClick={()=>{deselectAllCounslors(); setSelectAll(false)}}> Deselect All </button>
                            :
                            <button className="btn btn-primary" onClick={()=>{selectAllCounslors(); setSelectAll(true)}}> Select All </button>
                          :
                          <div className="btn_group">
                            <span onClick={()=>{addMoreAction()}} className="btn btn-primary me-3">Add More</span>
                            <Link to="/personnel" className="btn btn-primary">Back</Link>
                         </div>    
                          
                        }

                
                
                </div>

               
               {
                !addmore ?
                <div className="row">
                    <div className="col-lg-2" />

                    {
                      loading ? <div className="program_loader"><ProgramLoader /></div> :  
                        <div className="col-lg-8">
                    {
                             counselorList.length > 0 ? 
                             counselorList.map(counsloer=>(
                                <CounselorCard Details={counsloer} removeFromFolderAction={removeFromFolderAction}/>  
                             )) 
                             :
                             <div className="d-flex align-items-center counselor_card">
                                 No Personnel Found
                             </div>      
                        }
                     </div>
                    } 
                </div> 

                :

                <div className="row">
                    <div className="col-lg-2" />
                    <div className="col-lg-8">
                        {
                           counselorWarning ? <p className="waring_text">Please select the personnel First</p> : ''  
                        }
                        {
                            loading ? <div className="program_loader"><ProgramLoader /></div> :  
                            newCounsolerlist.length > 0 ? 
                            newCounsolerlist.map(counsloer=>(
                             <div className="d-flex align-items-center counselor_card">
  
                                <div className="me-3">
                                    <img src={counsloer.profileImage ? counsloer.profileImage : require('../../assets/img/rewardPoints.png')} />
                                </div> 
                
                                <div>
                                <h4>{counsloer.name}</h4>
                                </div>
                                   
                                <div className="ms-auto">
                                <div className="check_box_wrapper cm_dark_check">
                                   <input type="checkbox"  value={counsloer._id} checked={counselorIds.includes(counsloer._id) ? 'checked' :''} onChange={(e)=>handelCounslor(e)}></input>
                                   <span className="check_box"><span></span></span>
                                </div>   
                                </div> 
                        </div>
                            )) 
                            :
                            <div className="d-flex align-items-center counselor_card">
                                No Personnel Found
                            </div>    
                        }

                    <div className="col-sm-12 text-center mb-5">
                        <Link className="btn btn-light rounded-0 me-2" onClick={()=>{setCounselorWarning(false); setAddmore(false); setCounselorIds([]); setSelectAll(false);}} style={{width: '130px'}}>Cancel</Link>
                        <button  onClick={()=>{setBtnLoader(true); addMoreToFolder()}} className={`btn btn-primary rounded-0 ${btnloder ? 'btnLoader' : ''}`} style={{width: '130px'}}>Submit</button>
                    </div>

                    </div>
                </div>
                         
                
                }    





            </div>
        </div>
    )
}

export default FolderDetails;