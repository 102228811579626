import React, { useReducer, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { DatePicker } from 'antd';
import moment from 'moment';
import { notificationMsg, removeTime, resHandle } from '../../../helper';
import { addAdditionalEmi, removeAdditionalEmi, } from '../../../services/directorServices';

const ACTIONS = Object.freeze({
    SET_AMOUNT: 'SET_AMOUNT',
    SET_REASON: 'SET_REASON',
    SET_DATE: 'SET_DATE',
    RESET:'RESET'
})
const disabledDate = (current) => {
    return current && current < moment().endOf('day');
}
const emiAmountReducer = (state, action) => {
    switch (action.type) {
        case 'SET_AMOUNT':
            return { ...state, emiAmount: action.payload };
        case 'SET_REASON':
            return { ...state, reason: action.payload };
        case 'SET_DATE':
            return { ...state, emiDate: action.payload };
        case 'RESET':
        return { ...state, emiDate: '', reason :'', emiAmount:''};
        default:
            return state;
    }
}

export const initialEmiState = {
    emiAmount: 0,
    emiDate: '',
    reason: '',
};

const ScheduleEmi = ({ show, setShow, bookingId, emiId = '', deleteAction }) => {
    const [emiData, dispatch] = useReducer(emiAmountReducer, initialEmiState);
    const [error, setError] = useState('');

    const handleChange = (data, action) => {
        setError('')
        dispatch({ type: action, payload: data });
    }
    const handleScheduleEmi = () => {
        const { emiDate, emiAmount, reason } = emiData
        if (deleteAction) {
            if (!reason) {
                setError("Please select a reason to delete emi.")
                return;
            } else {
                const params = { bookingId, reason, emiId }
                removeEmi(params)
            }
        } else {
            if (!emiDate || !emiAmount || !reason) {
                !reason && setError("Please select a reason.")
                !emiAmount && setError("Please select emi Amount.")
                !emiDate && setError("Please select emi date.")
                return;
            }
            const params = {
                bookingId, reason, emiAmount,
                emiDate: new Date(removeTime(emiDate._d)).getTime()
            }
            createEmi(params)
        }

    }

    const createEmi = async (params) => {
        const res = await addAdditionalEmi(params)
        const { status } = resHandle(res)
        if (status) {
            notificationMsg(status, "Emi created successfully")
            closeModal(1);
        } else {
            notificationMsg(status, "!failed to create Emi")
            closeModal(0)

        }
    }
    const removeEmi = async (params) => {
        const res = await removeAdditionalEmi(params)
        const { status } = resHandle(res)
        if (status) {
            closeModal(1)
            notificationMsg(status, "Emi deleted successfully.")
        } else {
            closeModal(0)
            notificationMsg(status, "!failed to delete Emi")
        }
    }
    const closeModal = (act = 0) => {
        setError('')
        dispatch({ type: ACTIONS.RESET});
        setShow(act)
    }
    return (
        <Modal
            show={show}
            animation={false}
            dialogClassName="folder_model"
            size="lg"
            centered
        >
            <Modal.Header>
                <Modal.Title>Schedule Emi</Modal.Title>
                <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
            </Modal.Header>
            <Modal.Body className='text-center px-4'>
                <div className='container'>
                    <div className="form-group row">
                        <label>Reason</label>
                        <div className="form-group">
                            <input
                                placeholder='Enter Reason......'
                                className={`form-control ms-3 ${(!emiData.reason && error) ? 'is-invalid' : ''}`}
                                onChange={(e) => handleChange(e.target.value, ACTIONS.SET_REASON)}
                            />
                        </div>
                    </div>

                    {!deleteAction &&
                        <div className='row'>
                            <div className="form-group col">
                                <label>Emi Amount</label>
                                <div className="form-group">
                                    <input
                                        placeholder='Enter Emi Amount ......'
                                        className={`form-control ms-3 ${(!emiData.amount && error) ? 'is-invalid' : ''}`}
                                        type='number'
                                        onChange={(e) => handleChange(Number(e.target.value), ACTIONS.SET_AMOUNT)}
                                    />
                                </div>

                            </div>
                            <div className="form-group col">
                                <label>Select date</label>
                                <div className="form-group">
                                    <DatePicker
                                        disabledDate={disabledDate}
                                        value={emiData.emiDate}
                                        className="form-control"
                                        placeholder="Select Date"
                                        format="MM/DD/YYYY"
                                        onChange={e => handleChange(e, ACTIONS.SET_DATE)} />
                                </div>

                            </div>
                        </div>}
                    {error && <p className='text-danger'>{error}</p>}
                    <button
                        className='btn btn-sm btn-primary'
                        onClick={handleScheduleEmi}
                    >{deleteAction ? 'Remove EMI' : 'Schedule EMI'}
                    </button>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default ScheduleEmi
