import React from 'react'
import { Modal } from 'react-bootstrap'
import CurrentParticipentComponent from '../selectcampers/CurrentParticipentComponent'

const SelectParticipantModal = ({ selectCamperModal, handleCloseParticipantModal, cartProgramData, activeCamper, cartId, getCartItem}) => {
  return (
    <Modal
      dialogClassName="h_auto"
      show={selectCamperModal}
      animation={false}
      size="lg"
      centered
    >
      <Modal.Header className='d-flex  justify-content-center'>
        <Modal.Title  >
          <h2>Participant</h2>
        </Modal.Title>
        <span className="modal_close" onClick={handleCloseParticipantModal}><i className="icon-cross" /></span>
      </Modal.Header>
      <Modal.Body>
        <CurrentParticipentComponent
          closeSelectingView={handleCloseParticipantModal}
          cartProgramData={cartProgramData}
          currentCamper={activeCamper}
          cartId={cartId}
          reload={getCartItem}
        />
      </Modal.Body>
    </Modal>
  )
}

export default SelectParticipantModal
