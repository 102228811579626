import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { notification } from 'antd';
import moment from 'moment';

import AddCoundelor from './addCounselor';
import { counselordetail, getPersonnelActivityListService, editcounselor } from '../../services/directorServices';
import { reInviteCounselorService } from '../../services/userServices';
import { PDFIcon, ProgramLoader } from '../reuse/SVG';


const EditCounselor = (props) => {
    let history = useHistory();
    const [counselorDetails, setCounselorDetails] = useState({});
    const [cd, setCd] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [inviteEmailModal, setInviteEmailModal] = useState(false);
    const [pAList, setPAList] = useState([]);
    const [activityList, setActivityList] = useState([]);
    const [permissions, setPermissions] = useState({});

    useEffect(() => {
        let counselorId = props.match.params.id;
        counselordetail(`/?counselorId=${counselorId}`).then((res) => {
            if (res.data.statusCode) {
                setCounselorDetails(res.data.responseData.result);
                setCd(res.data.responseData.result.counselorDetails);
                setActivityList(res.data.responseData.result.activityList);
                setPermissions(res.data.responseData.result.permissions || {});
                setLoading(false);

            } else {
                history.push("/counselors");
            }
        });


        getPersonnelActivityListService(`/?personnelid=${props.match.params.id}`).then(res => {
            if (res.data.statusCode) {
                setPAList(res.data.responseData.result || []);
            }
        })
    }, [])


    const handelEditmode = () => {
        setLoading(true);
        let counselorId = props.match.params.id;
        counselordetail(`/?counselorId=${counselorId}`).then((res) => {
            setLoading(false);
            if (res.data.statusCode) {
                setCounselorDetails(res.data.responseData.result);
                setCd(res.data.responseData.result.counselorDetails);
                setEditMode(false)
            } else {
                history.push("/counselors");
            }
        })
    }


    const reInviteNotification = () => {

        let params = {
            email: counselorDetails.email,
            campCode: props.campCode
        }
        setInviteEmailModal(false);

        reInviteCounselorService(params).then(res => {

        })
    }


    const handlePermissionsChange = (key, val) => {
        let temp = {...permissions, [key]: val === 1 ? 0 : 1}
        setPermissions(temp);
        let form_data = new FormData();
        form_data.append('counselorId', props.match.params.id);
        form_data.append('permissions', JSON.stringify(temp));

        editcounselor(form_data).then( res => {
            notification.open({
                description: 'Permissions Updated Successfully',
            });
        })
    }


    return (
        <div className="body_container bg_light">
            {loading ? <div className="page_loader"><ProgramLoader /></div> : ''}

            <div className="container mt-5 mb-5 edit-counselor">
                <div className="row">
                    {
                        !editMode ?
                            <div className="col-lg-4">
                                <div className="card">
                                    <div className="profile_upload">
                                        <img src={counselorDetails.profileImage ? counselorDetails.profileImage : require('../../assets/img/user.png')} />
                                    </div>
                                    <p className="text-center">{counselorDetails.name}</p>
                                    <div className="col-sm-12 text-center mt-10">

                                        <Link className="btn btn-primary"
                                            to={{
                                                pathname: '/inbox',
                                                user: { "_id": counselorDetails._id, "name": counselorDetails.name, "type": 1 },

                                            }} style={{ width: '130px' }}>Message</Link>

                                        <br />
                                        {counselorDetails.firstLogin === 2 ? <button className="btn btn-secondary btn-sm mt-3" onClick={() => setInviteEmailModal(true)}>Resend invite email</button> : ''}


                                    </div>
                                </div>

                                <div className="card">
                                    <p><b>Cabin Assigment: </b> --</p>
                                    <p><b>Cabin Name: </b> ----</p>
                                </div>



                                <div className="card">
                                    <h5 className="mb-4">Activity View List</h5>
                                    {
                                        activityList.map(item => (
                                            <Link to={`/activity/edit/${item._id}`} key={item._id} className="cm_activity_card">
                                                <p><b>Name</b> : {item.activityName}</p>
                                                <p><b>Equipment</b>: {item.equipment}</p>
                                                <p><b>Cost</b>: {item.cost ? `$${item.cost}` : '0'}</p>
                                                <p><b>Time needed</b>: {item.timeNeeded}</p>
                                            </Link>
                                        ))
                                    }
                                </div>

                                <div className="card staff_permissions">
                                    <h5 className="mb-4">Permissions</h5>
                                    <p>
                                        <b>View Medical Information</b>
                                        <div className="cm_swich_wrap"><input onChange={() => handlePermissionsChange('viewMedical', permissions.viewMedical)} checked={permissions.viewMedical} type="checkbox" /><span /></div>
                                    </p>

                                    <p>
                                        <b>View Camper Information</b>
                                        <div className="cm_swich_wrap"><input onChange={() => handlePermissionsChange('viewCamper', permissions.viewCamper)} checked={permissions.viewCamper} type="checkbox" /><span /></div>
                                    </p>

                                    <p>
                                        <b>Ability to add user</b>
                                        <div className="cm_swich_wrap"><input onChange={() => handlePermissionsChange('addUser', permissions.addUser)} checked={permissions.addUser} type="checkbox" /><span /></div>
                                    </p>

                                    <p>
                                        <b>Ability to create booking</b>
                                        <div className="cm_swich_wrap"><input onChange={() => handlePermissionsChange('createBooking', permissions.createBooking)} checked={permissions.createBooking} type="checkbox" /><span /></div>
                                    </p>

                                    <p>
                                        <b>Ability to create program</b>
                                        <div className="cm_swich_wrap"><input onChange={() => handlePermissionsChange('createProgram', permissions.createProgram)} checked={permissions.createProgram} type="checkbox" /><span /></div>
                                    </p>

                                    <p>
                                        <b>Ability to edit program</b>
                                        <div className="cm_swich_wrap"><input onChange={() => handlePermissionsChange('editProgram', permissions.editProgram)} checked={permissions.editProgram} type="checkbox" /><span /></div>
                                    </p>

                                    <p>
                                        <b>Message Camper</b>
                                        <div className="cm_swich_wrap"><input onChange={() => handlePermissionsChange('messageCamper', permissions.messageCamper)} checked={permissions.messageCamper} type="checkbox" /><span /></div>
                                    </p>

                                    <p>
                                        <b>Post on Feeds</b>
                                        <div className="cm_swich_wrap"><input onChange={() => handlePermissionsChange('feedsByStaff', permissions.feedsByStaff)} checked={permissions.feedsByStaff} type="checkbox" /><span /></div>
                                    </p>
                                   
                                </div>
                            </div>
                            : ''
                    }
                    <div className={`col-lg-${editMode ? '12' : '8'}`}>
                        {
                            !editMode ?
                                <div className="card mb-4">
                                    <h4 className="color_red">Personnel Information</h4>
                                    <div className="all_counselorDetails">
                                        <p><b>Title: </b> {counselorDetails.userTitle ? counselorDetails.userTitle : 'N/A'}</p>
                                        <p><b>Email: </b> {counselorDetails.email ? counselorDetails.email : 'N/A'}</p>
                                        <p><b>Date of Birth: </b> {counselorDetails.dob ? moment.utc(counselorDetails.dob).format('MM/DD/YYYY') : 'N/A'}</p>
                                        <p><b>Program Name: </b> {counselorDetails.programName ? counselorDetails.programName : 'N/A'}</p>
                                        <p><b>Assign to folder: </b> {counselorDetails.folderName ? counselorDetails.folderName.length ? counselorDetails.folderName.map((item, i) => (
                                            <span>{item}{i === counselorDetails.folderName.length - 1 ? "" : ", "}</span>
                                        )) : 'N/A' : 'N/A'}
                                        </p>
                                        <p><b>Phone Number: </b> {counselorDetails.phone ? counselorDetails.phone === "undefined" ? 'N/A' : counselorDetails.phone : 'N/A'}</p>
                                        <p><b>Age Group: </b> {counselorDetails.ageGroup ? counselorDetails.ageGroup : 'N/A'}</p>
                                        <p><b>Serving: </b> {cd?.serving ? cd.serving : 'N/A'}</p>
                                        <p><b>Street Address: </b> {cd?.streetAddress ? cd.streetAddress : 'N/A'}</p>
                                        <p><b>Appartment: </b> {cd?.appartment ? cd.appartment : 'N/A'}</p>
                                        <p><b>City: </b> {cd?.city ? cd.city : 'N/A'}</p>
                                        <p><b>Zipcode: </b> {cd?.zipCode ? cd.zipCode : 'N/A'}</p>
                                        <p><b>State: </b> {cd?.state ? cd.state : 'N/A'}</p>
                                        <p><b>Tshirt Size: </b> {cd?.tshirtSize ? cd.tshirtSize : 'N/A'}</p>
                                        <p><b>Shorts Size: </b> {cd?.shortsSize ? cd.shortsSize : 'N/A'}</p>
                                        <p><b>Medical: </b> {cd?.medicalvalues?.length ? cd.medicalvalues.map((item, i) => <span key={i}>{item}</span>) : 'N/A'}</p>
                                        <p><b>Allergy: </b> {cd?.allergyvalues?.length ? cd.allergyvalues.map((item, i) => <span key={i}>{item}</span>) : 'N/A'}</p>
                                        <p><b>Allergy Deadly: </b> {cd?.allergyDeadly === 1 ? 'Yes' : 'No'}</p>
                                        <p><b>Medical Insurance Company: </b> {cd?.medicalInsuranceCompany ? cd.medicalInsuranceCompany : 'N/A'}</p>
                                        <p><b>Policy Number: </b> {cd?.policyNumber ? cd.policyNumber : 'N/A'}</p>
                                        <p><b>Group: </b> {cd?.group ? cd.group : 'N/A'}</p>
                                        <p><b>Primary Physician: </b> {cd?.primaryPhysician ? cd.primaryPhysician : 'N/A'}</p>
                                        <p><b>Physician Address: </b> {cd?.physicianAddress ? cd.physicianAddress : 'N/A'}</p>
                                        <p><b>Physician Phone: </b> {cd?.physicianPhone ? cd.physicianPhone : 'N/A'}</p>
                                        <p><b>Medications Taken: </b> {cd?.medicationsTaken ? cd.medicationsTaken : 'N/A'}</p>
                                        <p><b>Bringing Own Medication: </b> {cd?.participantBringingOwnMedication === 1 ? 'Yes' : 'No'}</p>
                                        <p><b>Allowed to Document Personnel: </b> {cd?.allowDocumentCamper === 1 ? 'Yes' : 'No'}</p>
                                        <p><b>Medical Document: </b> {cd?.medicalDocument ? <a href={cd.medicalDocument} target="_blank" className="cm_pdf_icon m-0"><PDFIcon /></a> : 'N/A'}</p>
                                        <p><b>User Description: </b> {cd?.userDescription ? cd.userDescription : 'N/A'}</p>
                                        <p><b>Signature Image: </b> {cd?.signatureImg ? <img src={cd.signatureImg} /> : 'N/A'}</p>


                                    </div>




                                    <div className="col-sm-12 text-center mb-5">
                                        <button onClick={() => setEditMode(true)} className="btn btn-primary rounded-0" style={{ width: '130px' }}>Edit</button>
                                    </div>
                                </div>
                                :
                                <div className="card mb-4">
                                    <AddCoundelor Details={counselorDetails} HideEditmode={handelEditmode} />
                                </div>
                        }




                    </div>



                </div>

            </div>


            <Modal
                dialogClassName="h_auto"
                show={inviteEmailModal}
                animation={false}
                centered
            >
                <Modal.Body>
                    <span className="modal_close" onClick={() => { setInviteEmailModal(false) }}><i className="icon-cross" /></span>
                    <div className="text-center p-4 pickup_modal_body">
                        <p>Are you sure you want to resend invite email?</p>
                    </div>
                    <div className="text-center mb-4">
                        <button onClick={() => setInviteEmailModal(false)} style={{ width: '120px' }} className="btn btn-light rounded-0">No</button>
                        <button onClick={reInviteNotification} style={{ width: '120px' }} className="btn btn-primary ms-3">Yes</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}


const mapStateToProps = state => {
    let { userProfile } = state.user;
    let { campCode } = userProfile;

    return {
        campCode
    };
}

export default connect(mapStateToProps)(EditCounselor);