import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { successModalAction, clearTempEmailAction } from '../../store/ui';
import { Link } from 'react-router-dom';


const SuccessModal = props => {

    return(
        <Modal
            dialogClassName="h_auto"
            show={props.successModal}
            animation={false}
            centered
        >
            <Modal.Body>
                <div className="p-5">
                    <h3 className="text-center mb-4">Success!</h3>
                    <p className="text-center">You will receive detailed instruction on resetting your password to your {props.tempEmail} address in the shortest time possible. </p>
                    <Link className="btn btn-primary w-100 mt-4" onClick={() => props.successModalAction()} to='/'>Back to Sign In</Link>
                </div>
            </Modal.Body>
        </Modal>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { successModal, tempEmail } = state.ui
    return {
        successModal,
        tempEmail
    };
}
  
const mapDispatchToProps = dispatch => ({
    successModalAction: () => dispatch(successModalAction()),
    clearTempEmailAction: () => dispatch(clearTempEmailAction()),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(SuccessModal);