import { jsPDF } from "jspdf";
export const Practice = [
    'We expect you to be 15 minutes EARLY to practice and team events.  Plan ahead.',
    'We expect you to come to practice ready to train hard mentally and physically. We expect you to train, compete, and play with a sense of urgency',
    'We expect you to participate in ALL conditioning at all times.  If you are injured, please discuss with one of the coaches before practice.',
    'We expect you to take care of details with a sense of urgency. This means getting the field set-up, getting balls to throw and catch, chasing balls so we do not lose them and have to go on LONG ball hunts at the end of practice, getting to and returning quickly from water breaks, focusing while you are not in the drill and being ready to step in at any moment.',
    'We expect you to support your teammates in practice, conditioning, and strength sessions by communicating positively verbally and non-verbally.  Also, to be respectful of other teams who may be training around us',
    'We expect you to mix up who you throw with, warm up next to, play in drills, DAILY',
    'We expect EVERYONE to help with equipment, it is not just an “underclassmen job”',
    'We expect you to have mouth guards, full equipment; correct practice clothes, etc., daily and on the road when we travel.  If in doubt, bring it'
]

export const Travel = [
    'For long distance travel outside of the Portland Metro area, we may use a bus for transportation to travel as a team. We expect you to be early for any bus departures that occur. We expect you to keep the bus clean.  It is no one’s job other than yourselves to clean up after you.',
    'If traveling on a bus, you are not allowed to take off with your friends, parents, or family members unless you have communicated and received approval from the head coach prior to the day of travel.',
    'We expect you to conduct yourself in a mature and respectful manner at ALL TIMES.  You are not only representing yourself, Tigard lacrosse, Oregon lacrosse, and lacrosse in general. Be respectful of others around you at all times.'

]

export const Game = [
    'We expect you to always keep our bench area neat (home & away), and to pick up all trash before leaving the field post-game.',
    'We expect you to cheer on our team in a positive manner during the game and to play with sportsmanship.  You are not to comment on the refs…ever! If you have concerns about the calls being made, please discuss with the coaches.',
    'We expect you to listen if you are on the field or on the sideline and to come off and go on through the designated sub-box.',
    'We expect you to NOT talk negatively, be-little, critique or yell at a teammate on the field',
    'We expect you to play with sportsmanship all the time.',
    'We expect you to be ready to go in the game at any point in time and if you don’t get in, that you support the team and learn.',
    'We expect you to communicate to family and friends that at games they must cheer in a positive manner',
    'We expect you to not leave after games until you have been released and the team talk is complete',
    'We expect you to not be distracted by cell phones, friends or family, pregame, DURING the game and post-game.',
    'If you have concerns regarding playing time, coaches are open to discussing this with players during practice. This is not a discussion that will be had during games',
    'School attendance is not optional. Any unexcused absences on gameday will result in ineligibility to play that day. This could lead to potential forfeiture and/or loss of the game for the team.',
]


const lineSpaciing = 7
export const warnningText = "*If these team expectations cannot be met, or, are violated, there will be consequences.  Playing time will be taken into account as well.  Consequences for violations off the field will be determined on a case-by-case basis*"

export const createDocument = (userInput) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin =10; // Standard margin
    const lineSpacing = 5; // Space between lines
    let currentY = margin; // Starting Y position

    // Helper function to add text and handle page breaks
    const addText = (text, fontSize = 10, bold = false, center = false) => {
        const textWidth = pageWidth - margin * 2; // Account for margins
        const textItemWidth = doc.getTextWidth(text);
        let centermargin = Math.floor((pageWidth - textItemWidth) / 2);
        centermargin = centermargin < 25? centermargin+ 14 :centermargin
        doc.setFontSize(fontSize);
        doc.setFont("helvetica", bold ? "bold" : "normal");
        
        const lines = doc.splitTextToSize(text, textWidth); // Split text into lines
        
        lines.forEach((line) => {
            if (currentY + lineSpacing > pageHeight - margin) {
                doc.addPage(); // Add a new page if we exceed the page height
                currentY = margin; // Reset Y position
            }
            doc.text(line, (center ? centermargin : margin), currentY);
            currentY += lineSpacing; // Move to the next line
        });
    };
            addText("Tigard Lacrosse", 22, true, true);
            currentY +=lineSpaciing;
            addText("Tigard Lacrosse Player Expectations/Contract", 16, true, true);
            currentY +=lineSpaciing;
            // Add the first list heading and list items
            addText("Practice Expectations", 14, true, true);
            currentY +=lineSpaciing; 
            Practice.forEach((item, index) => {
                addText(`${index + 1}. ${item}`, 10);
            });
            // Add the second list heading and list items
            currentY +=lineSpaciing; // Add some space before the next section
            addText("Travel Expectations", 14, true, true);
            currentY +=lineSpaciing; 
            Travel.forEach((item, index) => {
                addText(`${index + 1}. ${item}`, 10);
            });
        
            currentY +=lineSpaciing; // Add some space before the next section
            addText("Game Expectations", 14, true, true);
            currentY +=lineSpaciing; 
            Game.forEach((item, index) => {
                addText(`${index + 1}. ${item}`, 10);
            });

            currentY +=lineSpaciing;
            addText(warnningText, 10, true);
             const pledge =   [ `I (print name), ${userInput?.w_1 || "--------"} ,have read ALL the practice, game`, 
                "and player expectations and agree to follow each rule knowing that if I do not, consequences could result in running", 
                "loss of playing time, and/or removal from the team."].join(' ');
            currentY +=lineSpaciing;
            addText(pledge, 10, true);

            currentY +=lineSpaciing;
            addText("Participant signature:", 10, true);
            if(userInput.w_2){
                doc.addImage(userInput.w_2, "JPEG", 100, currentY);
            }
            currentY +=lineSpaciing;
            doc.line(40, currentY, 150, currentY);
            currentY +=(lineSpaciing +10);
            addText(`Date signed: ${userInput.w_3}`);    
            

            currentY +=lineSpaciing;
            addText("Parent guardian/signature:", 10, true);
            if(userInput.w_5){
                doc.addImage(userInput.w_5, "JPEG", 100, currentY);
            }
            currentY +=lineSpaciing;
            doc.line(40, currentY, 150, currentY)
            currentY +=(lineSpaciing+10);
            addText(`Date signed: ${userInput.w_6}`);         
    // Save the document
    const pdfBlob = doc.output("blob");
    const url = URL.createObjectURL(pdfBlob);
    window.open(url, "_blank");
};