import React, {useState, useEffect, useRef} from 'react';
import CamperForm from './Camperform';
import { useForm, Controller } from "react-hook-form";
import { Modal } from 'react-bootstrap';
import { Link, useLocation, useHistory} from 'react-router-dom';
import {removeTime, _calculateAge, groupCodeGenerate} from '../../../helper';
import {participantAdd} from '../../../services/campersServices';
import { connect } from "react-redux";
import ConfirmDialoag from '../../modals/ConfirmDiloag';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { getCartItems } from '../../../services/programService';
import settings from '../../../config/settings';
import { ProgramLoader } from '../../reuse/SVG';

var isFormSumbitByRef = true;

const Campers = (props) => {
    const formSumbitByRef                                       = useRef(null);
    let location                                                = useLocation();
    // let history                                                 = useHistory();
    let myRef                                                   = useRef(null);
    const [numberofcampers, setNumberofCampers]                 = useState(props?.numberOfCampers ? [props.numberOfCampers] : [])
    const [counter, setCounter]                                 = useState(props?.numberOfCampers  ?? 0);
    const [activeIndex, setActiveIndex]                         = useState(props?.numberOfCampers ?? 0);
    const [fielderros, setfiledErrors]                          = useState([])
    const [errormodal, setErrorModel]                           = useState(false)
    const [successmodal, setSuccessModel]                       = useState(false)
    const { register, handleSubmit,errors, control , setValue, getValues}            = useForm();
    const [nonloginuseconfirm, setNonloginuseConfirm]           = useState(false);
    const [additionalexp, setAddtionalExp]                      = useState([]);
    const [additionalexpcount, setAddtionalExpCount]            = useState(0);
    const [additionalexperrModel, setAddtionalExpErrModel]      = useState(false);
    const [userCamperList, setUserCamperList]                   = useState([]);
    const [confirmshow, setConfirmshow]                         = useState(false);
    const [camperId, setCamperId]                               = useState(false);
    const [allErr, setAllErr]                                   = useState([]);
    const [showConfirmPopup, setShowConfirmPopup]               = useState(false);
    const [confirmPopup, setConfirmPopup]                       = useState(false);
    const [isSportInternationalCamp, setIsSportInternationalCamp]     = useState(false);
    const [lastCamper, setlastCamper] = useState('');
    const [loader, setLoader] = useState(false)



    useEffect(() => {
        getCartItems().then(res => {
            setLoader(true)
            if(res.data.statusCode){
                let _res = res.data.responseData.result || []
                const cartitemsdetail =_res.find(i => (i._id === props.cartId))
                const campId = cartitemsdetail.campId
                if (campId === settings.api.sportInternationalCamp) {
                    setIsSportInternationalCamp(true);
                } else {
                    setIsSportInternationalCamp(false)
                }
                setLoader(false)
            }else{
                setLoader(false)
            }
        })
    }, [])


    useEffect(()=>{
        setUserCamperList(props.UserCamperList);
        (props.UserCamperList?.length > 0) && setlastCamper(props.UserCamperList[props.UserCamperList?.length -1])
    },[props.UserCamperList])   


    useEffect(()=>{
        if(location.state!==undefined)
        {
        window.scrollTo({
            top:450,
            behavior: 'smooth'
        });
        }
    },[])
  
  
    const addParticPent = () => {
        setNumberofCampers(prevIndexes => [...prevIndexes, counter]);
        setCounter(prevCounter => prevCounter + 1);
    };


    useEffect(() => {
        if(location?.state?.redirectToForm){
            setNumberofCampers(prevIndexes => [...prevIndexes, counter]);
            setCounter(prevCounter => prevCounter + 1);
            setTimeout(() => {
                let element = document.getElementById("cm_scrollInTo");
                element.scrollIntoView();
            }, 1000)
        }
    }, [location?.state?.redirectToForm])

  

    const removeCamper = index => () => {
        let numbercampers = [...numberofcampers];
        numbercampers.pop(index);
        setNumberofCampers(numbercampers);
        setCounter(prevCounter => prevCounter - 1);
        setfiledErrors([]);
        props.handleCancel();
    };


    const addAditionExptoCamper = (additionexp_id, camperindex) =>{
        let adddtionexp  =[...additionalexp];
        let preselect = adddtionexp.findIndex(item => item.camperIndex ===camperindex); 
        if(preselect === -1){
            let index = adddtionexp.findIndex(item => item._id ===additionexp_id);
            adddtionexp[index].camperIndex = camperindex;
            setAddtionalExp(adddtionexp);
            setAddtionalExpCount(additionalexpcount+1);
        }else{
            let index = adddtionexp.findIndex(item => item._id ===additionexp_id);
            delete adddtionexp[index].camperIndex;
            setAddtionalExp(adddtionexp);
            setAddtionalExpCount(additionalexpcount-1);
        }
    }



  const onSubmit =  async data => { 
     let field_errors  = [];

     let participantlist = data.participants;

     let temp_allErr = [];
     var i = 0;

     participantlist.forEach((item, ind) => {

        if(item.groupcode === ''){
            let errname = `camper${i}groupcode`;    
            field_errors.push(errname);
        }
        
        if(item.firstName === ''){
            let errname = `camper${i}firstName`;    
            field_errors.push(errname)
        }

        if(item.lastName === ''){
            let errname = `camper${i}lastName`;    
            field_errors.push(errname)
        }

        if(item.dob === ''){
            let errname = `camper${i}dob`;    
            field_errors.push(errname)
        }else{
            participantlist[i].dob =  new Date(removeTime(item.dob._d)).getTime();
        }

        if(item.currentGrade === ''){
            let errname = `camper${i}currentGrade`;    
            field_errors.push(errname)
        }

        if(item.gender === ''){
            let errname = `camper${i}gender`;    
            field_errors.push(errname)
        } 

        if(item.address.trim() === ''){
            let errname = `camper${i}address`;    
            field_errors.push(errname)
        }   
 
        if(item.city.trim() === ''){
            let errname = `camper${i}city`;    
            field_errors.push(errname)
        }   
        if(!String(item.zipCode)){
            let errname = `camper${i}zipCode`;    
            field_errors.push(errname)
        }
        if(String(item.zipCode).length < 5){
            let errname = `camper${i}zipCode`;
            field_errors.push(errname) 
        }
    

        if(item.state === ''){
            let errname = `camper${i}state`;    
            field_errors.push(errname)
        }

        // if(item.shortsSize === ''){
        //     let errname = `camper${i}shortsSize`;    
        //     field_errors.push(errname)
        // }  

        if(item.tshirtSize === ''){
            let errname = `camper${i}tshirtSize`;    
            field_errors.push(errname)
        }
        if(item.dob === ''){
            let errname = `camper${i}dob`;    
            field_errors.push(errname)
        }
        
        if(!isSportInternationalCamp){
            if(item.medical === undefined){
                let errname = `camper${i}medical`;    
                field_errors.push(errname)
            }else{
                participantlist[i].medical = JSON.stringify(item.medical);
            }   
    
            if(item.allergy === undefined){
                let errname = `camper${i}allergy`;    
                field_errors.push(errname)
            }else{
                participantlist[i].allergy = JSON.stringify(item.allergy); 
            }  
            
            if(item.allergyDeadly === ''){
                let errname = `camper${i}allergyDeadly`;    
                field_errors.push(errname)
            }  
            if(item.physicianPhone){
                if(!isMobilePhone(item.physicianPhone)){
                    let errname = `camper${i}physicianPhone`;    
                    field_errors.push(errname)
                }
                
            } 
        }
        
        

        if(item.groupcode === '' || item.firstName === '' || item.lastName === '' || item.dob === '' || item.currentGrade === '' || item.gender === '' || item.address === '' || item.city === '' || item.zipCode === '' || item.state === '' || item.shortsSize === '' || item.tshirtSize === '' || item.medical === undefined || item.allergy === undefined || item.allergyDeadly === ''){
            temp_allErr.push({cm_num: i});
        }else{
            temp_allErr.splice(i, 1);
        }

        i++;
     })
  
      setfiledErrors(field_errors);

      setAllErr(temp_allErr);

      if(field_errors.length > 0){
        setErrorModel(true);
      }else{
        if(userCamperList?.length || confirmPopup || props.addcampersfromcart){
            let params = {deviceTypeID:3, participants: JSON.stringify(participantlist)}
            let participantadded = await participantAdd(params)  
            setSuccessModel(true);
        }else{
            props.addcampersfromcart ? 
            setSuccessModel(true):
            setShowConfirmPopup(true) 
        }
  
      }
  }



const handleAddMore = () => {
    setNumberofCampers(prevIndexes => [...prevIndexes, counter]);
    setCounter(prevCounter => prevCounter + 1);
    setShowConfirmPopup(false);
    setActiveIndex(activeIndex + 1);
    setTimeout(() => {
    let element = document.getElementById("cm_scrollInTo");
    element.scrollIntoView();
}, 600)
}



const getRightIndex = () => {
    if(allErr.length){
       let _temp = allErr.filter(i => i.cm_num === activeIndex);
       if(_temp.length){
        return activeIndex + 1;
       }else{
        return allErr[0].cm_num + 1
       }
    }
}


  const confirmDialog =()=> {
    setConfirmshow(true);
}

const hideconfirmation =() =>{
       setConfirmshow(false);
}



  
if(location?.state?.isScroll){
    setTimeout(() => {
        let element = document.getElementById("cm_scrollInTo");
        element.scrollIntoView();
    }, 200)
  
}


const handleSave = () => {
    setConfirmPopup(true);
    setShowConfirmPopup(false);

    if(isFormSumbitByRef){
        isFormSumbitByRef = false;
        formSumbitByRef.current.click();
        setTimeout(() => {
            formSumbitByRef.current.click();
        }, 30)
    }
}


return(

    <React.Fragment>

        <Modal
            dialogClassName="h_auto"
            show={showConfirmPopup}
            animation={false}
            centered
            >
            <Modal.Body>
                <div className="p-5">
                    Would you like to add another Participant?
                  <div className='d-flex'>
                    <span onClick={handleSave} className="btn btn-secondary mt-2 w-100 me-2">No</span>
                    <span onClick={handleAddMore} className="btn btn-primary mt-2 w-100 ms-2">Yes</span>
                  </div>
                </div>
            </Modal.Body>
          </Modal>

         <Modal
            dialogClassName="h_auto"
            show={errormodal}
            animation={false}
            centered
            >
            <Modal.Body>
                <div className="p-5">
                    <h3 className="text-center mb-4">Error</h3>
                    Please Fill All Required Fields for Participant {getRightIndex()}
                    <button  onClick={() => {setErrorModel(false)}} className="btn btn-primary w-100 mt-2">Ok</button>
                </div>
            </Modal.Body>
          </Modal>

          <Modal
            dialogClassName="h_auto"
            show={successmodal}
            animation={false}
            centered
            >
            <Modal.Body>
                <div className="p-5">
                    <h3 className="text-center mb-4">Success</h3>
                    Successfully Added
                    {
                    props?.addcampersfromcart ?
                     <button className="btn btn-primary w-100 mt-2" 
                     onClick={() => {setSuccessModel(false); props.handleCancel()}}
                    >Ok</button>
                     :
                     <Link to="/profile/information" onClick={() => window.location.reload(false)} className="btn btn-primary w-100 mt-2">Ok</Link>   
                    }    
                </div>
            </Modal.Body>
          </Modal>

          <Modal
            dialogClassName="h_auto"
            show={nonloginuseconfirm}
            animation={false}
            centered
            >
            <Modal.Body>
                <div className="p-5">
                    <h3 className="text-center mb-4">Success</h3>
                   Participant(s) added successfully
                   <Link className="btn btn-primary w-100 mt-2" to={
                                {     
                                    pathname: '/confirmdetails',
                                    state:{"numbderofCampers":props.NonLoginNumberofCampers, "program_id": props.NonloginuseProgramId}
                                }
                                } > ok </Link>
                </div>
            </Modal.Body>
          </Modal>

          <Modal
            dialogClassName="h_auto"
            show={additionalexperrModel}
            animation={false}
            centered
            >
            <Modal.Body>
                <div className="p-5">
                    <h3 className="text-center mb-4">Error</h3>
                     You Forget to add Additional Experiences
                     <button  onClick={() => {setAddtionalExpErrModel(false)}} className="btn btn-primary w-100 mt-2">Ok</button>
                </div>
            </Modal.Body>
          </Modal>

    <form onSubmit={handleSubmit(onSubmit)}>

    <div className="card mb-4">
        <h4>Participant(s)</h4>
        <ul className="campers_list">
        {!props?.addcampersfromcart  && 
           userCamperList?.length > 0 &&  userCamperList.map(campers => (
                <li>
                    <img src={campers.profileImage ? campers.profileImage : require('../../../assets/img/user.png')} alt=''/>

                    <div>
                        <p>{`${campers.firstName} ${campers.lastName}`}</p>
                        {campers.groupcode ? <p><b>Group code: </b>{campers.groupcode}</p> : ''}
                        <p><b>Age: </b>{_calculateAge(campers.dob)} year old</p>
                        <p><b>Sex: </b> 
                            {campers.gender === 1 ? 'Male' : ''}
                            {campers.gender === 2 ? 'Female' : ''}
                            {campers.gender === 3 ? 'Co-Ed' : ''}
                        </p>
                    </div>
                    
                    <div>
                        <Link to={`/profile/camper/${campers._id}`}> <i className="icon-pancil"></i></Link>
                        <i onClick={() =>{confirmDialog(); setCamperId(campers._id) }} className="icon-delete" />
                    </div>
                </li>
                
            ))
        }

        </ul>

        {!props?.addcampersfromcart &&
           props.loggedIn && (
            <div className="mt-3" id="cm_scrollInTo">
            <span className="btn color_primary p-0" onClick={addParticPent}  ><i className="icon-plus" /> Add new participants</span>
            </div>
           )  
         }   

        <div className="mt-3 mb-3 bottom_border">
            {numberofcampers.map((item,i) => <span
                                                key={i}
                                                className={`me-2 mb-2 btn btn-outline-dark rounded-0 ${activeIndex === i ? 'cm_active' : ''}`}
                                                onClick={() => setActiveIndex(item)}>Participant {i + 1}</span>)}                    
        </div>


            {
                numberofcampers.map((index, i) => {
                   
                    return (
                        props.NonLoginNumberofCampers ?
                        (
                        <div className="camperformwapper" key={index} test={index} style={{display: activeIndex === index ? "block" : "none"}}> 
                            {loader ? 
                            <div className='program_loader'><ProgramLoader/></div>
                            :
                                <CamperForm
                                isSportInternationalCamp={isSportInternationalCamp}
                                Control={control}
                                Errors={fielderros}
                                key={index}
                                ActiveIndex={activeIndex}
                                FormIndex={index}
                                refprops={register}
                                setValue={setValue}  
                                getValues={getValues}  
                                />   
                            }
                        <ul className="campers_add_exp">                   
                        {
                            additionalexp && additionalexp.map((item)=>(   
                        <li key={item} style={{display: item.camperIndex===undefined ? 'block' :  item.camperIndex === index ? 'block' : "none"}}>
                            <p><b>Title</b>:{item.title}</p>
                            <p><b>Price</b>: ${item.price}</p>
                            <p><b>Description</b>: {item.description}</p>
                            <div className="check_box_wrapper ms-auto">
                                   <input onChange={() => {addAditionExptoCamper(item._id, index)} }  type="checkbox" value="1" />
                                        <span className="check_box"><span /></span> 
                            </div>
                        </li>
                             ))
                        }
                        </ul>
                        </div>
                        ) 
                        :
                        (
                       <div className="camperformwapper" ref={myRef} key={index} test={i} activein={activeIndex} style={{display: activeIndex === index ? "block" : "none"}} > 
                            <CamperForm
                                isSportInternationalCamp={isSportInternationalCamp}
                                Errors={fielderros}
                                key={index}
                                ActiveIndex={activeIndex}
                                RemoveCamper={removeCamper}
                                FormIndex={i}
                                refprops={register}
                                GroupCode={groupCodeGenerate()}
                                Control={control}
                                setValue={setValue}  
                                lastCamper={lastCamper}
                                getValues={getValues}  
                                />   
                        </div>
                        )
                    )
               })}  
    </div>
        {
            counter > 0 &&
            <div className="col-sm-12 text-center">
                    {
                        props.addcampersfromcart?
                        <button   style={{minWidth: '130px'}} className="btn btn-light rounded-0 me-2" 
                            onClick={() => props.handleCancel()}
                        >Cancel</button>
                        :
                        <span onClick={() => {setNumberofCampers([]); setCounter(0)}} style={{minWidth: '130px'}} className="btn btn-light rounded-0 me-2">Cancel</span>
                    }
            
            <button ref={formSumbitByRef} style={{minWidth: '130px'}} className="btn btn-primary rounded-0">Save</button>
            
        </div>
        }


</form>

<ConfirmDialoag show={confirmshow} hideModal={hideconfirmation} action={(e) =>{props.RemoveCamper(camperId); setConfirmshow(false); }} Message={["Are You Sure you want to remove this camper"]} />   

    </React.Fragment>
)

}

const mapStateToProps = state => {
    let { loggedIn } = state.user;
    return {
        loggedIn
    };
}

export default connect(mapStateToProps)(Campers);