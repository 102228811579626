import React, {useState, useEffect, useRef, Fragment} from 'react';
import CounselorCard from './CounselorCard';
import { Modal } from 'react-bootstrap';
import { Link, useHistory} from 'react-router-dom';
import moment from 'moment';
import { notification } from 'antd';
import { connect } from 'react-redux';

import { inviteCounselorService, getCampDetailService, editCampDetailService } from '../../services/userServices'
import {
    counselorlist,
    createcounselorfolder,
    deletecounselorFolder,
    deletecounselor,
    allowAllCounselorFeeds
} from '../../services/directorServices';
import { ProgramLoader, FolderIcon, PDFIcon } from '../reuse/SVG';
import { addBr, debounce, getBr } from '../../helper';
import {uploadMedia} from '../../services/campersServices';
import settings from '../../config/settings';

const Counselors = props => {
    
    const [addCounselorsPopup, setAddCounselorPopup]  = useState(false);
    const [counselorType, setCounselorType]           = useState(1);
    const [counselorList, setCounselorList]           = useState([]);
    const [folderList, setFloderList]                 = useState([]);
    const [loading, setLoading]                       = useState(true);
    const [folderPopup, setFolderPopup]               = useState(false);    
    const [folderName, setFoldername]                 = useState();  
    const [addFoder, setAddFolder]                    = useState(false);  
    const [btnloder, setBtnLoader]                    = useState(false);
    const [counselorIds, setCounselorIds]             = useState([]);
    const [counselorWarning, setCounselorWarning]     = useState(false);
    const [deleteFolder, setDeleteFolder]             = useState(false);
    const [deleteFolderId, setDeleteFolderId]         = useState();
    const [selectAll, setSelectAll]                   = useState(false);
    const [inviteEmailModal, setInviteEmailModal]     = useState(false);
    const [allowAllPost, setAllowAllPost]             = useState();
    const [inviteEmails, setInviteEmails]             = useState([]);
    const [inviteWarnings, setInviteWarnings]         = useState(false);
    const [emailError, setEmailError]                 = useState(false);
    const [folderalreadyExit, setFolderAlreadyExit]   = useState(false);
    const [feedWarning, setFeedWarning]               = useState(false);
    const [sameEmailWarning, setSameEmailWarning]     = useState(false);
    const [agreementModal, setAgreementModal]         = useState(false);
    const [trainingGuideModal, setTrainingGuideModal] = useState(false);
    const [isCopy, setIsCopy]                         = useState(false);
    const [agreement, setAgreement]                   = useState('');
    const [trainingGuidePdf, setTrainingGuidePdf]     = useState('');
    const [isNewTrainingGuidePdf, setIsNewTrainingGuidePdf] = useState(true);
    const [trainingGuideUploadLoader, setTrainingGuideUploadLoader]     = useState(false);
    let history                                       = useHistory();
    const debounceOnChange                            = React.useCallback(debounce(onChange, 300), []);
    const inputemail                                  = useRef(null);

    

const getCounselorlist = () => {
    let params = `/`
        counselorlist(params).then(res => {
            if(res?.data?.statusCode){   
                setCounselorList(res.data.responseData.result.counselor)
                setFloderList(res.data.responseData.result.folder)
                setLoading(false)  
            }else{setLoading(false)}
        })
}


const getCampDetail = () => {
    getCampDetailService(`?campCode=${props?.userProfile?.campCode}`).then(res => {
        if(res?.data?.statusCode){
            let tempAgreement = res.data.responseData.result?.agreement;
            let tempTrainingGuidePdf = res.data.responseData.result?.trainingGuidePdf;
            if(tempAgreement){
                setAgreement(getBr(tempAgreement))
            }
            if(tempTrainingGuidePdf){
                setTrainingGuidePdf(tempTrainingGuidePdf);
                setIsNewTrainingGuidePdf(false);
            }
            
            
        }
    })
}

    useEffect(()=>{
        getCounselorlist();
        getCampDetail();
    },[]) 



    const  handleClick = () => {
       if(counselorType===2){ history.push("/addcounselor");}
       else { setInviteEmailModal(true); setAddCounselorPopup(false)}
      }

    const handelCreateFolder = () => {
        if(folderName){setFolderPopup(false); setAddFolder(true)}
    }  

    const handelCounslor = (e) =>{
        let listcounslor = [...counselorIds];
        setCounselorWarning(false);
        if(listcounslor.includes(e.target.value))
        {
            setSelectAll(false)
            let newcousolerlist = listcounslor.filter(item => item!==e.target.value) 
            setCounselorIds(newcousolerlist)
        }
        else
        {
            
            listcounslor.push(e.target.value);
            if(listcounslor.length===counselorList.length){setSelectAll(true)}
            setCounselorIds(listcounslor)
        }
    }

    const addFolder = () =>{
        if(counselorIds.length > 0)
        {
                let params ={"folderName":folderName, "counselorIds": JSON.stringify(counselorIds)}
                createcounselorfolder(params).then((res) => {
                    if(res?.data?.statusCode)
                    {
                        let params2 ='/'
                        counselorlist(params2).then((res)=>{
                            if(res?.data?.statusCode)
                            {   
                                setFloderList(res.data.responseData.result.folder)
                                setCounselorList(res.data.responseData.result.counselor)
                                setBtnLoader(false);
                                setAddFolder(false); 
                                setCounselorIds([]);
                                setFoldername('');
                                setSelectAll(false)
                                notification.open({
                                    description:
                                      'Folder created successfully',
                                  });

                            }
                        })
                    }
                    else if(res.data.statusCode===0){
                            setBtnLoader(false);
                            setFolderAlreadyExit(true)
                    }
                    else
                    {

                    }
                })
        }
        else
        {
            setCounselorWarning(true);
            setBtnLoader(false);
        }
    }

    const deleteFolderAction = () => {
        let folders =[...folderList];
        let params = `/?folderId=${deleteFolderId}`;
        deletecounselorFolder(params).then((res)=>{
            if(res?.data?.statusCode)
            {
                let newfolderlist = folders.filter(item => item._id !== deleteFolderId)
                setFloderList(newfolderlist);
                setDeleteFolder(false);
                let params2 ='/'
                counselorlist(params2).then((res)=>{
                    if(res?.data?.statusCode)
                    {   
                        setCounselorList(res.data.responseData.result.counselor)
                        setLoading(false)  
                    }else{setLoading(false)}
                })


                notification.open({
                    description:
                      'Folder Deleted Successfully',
                  });
            }
        })   
    }

    const selectAllCounslors = () =>{
        let listcounslor = [...counselorIds];
        listcounslor.length = 0;
        counselorList.map(item=>{
            listcounslor.push(item._id);
            return null;
        })
        setCounselorIds(listcounslor)
    }

    const deselectAllCounslors = () =>{
        let listcounslor = [...counselorIds];
        listcounslor.length = 0;
        setCounselorIds(listcounslor)
    }    

    // const searchAction = (e) =>{
    //     let searchterm  = e.target.value;
        
    // }
    const addInviteEmail=(e)=>{
        let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(e.target.value)) {
            setEmailError(true);
            setSameEmailWarning(false);
        }
        else if(inviteEmails.includes(e.target.value))
        {
            setSameEmailWarning(true);
        }
        else
        {
            let emails = [...inviteEmails];
            emails.push(e.target.value);
            setInviteEmails(emails);
            setEmailError(false);
            e.target.value='';
            setSameEmailWarning(false);
        }
    }

    const addInviteEmailbutton=()=>{
        let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(inputemail.current.value)) {
            setEmailError(true);
            setSameEmailWarning(false);
        }
        else if(inviteEmails.includes(inputemail.current.value))
        {
            setSameEmailWarning(true);
        }
        else
        {
            let emails = [...inviteEmails];
            emails.push(inputemail.current.value);
            setInviteEmails(emails);
            setEmailError(false);
            inputemail.current.value='';
            setSameEmailWarning(false);
        }
    }


    function onChange(value) {
        if(value!=='')
        {
        setLoading(true);
        let params = `/?search=${value}`
        counselorlist(params).then((res)=>{
            if(res?.data?.statusCode)
            {   
                setCounselorList(res.data.responseData.result.counselor)
                setFloderList(res.data.responseData.result.folder)
                setLoading(false)  
            }else{setLoading(false)}
        })
        }
        else
        {
            let params = `/`
            counselorlist(params).then((res)=>{
                if(res?.data?.statusCode)
                {   
                    setCounselorList(res.data.responseData.result.counselor)
                    setFloderList(res.data.responseData.result.folder)
                    setLoading(false)  
                }else{setLoading(false)}
            })
        }
      }

    const handelInviteAction = () => {
        if(inviteEmails.length === 0){
            setInviteWarnings(true)
        }else{
         

            let params = {
                campCode: props.userProfile.campCode,
                emailArray: JSON.stringify(inviteEmails),
                counselorAddedby: props.userProfile._id
            }

            inviteCounselorService(params).then(res => {
                if(res?.data?.statusCode){
                    setInviteWarnings(false);
                    setInviteEmailModal(false);

                    notification.open({
                        message: 'Invite sent Successfully',
                        description:`${res.data.responseData.message}`
                    });

                    getCounselorlist();
                    setInviteEmails([]);
                }
            })
            
        }
    } 


    const removeEmail = i => {
        let emails = [...inviteEmails];
        emails.splice(i, 1);
        setInviteEmails(emails);
       
        //setInviteEmails([])
    }
    
    const deletecounselorAction =(id) => {
        deletecounselor(`/?counselorId=${id}`).then(res=>{
            if(res.data.statusCode===1)
            {
                let counsolernewlist = counselorList.filter(item => item._id !== id )
                setCounselorList(counsolernewlist);
                notification.open({
                    description:
                      'Personnel Deleted Successfully',
                  });
            }
            else
            {
                notification.open({
                    description:
                      'Please try after some time',
                  });   
            }
        })
    }

    const allowdisallowfeedforallAction =(type) =>{
        setLoading(true)
         let params={}  
         if(type===1)
           {
            setAllowAllPost(1);
            params.counselorAllowFeed = 1
           }
           else
           {
            params.counselorAllowFeed = 2   
            setAllowAllPost(2);
           }
        
         allowAllCounselorFeeds(params).then(res=>{
            setFeedWarning(false);
            
            if(res?.data?.statusCode)
            {
                let personnels = [...counselorList];
                personnels.map((item,i)=>{
                    personnels[i].counselorAllowFeed = type
                    return null;
                })
                setCounselorList(personnels);
                setLoading(false);
                notification.open({
                    description:
                      'Feed setting updated successfully',
                  });
            }
            else
            {
                setAllowAllPost();
                notification.open({
                    description:
                      'Please try after some time',
                  });
                  setLoading(false);
            }
        })   


    }



    const closeModal = () => {
        setInviteEmailModal(false);
        setSameEmailWarning(false);
        setInviteWarnings(false);
        setInviteEmails([])
    }



    const handleAgreementSubmit = e => {
        e.preventDefault();
        setTrainingGuideUploadLoader(true);
        let params = {
            campCode: props.userProfile.campCode,
            trainingGuidePdf: trainingGuidePdf,
            agreement: addBr(agreement)
        }

        editCampDetailService(params).then(res => {
            setTrainingGuideUploadLoader(false);
            if(trainingGuidePdf){
                setIsNewTrainingGuidePdf(false);
            }
            if(res?.data?.statusCode){
                getCampDetail();
                notification.open({
                    description: 'Data updated successfully',
                });
            }

            setAgreementModal(false)
            setTrainingGuideModal(false)
        })

    }



    const handleUploadMedia = e => {
        const data = new FormData();
        setTrainingGuideUploadLoader(true);
        data.append('file', e.target.files[0]);
        uploadMedia(data).then((res => {
            setTrainingGuideUploadLoader(false);
            if(res.data.responseData.result.fileUrl !== undefined){
                setTrainingGuidePdf(res.data.responseData.result.fileUrl);
            } 
            })
        )
    }

    const handleCopy = () => {
        setIsCopy(true);
        navigator.clipboard.writeText(`${settings.api.SITE_URL}signup-staff/${props.userProfile.campCode}/${props.userProfile._id}`);
        setTimeout(() => {
          setIsCopy(false)
        }, 3000)
      }


    const handleClose = () => {
        setTrainingGuideModal(false);
        if(isNewTrainingGuidePdf){
            setTrainingGuidePdf('')
        }
    }





    return(
        <div className="body_container pb-4 director_wrapper">
            <Modal
            show={inviteEmailModal}
            animation={false}
            dialogClassName="folder_model"
            size="lg"
            centered
            >
            <Modal.Body>
                <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
                <div className="text-center p-5 pickup_modal_body">
                    <h3>Invite by Email</h3>
                </div>
                <div className="form-group">
                    {inviteWarnings ? <div className="alert alert-danger" role="alert"> Email address missing</div> : ''}
                    {sameEmailWarning ? <div className="alert alert-danger" role="alert">Email address already present</div> : ''}
                    
                    <label>Email</label>
                    <div className="row">
                        <div className="col-10">
                        <input onKeyPress={e => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                addInviteEmail(e)
                                            }
                        }} onChange={() => setInviteWarnings(false)} type="email"  ref={inputemail} className={`form-control ${emailError ? 'is-invalid': ''}`} />    
                        </div>
                        <div className="col-2 ps-0">
                            <button className="btn btn-primary" onClick={addInviteEmailbutton}>Add</button> 
                        </div>   
                    </div>

                <div className="equipment_chip mt-3">
                    {inviteEmails.length > 0 ? inviteEmails.map((item, i) => <span className="mb-2" key={i}>{item} <i onClick={() => removeEmail(i)} className="icon-cross" /></span>) : ''}
                    </div>
                </div>  

                <div className="text-center p-5"> 
                    <button onClick={handelInviteAction} disabled={inviteEmails.length === 0 ? 'true': '' } style={{width: '100%'}} className="btn btn-primary">Done</button>
                </div>
            </Modal.Body>
            </Modal>




            <Modal
            show={addCounselorsPopup}
            animation={false}
            dialogClassName="pickup_modal"
            size="md"
            centered
            >
            <Modal.Body>
                <span className="modal_close" onClick={()=>{setAddCounselorPopup(false)}}><i className="icon-cross" /></span>
                <div className="text-center p-5 pickup_modal_body">
                    <h3>Invite/Create</h3>

                    <div className="d-flex justify-content-center">
                        <div className={`p-5 ${counselorType === 1 ? 'active' : ''}`} onClick={() => setCounselorType(1)}>
                            <img src={require('../../assets/img/invite.png')} />
                            <h4>Invite</h4>
                        </div>
                        <div className={`p-5 ${counselorType === 2 ? 'active' : ''}`} onClick={() => setCounselorType(2)}>
                            <img src={require('../../assets/img/create.png')} />
                            <h4>Create</h4>
                        </div>
                    </div>
                    <button  onClick={handleClick} className="btn btn-primary">Next</button>
                </div>
            </Modal.Body>
            </Modal>


            <Modal
            show={folderPopup}
            animation={false}
            dialogClassName="folder_model"
            size="lg"
            centered
            >
            <Modal.Body>
                <span className="modal_close" onClick={()=>{setFolderPopup(false)}}><i className="icon-cross" /></span>
                <div className="text-center p-5 pickup_modal_body">
                    <h3 className="create_f_title">Create a Folder</h3>
                </div>

               <div className="form-group">
                    <label>Folder Name</label>
                    <input type="text" onChange={(e)=>{ setFoldername(e.target.value) }} className="form-control"></input>  
                </div>  

                <div className="text-center mb-4"> 
                <button  onClick={handelCreateFolder} style={{width: '100%'}} className="btn btn-primary">Done</button>
                </div>
            </Modal.Body>
            </Modal>


            <Modal
            show={deleteFolder}
            animation={false}
            dialogClassName="folder_model"
            size="lg"
            centered
            >
            <Modal.Body>
                <span className="modal_close" onClick={()=>{setDeleteFolder(false)}}><i className="icon-cross" /></span>
                <div className="text-center p-5 pickup_modal_body">
                    <p>Are you sure you want to delete this folder</p>
                </div>
                <div className="text-center p-5"> 
                <button  onClick={()=>{setDeleteFolderId(''); setDeleteFolder(false)}} style={{width: '120px'}} className="btn btn-light rounded-0">No</button>
                <button  onClick={deleteFolderAction} style={{width: '120px'}} className="btn btn-primary ms-3">Yes</button>
                </div>
            </Modal.Body>
            </Modal>

            <Modal
            show={feedWarning}
            animation={false}
            dialogClassName="folder_model"
            size="lg"
            centered
            >
            <Modal.Body>
                <span className="modal_close" onClick={()=>{setFeedWarning(false)}}><i className="icon-cross" /></span>
                <div className="text-center p-5 pickup_modal_body">
                    <p>Are you sure you want to restrict all the personnel to  post on Feeds</p>
                </div>
                <div className="text-center p-5"> 
                <button  onClick={()=>{setFeedWarning(false)}} style={{width: '120px'}} className="btn btn-light rounded-0">No</button>
                <button  onClick={()=>{ allowdisallowfeedforallAction(2)}} style={{width: '120px'}} className="btn btn-primary ms-3">Yes</button>
                </div>
            </Modal.Body>
            </Modal>

            <Modal
                show={agreementModal}
                animation={false}
                dialogClassName="folder_model"
                size="lg"
                centered
            >
            <Modal.Body>
                <span className="modal_close" onClick={() => setAgreementModal(false)}><i className="icon-cross" /></span>
                <div className="text-center p-5 pickup_modal_body">
                    <div className="text-center p-5 pickup_modal_body">
                        <p>Edit Agreement</p>
                    </div>
                    <form onSubmit={handleAgreementSubmit}>
                        <div className="form-group trainingGuideUploadLoader">
                        {trainingGuideUploadLoader ? <div className="page_loader"><ProgramLoader /></div> : ''}
                            <textarea
                                value={agreement}
                                onChange={e => setAgreement(e.target.value)}
                                className="form-control cm_text_area"></textarea>
                        </div>

                        {agreement ? <button type="submit" className="btn btn-primary w-100">Save</button> : ''}

                        
                    </form>

                </div>
            </Modal.Body>
            </Modal>


            <Modal
                show={trainingGuideModal}
                animation={false}
                dialogClassName="folder_model"
                size="lg"
                centered
            >
            <Modal.Body>
                <span className="modal_close" onClick={handleClose}><i className="icon-cross" /></span>
                <div className="text-center p-5 pickup_modal_body">
                    <div className="text-center p-5 pb-0 pickup_modal_body">
                        <p>Upload Training Guide</p>
                    </div>
                    <form onSubmit={handleAgreementSubmit}>
                    <div className={`icon_upload_wrapper upload_2 ${trainingGuidePdf ? 'uploaded' : ''}`}>
                       
                        {trainingGuideUploadLoader ? <div className="page_loader"><ProgramLoader /></div> : ''}
                        <input type="file" accept=".pdf,.doc" onChange={handleUploadMedia} value="" />
                        <div>
                            <i className="icon-upload" />
                            <p>Upload PDF</p>
                        </div>
                    </div>
                    {trainingGuidePdf ? <Fragment>
                        <div className="trainingGuidePdf"><a target="_blank" href={trainingGuidePdf}><PDFIcon /> {trainingGuidePdf ? trainingGuidePdf.slice(trainingGuidePdf.lastIndexOf('_') + 1) : ''}</a> <span className="icon-close" onClick={() => setTrainingGuidePdf('')} /></div>
                        <button type="submit" className="btn btn-primary w-100">Save</button>
                    </Fragment> : ''}

                        
                    </form>

                </div>
            </Modal.Body>
            </Modal>


            
               {
                !addFoder ? 
                <div className="container">
                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Personnel</h4>
                    { 
                     counselorList.length > 0 ?  
                    <div className="btn_group">
                        <button className="btn btn-primary me-2" onClick={() => setAgreementModal(true)}><i className="icon-doc-check" /> Agreement</button>
                        <button className="btn btn-primary me-2" onClick={() => setTrainingGuideModal(true)}><i className="icon-upload" /> Upload Training Guide</button>
                        <button className="btn btn-primary" onClick={() => setFolderPopup(true)}><i className="icon-folder-2" /> Add Folder</button>
                    </div>
                    : ''
                    }
                </div>

                <div className={`staff_create_link ${isCopy ? 'copyied' : ''}`}>
                    <p><b>Personnel Signup Link:</b> {settings.api.SITE_URL}signup-staff/{props.userProfile.campCode}/{props.userProfile._id} <span onClick={handleCopy} className="icon-copy"></span> {isCopy ? <span className="copy_tooltip">Copied</span> : ''}</p>
                </div>

                <div className="row">
                    <div className="col-lg-2" />
                    <div className="col-lg-8">

                    

                        {counselorList.length ? <div className="mb-3">Allow all Personnel to post on Feeds
                        <div className="inline_ans">
                            <div className="check_box_wrapper cm_dark_check">
                                <input onChange={()=>{allowdisallowfeedforallAction(1)}} type="checkbox"  checked={allowAllPost ===1 ? 'checked' : '' }/>
                                <span className="check_box"><span></span>Yes</span>
                            </div>
                            <div className="check_box_wrapper cm_dark_check">
                                <input onChange={()=>{setFeedWarning(true)}} type="checkbox"  checked={allowAllPost ===2 ? 'checked' : '' }/>
                                <span className="check_box"><span></span>No</span>
                            </div>
                        </div>
                        </div> : ''}
                        
                        <div className="d-flex align-items-center justify-content-between page_card_header cm_revers">
                            <h4>Personnel Library</h4>
                            <div className="btn_group">
                                <Link to="/personnel/schedule" className="btn btn-secondary me-2">Schedule</Link>
                                <button className="btn btn-primary" onClick={()=>{ setAddCounselorPopup(true)}}>Add Personnel</button>
                            </div>
                        </div>
                        <div className="partner-form search_wrap" style={{maxWidth: '100%'}}>
                            <i className="icon-search" />
                            <input type="text" onChange={e => debounceOnChange(e.target.value)} className="form-control form-control-lg" placeholder="Search" />
                        </div>
                        {
                             loading ? <div className="program_loader"><ProgramLoader /></div> : 

                             folderList.length > 0 ?
                             folderList.map(folder =>(
                                <div className="d-flex align-items-center counselor_card folder_card" key={folder._id}>
                                
                                     <div>
                                        <div className="folder_icon"> <Link to={`personnel/folder/${folder._id}`}><FolderIcon /></Link></div>
                                        <h4> <Link to={`personnel/folder/${folder._id}`}>{folder.folderName}</Link> </h4>
                                        <small>{moment(folder.created).format('MM/DD/YYYY hh:mm')}</small>

                                    </div>
                                    <div className="ms-auto">
                                    <Link className="btn btn-primary"
                                        to={{     
                                            pathname: '/inbox',
                                            user:{"_id":folder?.groupId?._id, "name": folder?.folderName, "type":2}
                                        }} >Message Entire Folder</Link>
                                    </div> 
                                
                                <div className="remove_counselor"><i onClick={()=>{setDeleteFolder(true); setDeleteFolderId(folder._id)}} className="icon-delete" /></div>
                                </div>
                             )) 
                             : ''
                        }
                        {
                            loading ? '' : counselorList.length ? counselorList.map(counsloer => (
                                <CounselorCard
                                    Details={counsloer}
                                    key={counsloer._id}
                                    DeletecounselorAction={deletecounselorAction}/>  
                             )) : ''
                        }     
                           
                        {counselorList.length ? '' : <div className="d-flex align-items-center counselor_card">No Personnel Found</div>}

                        
                    </div>
                </div>
            </div>
   :
            <div className="container">
                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4> Select Personnel</h4>
                    <div className="btn_group">
                        {
                            selectAll ?
                            <button className="btn btn-primary" onClick={()=>{deselectAllCounslors(); setSelectAll(false)}}> Deselect All </button>
                            :
                            <button className="btn btn-primary" onClick={()=>{selectAllCounslors(); setSelectAll(true)}}> Select All </button> 
                        }
                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-2" />
                    <div className="col-lg-8">



                        <div className="partner-form search_wrap" style={{maxWidth: '100%'}}>
                            <i className="icon-search" />
                            <input type="text" onChange={e => debounceOnChange(e.target.value)} className="form-control form-control-lg" placeholder="Search" />
                        </div>
                        {
                           counselorWarning ? <p className="waring_text">Please select the personnel First</p> : ''  
                        }
                        {
                             folderalreadyExit ? <p className="waring_text">Folder with same name already exit</p> : ''
                        }
                        {
                            counselorList.length > 0 ? 
                            counselorList.map(counsloer=>(
                             <div className="d-flex align-items-center counselor_card">
  
                                <div className="me-3">
                                    <img src={counsloer.profileImage ? counsloer.profileImage : require('../../assets/img/camps-user.png')} />
                                </div> 
                
                                <div>
                                <h4>{counsloer.name}</h4>
                                </div>
                                   
                                <div className="ms-auto cm_mob_abs">
                                <div className="check_box_wrapper cm_dark_check">
                                   <input type="checkbox"  value={counsloer._id} checked={counselorIds.includes(counsloer._id) ? 'checked' :''} onChange={(e)=>handelCounslor(e)}></input>
                                   <span className="check_box"><span></span></span>
                                </div>   
                                </div> 
                        </div>
                            )) 
                            :
                            <div className="d-flex align-items-center counselor_card">
                                No Personnel Found
                            </div>    
                        }

                    <div className="col-sm-12 text-center mb-5">
                        <Link className="btn btn-light rounded-0 me-2" onClick={()=>{setFolderAlreadyExit(false); setAddFolder(false);  setCounselorIds([]); setSelectAll(false);setFoldername('')}} style={{width: '130px'}}>Cancel</Link>
                        <button  onClick={()=>{setBtnLoader(true); addFolder()}} className={`btn btn-primary rounded-0 ${btnloder ? 'btnLoader' : ''}`} style={{width: '130px'}}>Done</button>
                    </div>

                    </div>
                </div>
           
            </div>
        }




        </div>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { userProfile } = state.user;
    return {
        userProfile
    };
}
  
  
export default connect(mapStateToProps, null)(Counselors);
