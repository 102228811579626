import React from 'react';
import { Carousel } from 'react-bootstrap';
import Slider1 from '../../assets/img/slider-1.jpg';
import Slider2 from '../../assets/img/slider-2.jpg';
import Slider3 from '../../assets/img/slider-3.jpeg';


const MainSlider = () => (
    <Carousel controls={false} indicators={false}>
        <Carousel.Item><img src={Slider1} alt='slider'/></Carousel.Item>
        <Carousel.Item><img src={Slider2} alt='slider'/></Carousel.Item>
        <Carousel.Item><img src={Slider3} alt='slider'/></Carousel.Item>
    </Carousel>
)

export default MainSlider