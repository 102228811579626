import React, { useReducer, Fragment, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { jsPDF } from "jspdf";
import SignaturePad from 'react-signature-canvas';
import moment from 'moment';

import { waiverAction, waiverURLAction, waiver2URLAction, waiver3URLAction, waiver4URLAction } from '../../../store/ui';
import { uploadMedia } from '../../../services/campersServices';
import GroupList from './GroupList';
import {  Game, Practice, Travel, warnningText } from './TLCHelper';
import { LogoImage } from './LogoImg';





const WaiverPopups = ({waiverType, waiverURLAction, waiverModal, waiverModalAction}) => {
    const signatureRef1 = useRef();
    const signatureRef2 = useRef();
    const signatureRef3 = useRef();
    const signatureRef4 = useRef();
    const [userInput, setUserInput] = useReducer((state, newState) => ({...state, ...newState}),
        {
            w_1: '',
            w_2: '',
            w_3: moment().format("MMM Do YYYY"),
            w_4: '',
            w_5: '',
            w_6: moment().format("MMM Do YYYY"),
            wl_1: '',
            wl_2: '',
            wl_3: '',
            wl_4: '',
            wl_5: '',
            wl_6: '',
            wl_7: '',
            wl_8: '',
            wl_9: 'Lacrosse',
            wl_10: 'Lacrosse',
            wl_11: 'Lacrosse',
            wl_12: 'Lacrosse',
            wl_13: 'Lacrosse',
            wl_14: 'Lacrosse',
            wl_15: 'Lacrosse',
            wl_16: '',
            wl_17: moment().format("MMM Do YYYY"),
            wl_18: '',
            wl_19: moment().format("MMM Do YYYY"),

            kd_1: '',
            kd_2: '',
            kd_3: '',
            kd_4: '',

            ks_1: '',
            ks_2: '',
            ks_3: '',
            ks_4: '',
            ks_5: '',
            ks_6: '',
            ks_7: '',
        }
    );


    useEffect(() => {
        setUserInput({
            w_1: '',
            w_2: '',
            w_3: moment().format("MMM Do YYYY"),
            w_4: '',
            w_5: '',
            w_6: moment().format("MMM Do YYYY"),
            wl_1: '',
            wl_2: '',
            wl_3: '',
            wl_4: '',
            wl_5: '',
            wl_6: '',
            wl_7: '',
            wl_8: '',
            wl_9: 'Lacrosse',
            wl_10: 'Lacrosse',
            wl_11: 'Lacrosse',
            wl_12: 'Lacrosse',
            wl_13: 'Lacrosse',
            wl_14: 'Lacrosse',
            wl_15: 'Lacrosse',
            wl_16: '',
            wl_17: moment().format("MMM Do YYYY"),
            wl_18: '',
            wl_19: moment().format("MMM Do YYYY"),
            kd_1: '',
            kd_2: '',
            kd_3: '',
            kd_4: '',
            ks_1: '',
            ks_2: '',
            ks_3: '',
            ks_4: '',
            ks_5: '',
            ks_6: '',
            ks_7: '',
        })
    }, [waiverType])


    const handleChange = e => {
        setUserInput({[e.target.name]: e.target.value});
    }

    const lineSpaciing = 7

    const pdfGen = () => {
        const doc = new jsPDF();
        /** */
        if(waiverType === 1){
              const pageWidth = doc.internal.pageSize.getWidth();
              const pageHeight = doc.internal.pageSize.getHeight();
              const margin =10; // Standard margin
              const lineSpacing = 5; // Space between lines
              let currentY = margin; // Starting Y position
          
                // Helper function to add text and handle page breaks
                    const addText = (text, fontSize = 10, bold = false, center = false) => {
                        const textWidth = pageWidth - margin * 2; // Account for margins
                        const textItemWidth = doc.getTextWidth(text);
                        let centermargin = Math.floor((pageWidth - textItemWidth) / 2);
                        centermargin = centermargin < 25? centermargin+ 14 :centermargin
                        doc.setFontSize(fontSize);
                        doc.setFont("helvetica", bold ? "bold" : "normal");
                        
                        const lines = doc.splitTextToSize(text, textWidth); // Split text into lines
                        
                        lines.forEach((line) => {
                            if (currentY + lineSpacing > pageHeight - margin) {
                                doc.addPage(); // Add a new page if we exceed the page height
                                currentY = margin; // Reset Y position
                            }
                            doc.text(line, (center ? centermargin : margin), currentY);
                            currentY += lineSpacing; // Move to the next line
                        });
                    };
                      addText("Tigard Lacrosse", 22, true, true);
                      currentY +=lineSpaciing;
                      addText("Tigard Lacrosse Player Expectations/Contract", 16, true, true);
                      currentY +=lineSpaciing;
                      // Add the first list heading and list items
                      addText("Practice Expectations", 14, true, true);
                      currentY +=lineSpaciing; 
                      Practice.forEach((item, index) => {
                          addText(`${index + 1}. ${item}`, 10);
                      });
                      // Add the second list heading and list items
                      currentY +=lineSpaciing; // Add some space before the next section
                      addText("Travel Expectations", 14, true, true);
                      currentY +=lineSpaciing; 
                      Travel.forEach((item, index) => {
                          addText(`${index + 1}. ${item}`, 10);
                      });
                  
                      currentY +=lineSpaciing; // Add some space before the next section
                      addText("Game Expectations", 14, true, true);
                      currentY +=lineSpaciing; 
                      Game.forEach((item, index) => {
                          addText(`${index + 1}. ${item}`, 10);
                      });
          
                      currentY +=lineSpaciing;
                      addText(warnningText, 10, true);
                       const pledge =   [ `I (print name), ${userInput?.w_1 || "--------"} ,have read ALL the practice, game`, 
                          "and player expectations and agree to follow each rule knowing that if I do not, consequences could result in running", 
                          "loss of playing time, and/or removal from the team."].join(' ');
                      currentY +=lineSpaciing;
                      addText(pledge, 10, true);
          
                      currentY +=lineSpaciing;
                      addText("Participant signature:");
                      if(userInput.w_2){
                          doc.addImage(userInput.w_2, "JPEG", 100, currentY);
                      }
                      currentY +=lineSpaciing;
                      doc.line(40, currentY, 150, currentY);
                      currentY +=(lineSpaciing +10);
                      addText(`Date signed: ${userInput.w_3}`);    
                      
          
                      currentY +=lineSpaciing;
                      addText("Parent guardian/signature:");
                      if(userInput.w_5){
                          doc.addImage(userInput.w_5, "JPEG", 100, currentY);
                      }
                      currentY +=lineSpaciing;
                      doc.line(40, currentY, 150, currentY)
                      currentY +=(lineSpaciing+10);
                      addText(`Date signed: ${userInput.w_6}`);     
        }
        /** */


        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************


        if(waiverType === 2){
            doc.addImage(require('../../../assets/img/ttsd-logo.jpg'), "JPEG", 15, 10, 20, 20);
            doc.setFont('helvetica', "bold");
            doc.setFontSize(10);
            doc.text(" Tigard-Tualatin School District", 100, 20, null, null, "center");
            doc.text(" WAIVER OF LIABILITY AND HOLD HARMLESS FOR COMMUNICABLE", 100, 27, null, null, "center");
            doc.text(" DISEASES INCLUDING COVID-19", 100, 34, null, null, "center");
            doc.setFontSize(10);
            doc.setFont("helvetica", "normal")
            doc.text("Student Name:", 15, 50, null, null);
            doc.text(userInput.wl_1, 43, 50, null, null);
            doc.line(40, 50, 190, 50);
            doc.text("Grade:", 15, 57, null, null);
            doc.text(userInput.wl_2, 32, 57, null, null);
            doc.line(30, 57, 97, 57);
            doc.text("Home Phone:", 100, 57, null, null);
            doc.text(userInput.wl_3, 127, 57, null, null);
            doc.line(125, 57, 190, 57);
            doc.text("Address:", 15, 64, null, null);
            doc.text(userInput.wl_4, 32, 64, null, null);
            doc.line(30, 64, 190, 64);
            doc.text("Parent(s)/Guardian(s) Names:", 15, 71, null, null);
            doc.text(userInput.wl_5, 65, 71, null, null);
            doc.line(63, 71, 190, 71);
            doc.text("Parent/ Guardian phone: Work:", 15, 78, null, null);
            doc.text(userInput.wl_6, 67, 78, null, null);
            doc.line(65, 78, 100, 78);
            doc.text("Home:", 102, 78, null, null);
            doc.text(userInput.wl_7, 117, 78, null, null);
            doc.line(115, 78, 150, 78);
            doc.text("Other:", 152, 78, null, null);
            doc.text(userInput.wl_8, 167, 78, null, null);
            doc.line(165, 78, 190, 78);
            doc.text("The novel coronavirus (“COVID-19”), has been declared a worldwide pandemic by the World Health Organization.", 15, 85, null, null);
            doc.text("COVID-19 is extremely contagious and is believed to spread mainly from person-to-person contact. While rules,", 15, 92, null, null);
            doc.text("guidance, and personal discipline may reduce this risk, the risk of serious illness and death does exist.", 15, 99, null, null);
            doc.setFont('helvetica', "bold");
            doc.setFontSize(9);
            doc.text("Tigard-Tualatin School District (“District”) cannot completely mitigate the transfer of communicable diseases like", 15, 106, null, null);
            doc.line(15, 107, 187, 107);
            doc.text("COVID-19 especially when involved in", 15, 113, null, null);
            doc.text(userInput.wl_9, 100, 113, null, null);
            doc.text("Participation in", 150, 113, null, null);
            doc.line(15, 114, 175, 114);
            doc.text("includes possible exposure to and illness, injury, or death from infectious ", 78, 120, null, null);
            doc.text(userInput.wl_10, 15, 120, null, null);
            doc.line(15, 121, 190, 121);
            doc.text("diseases including COVID-19.", 15, 128, null, null);
            doc.line(15, 129, 60, 129);
            doc.setFontSize(10);
            doc.setFont("helvetica", "normal")
            doc.text("In consideration for providing my child the opportunity to participate in__________________________________and", 15, 138, null, null);
            doc.text(userInput.wl_11, 150, 138, null, null);
            doc.text("any related transportation to and from__________________________________events, both my child and I", 15, 144, null, null);
            doc.text(userInput.wl_12, 100, 144, null, null);
            doc.text("voluntarily agree to waive and discharge any and all claims against District and release it from liability for any", 15, 150, null, null);
            doc.text("exposure to or illness or injury from an infectious disease including COVID-19, including claims for any negligent", 15, 156, null, null);
            doc.text("actions of the District or its employees or agents, to the fullest extent allowed by law, for myself, my child, our", 15, 162, null, null);
            doc.text("estates, our heirs, our administrators, our executors, our assignees, and our successors.", 15, 168, null, null);
            doc.text("I also agree to release, exonerate, discharge and hold harmless the District, its Board of Directors, the individual", 15, 174, null, null);
            doc.text("members thereof, and all officers, agents, employees, volunteers, and representatives from all liability, claims,", 15, 180, null, null);
            doc.text("causes of action, or demands, including attorney fees, fines, fees, or other costs (e.g. medical costs) arising out of", 15, 186, null, null);
            doc.text("any exposure to or illness or injury from an infectious disease including COVID-19, which may result from or in", 15, 192, null, null);
            doc.text("connection with my child’s participation in__________________________________.", 15, 198, null, null);
            doc.text(userInput.wl_13, 100, 198, null, null);
            doc.text("I further certify and represent that I have the legal authority to waive, discharge, release, and hold harmless the", 15, 208, null, null);
            doc.text("released parties on behalf of myself and the above-named student.", 15, 214, null, null);
            doc.setFont('helvetica', "bold");
            doc.setFontSize(9);
            doc.text("I certify that I have read this document in its entirety and fully understand its contents. In exchange for the", 15, 224, null, null);
            doc.text("opportunity to participate in__________________________________, the above-named student and I freely and ", 15, 230, null, null);
            doc.text(userInput.wl_14, 65, 230, null, null);
            doc.text("voluntarily assume all risks of such hazards and notwithstanding such, release District from all liability for any loss", 15, 236, null, null);
            doc.text("regardless of cause, and claims arising from the student's participation in__________________________________.", 15, 242, null, null);
            doc.text(userInput.wl_15, 140, 242, null, null);
            doc.text("__________________________________________________ ___________________", 40, 252, null, null);
            doc.text("Student Signature Date", 40, 256, null, null);
            doc.text("Date", 135, 256, null, null);
           // doc.text(data.wl_16, 40, 252, null, null);
           if(userInput.wl_16){
            doc.addImage(userInput.wl_16, "JPEG", 60, 252);
           }
            doc.text(userInput.wl_17, 135, 252, null, null);
            doc.text("__________________________________________________ ___________________", 40, 266, null, null);
            doc.text("Parent/Legal Guardian Signature Date", 40, 272, null, null);
            doc.text("Date", 135, 272, null, null)
            //doc.text(data.wl_18, 40, 266, null, null);
            if(userInput.wl_18){
                doc.addImage(userInput.wl_18, "JPEG", 60, 266);
            }
            doc.text(userInput.wl_19, 135, 266, null, null);
        }






        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************


        if(waiverType === 3){
            doc.setFont('helvetica');
            doc.rect(20, 10, 41, 4, 'F');
            doc.setFontSize(10);
            doc.setTextColor("white");
            doc.text("PARENTS/GUARDIANS:", 21, 13, null, null);
            doc.setFontSize(11);
            doc.setTextColor("black");
            doc.text("This form must be completed before we can assign your participant a room!", 127, 13, null, null, "center");
             
             
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.text("Key/Dorm Damage Agreement", 105, 20, null, null, "center");
            
            doc.setFontSize(11);
            doc.setFont('helvetica', 'normal');
            doc.text("A signed Key/Dorm Damage Agreement is required by the school before you can attend the", 10, 30, null, null);
            doc.text("camp/academy. The cost of a lost key and/or any damage to the room is $100. We suggest", 10, 35, null, null);
            doc.text("participants put their dorm key on a very bright colored shoestring or lanyard. If needed, lanyards will be", 10, 40, null, null);
            doc.text("favailable in the camp store for the 4 day programs or during registration for all other programs.", 10, 45, null, null);
            
            doc.setFontSize(11);
            doc.setFont('helvetica', 'normal');
            doc.text("INSTRUCTIONS: The following amount will not be charged unless your participant’s dorm key is lost or", 10, 58, null, null);
            doc.text("damaged, the key is not turned in on the last day of the program, there is damage to the dorm room", 10, 63, null, null);
            doc.text("they will be staying in or damage to any other item issued by the school.", 10, 68, null, null);
            

            doc.setFont('helvetica', 'bold');
            doc.text("Participant's name:_____________________________________________ Participant’s age: _________", 10, 85, null, null);
            doc.text(userInput.kd_1, 50, 85, null, null);
            doc.text(userInput.kd_2, 180, 85, null, null);


            doc.setFontSize(11);
            doc.setFont('helvetica', 'normal');
            doc.text("Important: A parent or guardian must sign this form below for your participant to receive a dorm key", 10, 100, null, null);
            doc.text("and/or swipe card. Room checks will take place by the college after all participants have left campus.", 10, 105, null, null);


            doc.setTextColor("black");
            doc.setFont('helvetica', 'bold');
            doc.text("Parent Signature: ___________________________________________________DATE:________________", 10, 120, null, null);
            doc.text(userInput.kd_3, 48, 120, null, null);
            doc.text(userInput.kd_4, 168, 120, null, null);
           
            doc.setFontSize(11);
            doc.setFont('helvetica', 'normal');
            doc.text("*Both participants in a room will be held equally responsible for room damage unless it is reported to", 10, 135, null, null);
            doc.text("the dorm director when it happens. Staff (counselors included) cannot be held responsible for a", 10, 140, null, null);
            doc.text("participant’s key or swipe card at any time during the program. Sports International normally receives", 10, 145, null, null);
            doc.text("lost key/ dorm damage reports six to eight weeks after the program ends, but please keep in mind some", 10, 150, null, null);
            doc.text("schools do not send this report out until later.", 10, 155, null, null);
            
            
        }
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************
        // ***************************************************************************************************


        if(waiverType === 4){
        // Don't forget, that there are CORS-Restrictions. So if you want to run it without a Server in your Browser you need to transform the image to a dataURL
            // Use http://dataurl.net/#dataurlmaker
            var logoImg = LogoImage

            doc.addImage("examples/images/Octonyan.jpg", "JPEG", 0, 0, 210, 300);
            doc.setFillColor(255, 255, 255); // White color
            doc.roundedRect(15, 15, 180, 268, 4, 4, "F"); // 'F' for 'fill'
            doc.setFillColor(255, 250, 205);
            doc.setLineWidth(1);
            doc.roundedRect(15, 15, 180, 268, 4, 4, "S");
            doc.addImage(logoImg, "JPEG", 130, 22, 55, 40);

            doc.setFillColor("fffee1");
            doc.rect(105, 87, 80, 68, "F");
            doc.setDrawColor("273a89");
            doc.setLineWidth(0);
            doc.roundedRect(105, 87, 80, 68, 0, 0, "S");
            doc.setDrawColor(0, 0, 0);
            doc.setFontSize(18);
            doc.setFont("times", "italic");
            doc.setTextColor("273a89");
            doc.text("Did You Know?", 111, 95);
            // Bullets section start here -----------------
            doc.setFontSize(11);
            doc.setFont("times", "normal");
            const bulletTxt1 = `Most concussions occur without loss of consciousness.`;
            const bulletTxt1Wrapper = doc.splitTextToSize(bulletTxt1, 70);
            doc.setTextColor(240, 128, 0);
            doc.text("\u2022", 110, 105);
            doc.setTextColor("273a89");
            doc.text(bulletTxt1Wrapper, 113, 105);

            const bulletTxt2 = `Athletes who have, at any point in their lives, had a concussion have an increased risk for another concussion.`;
            const bulletTxt2Wrapper = doc.splitTextToSize(bulletTxt2, 70);
            doc.setTextColor(240, 128, 0);
            doc.text("\u2022", 110, 117);
            doc.setTextColor("273a89");
            doc.text(bulletTxt2Wrapper, 113, 117);

            const bulletTxt3 = `Young children and teens are more likely to get a concussion and take longer to recover than adults.`;
            const bulletTxt3Wrapper = doc.splitTextToSize(bulletTxt3, 70);
            doc.setTextColor(240, 128, 0);
            doc.text("\u2022", 110, 134);
            doc.setTextColor("273a89");
            doc.text(bulletTxt3Wrapper, 113, 134);
            // Bullets section ends here -----------------

            doc.setFontSize(14);
            doc.setFont("times");
            doc.setTextColor(240, 128, 0);
            const heading2 = `WHAT ARE THE SIGNS AND SYMPTOMS OF CONCUSSION?`;
            const heading2Wrapper = doc.splitTextToSize(heading2, 70);
            doc.text(heading2Wrapper, 30, 130);
            doc.setTextColor(0, 0, 0);

            doc.setFontSize(10);
            doc.setFont("times");
            let p2 = `A concussion is a type of traumatic brain injury that changes the way the brain normally works. A concussion is caused by bump, blow, or jolt to the head or body that causes the head and brain to move rapidly back and forth. 

            Even a “ding,” “getting your bell rung,” or what seems to be a mild bump or blow to the head can be serious.`;
            p2 = doc.splitTextToSize(p2, 70);
            doc.text(p2, 30, 143);

            let p3 = `A concussion is a type of traumatic brain injury that changes the way the brain normally works. A concussion is caused by bump, blow, or jolt to the head or body that causes the head and brain to move rapidly back and forth. Even a “ding,” “getting your bell rung,” or what seems to be a mild bump or blow to the head can be serious.`;
            p3 = doc.splitTextToSize(p3, 80);
            doc.text(p3, 105, 165);

            doc.setFontSize(26);
            const textToWrap = `Parent/Athlete Concussion Information Sheet`;
            const maxWidth = 100;
            const title = doc.splitTextToSize(textToWrap, maxWidth);
            doc.text(title, 30, 70);
            let p1 = `A concussion is a type of traumatic brain injury that changes the way the brain normally works. A concussion is caused by bump, blow, or jolt to the head or body that causes the head and brain to move rapidly back and forth. Even a “ding,” “getting your bell rung,” or what seems to be a mild bump or blow to the head can be serious.`;
            doc.setFontSize(10);
            p1 = doc.splitTextToSize(p1, 70);
            doc.text(p1, 30, 90);

            doc.setFontSize(26);
            doc.setLineWidth(0);
            var tableHeaders = [
            {
            id: "first",
            name: "first",
            prompt: "SIGNS OBSERVED BY COACHING STAFF",
            width: 105,
            align: "center",
            padding: 0,
            },
            {
            id: "second",
            name: "second",
            prompt: "SYMPTOMS REPORTED BY ATHLETES",
            width: 105,
            align: "center",
            padding: 0,
            },
            ];

            var tableData = [
            {
            id: "1",
            first: "Moves clumsily",
            second: "Sensitivity to light",
            },
            {
            id: "2",
            first: "Answers questions slowly",
            second: "Sensitivity to noise",
            },
            {
            id: "1",
            first: "Moves clumsily",
            second: "Sensitivity to light",
            },
            {
            id: "2",
            first: "Answers questions slowly",
            second: "Sensitivity to noise",
            },
            {
            id: "2",
            first: "Answers questions slowly",
            second: "Sensitivity to noise",
            },
            ];

            doc.table(27, 200, tableData, tableHeaders, {
            autoSize: false,
            headerBackgroundColor: "d8691b",
            headerTextColor: "FFFFFF",
            fontSize: 10,
            });

            //second page code ========================================
            doc.addPage();
            let x_col_point21 = 25;
            let x_col_point22 = 105;
            let y_point20 = 35;
            let col_width = 72;
            doc.setFont("times");

            doc.addImage("examples/images/Octonyan.jpg", "JPEG", 0, 0, 210, 300);
            doc.setFillColor(255, 255, 255); // White color
            doc.roundedRect(15, 15, 180, 268, 4, 4, "F"); // 'F' for 'fill'
            doc.setFillColor(255, 250, 205);
            doc.setLineWidth(1);
            doc.roundedRect(15, 15, 180, 268, 4, 4, "S");

            // Remember section Start---------------------------------
            doc.setDrawColor("273a89");
            doc.setTextColor("273a89");
            doc.rect(x_col_point22, y_point20 - 3, col_width + 7, y_point20 + 14, "F");
            doc.setLineWidth(0);
            doc.rect(x_col_point22, y_point20 - 3, col_width + 7, y_point20 + 14, "S");
            doc.setFontSize(18);
            doc.setFont("times", "italic");
            doc.text("Remember", x_col_point22 + 5, y_point20 + 8);

            doc.setFontSize(10);
            doc.setFont("times", "normal");
            let rememberTxt = `Concussions affect people differently. While most athletes with a concussion recover quickly and fully, some will have symptoms that last for days, or even weeks. A more serious concussion can last for months or longer.`;
            rememberTxt = doc.splitTextToSize(rememberTxt, col_width);
            doc.text(rememberTxt, x_col_point22 + 5, y_point20 + 15);
            // Remember section End---------------------------------

            // first section Start------------------------------------
            doc.setFontSize(14);
            doc.setTextColor(240, 128, 0);
            let heading21 = `CONCUSSION DANGER SIGNS?`;
            heading21 = doc.splitTextToSize(heading21, col_width);
            doc.text(heading21, x_col_point21, y_point20);
            doc.setTextColor(0, 0, 0);

            let p21 = `In rare cases, a dangerous blood clot may form on the brain in a person with a concussion and crowd the brain against the skull. An athlete should receive immediate medical attention if after a bump, blow, or jolt to the head or body s/he exhibits any of the following danger signs:


            • One pupil larger than the other
            • Is drowsy or cannot be awakened
            • A headache that not only does not diminish,
            but gets worse
            • Weakness, numbness, or decreased coordination
            • Repeated vomiting or nausea
            • Slurred speech
            • Convulsions or seizures
            • Cannot recognize people or places
            • Becomes increasingly confused, restless, or agitated
            • Has unusual behavior
            • Loses consciousness (even a brief loss of consciousness should be taken seriously)`;
            doc.setFontSize(10);
            p21 = doc.splitTextToSize(p21, col_width);
            doc.text(p21, x_col_point21, y_point20 + 7);
            // first section End------------------------------------

            doc.setFontSize(14);
            doc.setTextColor(240, 128, 0);
            let heading22 = `WHY SHOULD AN ATHLETE REPORT THEIR SYMPTOMS?`;
            heading22 = doc.splitTextToSize(heading22, col_width);
            doc.text(heading22, x_col_point21, y_point20 + 106);
            doc.setTextColor(0, 0, 0);

            let p22 = `If an athlete has a concussion, his/her brain needs time to heal. While an athlete’s brain is still healing, s/he is much more likely to have another concussion. Repeat concussions can increase the time it takes to recover. In rare cases, repeat concussions in young athletes can result in brain swelling or permanent damage to their brain. They can even be fatal.`;
            doc.setFontSize(10);
            p22 = doc.splitTextToSize(p22, col_width);
            doc.text(p22, x_col_point21, y_point20 + 120);

            doc.setFontSize(14);
            doc.setTextColor(240, 128, 0);
            let heading23 = `WHAT SHOULD YOU DO IF YOU THINK YOUR ATHLETE HAS A CONCUSSION?`;
            heading23 = doc.splitTextToSize(heading23, col_width);
            doc.text(heading23, x_col_point22, y_point20 + 60);
            doc.setTextColor(0, 0, 0);

            let p23 = `If you suspect that an athlete has a concussion, remove the athlete from play and seek medical attention. Do not try to judge the severity of the injury yourself. Keep the athlete out of play the day of the injury and until a health care professional, experienced in evaluating for concussion, says s/he is symptom-free and it’s OK to return to play.

            Rest is key to helping an athlete recover from a concussion. Exercising or activities that involve a lot of concentration, such as studying, working on the computer, or playing video games, may cause concussion symptoms to reappear or get worse. After a concussion, returning to sports and school is a gradual process that should be carefully managed and monitored by a health care professional.
            `;
            doc.setFontSize(10);
            p23 = doc.splitTextToSize(p23, col_width);
            doc.text(p23, x_col_point22, y_point20 + 81);

            let p24 = `It’s better to miss one game than the whole season. For more information on concussions, visit: www.cdc.gov/Concussion.`;
            doc.setFontSize(10);
            doc.setTextColor("273a89");
            p24 = doc.splitTextToSize(p24, 2 * col_width);
            doc.text(p24, x_col_point21, y_point20 + 160);

            doc.setFontSize(9);
            doc.setTextColor(0, 0, 0);
            doc.text(
            "Program Attending: ____________________________",
            x_col_point21,
            y_point20 + 180,
            null,
            null
            );
            doc.text(
            userInput.ks_1,
            x_col_point21+26.5,
            y_point20 + 180-2,
            null,
            null
            );

            doc.text(
            "____________________________________",
            x_col_point21,
            y_point20 + 190,
            null,
            null
            );
            doc.text(
            "Student-Athlete Name Printed",
            x_col_point21,
            y_point20 + 195,
            null,
            null
            );
            doc.text(
            userInput.ks_2,
            x_col_point21,
            y_point20 + 195-7,
            null,
            null
            );
            doc.text(
            "____________________________________",
            x_col_point21 + 65,
            y_point20 + 190,
            null,
            null
            );
            doc.text(
            "Student-Athlete Signature",
            x_col_point21 + 65,
            y_point20 + 195,
            null,
            null
            );
            doc.text(
            userInput.ks_3,
            x_col_point21 + 65,
            y_point20 + 195-7,
            null,
            null
            );
            doc.text("__________", x_col_point21 + 130, y_point20 + 190, null, null);
            doc.text("Date", x_col_point21 + 130, y_point20 + 195, null, null);
            doc.text(userInput.ks_4, x_col_point21 + 130, y_point20 + 195-7, null, null);

            doc.text(
            "____________________________________",
            x_col_point21,
            y_point20 + 205,
            null,
            null
            );
            doc.text(
            "Parent or Legal Guardian Printed",
            x_col_point21,
            y_point20 + 210,
            null,
            null
            );
            doc.text(
            userInput.ks_5,
            x_col_point21,
            y_point20 + 210-7,
            null,
            null
            );
            doc.text(
            "____________________________________",
            x_col_point21 + 65,
            y_point20 + 205,
            null,
            null
            );
            doc.text(
            "Parent or Legal Guardian Signature",
            x_col_point21 + 65,
            y_point20 + 210,
            null,
            null
            );
            doc.text(
            userInput.ks_6,
            x_col_point21 + 65,
            y_point20 + 210-7,
            null,
            null
            );
            doc.text("__________", x_col_point21 + 130, y_point20 + 205, null, null);
            doc.text("Date", x_col_point21 + 130, y_point20 + 210, null, null);
            doc.text(userInput.ks_7, x_col_point21 + 130, y_point20 + 210-7, null, null);

            
            
        }




        // const pdfBlob = doc.output("blob");
        // const url = URL.createObjectURL(pdfBlob);
        // console.log('pdf url', url)
        // window.open(url, "_blank");
  
        let cmFile = new File([doc.output('blob')], 'waiver', {type: 'application/pdf'});

        const data = new FormData();
        data.append('file', cmFile);
        uploadMedia(data).then(res => {
            if(res.data.responseData.result.fileUrl!==undefined){
                waiverURLAction(res.data.responseData.result.fileUrl)
            }  
        })

    }




    const handleSignatureRef1Save = name => {
        if(userInput[name] === ''){
            setUserInput({[name]: signatureRef1.current.getTrimmedCanvas().toDataURL('image/png')})
        }
    }

    const handleSignatureRef2Save = name => {
        if(userInput[name] === ''){
            setUserInput({[name]: signatureRef2.current.getTrimmedCanvas().toDataURL('image/png')})
        }
    }

    const handleSignatureRef3Save = name => {
        if(userInput[name] === ''){
            setUserInput({[name]: signatureRef3.current.getTrimmedCanvas().toDataURL('image/png')})
        }
    }
    const handleSignatureRef4Save = name => {
        if(userInput[name] === ''){
            setUserInput({[name]: signatureRef4.current.getTrimmedCanvas().toDataURL('image/png')})
        }
    }

 

  
    return (
        <Modal
            dialogClassName="h_auto"
            show={waiverModal}
            animation={false}
            centered
            size="xl"
            >
            <Modal.Body>
                <div className="pdf_box">

                    {
                        waiverType === 1 ? <Fragment>

                            <div className="text-center">
                                <h2>Tigard Lacrosse Club</h2>
                                <h3>Tigard Lacrosse Player Expectations/Contract </h3>
                            </div> 
                           <GroupList
                            heading={'Practice Expectations'}
                            listItems={Practice}
                           />
                           <GroupList
                            heading={'Travel Expectations'}
                            listItems={Travel}
                           />
                           <GroupList
                            heading={'Game Expectations'}
                            listItems={Game}
                           />
                           <p>
                                *If these team expectations cannot be met, or, are violated, there will be consequences.  Playing time will be taken into account as well.  Consequences for violations off the field will be determined on a case-by-case basis*
                           </p>
                            <hr/>
                            <p>I (print name), <input type="text" name="w_1" value={userInput.w_1} onChange={handleChange} style={{width: '370px'}} />, have read ALL the practice, game, and player expectations and agree to follow each rule knowing that if I do not, consequences could result in running, loss of playing time, and/or removal from the team. </p>
                            <p>Player Signature:</p>

                            <div className="sign_box">
                                <SignaturePad
                                    ref={signatureRef1}
                                    canvasProps={{width: 350, height: 70, className: 'sigCanvas'}} />
                                <div className="sign_box_clear">
                                   {userInput.w_2 ? '' : <button disabled={userInput.w_2 ? true : false} className="btn btn-primary btn-sm btn_1" onClick={() => handleSignatureRef1Save('w_2')}>Save</button> }
                                    <button className="btn btn-dark btn-sm btn_2" onClick={() => (signatureRef1.current.clear(), setUserInput({w_2: ''}))}>Clear</button>
                                </div> 
                            </div>
                            <p>Date signed: 
                            <input
                                name="w_3"
                                value={userInput.w_3}
                                disabled={true}
                                onChange={handleChange}
                                style={{width: '365px'}} />
                            </p>
                            <br/>
                            <p>Parent Signature (If player under 18): , </p>
                            <div className="sign_box">
                                <SignaturePad
                                    ref={signatureRef2}
                                    canvasProps={{width: 365, height: 70, className: 'sigCanvas'}} />


                                <div className="sign_box_clear">
                                   {userInput.w_5 ? '' : <button disabled={userInput.w_5 ? true : false} className="btn btn-primary btn-sm btn_1" onClick={() => handleSignatureRef2Save('w_5')}>Save</button> }
                                    <button className="btn btn-dark btn-sm btn_2" onClick={() => (signatureRef2.current.clear(), setUserInput({w_5: ''}))}>Clear</button>
                                </div> 
                            </div>
                           

                            <p>Date signed: 
                            <input
                                type="text"
                                name="w_6"
                                value={userInput.w_6}
                                disabled={true}
                                onChange={handleChange}
                                style={{width: '458px'}} />
                            </p>
                        </Fragment> : ''
                    }

                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}

                    {
                        waiverType === 2 ? <Fragment>

                            <div className="with_logo">
                                <img src={require('../../../assets/img/ttsd-logo.jpg')} />
                                <p> Tigard-Tualatin School District</p>
                                <h3> WAIVER OF LIABILITY AND HOLD HARMLESS FOR COMMUNICABLE <br />DISEASES INCLUDING COVID-19</h3>
                            </div>

                            <p>Student Name:
                                <input
                                    type="text"
                                    value={userInput.wl_1}
                                    name="wl_1"
                                    onChange={handleChange}
                                    style={{ width: 'calc(100% - 100px)'}}
                                />
                            </p>

                            <p>Grade: 
                                <input
                                    type="text"
                                   value={userInput.wl_2}
                                    name="wl_2"
                                    onChange={handleChange}
                                    style={{width: 'calc(50% - 42px)'}} />
                            Home Phone: 
                                <input
                                    type="text"
                                    value={userInput.wl_3}
                                    name="wl_3"
                                    onChange={handleChange}
                                    style={{width: 'calc(50% - 86px)'}} /></p>
                            <p>Address:
                                <input
                                    type="text"
                                    value={userInput.wl_4}
                                    name="wl_4"
                                    onChange={handleChange}
                                    style={{width: 'calc(100% - 56px)'}}
                                /></p>
                            <p>Parent(s)/Guardian(s) Names:
                                <input
                                    type="text"
                                    value={userInput.wl_5}
                                    name="wl_5"
                                    onChange={handleChange}
                                    style={{width: 'calc(100% - 189px)'}}
                                /></p>

                            <p>Parent/ Guardian phone: Work: 
                                <input
                                    type="text"
                                    value={userInput.wl_6}
                                    name="wl_6"
                                    onChange={handleChange}
                                    style={{width: 'calc(50% - 198px)'}} />
                            Home: 
                                <input
                                    type="text"
                                    value={userInput.wl_7}
                                    name="wl_7"
                                    onChange={handleChange}
                                    style={{width: 'calc(25% - 42px)'}} />
                            Other: 
                                <input
                                    type="text"
                                    value={userInput.wl_8}
                                    name="wl_8"
                                    onChange={handleChange}
                                    style={{width: 'calc(25% - 40px)'}} />
                                    </p>

                            <p>The novel coronavirus (“COVID-19”), has been declared a worldwide pandemic by the World Health Organization. COVID-19 is extremely contagious and is believed to spread mainly from person-to-person contact. While rules, guidance, and personal discipline may reduce this risk, the risk of serious illness and death does exist. </p>

                            <p>
                                <b>Tigard-Tualatin School District (“District”) cannot completely mitigate the transfer of communicable diseases like </b>
                                <b>COVID-19 especially when involved in</b>
                                <input
                                    type="text"
                                    value={userInput.wl_9}
                                    name="wl_9"
                                    onChange={handleChange}
                                    style={{width: '350px'}} />
                                <b>Participation in</b>
                                <input
                                    type="text"
                                    value={userInput.wl_10}
                                    name="wl_10"
                                    onChange={handleChange}
                                    style={{width: '350px'}} />
                                <b>includes possible exposure to and illness, injury, or death from infectious </b>
                                <b>diseases including COVID-19.</b>
                            </p>

                            <p>In consideration for providing my child the opportunity to participate in
                            <input
                                type="text"
                                value={userInput.wl_11}
                                name="wl_11"
                                onChange={handleChange}
                                    style={{width: '425px'}} />
                                and<br />
                                any related transportation to and from
                                <input
                                    value={userInput.wl_12}
                                    name="wl_12"
                                    onChange={handleChange}
                                    style={{width: '425px'}} />
                                    events, both my child and I<br />voluntarily agree to waive and discharge any and all claims against District and release it from liability for any exposure to or illness or injury from an infectious disease including COVID-19, including claims for any negligent actions of the District or its employees or agents, to the fullest extent allowed by law, for myself, my child, our estates, our heirs, our administrators, our executors, our assignees, and our successors. 
                            </p>

                            <p>I also agree to release, exonerate, discharge and hold harmless the District, its Board of Directors, the individual members thereof, and all officers, agents, employees, volunteers, and representatives from all liability, claims, causes of action, or demands, including attorney fees, fines, fees, or other costs (e.g. medical costs) arising out of any exposure to or illness or injury from an infectious disease including COVID-19, which may result from or in connection with my child’s participation in 
                                <input
                                    type="text"
                                    value={userInput.wl_13}
                                    name="wl_13"
                                    onChange={handleChange}
                                    style={{ width: '425px'}} />. 
                            </p>

                            <p>I further certify and represent that I have the legal authority to waive, discharge, release, and hold harmless the released parties on behalf of myself and the above-named student.</p>

                            <p><b>I certify that I have read this document in its entirety and fully understand its contents. In exchange for the opportunity to participate in</b>
                            <input
                                type="text"
                                value={userInput.wl_14}
                                name="wl_14"
                                onChange={handleChange}
                                style={{width: '350px'}} />.
                            <b>the above-named student and I freely and</b><br />
                            <b>voluntarily assume all risks of such hazards and notwithstanding such, release District from all liability for any loss</b><br />
                            <b>regardless of cause, and claims arising from the student's participation in</b>
                            <input
                                type="text"
                                value={userInput.wl_15}
                                name="wl_15"
                                onChange={handleChange}
                                style={{border: '0', borderBottom: '1px solid #000', outline: 'none', width: '350px'}} />. 
                            </p>


                            <div className="last_pdf mt-4">
                                <p>
                                    {/* <input
                                    type="text"
                                    value={userInput.wl_16}
                                    name="wl_16"
                                    onChange={handleChange}
                                    style={{width: '350px'}} /> */}

                            <div className="sign_box" style={{display: 'block', width: 'calc(100% - 120px)'}}>
                                <SignaturePad
                                    ref={signatureRef3}
                                    canvasProps={{width: 350, height: 70, className: 'sigCanvas'}} />


                                <div className="sign_box_clear">
                                   {userInput.wl_16 ? '' : <button disabled={userInput.wl_16 ? true : false} className="btn btn-primary btn-sm btn_1" onClick={() => handleSignatureRef3Save('wl_16')}>Save</button> }
                                    <button className="btn btn-dark btn-sm btn_2" onClick={() => (signatureRef3.current.clear(), setUserInput({wl_16: ''}))}>Clear</button>
                                </div> 
                            </div>
                                    Student Signature</p>
                                <p> <input
                                    type="text"
                                    value={userInput.wl_17}
                                    name="wl_17"
                                    disabled={true}
                                    onChange={handleChange}
                                    style={{width: '350px'}} />
                                    Date</p>
                            </div>

                            <div className="last_pdf">
                                <p>
                                    {/* <input
                                    type="text"
                                    value={userInput.wl_18}
                                    name="wl_18"
                                    onChange={handleChange}
                                    style={{width: '350px'}} /> */}

                            <div className="sign_box" style={{display: 'block', width: 'calc(100% - 120px)'}}>
                                <SignaturePad
                                    ref={signatureRef4}
                                    canvasProps={{width: 350, height: 70, className: 'sigCanvas'}} />


                                <div className="sign_box_clear">
                                   {userInput.wl_18 ? '' : <button disabled={userInput.wl_18 ? true : false} className="btn btn-primary btn-sm btn_1" onClick={() => handleSignatureRef4Save('wl_18')}>Save</button> }
                                    <button className="btn btn-dark btn-sm btn_2" onClick={() => (signatureRef4.current.clear(), setUserInput({wl_18: ''}))}>Clear</button>
                                </div> 
                            </div>
                                    SParent/Legal Guardian Signature</p>
                                <p> <input
                                    type="text"
                                    value={userInput.wl_19}
                                    disabled={true}
                                    name="wl_19"
                                    onChange={handleChange}
                                    style={{width: '350px'}} />
                                    Date</p>
                            </div>

                        </Fragment> : ''
                    }







                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}

                    {
                        waiverType === 3 ? <Fragment>

                            <div className="with_logo">
                              <h3><span style={{background: '#000', color: '#fff', fontWeight: '500'}}>PARENTS/GUARDIANS:</span> <small>This form must be completed before we can assign your participant a room!</small></h3>
                                <h2 style={{fontSize: '21px', fontWeight: '500'}}>Key/Dorm Damage Agreement</h2>
                                
                            </div>

                            <p className='mt-4' style={{lineHeight: '1.2'}}>
                            A signed Key/Dorm Damage Agreement is required by the school before you can attend the camp/academy. The cost of a lost key and/or any damage to the room is $100. We suggest
                            participants put their dorm key on a very bright colored shoestring or lanyard. If needed, lanyards will be available in the camp store for the 4 day programs or during registration for all other programs.
                            </p>

                            <p className='mt-4' style={{lineHeight: '1.2'}}>
                            INSTRUCTIONS: The following amount will not be charged unless your participant’s dorm key is lost or damaged, the key is not turned in on the last day of the program, there is damage to the dorm room they will be staying in or damage to any other item issued by the school.
                            </p>

                        
                            <p className='mb-5' style={{marginTop: '30px', fontWeight: '500'}}>Participant's name:
                                <input
                                    name="kd_1"
                                    value={userInput.kd_1}
                                    onChange={handleChange}
                                    type="text"
                                    style={{width: 'calc(100% - 350px)'}} />
                             Participant’s age: 
                                <input
                                    name="kd_2"
                                    value={userInput.kd_2}
                                    onChange={handleChange}
                                    type="text"
                                    style={{width: '100px'}} /></p>


                            <p>Important: A parent or guardian must sign this form below for your participant to receive a dorm key and/or swipe card. Room checks will take place by the college after all participants have left campus.</p>



                            <p className='mb-5' style={{marginTop: '30px', fontWeight: '500'}}>Parent Signature:
                            <input
                                name="kd_3"
                                value={userInput.kd_3}
                                onChange={handleChange}
                                type="text"
                                style={{width: 'calc(100% - 350px)'}} />
                            DATE: 
                            <input
                                name="kd_4"
                                value={userInput.kd_4}
                                onChange={handleChange}
                                type="text"
                                style={{width: '180px'}} /></p>


                            <p>
                            *Both participants in a room will be held equally responsible for room damage unless it is reported to the dorm director when it happens. Staff (counselors included) cannot be held responsible for a participant’s key or swipe card at any time during the program. Sports International normally receives lost key/ dorm damage reports six to eight weeks after the program ends, but please keep in mind some schools do not send this report out until later.
                            </p>
            

                        </Fragment> : ''
                    }



                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}

                    {
                        waiverType === 4 ? <Fragment>

                            <div className='waiverType4'>
                                <h2>Parent/Athlete Concussion<br />Information Sheet </h2>
                            </div>

                            <div className='row waiverType4'>
                                <div className='col-6'>
                                    <p>
                                        A concussion is a type of traumatic brain injury
                                        that changes the way the brain normally works. A
                                        concussion is caused by bump, blow, or jolt to the
                                        head or body that causes the head and brain to move
                                        rapidly back and forth. Even a “ding,” “getting your
                                        bell rung,” or what seems to be a mild bump or blow
                                        to the head can be serious.
                                    </p>

                                    <h3>
                                    WHAT ARE THE SIGNS AND<br />SYMPTOMS OF CONCUSSION?
                                    </h3>

                                    <p>
                                    Signs and symptoms of concussion can show up right
                                    after the injury or may not appear or be noticed until
                                    days or weeks after the injury. 
                                    </p>

                                    <p>
                                    If an athlete reports one or more symptoms of
                                        concussion listed below after a bump, blow, or jolt to 
                                    </p>
                                </div>
                                <div className='col-6'>
                                    <div className='yellow_box'>
                                        <h4>Did You Know?</h4>
                                        <ul>
                                            <li>Most concussions occur without loss of consciousness.</li>
                                            <li>Athletes who have, at any point in their
                                                lives, had a concussion have an increased
                                                risk for another concussion.</li>
                                                <li>
                                                Young children and teens are more likely to
                                                get a concussion and take longer to recover
                                                than adults.
                                                </li>
                                        </ul>
                                    </div>

                                    <p>the head or body, s/he should be kept out of play the
day of the injury and until a health care professional,
experienced in evaluating for concussion, says s/he is
symptom-free and it’s OK to return to play. </p>
                                </div>
                            </div>


                            <div className='waiverType4_table'>
                                <ul>
                                    <li>SIGNS OBSERVED BY COACHING STAFF</li>
                                    <li>Appears dazed or stunned </li>
                                    <li>Is confused about assignment or position</li>
                                    <li>Forgets an instruction </li>
                                    <li>Is unsure of game, score, or opponent</li>
                                    <li>Moves clumsily</li>
                                    <li>Answers questions slowly </li>
                                    <li>Loses consciousness (even briefly)</li>
                                    <li>Shows mood, behavior, or personality changes</li>
                                    <li>Can’t recall events prior to hit or fall</li>
                                    <li>Can’t recall events after hit or fall </li>
                                </ul>

                                <ul>
                                    <li>SYMPTOMS REPORTED BY ATHLETES</li>
                                    <li>Headache or “pressure” in head</li>
                                    <li>Nausea or vomiting</li>
                                    <li>Balance problems or dizziness</li>
                                    <li>Double or blurry vision</li>
                                    <li>Sensitivity to light</li>
                                    <li>Sensitivity to noise</li>
                                    <li>Feeling sluggish, hazy, foggy, or groggy</li>
                                    <li>Concentration or memory problems</li>
                                    <li>Confusion</li>
                                    <li>Just not “feeling right” or “feeling down”</li>
                                </ul>
                            </div>




                            <div className='row waiverType4'>
                                <div className='col-6'>
                                    <h3>CONCUSSION DANGER SIGNS</h3>
                                    <p>
                                    In rare cases, a dangerous blood clot may form on
                                    the brain in a person with a concussion and crowd
                                    the brain against the skull. An athlete should receive
                                    immediate medical attention if after a bump, blow,
                                    or jolt to the head or body s/he exhibits any of the
                                    following danger signs:
                                    </p>

                                   <ul>
                                    <li>One pupil larger than the other</li>
                                    <li>Is drowsy or cannot be awakened</li>
                                    <li>A headache that not only does not diminish, but gets worse</li>
                                    <li>Weakness, numbness, or decreased coordination</li>
                                    <li>Repeated vomiting or nausea</li>
                                    <li>Slurred speech</li>
                                    <li>Convulsions or seizures</li>
                                    <li>Cannot recognize people or places</li>
                                    <li>Becomes increasingly confused, restless, or agitated</li>
                                    <li>Has unusual behavior</li>
                                    <li>Loses consciousness (even a brief loss of consciousness should be taken seriously)</li>
                                   </ul>

                                   <h3>WHY SHOULD AN ATHLETE<br />REPORT THEIR SYMPTOMS?</h3>
                                   <p>
                                    If an athlete has a concussion, his/her brain needs time
                                    to heal. While an athlete’s brain is still healing, s/he is
                                    much more likely to have another concussion. Repeat
                                    concussions can increase the time it takes to recover.
                                    In rare cases, repeat concussions in young athletes can
                                    result in brain swelling or permanent damage to their
                                    brain. They can even be fatal.</p>
                                </div>
                                <div className='col-6'>
                                    <div className='yellow_box'>
                                        <h4>Remember</h4>
                                        <p>Concussions affect people differently. While
                                            most athletes with a concussion recover
                                            quickly and fully, some will have symptoms
                                            that last for days, or even weeks. A more serious concussion can last for months or longer. </p>
                                    </div>

                                    <h3>WHAT SHOULD YOU DO IF YOU
                                        THINK YOUR ATHLETE HAS A
                                        CONCUSSION?</h3>

                                    <p>
                                    If you suspect that an athlete has a concussion,
                                    remove the athlete from play and seek medical
                                    attention. Do not try to judge the severity of the injury
                                    yourself. Keep the athlete out of play the day of the
                                    injury and until a health care professional, experienced
                                    in evaluating for concussion, says s/he is symptom-free
                                    and it’s OK to return to play. 
                                    </p>

                                    <p>
                                        Rest is key to helping an athlete recover from a
                                        concussion. Exercising or activities that involve a
                                        lot of concentration, such as studying, working on
                                        the computer, or playing video games, may cause
                                        concussion symptoms to reappear or get worse.
                                        After a concussion, returning to sports and school is
                                        a gradual process that should be carefully managed
                                        and monitored by a health care professional.</p>
                                </div>
                            </div>


                            <p>It’s better to miss one game than the whole season. For more information on
concussions, visit: <a href='http://www.cdc.gov/Concussion' target='_blank'>www.cdc.gov/Concussion</a>.</p>


<p style={{marginTop: '30px', fontWeight: '500'}}>Program Attending:
                                <input
                                    name="ks_1"
                                    type="text"
                                    onChange={handleChange}
                                    style={{width: '450px'}} />
                                    </p>
                            <div className='row mt-5'>
                                <div className='col-4'>
                                    <input
                                        name="ks_2"
                                        placeholder='Student-Athlete Name Printed '
                                        type="text"
                                        onChange={handleChange}
                                        style={{width: '100%'}} />
                                </div>
                               
                                <div className='col-4'>
                                    <input
                                        name="ks_3"
                                        onChange={handleChange}
                                        placeholder='Student-Athlete Signature'
                                        type="text"
                                        style={{width: '100%'}} />
                                </div>

                                <div className='col-4'>
                                    <input
                                        name="ks_4"
                                        onChange={handleChange}
                                        placeholder='Date'
                                        type="text"
                                        style={{width: '100%'}} />
                                </div>
                            </div>

                            <div className='row mt-5'>
                                <div className='col-4'>
                                    <input
                                        name="ks_5"
                                        onChange={handleChange}
                                        placeholder='Parent or Legal Guardian Printed '
                                        type="text"
                                        style={{width: '100%'}} />
                                </div>
                               
                                <div className='col-4'>
                                    <input
                                        name="ks_6"
                                        onChange={handleChange}
                                        placeholder='Parent or Legal Guardian Signature '
                                        type="text"
                                        style={{width: '100%'}} />
                                </div>

                                <div className='col-4'>
                                    <input
                                        name="ks_7"
                                        onChange={handleChange}
                                        placeholder='Date'
                                        type="text"
                                        style={{width: '100%'}} />
                                </div>
                            </div>



                        </Fragment> : ''
                    }





                    
                    </div>
                    
                    <div className="d-flex mt-3">
                        <button onClick={waiverModalAction} className="btn btn-dark w-100">Cancel</button>
                        <button onClick={pdfGen} className="btn btn-primary w-100 ms-3">I Agree</button>
                    </div>
            </Modal.Body>
        </Modal>
  )
}





export default WaiverPopups;