import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Empty, notification } from 'antd';

import { directorActivityListService, deleteActivityFolderService, deleteactivity } from '../../services/activityService';
import { FolderIcon, ProgramLoader } from '../reuse/SVG';


const Activity = () => {
    const [pageLoader, setPageLoader] = useState(false);
    const [activities, setActivities] = useState([]);
    const [folders, setFolders] = useState([]);
    const [addFolderModal, setAddFolderModal] = useState(false);
    const [folderName, setFolderName] = useState('');
    const [search, setSearch] = useState('');
    const [deleteFolder, setDeleteFolder] = useState(false);
    const [deleteFolderId, setDeleteFolderId] = useState();
    const [deleteActivity, setDeleteActivity] = useState(false);
    const [deleteActivityId, setDeleteActivityId] = useState();


    const getActivities = () => {
        setPageLoader(true);
        let params = `?search=${search}`;
        directorActivityListService(params).then(res => {
            setPageLoader(false);
            if (res.data.statusCode) {
                setFolders(res.data.responseData.result.folder || []);
                setActivities(res.data.responseData.result.activity || []);
            }
        })
    }


    useEffect(() => {
        const _setTimeout = setTimeout(() => {
            getActivities();
        }, 1000)
        return () => clearTimeout(_setTimeout)
    }, [search])

    const deleteFolderAction = () => {
        let folderslist = [...folders];
        let params = `/?folderId=${deleteFolderId}`;

        deleteActivityFolderService(params).then((res) => {
            if (res.data.statusCode === 1) {
                let newfolderlist = folderslist.filter(item => item._id !== deleteFolderId)
                setFolders(newfolderlist);
                setDeleteFolder(false);
                let params2 = `?search=${search}`;
                directorActivityListService(params2).then(res => {
                    if (res.data.statusCode) {
                        setFolders(res.data.responseData.result.folder);
                        setActivities(res.data.responseData.result.activity);
                    }
                })
                notification.open({
                    description:
                        'Folder Deleted Successfully',
                });
            }
        })
    }

    const deleteActivityAction = () => {
        let params = `/?activityId=${deleteActivityId}`;
        deleteactivity(params).then(res => {
            setDeleteActivity(false);
            if (res.data.statusCode === 1) {
                let newactivity = activities.filter(item => item._id !== deleteActivityId)
                setActivities(newactivity);
                notification.open({
                    description:
                        'Activity Deleted Successfully',
                });
            }
            else {
                notification.open({
                    description:
                        'Please try after some time',
                });

            }
        })
    }

    return (
        <div className="body_container pb-4 director_wrapper">
            <div className="container">

                <Modal
                    show={deleteFolder}
                    animation={false}
                    dialogClassName="folder_model"
                    size="lg"
                    centered
                >
                    <Modal.Body>
                        <span className="modal_close" onClick={() => { setDeleteFolder(false) }}><i className="icon-cross" /></span>
                        <div className="text-center p-5 pickup_modal_body">
                            <p>Are you sure you want to delete this folder</p>
                        </div>
                        <div className="text-center p-5">
                            <button onClick={() => { setDeleteFolderId(''); setDeleteFolder(false) }} style={{ width: '120px' }} className="btn btn-light rounded-0">No</button>
                            <button onClick={deleteFolderAction} style={{ width: '120px' }} className="btn btn-primary ms-3">Yes</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={deleteActivity}
                    animation={false}
                    dialogClassName="folder_model"
                    size="lg"
                    centered
                >
                    <Modal.Body>
                        <span className="modal_close" onClick={() => { setDeleteActivity(false); setDeleteActivityId() }}><i className="icon-cross" /></span>
                        <div className="text-center p-5 pickup_modal_body">
                            <p>Are you sure you want to delete this activity</p>
                        </div>
                        <div className="text-center p-5">
                            <button onClick={() => { setDeleteActivityId(''); setDeleteActivity(false) }} style={{ width: '120px' }} className="btn btn-light rounded-0">No</button>
                            <button onClick={deleteActivityAction} style={{ width: '120px' }} className="btn btn-primary ms-3">Yes</button>
                        </div>
                    </Modal.Body>
                </Modal>




                <Modal
                    centered
                    show={addFolderModal}
                    onHide={() => setAddFolderModal(false)}
                    dialogClassName="folder_model">

                    <Modal.Body>
                        <span className="modal_close" onClick={() => setAddFolderModal(false)}><i className="icon-cross" /></span>
                        <div className="text-center p-5 pickup_modal_body">
                            <h3 className="create_f_title">Create a Folder</h3>
                        </div>

                        <div className="form-group">
                            <label>Folder Name</label>
                            <input
                                type="text"
                                onChange={e => setFolderName(e.target.value)}
                                className="form-control" />
                        </div>
                        {folderName ?
                            <Link to={`/activity/${folderName}`} className="btn btn-primary w-100">Done</Link>
                            :
                            <span className="btn btn-primary disabled w-100">Done</span>}

                    </Modal.Body>

                </Modal>

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Activity List</h4>
                    {
                        activities.length > 0 ?
                            <div className="btn_group">
                                <button onClick={() => setAddFolderModal(true)} className="btn btn-primary"><i className="icon-folder-2" /> Add Folder</button>
                            </div>
                            : ''
                    }
                </div>


                <div className="row">
                    <div className="col-lg-2" />

                    <div className="col-lg-8">

                        <div className="d-flex align-items-center justify-content-between page_card_header cm_border_0">
                            <h4>Activity</h4>
                            <div className="btn_group">
                                <Link to="/activity/add" className="btn btn-primary">Add Activity</Link>
                            </div>
                        </div>

                        <div className="partner-form search_wrap" style={{ maxWidth: '100%' }}>
                            <i className="icon-search" />
                            <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Search"
                                value={search}
                                onChange={e => setSearch(e.target.value)} />
                        </div>

                        {pageLoader ? <div className="program_loader"><ProgramLoader /></div> : (
                        
                        <Fragment>
                            {!pageLoader ? folders.length === 0 && activities.length === 0 ? <div className="cm_empty m-auto"><Empty description="Data Not Found" /> </div> : '' : ''}



                            {
                                folders.map(item => (
                                    <div className="d-flex align-items-center counselor_card folder_card cm_mob_pad_0" key={item._id}>
                                        <div>
                                            <div className="folder_icon"><Link to={`activity/folder/${item._id}`}><FolderIcon /></Link></div>
                                            <h4><Link to={`activity/folder/${item._id}`}>{item.folderName}</Link></h4>
                                            <small>{moment(item.created).format('MM/DD/YYYY hh:mm')}</small>
                                            <div className="remove_counselor"><i onClick={() => { setDeleteFolder(true); setDeleteFolderId(item._id) }} className="icon-delete" /></div>
                                        </div>
                                    </div>
                                ))
                            }



                            {
                                activities.map((item, i) => (
                                    <div className={`d-flex align-items-center counselor_card cm_mob_pad_0 ${item?.personnels?.length ? 'is_alert_' : ''}`} key={item._id}>
                                        <div>
                                            <h4>Activity name : {item.activityName}</h4>
                                            <h4>Equipment : {item.equipment}</h4>
                                            <h4>Cost : ${item.cost ? item.cost.toFixed(2) : '0'}</h4>
                                            <h4>Time needed : {item.timeNeeded} minutes</h4>
                                        </div>
                                        <div className="ms-auto">
                                            <Link to={`/activity/edit/${item._id}`} className="btn btn-primary">View</Link>
                                            <div className="remove_counselor"><i onClick={() => { setDeleteActivity(true); setDeleteActivityId(item._id) }} className="icon-delete" /></div>
                                        </div>
                                    </div>
                                ))
                            }    

                        
                        </Fragment>)}

                        

                    </div>
                </div>

            </div>
        </div>
    )
}

export default Activity;