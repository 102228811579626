import React, { useEffect, useState, Fragment, useRef, useCallback } from 'react';
import { OverlayTrigger, Tooltip, Modal, Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Empty, notification } from 'antd';
import Select from 'react-select';
import { Link, useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import { userListService, getUserDetailService, getProgramsService } from '../../services/otherService';
import { resHandle, _calculateAge, getgendersdisplay, getHtml, gerFavpropgramdate, shortsAndTshirtSizes, gradeArr, hasMatchingAnswers, isLessThan600msAgo } from '../../helper';
import PaymentInformationCompoant from './PaymentInformationCompoant';
import { getProductsService } from '../../services/onlineStoreService';
import { singleEditCamperService } from '../../services/campersServices';
import Addon from '../online-store/Addon';
import QuestionsComponent from '../common/Questions/QuestionsComponent';
import { ProgramLoader } from '../reuse/SVG';
import { getBookingCampers } from '../../services/directorServices';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import LacroseCheck from '../Lacrose/LacroseCheck';
import { uslMemSecCheck } from '../Lacrose/helper';


const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};


const settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
      ]
};




const getSizes = arr => {
    let filterArr = [];
    if(arr?.length){
        let isHasSize = arr.filter(i => (i.name !== 'No Size'));
        if(isHasSize.length){
            let onlyName = isHasSize.map(item => item.name);
            let uniqArr =  onlyName.filter((item, index) => onlyName.indexOf(item) === index);
            filterArr = uniqArr.map(i => ({value: i, label: i}))
        }
    }
    return filterArr;
}


const getColors2 = (arr, val) => {
    let filterArr = [];

    if(val){
        if(arr?.length){
            let isHasColor = arr.filter(i => i.name === val);
            let isHasColor2 = isHasColor.filter(i => i.color !== 'No Color');
            if(isHasColor2.length){
                let onlyName = isHasColor2.map(item => item.color);
                let uniqArr =  onlyName.filter((item, index) => onlyName.indexOf(item) === index);
                filterArr = uniqArr.map(i => ({value: i, label: i}))
            }
        }
    }else{
        if(arr?.length){
            let isHasColor = arr.filter(i => i.color !== 'No Color');
            if(isHasColor.length){
                let onlyName = isHasColor.map(item => item.color);
                let uniqArr =  onlyName.filter((item, index) => onlyName.indexOf(item) === index);
                filterArr = uniqArr.map(i => ({value: i, label: i}))
            }
        }
    }
    return filterArr;
}




const getDatesPrices = (_dates, _selectedDates, _campers) => {
    let _price = 0;
    for (let i = 0; i < _selectedDates.length; i++) {
        _price += _dates.find(itm => itm._id === _selectedDates[i]).standardPrice
    } 
    return _price * _campers.length;
}



const CreateBookingPage = props => {
    const history = useHistory();
    const [steps, setSteps] = useState(1);
    const [userList, setUserList] = useState([]);
    const [userSearch, setUserSearch] = useState('');

    const [programList, setProgramList] = useState([]);
    const [programListLoader, setProgramListLoader] = useState(false);
    const [programSearch, setProgramSearch] = useState('');

    const [campersList, setCampersList] = useState([]);
    const [campersListCheck, setCampersListCheck] = useState([]);

    const [userId, setUserId] = useState('');
    const [userIdErr, setUserIdErr] = useState('');

    const [selectedPrograms, setSelectedPrograms] = useState([]);
    const [selectedProgramsWithCampers, setSelectedProgramsWithCampers] = useState([]);
    const [selectedProgramsErr, setSelectedProgramsErr] = useState('');
    const [activeProgram, setActiveProgram] = useState({});
    const [participantForm, setParticipantForm] = useState(false);
    const [selectedCamper, setSelectedCamper] = useState('');
    const [resErr, setResErr] = useState('');
    const [selectedDateErr, setSelectedDateErr] = useState('');

    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [updatesSizes, setUpdatesSizes] = useState(false);
    const[currentGrade, setCurrentGrade] = useState('');
    const[tshirtSize, setTshirtSize] = useState('');
    const [conditionalQues, setConditionalQues] = useState([]);
    const[camplistid, setcamplistid] = useState('');
    const[camplistidErr, setcamplistidErr] = useState('');

    const [isViewAddProducts, setIsViewAddProducts] = useState(false)
    const [activeAddProduct, setActiveAddProduct] = useState('')
    const [currentCount, setCurrentCount] = useState(1);
    const [addons, setAddons] = useState([]);
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedSize, setSelectedSize] = useState('');
    const [selectedColorErr, setSelectedColorErr] = useState('');
    const [selectedSizeErr, setSelectedSizeErr] = useState('');
    const [activeIndex, setActiveIndex] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0);
    const  [loader, setLoader] = useState(false)
    const [lastTypedTime, setLastTypedTime] = useState(new Date());
    const [searchCampers, setSearchCampers] = useState(false)
    const [camperLoader, setCamperLoader] = useState(false);

    const [USLResponseSuccess, setUSLResponseSuccess] = useState('');
    const [USLMemberIDErr, setUSLMemberIDErr] = useState('');

    const handleUSLSuccessResponse  = (res) => {
        setUSLMemberIDErr('');
        setUSLResponseSuccess(res);
    }

    const handleRemoveProgram = id => {
        let _selectedPrograms = [...selectedPrograms];

        for (var i in _selectedPrograms) {
            if (_selectedPrograms[i]._id === id) {
                _selectedPrograms.splice(i, 1);
            }
        }

        if(_selectedPrograms.length === 0){
            setSteps(2);
            setActiveProgram({});
            setSelectedPrograms([]);
        }else{
            setSelectedPrograms(_selectedPrograms);
            setActiveProgram(_selectedPrograms[0])
        }
    }



    const handleUserSelect = () => {
        if(userId?.value){
            setSteps(2);
            getCampersList(userId.value);
        }else{
            setUserIdErr('User is required')
        }
    }


    const handleProgramsSelect = () => {

        if(selectedPrograms?.length){
            setSteps(3);

            if(selectedPrograms.length){
                let mapedAllProgram =  selectedPrograms.map(i => ({
                    ...i,
                    campers: campersList.map(m => ({
                        ...m,
                        isSelected: false,
                        camperId: m._id,
                        camperName: m.firstName,
                        
                        additionalExperience: i.additionalExperience.map(g => ({
                            ...g,
                            additionalExperienceId: g._id,
                            numberOfBooking: 0,
                        })),
                        answers: i.questions.map(j => ({
                            ...j,
                            questionId: j.questionId,
                            question: j.title,
                            answer: '',
                            answerErr: '',
                            isRequred: j.isRequred
                        })),
                        waiver: '',
                        waiverLiability: ''
                    }))
                }))

                setSelectedPrograms(mapedAllProgram);
                setActiveProgram(selectedPrograms[0])  
            }
        }else{
            setSelectedProgramsErr('program is requied')
        }
    }

    const getList = async (type=2, query="", isChild=false) => {
        type ===1 && setLoader(true)
        
        let params = {
            types: [1],
            search: query? query :userSearch,
            page: 0,
            limit: 20,
            isBooking:true,
            isSearchInChild:isChild? isChild: searchCampers,
        };
        return await userListService(params).then(res => {
           
           if(res?.data?.statusCode){
            const _userList = res.data.responseData.result;
            setUserList(_userList);
            return _userList.map(i => ({...i, value: i._id, label: `${i.name} - ${i.email}`}))
           }
           return []
       }).catch((e) => {
        setLoader(false)
        return []
        }).finally(() => {
            setLoader(false)
        return[]
       })
    }
    const getCamperList = async () => {
        setCamperLoader(true);
        const params = {
            userId: userId._id,
            program: {
                programId: activeProgram?._id, 
                selectedDates: activeProgram?.selectedDates ?activeProgram?.selectedDates : [] }
        }
        try {const res = await getBookingCampers(params)
        if(res?.data){
                setCampersListCheck(res?.data?.responseData)
                setCamperLoader(false)
        }}catch(e){
            setCamperLoader(false)
        }finally{
            setCamperLoader(false)
        }
    }

    useEffect(() => {
        if(steps ===1){
            getList(1)
        }else if(steps ===2){
            getProgramsList(1)
        }else if(steps ===3){
            getCamperList()
        }
    }, [steps])

    const getProgramsList = (type=2) => {
        type ===1 && setLoader(true)
        let query = `?pageNo=0&search=${programSearch}&limit=20&campCode=${props.campCode}`;
        getProgramsService(query).then(res => {
            setProgramListLoader(false);
           if(res?.data?.statusCode){
            setProgramList(res.data.responseData.result);
            setLoader(false)
           }
       }).catch((e) => {
            setLoader(false)
        }).finally(() => {
            setLoader(false)
       })
    }



    useEffect(() => {
        if(steps ===2 ){
            getProgramsList()
        }
    }, [programSearch])

    useEffect(() => {
        let handler
        if((userSearch.length) % 3 === 0){
            getList();
        }else if (userSearch.length > 0){
            handler = setTimeout(() => {
                if (isLessThan600msAgo(lastTypedTime)) {
                getList()
                }
            }, 600);
        }
  
      return () => {
        clearTimeout(handler);
      };
    }, [userSearch, lastTypedTime]);

    const handleInputChange = (e) => {
        setUserSearch(e)
        setLastTypedTime(new Date());
    };


    const debounceSearch1 = useCallback(
        debounce(async (inputValue, callback, isChild) => {
          const options = await getList(2, inputValue, isChild);
          callback(options); // Pass options to AsyncSelect
        }, 800), // Delay of 400ms
        []
      );
      const loadOptions = (inputValue, callback, ) => {
         debounceSearch1(inputValue, callback, searchCampers);
      };   


    const getCampersList = params => {
        getUserDetailService(params).then(res => {
            let { status, data } = resHandle(res);
            if(status){
                setCampersList(data?.result?.campersData || [])
            }
        })
    }


    const handleAddProgram = e => {
        setSelectedPrograms(e || []);
        setSelectedProgramsErr('')
    }


    const handleCamperSelect = (_activeProgram, _selectedCamper, isCamperSelected) => {
        setUpdatesSizes(false);
        setTshirtSize('');
        setCurrentGrade('');
        if(!isCamperSelected){
            setParticipantForm(true);
            setResErr('');
            setSelectedCamper(_selectedCamper);
        }else{
            handleAddCamer(_selectedCamper);
        }
        

    }

    const handleCloseModal = () => {
        setParticipantForm(false)
        handleUSLSuccessResponse('') 
    }
    const handleCloseProductModal = () => {
        setIsViewAddProducts(false);
        setActiveAddProduct([])
        setAddons([])
        setSelectedColor('')
        setSelectedSize('')
        setSelectedColorErr('')
        setSelectedSizeErr('')
        setCurrentCount(1)
    }


    const handleAddCamer = (_params) => {
        if(activeProgram.is_lacrose ===1){
            const validate =  uslValidate();
            if(!validate) return;
        }
        if(activeProgram?.agegroups?.length){
            if(!camplistid){
                setcamplistidErr('Please select one age group');
                return;
            }
        }

        let __selectedCamper = _params._id ? _params : selectedCamper;
        let _selectedPrograms = [...selectedPrograms];
        let programIndex = _selectedPrograms.findIndex(x => x._id === activeProgram._id);
        let _campers = [..._selectedPrograms[programIndex].campers];

        

        for (let i in _campers) {
            if (_campers[i]._id === __selectedCamper._id) {
                _campers[i] = {
                    ...__selectedCamper,
                    camplistid,
                    selectedProduct,
                    isSelected: _params._id ? false : true,
                    answers: __selectedCamper.answers.map(j => ({
                        ...j,
                        answerErr: j.isRequred === 1 ? j.answer === '' ? 'Answer is required' : '' : ''
                    })),
                    additionalExperience: __selectedCamper.additionalExperience.map((j) => ({
                        ...j,
                        answer: {
                            ...j.question,
                            answerErr:  (j.numberOfBooking && j.question?.isRequred === 1) ? j.question.answer ? '' : 'Answer is requierd' : ''
                        }
                    })),

                };
               break;
            }
        }

        
        for (let i in _selectedPrograms) {
            if (_selectedPrograms[i]._id === activeProgram._id) {
                _selectedPrograms[i] = {
                ..._selectedPrograms[i],
                campers: _campers
               };
               break;
            }
        }

        let _selectedCamper = _campers.find(x => x._id === __selectedCamper._id);




        let checkQues = _selectedCamper.answers.filter(ans => {
            if(ans.isRequred && !ans.isSkipped){
                return ans.answerErr
            }
        })

        let innerErrs = 0;

        for (let z = 0; z < _selectedCamper.additionalExperience.length; z++) {
            if(_selectedCamper.additionalExperience[z].question?.answerErr){
                innerErrs = innerErrs + 1;
            }
        }

        if(checkQues.length === 0 && innerErrs === 0){
            handleCloseModal();
            setSelectedCamper(_selectedCamper);
            setSelectedPrograms(_selectedPrograms);
        }else{

            for (var i in _campers) {
                if (_campers[i]._id === __selectedCamper._id) {
                    _campers[i] = {
                        ...__selectedCamper,
                        isSelected: false,
    
                    };
                   break;
                }
            }

            setSelectedCamper(_selectedCamper);
            setSelectedPrograms(_selectedPrograms);
        }

        let _products = [...products];

        if(_products.length){
            let _res = _products.map(i => ({...i, count: 1}));
            setProducts(_res);
        }

        setSelectedProduct([])


    }
    

    const handleProceedPayment = () => {
        let isCamperSelected = false;
        let manipulatePrograms = selectedPrograms.map(item => {            

            return {
                ...item,
                
                campers: item.campers.map(camper => {

                    if(camper.isSelected){
                        isCamperSelected = true
                    }

                    return {
                        ...camper,
                        answers: camper.answers.map(_answer => {
                            let final_answer = '';
                            if(Array.isArray(_answer.answer)){
                                let temp_ans = `${_answer?.answer?.join(',')}, ${_answer.answerOther}`;
                                 final_answer = temp_ans.replace("undefined", "");
                                 if(temp_ans.length === 0 || temp_ans === '[]' || temp_ans === ', undefined' || temp_ans === 'undefined'){
                                    final_answer = '';
                                }
                            }else{
                                final_answer = _answer.answer
                            }
                            return {
                                ..._answer,
                                answer: final_answer
                            }
                        })
                    }
                })
            }
        })

        let _programs = manipulatePrograms.map(item => {

            let campers = item.campers.filter(i => i.isSelected);

            let _totalCost = 0;
            let _additionalExperienceCost = 0;
            let _convenienceCost = item.convenienceFee * campers.length;
            let _programCost = item.registrationFee * campers.length;
            let _taxesCost = item.tax * campers.length;
            let _registerNonRefundFee = item.registerNonRefundFee * campers.length;
            let additionalDiscount = 0;

            let arr = [];

            if(campers.length){
                for (let i = 0; i < campers.length; i++) {
                    if(campers[i].additionalExperience.length){
                        for (let j = 0; j < campers[i].additionalExperience.length; j++) {
                            if(campers[i].additionalExperience[j].numberOfBooking !== 0){
                                arr.push(campers[i].additionalExperience[j].numberOfBooking * campers[i].additionalExperience[j].price)  
                            }  
                        }
                    }   
                }
            }

            if (campers !== undefined) {
                campers.map(camper => {
                    if (camper.answers !== undefined) {
                        camper.answers.map(answ => {
                            if (answ.options !== undefined) {
                                answ.options.map(optio => {
                                    if(optio.opt === answ.answer){
                                        if(optio.discountType && optio.discount){
                                            additionalDiscount = (optio.discountType === 1 ? optio.discount : (((item.registrationFee + item.registerNonRefundFee + item.convenienceFee + item.tax) / 100) * optio.discount))
                                        }
                                    }
                                })
                            }
                        })
                    }
                }) 
            }

            if(arr.length){
                for (let i = 0; i < arr.length; i++) {
                    _additionalExperienceCost += arr[i];
                }
            }

            


            if(item.byDates === 1){
                _totalCost += getDatesPrices(item.additionalDates, item.selectedDates, campers);
            }else {
                _totalCost = _additionalExperienceCost + _programCost + _convenienceCost + _taxesCost;
            }

            return {
                ...item,
                _totalCost,
                _additionalExperienceCost,
                _programCost,
                _convenienceCost,
                _taxesCost,
                campers,
                _registerNonRefundFee,
                _additionalDiscount: additionalDiscount * campers.length
            }
        })



        if(isCamperSelected){
            setSelectedProgramsWithCampers(_programs);
            setSteps(4);
            
        }else{
            setResErr('Please select Participant');
        }
    }



    const handleSetAnswer = (answerdQuestions) => {
        let _selectedCamper = {...selectedCamper};
        _selectedCamper.answer = answerdQuestions;
        setSelectedCamper(_selectedCamper);
    }   

    const handleGroupCodeChange = e => {
        let _selectedCamper = {...selectedCamper}
        _selectedCamper.groupCode = e.target.value;
        setSelectedCamper(_selectedCamper);
    }


    const handleAdditionalExCountChange = (index, type, oldVal) => {
        let _selectedCamper = {...selectedCamper};
            if(type === 'add'){
                _selectedCamper.additionalExperience[index].numberOfBooking = oldVal + 1
            }else{
                _selectedCamper.additionalExperience[index].numberOfBooking = oldVal - 1
            }
        setSelectedCamper(_selectedCamper)
    }




    const onChangeSelectedDates2 = (dateId, indx) => {
        setSelectedDateErr('');
        let _selectedPrograms = [...selectedPrograms];
        let _selectedDates = [..._selectedPrograms[indx].selectedDates || []];
            if(_selectedDates?.includes(dateId)){
                let res = _selectedDates.filter(i => i !== dateId);
                _selectedPrograms[indx].selectedDates = res;
            }else{
                _selectedDates.push(dateId);
                _selectedPrograms[indx].selectedDates = _selectedDates
            }

            setSelectedPrograms(_selectedPrograms);
    } 


    const uslValidate = () => {
        let validate = true;
        if(!USLResponseSuccess?.mem_status){
            setUSLMemberIDErr('*USAL member ID is required.');
            validate = false;

        }else if (USLResponseSuccess?.mem_status !== 'Active') {
            setUSLMemberIDErr('*Cannot proceed without a valid USAL membership.');
            validate = false;
        }else if (uslMemSecCheck(USLResponseSuccess.exp_date,  activeProgram.endDate)){
            setUSLMemberIDErr('*Cannot proceed without a valid USAL membership.');
            validate = false;
        }

        return validate;
    }






    const getProducts = () => {
        let query = `?&page=0&limit=100&type=2&itemType=1&programId=${activeProgram.programId}`;
        getProductsService(query).then(res => {
            if(res?.data?.statusCode){
                if(res?.data?.responseData?.result?.length){
                    let temp = res.data.responseData.result.map(i => ({
                        ...i,
                        count: 1,
                        selectColorErr: '',
                        selectColor: '',
                        selectSize: '',
                        selectSizeErr: '',
                    }))

                    setProducts(temp);
                }
            }
        })
    }

    useEffect(() => {
        if(activeProgram.programId){
            getCamperList()
            getProducts()
        }

    }, [activeProgram])



    const handleItemCount = (itemIndex, type) => {

        let _products = [...products];
        let oldCount = _products[itemIndex].count;
        if(type === 'add'){
            _products[itemIndex].count = oldCount + 1;
            setCurrentCount(prev => prev+1)   
        }else{
            if(oldCount !== 1){
                _products[itemIndex].count = oldCount - 1;
                setCurrentCount(prev => prev -1)
                
            }
        }

        setProducts(_products);

    }

    const handleRemoveItem = (item) => {
        let _selectedProduct = [...selectedProduct];
        setSelectedProduct(_selectedProduct.filter(sp => sp._id !== item._id));
    }
    const handleShowModalProductDetail = (item, i) => {
        setActiveIndex(i)
        setIsViewAddProducts(true);
        setActiveAddProduct(item);
        setCurrentCount(item.count);
    }
    const calculateTotalProductPrice = () => {
        const _totalPrice = addons.reduce((tPrice, addon ) => {
            let  _totalAddonPrice = 0
            if(addons?.length){
                _totalAddonPrice =  Number(tPrice) + (Number(addon.count) * Number(addon.price));
            }
           return _totalAddonPrice
        
        }, activeAddProduct? activeAddProduct?.price * currentCount : 0) 
        setTotalPrice(_totalPrice.toFixed(2))
        return _totalPrice;
    }
  
    const addAddonsToList = (totalQuantity, addonId,selectedSize,selectedColor,) => {
        if(totalQuantity>= 0 ){
            const addonItem = activeAddProduct?.subItems.filter(item => item.id === addonId)[0]
            const _newAddon = {
                count:totalQuantity,
                color:selectedColor?.value,
                price:addonItem?.price,
                title:addonItem?.title,
                itemId: addonId,
                sizes:selectedSize?.value,

            }
            const _PreviousAddon = addons.filter(item => item.itemId !== addonId); 
            setAddons([..._PreviousAddon.filter(obj => Object.keys(obj).length !== 0 || obj.constructor !== Object), _newAddon])
        }
    }



    const handleAddProduct = (item, i) => {
        let _newItem = {...item}
        _newItem['programId'] = activeProgram._id
        let _products = [...products];
        let _selectedProduct = [...selectedProduct];

        let validate = true;

        if(getColors2(item.sizes).length){
            if(!item.selectColor){
                _products[i].selectColorErr = 'Please Select Color';
                validate = false;
            }
        }

        if(getSizes(item.sizes).length){
            if(!item.selectSize){
                _products[i].selectSizeErr = 'Please Select Size';
                validate = false;
            }
        }


        if(addons.length > 0 ){
            _newItem.subItems = [...addons];
        }
        if(validate){
            let isHasItem = _selectedProduct.filter(sp => sp._id === item._id);

            if(isHasItem.length){
                _selectedProduct = _selectedProduct.filter(sp => sp._id !== item._id);
            }else{
                _selectedProduct.push(_newItem)
            }
    
            setSelectedProduct(_selectedProduct);
        }

        setProducts(_products)
        handleCloseProductModal();

    }



    const handleColorChange = (e, i) => {
        setSelectedColor(e)
        setSelectedColorErr('')
        let _products = [...products];
        _products[i].selectColor = e.value;
        _products[i].selectColorErr = '';
        setProducts(_products);

    }


    const handleSizeChange = (e, i) => {
        setSelectedSize(e)
        setSelectedSizeErr('')
        setSelectedColor('')
        let _products = [...products];
        _products[i].selectSize = e.value;
        _products[i].selectSizeErr = '';
        _products[i].selectColor = '';
        _products[i].selectColorErr = '';
        setProducts(_products);
    }


    const checkIfItemInclude = (item, all) => {
        let isHasItem = all.filter(sp => sp._id === item._id);

        if(isHasItem.length){
            return true;
        }else{
            return false
        }
    }




    const change1 = (e, i) => {
        let _selectedCamper = {...selectedCamper};
        _selectedCamper.additionalExperience[i].question.answer = e.target.value;
        _selectedCamper.additionalExperience[i].question.answerErr = '';
        setSelectedCamper(_selectedCamper);
    }

    const change2 = (e, i) => {
        let _selectedCamper = {...selectedCamper};
        _selectedCamper.additionalExperience[i].question.answer = e.value;
        _selectedCamper.additionalExperience[i].question.answerErr = '';
        setSelectedCamper(_selectedCamper);
    }

    const change3 = (e, i) => {
        let _selectedCamper = {...selectedCamper};
        _selectedCamper.additionalExperience[i].question.answer = e;
        _selectedCamper.additionalExperience[i].question.answerErr = '';
        setSelectedCamper(_selectedCamper);
    }



    const getMultipleDatePriceWithCommuter = () => {
        const _res = activeProgram?.additionalDates?.filter(el => activeProgram?.selectedDates?.find((id) => el._id === id));
        let _totalPrice = 0;
            if(_res?.length){
                _res.map(i => _totalPrice += i.commuterPrice)
            }
        return _totalPrice;
    }

    const getMultipleDatePrice = () => {
        const _res = activeProgram?.additionalDates?.filter(el => activeProgram?.selectedDates?.find((id) => el._id === id));
        let _totalPrice = 0;
            if(_res?.length){
                _res.map(i => _totalPrice += i.standardPrice)
            }
        return _totalPrice;
    }


    const handleUpdatesSizes = () => {
        setUpdatesSizes(true);
        if(tshirtSize === ''){
            setTshirtSize({label: selectedCamper.tshirtSize, value: selectedCamper.tshirtSize});
        }
        if(currentGrade === ''){
            setCurrentGrade({label: selectedCamper.currentGrade, value: selectedCamper.currentGrade});
        }
    }



    const updateSingleCamper = () => {
        let params = {
            participantId: selectedCamper._id,
            tshirtSize: tshirtSize.value,
            currentGrade: currentGrade.value,
        }
        setUpdatesSizes(false);
        singleEditCamperService(params).then(res => {
            if(res?.data?.statusCode){
                setTshirtSize('');
                setCurrentGrade('');
                notification.open({
                    description: res?.data?.responseData?.message,
                });
            }
        })
    }



    useEffect(() => {
        calculateTotalProductPrice()
    }, [addons,currentCount, activeAddProduct])
    

    const checkIfBooked = (campersListCheck) => {
        if(!campersListCheck?.availableBookings){
            return {isAlradyBooked: false, bookingNumber: ''}
        }else if(campersListCheck?.availableBookings?.length === 0){
            return {isAlradyBooked: false, bookingNumber: ''}
        }
         const bookedData =  campersListCheck?.availableBookings?.find(item => (item?.programId === activeProgram._id));
         return {isAlradyBooked: true, bookingNumber: bookedData?.bookingNumber}

    }


    return(
        <div className="page_wrapper">
            <div className="page_card">
            <div className="container">

                <div className='row'>
                    <div className='col-md-3'>
                        <ul className='cm_steppes_list'>
                            <li className={`${steps > 1 ? 'done' : ''} ${steps === 1 ? 'active' : ''}`}>Select User</li>
                            <li className={`${steps > 2 ? 'done' : ''} ${steps === 2 ? 'active' : ''}`}>Select Programs</li>
                            <li className={`${steps > 3 ? 'done' : ''} ${steps === 3 ? 'active' : ''}`}>Select Participants</li>
                            <li className={`${steps > 4 ? 'done' : ''} ${steps === 4 ? 'active' : ''}`}>Payment Information</li>
                        </ul>
                    </div>
                    <div className='col-md-9 p-3 pe-5'>
                        {
                            (steps === 1) ?loader ?  <div className="program_loader"><ProgramLoader /></div>  :  (
                                <>
                                <div className='cm_stepper_box'>
                                    <div className='d-flex justify-content-end'>
                                        <div className="check_box_wrapper cm_dark_check">
                                                <input
                                                    checked={searchCampers}
                                                    onChange={() => setSearchCampers(prev => !prev)}
                                                    type="checkbox" />
                                                <span className="check_box"><span></span></span>
                                                <label>Search by Camper's Name</label>
                                            </div>
                                    </div>
                                        <div className='form-group'>
                                            <label>Select User</label>
                                            <AsyncSelect
                                                cacheOptions
                                                loadOptions={loadOptions}
                                                placeholder="Select User"
                                                onChange={e => (setUserId(e), setUserIdErr(''))}
                                                classNamePrefix="cm_select"
                                                value={userId ? userId : ''}
                                                defaultOptions={userList.map(i => ({...i, value: i._id, label: `${i.name} - ${i.email}`}))}
                                                className={`form-control ${userIdErr ? 'is-invalid' : ''}`}
                                            />

                                            {userIdErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{userIdErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>

                                        <div className='d-flex justify-content-end mt-5'>
                                            <Link to="/bookings" className='btn btn-dark me-3'>Cancel</Link>
                                            <span className='btn btn-primary' onClick={handleUserSelect}>Next</span>
                                        </div>
                                    </div>
                                </>
                            ) : ''
                        }




                        {
                            (steps === 2) ? loader ?  <div className="program_loader"><ProgramLoader /></div>  : (
                                <div className='cm_stepper_box'>
                                    <div className='form-group'>
                                        <label>Select Programs</label>
                                        <Select
                                            isMulti
                                            onInputChange={e => setProgramSearch(e)}
                                            placeholder="Select programs"
                                            onChange={handleAddProgram}
                                            classNamePrefix="cm_select"
                                            value={selectedPrograms || []}
                                            options={programList.map(i => ({...i, value: i._id, label: `${i.campName} - ${i.programName}`}))}
                                            className={`form-control ${selectedProgramsErr ? 'is-invalid' : ''}`}
                                        />

                                        {selectedProgramsErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{selectedProgramsErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                    </div>

                                  

                                  {
                                    selectedPrograms.map((_program, indx) => {
                                        if(_program.byDates === 1){
                                            return (
                                                <Fragment>
                                                    <p>Select Dates For <b>{_program.programName}</b></p>
                                                    <div className='multi_dates pt-0 ps-5'>
                                                        <ul>
                                                            {_program.additionalDates.filter(mi => mi.slots > 0).map((additionalDate, i) => (
                                                                <li key={i}>
                                                                    <div className="check_box_wrapper cm_dark_check">
                                                                        <input
                                                                            onChange={e => onChangeSelectedDates2(additionalDate._id, indx)}
                                                                            checked={_program?.selectedDates?.includes(additionalDate._id)}
                                                                            type="checkbox"
                                                                            name="selectedDate" />
                                                                        <span className="check_box"><span></span> {gerFavpropgramdate(additionalDate.startDate, additionalDate.endDate)} <b className='ms-3'>${additionalDate.standardPrice?.toFixed(2)}</b></span>
                                                                    </div>
                                                                    <small className='cm_a_slots'>available slots: <b>{additionalDate.slots}</b></small>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </Fragment>
                                                
                                            
                                            )
                                        }
                                    })
                                  }
                                    <div className='d-flex justify-content-end mt-5'>
                                        <span onClick={() => setSteps(steps - 1)} className='btn btn-dark me-3'>Back</span>
                                        {programListLoader ? <span className='miniBtnLoader'>Data Loading...</span> : <span className='btn btn-primary' onClick={handleProgramsSelect}>Next</span>}
                                        
                                    </div>
                                </div>
                            ) : ''
                        }




                        {
                            (steps === 3) ? (
                                <div className='cm_stepper_box'>
                                        <div className='program_btn_group'>
                                            {selectedPrograms.map(i => <div className='program_btn_item' key={i._id}><span className='program_btn_close' onClick={() => handleRemoveProgram(i._id)}><i className="icon-cross" /></span> <span onClick={() => setActiveProgram(i)} className={`me-2 btn btn-${activeProgram._id === i._id ? 'primary' : 'outline-primary'}`}>{`${i.campName} - ${i.programName}`}</span></div>)}
                                        </div>
                                        
                                        <div className='form-group selct_program'>
                                            <label>Select Participants for {activeProgram.campName} - {activeProgram.programName}</label>
                                            

                                            <ul className='campers_list' style={{maxWidth: '100%'}}>
                                                {camperLoader ?<div className='program_loader'><ProgramLoader/></div> : 
                                                    selectedPrograms.find(i => activeProgram._id === i._id).campers.map(item=> {
                                                        const _alreadyBooked = checkIfBooked(campersListCheck.find(cm => cm.camperId === item._id));
                                                        return (
                                                        <li key={item._id} className='d-flex justify-content-between'>
                                                            <Container>
                                                                <Row className="d-flex justify-content-between">
                                                                <Col className="d-flex justify-content-start" style={{ flex: '0 0 80%' }}>
                                                                    <img src={item.profileImage || require('../../assets/img/user.png')} alt='profile'/>
                                                                    <div className='d-flex flex-column ms-5'>
                                                                        <p >{item.firstName} {item.lastName}</p>
                                                                        <p ><b >Group code: </b>{item.groupcode} <b >Age: </b> {_calculateAge(item.dob)} <b>Sex: </b> {getgendersdisplay(item.gender)}</p>
                                                                        {_alreadyBooked.isAlradyBooked ? <p ><b>Booking Number: </b>{_alreadyBooked?.bookingNumber}</p> :""}
                                                                        {_alreadyBooked.isAlradyBooked? <p ><small className='text-danger'>Already Booked for this participant.</small></p> : ""}
                                                                    </div>
                                                                
                                                                </Col>
                                                                <Col className='d-flex justify-content-end' style={{ flex: '0 0 20%' }}>
                                                                {_alreadyBooked.isAlradyBooked ?   
                                                                    <div className="check_wrapper invisible">
                                                                        <input checked type="checkbox" />
                                                                        <span />
                                                                    </div>:  
                                                                    <div className="check_wrapper">
                                                                        <input
                                                                            onChange={() => handleCamperSelect(activeProgram, item, item.isSelected)}
                                                                            checked={item.isSelected}
                                                                            type="checkbox" />
                                                                        <span />
                                                                    </div>
                                                                    }
                                                                </Col>
                                                                </Row>

                                                            </Container>
                                                        </li>
                                                    )})
                                                }
                                            </ul>

                                            {
                                                campersList.length ? '' : <div className="mt-4 text-center cm_empty"><Empty description="Data Not Found" /></div>
                                            }
                                        </div>

                                        <div>{resErr ? <span className='cm_error text-center'>{resErr}</span> : ''}</div>


                                        <div className='d-flex justify-content-end mt-5'>
                                            <span onClick={() => setSteps(steps - 1)} className='btn btn-dark me-3'>Back</span>
                                            <span className='btn btn-primary' onClick={handleProceedPayment}>Proceed to Payment</span>
                                        </div>
                                    </div>
                            ) : ''
                        }







                        {
                            (steps === 4) ? (
                                <div className='cm_stepper_box'>
                                        <div className='form-group'>
                                            <label>Payment Information</label>
                                        </div>
                                        <PaymentInformationCompoant
                                            userEmail={userId.email}
                                            userName={userId.name}
                                            userLastName={userId.lastName}
                                            handleBack={() => setSteps(steps - 1)}
                                            programs={selectedProgramsWithCampers.filter(i => i?.campers?.length > 0)}  
                                            userId={userId._id} 
                                            camperList={campersList}
                                            />
                                    </div>
                            ) : ''
                        }




                    </div>
                </div>
                </div>


                
             </div>
             <Modal
                dialogClassName="h_auto"
                show={isViewAddProducts}
                animation={false}
                size="lg"
                backdrop="static"
                centered>
                <Modal.Body>
                    <div className="p-2 py-5 product_moda input_err_pos">
                        <span className="modal_close" onClick={handleCloseProductModal}><i className="icon-cross" /></span>

                        <div className='row'>
                            <div className='col-lg-5'>
                            {
                                activeAddProduct?.image?.length ? <Slider {...settings}>
                                                        {activeAddProduct?.image.map(img => (
                                                                <img src={img} className="img-fluid" />
                                                        ))}
                                                    </Slider> : ''
                            }

        
                            </div>
                            <div className='col-lg-7'>
                                <h3 style={{color: activeAddProduct.textColor || '#000'}}>{activeAddProduct.title}</h3>

                                
                                 <Fragment>


                                    <div className='d-flex justify-content-between align-items-center'>
                                        {getSizes(activeAddProduct.sizes)?.length ? <div  className="form-group w-100 me-2">
                                            <label>Size</label>
                                            <Select
                                                className={`form-control ${selectedSizeErr ? 'is-invalid' : ''}`}
                                                placeholder="Select Size"
                                                value={selectedSize}
                                                onChange={e => (handleSizeChange(e, activeIndex))}
                                                classNamePrefix="cm_select"
                                                options={getSizes(activeAddProduct.sizes)} />
                                             

                                            {selectedSizeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{selectedSizeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div> : ''}

                                        {
                                            getColors2(activeAddProduct.sizes, selectedSize.value)?.length ? <div className={`form-group w-100 ${getSizes(activeAddProduct.sizes)?.length ? 'ms-2' : ''}`}>
                                            <label>Color</label>
                                            <Select
                                                isDisabled={(getSizes(activeAddProduct.sizes).length && !selectedSize.value) ? true : false}
                                                className={`form-control ${selectedColorErr ? 'is-invalid' : ''}`}
                                                placeholder="Select Color"
                                                value={selectedColor}
                                                onChange={e => (handleColorChange(e, activeIndex))}
                                                classNamePrefix="cm_select"
                                                // options={getColors(activeProduct.sizes)?.length ? getColors(activeProduct.sizes) : []} />
                                                options={getColors2(activeAddProduct.sizes, selectedSize.value)} />

                                            {selectedColorErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{selectedColorErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        
                                        </div> : ''
                                        }                                       

                                        
                                    </div>


                                    <div className="quantity mb-4 mt-0">
                                        <button onClick={() => handleItemCount(activeIndex, 'remove')} className="inc"></button>
                                        <b>{currentCount}</b>
                                        <button onClick={() => handleItemCount(activeIndex, 'add')} className="dec"></button>
                                    </div>

                                </Fragment>

                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                    

                                    <div className="product_price">
                                        <h2><b>Item Price: ${activeAddProduct?.price?.toFixed(2)}</b></h2>
                                    </div>
                                    <div className="product_price" >
                                        <h2><b>Total Price: ${totalPrice}</b></h2>
                                </div>

                                    
                                </div>
                                
                                <div className=''>
                                    {getHtml(activeAddProduct.desc)}
                                </div>
                                
                                <div className='mt-4 d-flex justify-content-end'>
                                    <button className="btn btn-primary btn-sm" onClick={() => handleAddProduct(activeAddProduct, activeIndex)}>ADD ITEM</button>
                                </div>
                                    
                                </div>
                                {
                                    (activeAddProduct && activeAddProduct?.subItems?.length > 0) &&  
                                    <div className='col-sm mt-3'>
                                    <h4>Add-ons</h4>
                                    <div className ="container">
                                        <div className="row">
                                            {       
                                            activeAddProduct?.subItems?.map(item => <Addon key={item._id} item={item} updateAddons={addAddonsToList}/>)
                                            }
                                        </div>
                                    </div>
                                    
                                    </div>
                                }
                            </div>                       
                    </div>
                </Modal.Body>
            </Modal>



             <Modal
                size="xl"
                show={participantForm}
                animation={false}
                centered>

                <Modal.Header>
                    <Modal.Title>Participant</Modal.Title>
                    <span className="modal_close" onClick={handleCloseModal}><i className="icon-cross" /></span>
                </Modal.Header>

                <Modal.Body>

                   

                    <h3>{selectedCamper.firstName }</h3>
                    <p>Age: {_calculateAge(selectedCamper.dob)} &nbsp; &nbsp; &nbsp; Sex: {getgendersdisplay(selectedCamper.gender)}</p>
                    
                    

                    {
                        updatesSizes ? (
                            <div className="update_sizes">
                                <h5 className="text-center mb-4">Update T-shirt size & Grade</h5>
                                <div className='row'>
                                    <div className="form-group col-md-6">
                                        <label>T-shirt Size</label>
                                        <Select
                                            className="form-control"
                                            placeholder="Select"
                                            value={tshirtSize}
                                            onChange={e => setTshirtSize(e)}
                                            classNamePrefix="cm_select"
                                            options={shortsAndTshirtSizes.map(item => ({label: item, value: item}))} />
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label>Select Current Grade As of Today</label>
                                        <Select
                                            className="form-control"
                                            placeholder="Select"
                                            classNamePrefix="cm_select"
                                            value={currentGrade}
                                            onChange={e => setCurrentGrade(e)}
                                            options={gradeArr.map(item => ({label: item, value: item}))} />
                                    </div>
                                </div>

                                

                                <div className='d-flex justify-content-center'>
                                    <span onClick={() => setUpdatesSizes(false)} className='btn btn-dark ps-5 pe-5 me-3'>Cancel</span>
                                    <span className='btn btn-primary ps-5 pe-5' onClick={updateSingleCamper}>Update</span>
                                </div>
                                    
                                
                            </div>
                        ) : (
                            <p><b>Would you like to update the T-Shirt size or Grade of this participant? <span className='btn btn-dark btn-sm' onClick={handleUpdatesSizes}>Yes</span></b></p>
                        )
                    }
                   


                    {/* <div className="col-md-6 form-group">
                        <label>Group Code [Optional]</label>
                        <input
                            type="text"
                            className="form-control"
                            onChange={handleGroupCodeChange}
                            value={selectedCamper.groupCode} />
                    </div> */}

                    


                    {
                        activeProgram?.agegroups?.length ? activeProgram?.agegroups.filter(aa => aa.spotsavailable).map((ageG, ind) => (
                            <div className='form-group d-flex' key={ageG.camplistid}>
                                <div>
                                    <div className="check_box_wrapper cm_dark_check">
                                        <b style={{minWidth: '200px', display: 'inline-block'}}>Age Group: {ageG.age}</b>
                                        <input
                                            onChange={() => (setcamplistid(ageG.camplistid), setcamplistidErr(''))}
                                            checked={ageG.camplistid === camplistid}
                                            type="radio"
                                            name="ageG" />
                                        <span className="check_box"><span></span></span>
                                    </div>
                                </div>
                                
                                <div className='age_g_days'>
                                    {ageG?.days.map((dd, i) => (<div key={i}>
                                        <span>{dd.date} From <b>{dd.starttime}</b> to <b>{dd.endtime}</b></span>
                                    </div>))}
                                </div>


                                
                               
                            </div>
                        )) : ''
                    }

                    {camplistidErr ? <div className='cm_err text-center'>{camplistidErr}</div> : ''}

                    {
                        (getMultipleDatePrice() && getMultipleDatePriceWithCommuter()) || (activeProgram?.campRegistrationFee > 0 && activeProgram?.commuterPrice > 0) ? <div className='isCommuter_wrapper'>
                                <p><b>Are you staying overnight or day only?</b></p>
                                <p>Overnight campers receive breakfast, lunch, & dinner each day. All overnight campers stay on campus in the dorm rooms and will be roomed with at least one fellow camper their age. They are supervised by our
                                team of counselors who are background checked. Day campers receive lunch & dinner each day. They will NOT receive breakfast. Day campers are also supervised throughout the day by our team of counselors
                                who are background checked.</p>



                                {
                                    activeProgram.byDates === 1 ? <Fragment>

                                    <div className="check_box_wrapper cm_dark_check">
                                        <b className='ms-3'>Overnight (${getMultipleDatePrice()})</b>
                                        <input
                                            type="checkbox"
                                            checked={!selectedCamper.isCommuter}
                                            onChange={() => setSelectedCamper({...selectedCamper, isCommuter: selectedCamper.isCommuter ? false : true})}
                                            name="isCommuter" />
                                        <span className="check_box"><span></span></span>
                                    </div>


                                <div className="check_box_wrapper cm_dark_check">
                                    <b className='ms-3'>Day only (${getMultipleDatePriceWithCommuter()})</b>
                                    <input
                                        type="checkbox"
                                        checked={selectedCamper.isCommuter}
                                        onChange={() => setSelectedCamper({...selectedCamper, isCommuter: selectedCamper.isCommuter ? false : true})}
                                        name="isCommuter" />
                                    <span className="check_box"><span></span></span>
                                </div>

                                    </Fragment> : <Fragment>
                                            <div className="check_box_wrapper cm_dark_check">
                                                <b className='ms-3'>Overnight (${activeProgram?.campRegistrationFee?.toFixed(2)})</b>
                                                <input
                                                    type="checkbox"
                                                    checked={!selectedCamper.isCommuter}
                                                    onChange={() => setSelectedCamper({...selectedCamper, isCommuter: selectedCamper.isCommuter ? false : true})}
                                                    name="isCommuter" />
                                                <span className="check_box"><span></span></span>
                                            </div>


                                            <div className="check_box_wrapper cm_dark_check">
                                                <b className='ms-3'>Day only (${(activeProgram?.commuterPrice + (activeProgram.registerNonRefundFee || 0))?.toFixed(2)})</b>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedCamper.isCommuter}
                                                    onChange={() => setSelectedCamper({...selectedCamper, isCommuter: selectedCamper.isCommuter ? false : true})}
                                                    name="isCommuter" />
                                                <span className="check_box"><span></span></span>
                                            </div>
                                    </Fragment>
                                }
                        </div>  : ''
                    }



                    





                    {
                        selectedCamper?.additionalExperience?.length ? (
                            <Fragment>
                                <label>Additional Experiences</label>
                                <ul className="campers_add_exp">
                                    {
                                        selectedCamper?.additionalExperience.map((item, i) => (
                                            <li key={item._id} style={{color: item.color}}>
                                                <p className={`${item.title.includes('Session 1:') ? 'DarkOrange' : ''} ${item.title.includes('Session 2:') ? 'Blue' : ''} ${item.title.includes('Session 3:') ? 'Green' : ''}`}><div><b>Title</b></div> {getHtml(item.title)}</p>
                                                <p><b>Price</b>: ${item.price}</p>
                                                <p><div><b>Description </b></div> {getHtml(item.description)}</p>
                                                <div className="quantity mb-3">
                                                    <button disabled={item.numberOfBooking === 0} className="inc" onClick={() => handleAdditionalExCountChange(i, 'remove', item.numberOfBooking)} />
                                                    <b>{item.numberOfBooking}</b>
                                                    <button className="dec" onClick={() => handleAdditionalExCountChange(i, 'add', item.numberOfBooking)} />
                                                </div>


                                                {
                                                    item.numberOfBooking ? <Fragment>
                                                         {
                                                    item?.question !== undefined &&
                                                    <Fragment>
                                                        <div className="form-group mb-3 mt-3">
                                                            {item?.question?.title ? <p className='m-0'>Q. {getHtml(item.question.title)}</p> : ''}
                                                            
                                                            {item?.question.subTitle ? <p><small>{getHtml(item?.question.subTitle)}</small></p> : ''}
                                                        </div>

                                                        {
                                                            item?.question.questionType === 1 ? (
                                                                <Fragment>
                                                                    <textarea
                                                                        value={selectedCamper.additionalExperience[i].question.answer}
                                                                        onChange={e => change1(e, i)}
                                                                        className={`form-control ${selectedCamper.additionalExperience[i].question.answerErr ? 'is-invalid' : ''}`}  />
                                                                    {selectedCamper.additionalExperience[i].question.answerErr ? <h6 className='cm_error'>{selectedCamper.additionalExperience[i].question.answerErr}</h6> : ''}
                                                                </Fragment>
                                                            ) : ''
                                                        }

                                                        {
                                                            item?.question.questionType === 2 ? (
                                                                <Fragment>
                                                                    <Select
                                                                        className={`form-control ${selectedCamper.additionalExperience[i].question.answerErr ? 'is-invalid' : ''}`} 
                                                                        placeholder="Select"
                                                                        classNamePrefix="cm_select"
                                                                        onChange={e => change2(e, i)}
                                                                        options={item?.question.options.map(im => ({label: im.opt, value: im.opt}))} />
                                                                        {selectedCamper.additionalExperience[i].question.answerErr ? <h6 className='cm_error'>{selectedCamper.additionalExperience[i].question.answerErr}</h6> : ''}
                                                                </Fragment>
                                                            ) : ''
                                                        }


                                                        {
                                                            item?.question.questionType === 4 ? (
                                                                <Fragment>
                                                                    {item?.question.options.length ? item?.question.options.map(im => (
                                                                        <div className="check_box_wrapper cm_dark_check cm_dark_check_2">
                                                                            <input
                                                                                checked={im.opt === selectedCamper.additionalExperience[i].question.answer}
                                                                                onChange={() => change3(im.opt, i)}
                                                                                type="checkbox" />
                                                                            <span className="check_box"><span></span></span>
                                                                            <p>{im.opt}</p>
                                                                        </div>
                                                                    )) : ''}

                                                                    {
                                                                        item?.question.isOtherOption === 1 ? <div className="check_box_wrapper cm_dark_check">
                                                                        <input
                                                                            type="checkbox" />
                                                                        <span className="check_box">
                                                                            <span>
                                                                        </span>
                                                                        </span>

                                                                        <p>Other</p>
                                                                    </div> : ''
                                                                    }

                                                                    <textarea
                                                                        className="form-control d-none" name="" />

                                                                    {selectedCamper.additionalExperience[i].question.answerErr ? <h6 className='cm_error'>{selectedCamper.additionalExperience[i].question.answerErr}</h6> : ''}
                                                                </Fragment>
                                                            ) : ''
                                                        }



                                                    </Fragment>
                                                }
                                                    </Fragment> : ''
                                                }



                                               
                                            </li>
                                        ))
                                    }
                                </ul>
                            </Fragment>
                        ) : ''
                    }




                    {
                        selectedCamper?.answers?.length ? (
                            <Fragment>
                                <label className="ms-3"><b>Responses Required</b></label>
                                <QuestionsComponent
                                questions={selectedCamper.answers}
                                setQuestions={handleSetAnswer}
                                source={1}
                            />
                            </Fragment>
                        ) : ''
                           
                    }
                    {
                        activeProgram.is_lacrose === 1 ?
                            <LacroseCheck
                                camperId={selectedCamper._id}
                                programEndDate={activeProgram.endDate}
                                USLResponseSuccess={USLResponseSuccess}
                                setUSLResponseSuccess={handleUSLSuccessResponse}
                                Email={userId.email}
                            />
                        : ''
                    }
                    {USLMemberIDErr && <p className='text-danger ms-3'>{USLMemberIDErr}</p>}





                    <label>Camp Related Products</label>

                    <div className='dir_product_slider'>
                        <Slider {...settings2} className='product_slider'>
                            {
                                products.map((item, i) => (
                                    <div className='product_card product_card_2'>
                                    {
                                        item?.image?.length ? (
                                            <Slider {...settings}>
                                                {item?.image.map(img => (
                                                    <img src={img} className="img-fluid pointer" />
                                                ))}
                                            </Slider>
                                        ) : ''
                                    }

                                    <div className='product_text'>
                                        <div className='pro_card_price'>
                                            <p>${item?.price?.toFixed(2)}</p>
                                        </div>
                                        <h3 style={{color: item.textColor || '#000'}}>{item.title}</h3>




                                        <div className='d-flex justify-content-between align-items-center'>

                                            <div>
                                            {
                                                checkIfItemInclude(item, selectedProduct) ? 
                                                <span onClick={() => handleRemoveItem(item)} className='btn btn-secondary'>Remove</span>
                                                :
                                                <span onClick={() => handleShowModalProductDetail(item, i)} className='btn btn-secondary'>Add</span>
                                            }
                                            
                                                
                                            
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                ))
                            }
                        </Slider> 
                    </div>




                    

{selectedDateErr ? <h6 className='res_error'>{selectedDateErr}</h6> : ''}


                    <div className="text-center">
                        <button
                            onClick={handleCloseModal}
                            className="btn btn-light rounded-0 me-2"
                            style={{ width: '130px' }}>Cancel</button>
                        <button
                            className="btn btn-primary rounded-0"
                            onClick={handleAddCamer}
                            style={{ width: '130px' }}>
                            Done
                        </button>
                    </div>


                    

                   
                </Modal.Body>
            </Modal>

        </div>
    )
}



const mapStateToProps = state => {
    let { userProfile } = state.user;
    let { campCode } = userProfile;

    return {
        campCode
    };
}


export default connect(mapStateToProps)(CreateBookingPage);