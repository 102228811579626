import React, { useState, useEffect, Fragment, } from 'react';
import { connect } from 'react-redux';
import {Dropdown } from 'react-bootstrap';
import { Link, } from 'react-router-dom';

import ConfirmDialoag from '../modals/ConfirmDiloag';
import { getprogramdatesinCart, getgendersdisplay, resHandle,} from '../../helper';
import { LogoWhite, ProgramLoader } from '../reuse/SVG';
import { getCartItems, } from '../../services/programService';
import { calculateTotalPriceForItem, getProductPriceFun, getTotalAmount, getTotalAmount2 } from './helper';
import CamperList from './CamperList'
import SelectParticipantModal from './Modals/SelectParticipantModal';
import { getCartItemService } from '../../services/campersServices';
import { activeStepCount, detailsConfirmedCount, setIsAllFree } from '../../store/cart';

const getSelectedDates = (_all, _sel) => {
    const _res = _all.filter(el => _sel.find((id) => el._id === id));
    return _res.map(i => (
        <span className='cm_selected_date' key={i._id}>
            {getprogramdatesinCart(i.startDate, i.endDate)}
        </span>
    ));
}

const CartItem = props => {
    const [cartitem, setItem] = useState(props.Item)
    const [confirmshow, setConfirmshow] = useState(false);
    const [numbderofCampers, setnumberofCampers] = useState(false);
    const [addtionalExptotalPrice, setAddtionalTotalPrice] = useState(0);
    const [cartTotalPrice, setCartTotalPrice] = useState();
    const [filterCamperListData, setFilterCamperListData] = useState();
    const [associatedCartInfo, setAssociatedCartInfo] = useState([]);
    const [activeCamper, setActiveCamper] = useState({});
    const [selectCamperModal, setSelectCamperModal] = useState(false);
    const [cartProgramData, setCartProgramData] = useState({});
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setCartData();
        setnumberofCampers(props.Item.numberOfBooking);
        setAssociatedCartInfo(props.Item.associatedCartInfo || [])

        let cartitemdetails = cartitem;
        let addtionalexptotalprice = 0;
        let questions = cartitemdetails.questions;
        let _totalDueAmount = 0;

        // camperListSelectCamperScreen().then(res => {

        let usecamperList = props.camperList;
        let filtercamperlist = [];

        usecamperList.map((camper) => {
            filtercamperlist.push(camper);
        });

        if (cartitemdetails.campers !== undefined) {
            cartitemdetails.campers.map(item => {
                if (item.additionalExperience !== undefined) {
                    item.additionalExperience.map(addtional => {
                        addtionalexptotalprice = addtionalexptotalprice + addtional.price * addtional.numberOfBooking;
                    })
                }
            })
        }

        setFilterCamperListData(filtercamperlist);

        // })




        if (cartitemdetails.campers !== undefined) {
            cartitemdetails.campers.forEach(item => {
                if (item.additionalExperience !== undefined) {
                    item.additionalExperience.map(addtional => {
                        addtionalexptotalprice = addtionalexptotalprice + addtional.price * addtional.numberOfBooking;
                    })
                }
            })
            setAddtionalTotalPrice(addtionalexptotalprice);
        }


        setCartTotalPrice(cartitemdetails.registrationFee * (cartitemdetails?.campers?.length || 1));

        getCartItemFun()
    }, [])

    const setCartData = () => {
        const _cartItem = {...props.Item}
        setItem(_cartItem); 
    }

    const getCartItemFun = async () => {
        const res = await getCartItems()
        if (res.data.statusCode) {
            let _cartProgramData = res.data.responseData.result.find(item => item._id === cartitem._id);
            setCartProgramData(_cartProgramData);
        }

    }



    const handleCamperSelection = (camper) => {
        setSelectCamperModal(true);
        setActiveCamper(camper)
    }
    const handleCloseParticipantModal = () => {
        setSelectCamperModal(false);
        setActiveCamper('')
    }


    const getCartItem = async (id) => {
        setLoader(true)
        props.fetchCartItems();
        const params = `?cartId=${id}`
        const res = await getCartItemService(params);
        const {status, data: {result}} = resHandle(res);
        if(status){
            const _cartItems = {...result}
            setLoader(false)
            props.detailsConfirmedCount(props.detailsConfirmed  || (_cartItems?.detailConfirmed > 0));
            props.detailsConfirmed ?  props.activeStepCount(2) :props.activeStepCount(1) ;
            setItem(_cartItems)
            setAssociatedCartInfo(_cartItems.associatedCartInfo)
            let _addtionalexptotalprice = 0
            _cartItems.campers.forEach(item => {
                if (item.additionalExperience !== undefined) {
                    item.additionalExperience.forEach(addtional => {
                        _addtionalexptotalprice = _addtionalexptotalprice + addtional.price * addtional.numberOfBooking;
                    })
                }
            })
            setAddtionalTotalPrice(_addtionalexptotalprice);
            
        }else{
            setLoader(false) 
        }
    }



    return ( 
        (Object.keys(cartitem)?.length > 0) && 
        <Fragment>

                <div className="cart_card" key={cartitem._id}>{
                loader ?
               <div className="program_loader"><ProgramLoader /> </div> :
                <Fragment>
                    {props.miniLoader[cartitem._id] ? <div className='cart_loader'><div className="program_loader"><ProgramLoader /></div> </div> : ''}
                    {cartitem.isSpecialDeal ? <span className="SD_tag">Special Offers</span> : ''}
                    {
                        cartitem.campImage ? <Link to={`/camps/${cartitem.programId}`}>
                                <img src={cartitem.campImage} alt='' />
                        </Link> : <div className="no_img"><Link to="#"><LogoWhite /></Link></div>
                    }
                    <h5 className='hide_sm'><b>{cartitem?.campers?.length ? "Add More ?(Optional)" : "Choose"}</b></h5>

                    <h3><Link to={`/camps/${cartitem.programId}`}>{cartitem.campName} - {cartitem.programName}</Link></h3>
                    {cartitem.byDates === 1 ? (
                        <p>{getSelectedDates(cartitem.additionalDates, cartitem.selectedDates)}</p>
                    ) : (
                        <p>{cartitem.programId === "621d3620c9cc80756fe0a171" ? "TBA" : getprogramdatesinCart(cartitem.startDate, cartitem.endDate)}<span className="d_md_block">&nbsp; &nbsp; &nbsp;</span>
                            {cartitem.addressLine1 !== '' ? <> Address : {cartitem.address}</> : ''}
                        </p>
                    )}



                <div className='d-flex flex-md-row flex-sm-column justify-content-between align-items-md-center my-2 w-50'>
                    <span>Age : {cartitem.ageGroup} </span>
                    <span >  Gender : {getgendersdisplay(cartitem.gender)} </span>
                    <small >Number of Bookings :<b> {cartitem?.campers?.length ?? 0}</b></small>
                </div>
              
                <CamperList
                    setActiveCamper={handleCamperSelection}
                    cartitem={cartitem}
                    getCartItem={getCartItem}
                />

                {
                    addtionalExptotalPrice > 0 ?
                        <React.Fragment>
                            <p className='m-0'>Additional Experiences Price: ${addtionalExptotalPrice}</p>
                        </React.Fragment>
                        : ''
                }

                {
                    associatedCartInfo.length ? <ul className='mt-0'>
                        {associatedCartInfo.map(({ items }, i) => (
                            <li key={items?.itemId}>{i + 1} : <strong>{items?.title}</strong> - {items?.count} {items?.color} {items?.sizes}</li>
                        ))}
                    </ul> : ''
                }




                    <div className="d-flex align-items-start flex-column cart_actions">
                        <div className="ml-auto text-right">
                            <div className="ms-auto text-right">
                                <span onClick={() => setConfirmshow(true)} className="d-block text-center cart_remove_camp_icon"><i className="icon-delete" /></span>
                            </div>


                            <div className="cart_price text-right mt-3">

                                {cartitem.detailConfirmed === 1 ? <h4>{getTotalAmount(cartitem) ? `$${getTotalAmount(cartitem)?.toFixed(2)}` : 'Free'}</h4> : <h4>{getTotalAmount2(cartitem, numbderofCampers) ? `$${getTotalAmount2(cartitem, numbderofCampers)?.toFixed(2)}` : 'Free'}</h4>}

                                {cartitem.byDates !== 1 ? <Dropdown className="price_detail_popover">
                                    <Dropdown.Toggle><small className="cart_remove_camp">Price detail</small></Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div className="d-flex justify-content-between">
                                            <span>Camp Registration Fee :</span>
                                            <b className="color_primary">${getTotalAmount(cartitem)?.toFixed(2)}</b>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span>Sales Tax :</span>
                                            <b className="color_primary">${cartitem.taxPrice.toFixed(2)}</b>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span>Convenience Fee :</span>
                                            <b className="color_primary">${cartitem?.convenienceFee?.toFixed(2)}</b>
                                        </div>
                                        {
                                            cartitem.discountPrice ?
                                                <div className="d-flex justify-content-between">
                                                    <span>Discount :</span>
                                                    <b className="color_primary">-${cartitem.discountPrice.toFixed(2)}</b>
                                                </div> : ''
                                        }
                                        <div className="d-flex justify-content-between gt_total">
                                            <span>Grand Total : </span>
                                            <b>${(getTotalAmount(cartitem) + cartitem.taxPrice + cartitem?.convenienceFee).toFixed(2)}</b>
                                        </div>

                                    </Dropdown.Menu>
                                </Dropdown> : ''}


                                {addtionalExptotalPrice > 0 ? <div className="mt-3">${addtionalExptotalPrice.toFixed(2)} </div> : ''}

                                {
                                    associatedCartInfo.length ? <ul>
                                        {associatedCartInfo.map(({ items }) => (
                                            <li key={items.itemId}>${calculateTotalPriceForItem(items).toFixed(2)}</li>
                                        ))}
                                    </ul> : ''
                                }


                                {cartitem.detailConfirmed === 1
                                    ? getTotalAmount(cartitem) ?
                                        cartitem.additionalDiscount < cartitem.campRegistrationFee
                                            ? `Total : $${(getTotalAmount(cartitem) + addtionalExptotalPrice + getProductPriceFun(associatedCartInfo)).toFixed(2)}`
                                            : '' : '' : cartTotalPrice
                                        ? `Total : $${(getTotalAmount2(cartitem, numbderofCampers) + addtionalExptotalPrice + getProductPriceFun(associatedCartInfo)).toFixed(2)}` : ''}



                            </div>
                        </div>

                    </div>
                </Fragment>
                }</div>

            <ConfirmDialoag
                show={confirmshow}
                hideModal={() => setConfirmshow(false)}
                ProgramName={cartitem.programName}
                action={(e) => { props.RemoveFromCart(cartitem._id); setConfirmshow(false); }}
                Message={["Are You Sure you want to remove ", <strong>{cartitem.programName}</strong>, " from cart ?"]}
            />
            <SelectParticipantModal
                selectCamperModal={selectCamperModal}
                handleCloseParticipantModal={handleCloseParticipantModal}
                cartProgramData={cartProgramData}
                activeCamper={activeCamper}
                cartId={cartitem._id}
                getCartItem={getCartItem}
            />
        </Fragment>
    )
}

const mapStateToProps = state => {
    let { loggedIn, userProfile } = state.user;
    let { camperList } = state.program;
    let { name, lastName, phone, gender, dob, address } = userProfile;
    let { detailsConfirmed, isAllFree } = state.cartReducer;

    return {
        loggedIn,
        camperList,
        name,
        lastName,
        phone,
        gender,
        dob,
        address,
        detailsConfirmed,
        isAllFree
    };
}
const mapDispatchToProps = dispatch => ({
    detailsConfirmedCount: (params) => dispatch(detailsConfirmedCount(params)),
    activeStepCount: (params) => dispatch(activeStepCount(params)),
    setIsAllFree: (params) => dispatch(setIsAllFree(params))
});
export default connect(mapStateToProps, mapDispatchToProps)(CartItem);
