import React from 'react'
import { Modal } from 'react-bootstrap'

const ConfirmationModal = ({show, onClose, dialougeMessage, onConfirm}) => {
  return (
        <Modal
            show={show}
            animation={false}
            dialogClassName="folder_model"
            size="lg"
            centered
        >
            <Modal.Body>
                <span className="modal_close" onClick={onClose}><i className="icon-cross" /></span>
                <div className="text-center p-5 pickup_modal_body">
                    <p>{dialougeMessage}</p>
                </div>
                <div className="text-center p-5">
                    <button onClick={onClose} style={{ width: '120px' }} className="btn btn-light rounded-0">No</button>
                    <button onClick={onConfirm} style={{ width: '120px' }} className="btn btn-primary ms-3">Yes</button>
                </div>
            </Modal.Body>
        </Modal>
  )
}

export default ConfirmationModal
