import React, { useState } from 'react'
import moment from 'moment'
import { convertTimeToCurrentTimeZone, paraClass, PaymentDateFormat, userTimezone } from '../../helper'
import PaymentDetails from '../payment/PaymentDetails'
const getCamperStatusText = (status, name) =>  {
    switch(status){
        case 2:
            return <p>Cancelled : {name}</p>
        case 3:
            return <p  style={{ color: 'blue'}}>Refunded : {name}</p>
         case 4:
            return <p>Cancelled & <span  style={{ color: 'blue'}}>Refunded : </span>{name}</p>
        default :
          return   ''
    }
}
const PaymentCard = ({ item, pendingPaymentType, setActivePaymentObject, setPaymentModal, timezone }) => {
    const [paymentOptionType, setPaymentOptionType] = useState(1)
    const handleProductDetailsTab = (i) => {
        if (paymentOptionType === (i + 2)) {
            setPaymentOptionType(1)
            return;
        }
        setPaymentOptionType(i + 2)
    }
    return (
        <div className="pending_payment_card" key={item._id}>
            {
                (pendingPaymentType === 1 || pendingPaymentType === 2) ? <>

                            <div className='row'>
                                <div className='col-md-7'>
                                    <span><b>Booking Number:</b> {item.bookingNumber}</span>
                                    <p className='mb-0'><b>Programs:</b></p>
                                    {
                                        item?.programs?.map(prog => (
                                            <span className='pp_names'>{prog.programName}</span>
                                        ))
                                    }


                                    {
                                        item?.editNotes?.length ? <div className={`mt-3`}>
                                            <h6>Payments Notes</h6>

                                            {
                                                item?.editNotes.map((_note, i) => <div key={i} className='_note'>
                                                    <p className={`${_note.diff ? '_note_dic' : '_note_plus'}`}>
                                                        {_note.diff ? '+' : '-'} {_note.difference ? <b>${Math.abs(_note.difference).toFixed(2)}</b> : ''} {_note.notes}</p>
                                                </div>)
                                            }
                                        </div> : ''
                                    }
                                    {item?.purchaseItems?.length > 0 && 
                                    <div>
                                        <span className='ms-2'>Product Remaining Amount : <b>${(( item?.purchaseItems?.reduce((tp, ip) => tp + ip.remainingAmount, 0)))?.toFixed(2)}</b></span>
                                        <div className='inbox_tabs mb-3'>
                                            {
                                                item?.purchaseItems?.map((_item, i) =>
                                                    <span className={paymentOptionType === (i + 2) ? 'active' : ''} onClick={() => handleProductDetailsTab(i)}>Product Details {i + 1}</span>
                                                )
                                            }

                                        </div>
                                    </div>
                                    }
                                    {
                                        item?.purchaseItems?.length && paymentOptionType > 1 ?
                                            <div className='col m-3'>
                                                
                                                    <div className='d-inline-block camp_related_product'>
                                                         {item?.purchaseItems[paymentOptionType - 2]?.purchaseType === 2 ? <p style={{ color: 'red', fontSize: "10px"}}>* <i>Purchased with program</i></p> : ''}
                                                         <div className='d-flex justify-content-between align-items-center mb-1'>
                                                            <p className='m-0'><b>Purchase Date: </b> 
                                                            {convertTimeToCurrentTimeZone(item?.purchaseItems[paymentOptionType - 2]?.updated, timezone,PaymentDateFormat)}
                                                            </p>
                                                        </div>
                                                        {item?.purchaseItems[paymentOptionType - 2]?.items.map((pI, index) =>
                                                            <div key={pI?.itemId} >
                                                                <p><b>Item {index + 1} : </b>{pI?.title} || <b>Price:</b> ${pI?.price || 0}<b>  || </b><b>Count:</b> {pI?.count}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                            </div>
                                            : ""

                                    }
                                </div>

                                <div className='col-md-5 pp_payments'>

                                    {
                                        item?.isEmi?.allEmiRemains?.length ? (
                                            <>
                                                {
                                                    item?.isEmi?.allEmiRemains?.filter(mmi => mmi.status !== 1).map(emi => (
                                                        <div className='d-flex justify-content-between align-items-center emi_chip'>
                                                            <div><date>Date: {moment(emi.emiDate).format('MMM DD, YYYY')}</date></div> -
                                                            <div>Amount: <b>${emi?.emiAmount?.toFixed(2)}</b></div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        ) : (
                                            <>
                                                <date><b>Booking Date:</b> 
                                                    {convertTimeToCurrentTimeZone(item.bookingDate, timezone,'MMMM DD, YYYY : hh:mm a')} 
                                                </date>
                                                <p><b>Total Amount:</b> <h5>${(item?.totalAmount + (item?.purchaseItems?.length && item?.purchaseItems?.reduce((tp, ip) => tp + (ip?.totalAmount ? ip.totalAmount : 0), 0)))?.toFixed(2)}</h5></p>
                                                <p><b>Remaining Amount:</b> <h5>${(item?.remainingAmount + (item?.purchaseItems?.length && item?.purchaseItems?.reduce((tp, ip) => tp + ip.remainingAmount, 0)))?.toFixed(2)}</h5></p>
                                            </>
                                        )
                                    }
                                    {//TODO: add pending  status  to filter pending payments as well
                                        item?.isEmi?.allEmiRemains?.filter(mmi => mmi.status !== 1).length ||
                                            item?.remainingAmount > 0 ||
                                            item?.purchaseItems?.filter(pi => pi?.remainingAmount > 0).length > 0
                                            ? <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                                                <button onClick={() => (setActivePaymentObject(item), setPaymentModal(true))} className='btn btn-primary btn-end mt-4'>Pay Now</button>
                                            </div>
                                            : ''
                                    }
                                </div>
                            </div>

                        </> : ''
            }


            {
                pendingPaymentType === 3 ? <>
                            <p className='mb-0'><b>Programs:</b></p>
                            {
                                item?.programs?.map(prog => (
                                    <span className='pp_names'>{prog.programName}</span>
                                ))
                            }
                            {
                                <div className='inbox_tabs mb-3'>
                                    {
                                        item?.purchaseItems?.map((_item, i) =>
                                            <span className={paymentOptionType === (i + 2) ? 'active' : ''} onClick={() => handleProductDetailsTab(i)}>Product Receipt {i + 1}</span>
                                        )
                                    }

                                </div>
                            }
                             {
                                        item?.purchaseItems?.length && paymentOptionType > 1 ?
                                            <div className=' col m-3'>
                                                {(item?.purchaseItems[paymentOptionType - 2]?.purchaseType === 1) ?
                                                    <div className={`d-inline-block camp_related_product`}>
                                                        {item?.purchaseItems[paymentOptionType - 2]?.items.map((pI, index) =>
                                                            <div key={pI?.itemId} >
                                                                <p><b>Item {index + 1} : </b>{pI?.title}  || <b>Price:</b> ${pI?.price || 0}<b>  || </b><b>Count:</b> {pI?.count}</p>
                                                            </div>
                                                        )}
                                                        {
                                                            item?.purchaseItems[paymentOptionType - 2]?.paymentArr?.map(ri =>
                                                                <div className='receipt_wrap'>
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <p className='m-0'><b>Payment Date: </b> {
                                                                            convertTimeToCurrentTimeZone(ri?.paymentDate, timezone,PaymentDateFormat)
                                                                        }</p>
                                                                    </div>
                                                                    <p className='m-0'><b>Payment Type: </b> {ri?.method}</p>

                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <p className='m-0'><b>Transaction Id: </b> {ri?.transaction_id}</p>
                                                                    </div>
                                                                    <PaymentDetails transactionId={ri?.transaction_id}/>
                                                                    <div className='d-flex justify-content-end'>
                                                                        <p className='d-flex m-0'><b>Amount: </b></p><h5 className='ms-2'>${ri?.amount?.toFixed(2)}</h5>
                                                                    </div>
                                                                    {
                                                                        ri?.paymentStatus === 'failed' ?
                                                                            <>
                                                                                <span className='p_cat_text' style={{ background: 'red' }}>Failed Payment</span>
                                                                            </>
                                                                            : ''

                                                                    }
                                                                </div>
                                                            )
                                                        }

                                                    </div>
                                                    :
                                                    <div className='receipt_wrap d-inline-block'>
                                                        {item?.purchaseItems[paymentOptionType - 2]?.items.map((pI, index) =>
                                                            <div key={pI?.itemId} >
                                                                <p><b>Item {index + 1} : </b>{pI?.title} || <b>Price:</b> ${pI?.price || 0}<b>  || </b><b>Count:</b> {pI?.count}</p>
                                                            </div>
                                                        )}
                                                        <span style={{ color: 'red', fontSize: "10px"}}><i>*A receipt for this product will be generated with the program payment.</i></span>
                                                    </div>
                                                }
                                            </div>
                                            : ""

                                    }


                            {
                                item?.paymentArr?.length ? item?.paymentArr.map(pp => (
                                    <>
                                        <div className='receipt_wrap' style={{maxWidth:'1000px'}}>
                                            <div>
                                                <p className={paraClass}><b>Payment Date: </b> 
                                                    {convertTimeToCurrentTimeZone(pp.paymentDate, timezone,PaymentDateFormat)}
                                                </p>
                                                <p className={paraClass}><b>Payment Type: </b> {pp.method}</p>
                                                <p className={paraClass}><b>Transaction Id: </b> {pp.transaction_id}</p>

                                            </div>
                                            
                                            <PaymentDetails transactionId={pp?.transaction_id}/>
                                            <div className='d-flex justify-content-end mt-2'>
                                                <p className='d-flex m-0'><b>Amount: </b> <h5 className='ms-2'>${pp.amount?.toFixed(2)}</h5></p>
                                            </div>
                                            {
                                                pp?.paymentStatus === 'failed' ?
                                                    <>
                                                         <p style={{ fontSize: "12px" , color:'red'}}><i>{pp?.failed?.errorDescription}   </i></p>
                                                         <p style={{color:'red'}} className='d-flex justify-content-between align-items-center'> <span className='p_cat_text' style={{background:'red'}}>Payment Failed</span> 
                                                            {convertTimeToCurrentTimeZone(pp?.failed?.paymentDate, timezone,PaymentDateFormat)}
                                                         </p>
                                                    </>
                                                    : ''

                                            }
                                           {       pp?.paymentStatus === 'succssful' ?
                                                                pp?.refunds?.length > 0 ? 
                                                                <>
                                                                    <span className='p_cat_text' style={{background:'blue'}}>Payment Refunded</span> 
                                                                    {
                                                                        pp?.refunds?.map( rf =>  <p style={{color:'blue'}} className='d-flex justify-content-between align-items-center'><span><i> Refunded Amount : $ {rf?.amount}</i></span>
                                                                        {convertTimeToCurrentTimeZone(rf?.paymentDate, timezone,PaymentDateFormat)}

                                                                        </p>)
                                                                    }
                                                                </>
                                                                : <span className='p_cat_text' style={{background:'green'}}>Payment successful</span> 

                                                                : ''

                                                }

                                        </div>
                                        <div className='has_canceled_text'>
                                            { 
                                                item?.programs?.map((program) => program.campers?.map((camper) => getCamperStatusText(camper.status, camper.camperName) ))

                                            }
                                        </div>
                                    </>
                                )) : <h6 className="text-center mt-3">No payment for this receipt</h6>
                            }



                        </> : ''
            }






        </div>
    )
}

export default PaymentCard
