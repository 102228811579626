import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from './components/home/Home';
import Dashboard from './components/dashboard/Dashboard';
import ResetPassword from './components/auth/ResetPassword';
import Header from './components/templates/Header';
import Footer from './components/templates/Footer';
import Modals from './components/modals';
import Camps from './components/camps/Camps';
import CampDetail from './components/camps/CampDetail';
import SpecialDeals from './components/camps/SpecialDeals';
import AboutUs from './components/about/AboutUs';
import TermsConditions from './components/static/TermsConditions';
import PrivacyPolicy from './components/static/PrivacyPolicy';
import ScrollToTop from './components/static/ScrollToTop';
import Profile from './components/profile/Profile';
import Feeds from './components/feed/Feeds';
import Favorites from './components/favorites/Favorites';
import Pickup from './components/pickup/Pickup';
import NotFoundPage from './components/NotFoundPage';
import { MainLoader } from './components/reuse/SVG';
import { mainLoaderAction, checkIsTeamsEZAction } from './store/ui';
import NonUser from './components/pickup/NonUser';
import Career from './components/careers/Career'
import ListCamp from "./components/listCamp/ListCamp";
import Advertising from './components/advertising/Advertising'
import Partner from './components/partner/Partner'
import NewsAndEvents from './components/newsAndEvent/NewsAndEvents'
import AutoScheduler from './components/autoscheduler/AutoScheduler';
import Cart from './components/cart';
import ConfirmedCart from './components/cart/loginuserconfirmedlist';
import ConfirmDetails from './components/cart/confirmDetails';
import EventCalendar from './components/eventCalendar';
import PaymentConfirmation from './components/cart/PaymentConfirmation';
import SelectCampers from './components/cart/selectcampers';
import AddCamper from './components/cart/addcampers';
import GiftCards from './components/giftCards/GiftCards';
import BuyGiftCard from './components/giftCards/BuyGiftCard';
import ViewBooking from './components/booking/ViewBooking';
import RegistrationSucces from './components/cart/regisrationSuccess';
import SupportFAQs from './components/static/SupportFAQs';
import GetUserDetailsPosts from './components/feed/GetUserDetailsPosts';
import Inbox from './components/inbox/Inbox';
import DirectorCamp from './components/directorCamp/DirectorCamp';
import AssociatePrograms from './components/associatePrograms';
import Counselors from './components/counselor/Counselors';
import Activity from './components/activity/Activity';
import Bookings from './components/bookings/Bookings';
import BookingDetail from './components/bookings/BookingDetail';
import CamperDetail from './components/bookings/CamperDetail';
import Donate from './components/donate/Donate';
import AddActivity from './components/activity/AddActivity';
import EditActivity from './components/activity/EditActivity';
import AddCounselor from './components/counselor/addCounselor';
import EditCounselor from './components/counselor/editCounselor';
import FolderDetails from './components/counselor/folderDetails';
import SelectActivity from './components/activity/SelectActivity';
import DirectorcampDetails from './components/directorCamp/DirectorcampDetails'
import FolderDetailsActivity from './components/activity/folderDetails'
import FolderDetailsBooking from './components/bookings/folderDetails'
import FolderDetailsBookingCamper from  './components/bookings/folderDetailscampers'
import Payments from  './components/profile/Payments'
import CabinOrganizer from './components/directorCamp/CabinOrganizer';
import NewsDetail from './components/newsAndEvent/NewsDetail';
import Welcome from './components/Welcome';
import Waiver1 from './components/static/Waiver1';
import Waiver2 from './components/static/Waiver2';
import Schedule from './components/counselor/Schedule';
import DistrictCamps from './components/camps/DistrictCamps';
import Users from './components/users/Users';
import UserDetail from './components/users/UserDetail';
import CreateBookingPage from './components/bookings/CreateBookingPage';
import OnlineStore from './components/online-store/OnlineStore';
import InboxPage from './components/inboxPage/InboxPage';
import SportsInternationalCamps from './components/camps/SportsInternationalCamps';
import SportInternationalCampDetail from './components/camps/SportInternationalCampDetail';
import ProductBookings from './components/product-bookings/ProductBookings';
import { getPageDataService } from './services/otherService';
import SiteDown from './components/SiteDown';
import AdminLogin from './components/auth/AdminLogin';

const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => (
  <Route {...rest} render={(props) => (
    loggedIn ? <Component {...props} /> : <Redirect to='/' />
  )} />
)


export const scrollTop = () => window.onscroll = () => window.pageYOffset >= 40 ? document.body.classList.add('scroll_header') : document.body.classList.remove('scroll_header');

scrollTop();

const App = props => {

  const [UIData, setUIData] = useState({});

  const selectCSS = () => {
    let url = window.location.host;
    if (url.toLowerCase() === 'teamsez.com'){
      document.body.classList.add('teamsez_page');
      props.checkIsTeamsEZAction();
    }
  }
  const getPageData = () => {
    let query = `?page=maintenance`;
    getPageDataService(query).then(res => {
        if (res.data.statusCode){
          setUIData(res.data.responseData.result[0]);
        }
    })
  }

  useEffect(() => {
    getPageData();
      selectCSS();
  }, []);



  return(
    <div className="site_wrapper">
      <ScrollToTop />
      {props.mainLoader ? <MainLoader /> : ''}
      <Header  isMaintenance = {UIData?.isMaintenance}/>
      {UIData?.isMaintenance ? 
        <SiteDown UIData={UIData}/>
        : 
      <Switch>
        <Route exact path='/'                                                      {...props} component={ props.userProfile ? props.userProfile.UserType === 2 ? Dashboard : props.userProfile.UserType === 4 ?  Bookings : Home :Home } />
        <PrivateRoute path='/profile'                                              {...props} component={ Profile } />
        <Route exact path='/terms-and-conditions'                                  {...props} component={ TermsConditions } />
        <Route exact path='/privacy-policy'                                        {...props} component={ PrivacyPolicy } />
        <Route exact path='/camps'                                                 {...props} component={ Camps } />
        <Route exact path='/district-camps'                                        {...props} component={ DistrictCamps } />
        <Route exact path='/sports-international-camps'                            {...props} component={ SportsInternationalCamps } />
        <Route exact path='/camps/:id'                                             {...props} component={ CampDetail } />
        <Route exact path='/sports-international-camps/:spcampname'                {...props} component={ SportInternationalCampDetail } />
        <Route exact path='/special-offers'                                        {...props} component={ SpecialDeals } />
        <Route exact path='/about-us'                                              {...props} component={ AboutUs } />
        <Route exact path='/reset-password/:token'                                 {...props} component={ ResetPassword } />
        <Route exact path='/feeds'                                                 {...props} component={ Feeds } />
        <PrivateRoute exact path='/online-store'                                   {...props} component={ OnlineStore } />
        <PrivateRoute exact path='/online-store/:id'                               {...props} component={ OnlineStore } />
        <PrivateRoute exact path='/pickup'                                         {...props} component={ Pickup } />
        <PrivateRoute exact path='/pickup/add-non-user'                            {...props} component={ NonUser } />
        <PrivateRoute exact path='/favorite'                                       {...props} component={ Favorites } />
        <PrivateRoute exact path='/inbox'                                          {...props} component={ props.userProfile ? props?.userProfile?.UserType === 1 ? Inbox :  InboxPage : Home } />
        <PrivateRoute exact path='/director-camps'                                 {...props} component={ DirectorCamp } />
        <PrivateRoute exact path='/cabin-organizer/:id'                            {...props} component={ CabinOrganizer } />
        <PrivateRoute exact path='/personnel'                                      {...props} component={ Counselors } />
        <PrivateRoute exact path='/personnel/schedule'                             {...props} component={ Schedule } />
        <PrivateRoute exact path='/my-calendar'                                    {...props} component={ Schedule } />
        <PrivateRoute exact path='/activity'                                       {...props} component={ Activity } />
        <PrivateRoute exact path='/activity/edit/:id'                              {...props} component={ EditActivity } />
        <PrivateRoute exact path='/activity/add'                                   {...props} component={ AddActivity } />
        <PrivateRoute exact path='/users'                                          {...props} component={ Users } />
        <PrivateRoute exact path='/users/detail/:id'                               {...props} component={ UserDetail } />
        <PrivateRoute exact path='/bookings'                                       {...props} component={ Bookings } />
        <PrivateRoute exact path='/bookings/:id'                                   {...props} component={ BookingDetail } />
        <PrivateRoute exact path='/bookings/:bookingId/:camperId/:programId'       {...props} component={ CamperDetail } />
        <PrivateRoute exact path='/booking/create'                                {...props} component={ CreateBookingPage } />
        <Route exact path='/career'                                                {...props} component={ Career } />
        <Route exact path='/list-camp'                                             {...props} component={ ListCamp } />
        <Route exact path='/advertising'                                           {...props} component={ Advertising } />
        <Route exact path='/partner'                                               {...props} component={ Partner } />
        <Route exact path='/news-and-events'                                       {...props} component={ NewsAndEvents } />
        <Route exact path='/news-and-events/:id'                                   {...props} component={ NewsDetail } />
        <PrivateRoute exact path='/calendar'                                       {...props} component={ EventCalendar } />
        <PrivateRoute exact path='/auto-scheduler'                                 {...props} component={ AutoScheduler } />
        <PrivateRoute exact path='/products-booking'                               {...props} component={ ProductBookings } />
        <Route exact path='/cart'                                                  {...props} component={ Cart } />
        <Route exact path='/cartconfirmed'                                         {...props} component={ ConfirmedCart } />
        <Route exact path='/payment-confirmation'                                  {...props} component={ PaymentConfirmation } />
        <Route exact path='/confirmdetails'                                        {...props} component={ ConfirmDetails } />
        <Route exact path='/selectcampers'                                         {...props} component={ SelectCampers } />
        <Route exact path='/addcampers'                                            {...props} component={ AddCamper } />
        <Route exact path='/registrationsuccess'                                   {...props} component={ RegistrationSucces } />
        <Route exact path='/gift-cards'                                            {...props} component={ GiftCards } />
        <Route exact path='/gift-cards/:id'                                        {...props} component={ BuyGiftCard } />
        <Route exact path='/faq'                                                   {...props} component={ SupportFAQs } />
        <Route exact path='/associateprograms'                                     {...props} component={ AssociatePrograms } />
        <Route exact path='/user/:id'                                              {...props} component={ GetUserDetailsPosts } />
        <Route exact path='/donate/:programId'                                     {...props} component={ Donate } />
        <Route exact path='/addcounselor'                                          {...props} component={ AddCounselor } />
        <Route exact path='/signup-staff/:id/:id2'                                 {...props} component={ AddCounselor } />
        <Route exact path='/personnel/:id'                                         {...props} component={ EditCounselor } />
        <Route exact path='/personnel/folder/:id'                                  {...props} component={ FolderDetails } />
        <Route exact path='/director/camp/:id'                                     {...props} component={ DirectorcampDetails } />
        <Route exact path='/activity/:folderName'                                  {...props} component={ SelectActivity } />
        <Route exact path='/activity/folder/:id'                                   {...props} component={ FolderDetailsActivity } />
        <Route exact path='/payments'                                              {...props} component={ Payments } />
        <Route exact path='/bookings/folder/:id'                                   {...props} component={ FolderDetailsBooking } />
        <Route exact path='/camper/folder/:id'                                     {...props} component={ FolderDetailsBookingCamper } />
        <Route exact path='/waiver-1'                                              {...props} component={ Waiver1 } />
        <Route exact path='/waiver-2'                                              {...props} component={ Waiver2 } />
        <PrivateRoute exact path='/booking/:id/:programId'                         {...props} component={ ViewBooking } />
        <Route path="/404"                                                         {...props} component={ NotFoundPage } />
        <Route exact path='/sign-in/:userId/:isAdmin'                                             {...props} component={ AdminLogin } />
        <Welcome />
        <Redirect to="/404" />
      </Switch>
    }
      <Footer />
      <Modals isMaintenance = {UIData?.isMaintenance}/>
    </div>
  )
}


const mapStateToProps = state => {
  let { mainLoader, isTeamsEZ } = state.ui;
  let { loggedIn, userProfile } = state.user;
  return {
    mainLoader,
    loggedIn,
    userProfile,
    isTeamsEZ
  }
}

const mapDispatchToProps = dispatch => ({
  mainLoaderAction: params => dispatch(mainLoaderAction(params)),
  checkIsTeamsEZAction: () => dispatch(checkIsTeamsEZAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
