import React from 'react'
import CampCard from './CampCard';

const BookingsList = (props) => {
    const { bookings, permissions} = props
    return (bookings?.length > 0 ?
        <div className="camp_list" id="section-to-print">
            {bookings.map((program, i) =>
                <CampCard
                    viewCamper={permissions.viewCamper}
                    messageCamper={permissions.messageCamper}
                    key={program._id}
                    ProgramIndex={i}
                    CampDetails={program}
                />)}
        </div>
        :
        <div className="d-flex align-items-center counselor_card">No Programs Found</div>
    )
}

export default BookingsList
