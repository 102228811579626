import React, { Fragment, useState, useEffect,useRef } from 'react';
import { connect } from 'react-redux';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';


import { DatePicker, Select, InputNumber } from 'antd';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import { programTypes, PriceArr, gradeArr, ageArr, genders3, formatDate, getdatedifference, removeTime, dateFormat_second} from '../../helper';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';


const { RangePicker } = DatePicker;
const dateFormatNew = 'MM/DD/YYYY';
const { Option } = Select;


const MainFilter = props => {
const[keywordSearch, setKeywordSearch] = useState();
const[city, setCity] = useState();
const[gender, setGender] = useState();
const [ageGroup, setageGroup] = useState();
const[grade, setGrade] = useState();
const[startDate, setStartDate] = useState();
const[endDate, setEndDate] = useState();
const[programType, setProgramType] = useState();
const[budgetPerProgram, setbudgetPerProgram] = useState();
const[categories, setCategory] = useState([]);
const[selctnum, setSelctum] = useState(1); 
const[showdateRange, setDateRangeVisiblity] = useState(false);
const[sendBockoutDateParms, setSendBockoutDateParms] = useState(0);
const[radius, setRadius] = useState();
const[lat, setlat] = useState();
const[long, setLong] = useState();
const[daterangeError, setDaterangeError] = useState(false);
const myRef = useRef();

const[dateRanges, setDaterange] = useState({
        selection1: {
           startDate: addDays(new Date(),0),
          endDate: addDays(new Date(),0),
        }
});



useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });  


useEffect( () => {
    const{ ageGroup, budgetPerProgram, categories, city, endDate, gender, grade, keywordSearch, programType, startDate, radius, lat, long } = props.filters;
    setKeywordSearch(keywordSearch);
    setCity(city);
    setGender(gender);
    setageGroup(ageGroup);
    setGrade(grade);
    setStartDate(startDate);
    setEndDate(endDate);
    setProgramType(programType);
    setbudgetPerProgram(budgetPerProgram);
    setCategory(categories);
    setlat(lat);
    setLong(long);
    setRadius(radius);

    if(typeof props.filters.blackOutDates!=='undefined' && props.filters.blackOutDates.length>0)
    { 
        setSendBockoutDateParms(1);
        const arr = props.filters.blackOutDates;
        let first_val = arr[0];
        let range_start = arr[0];
        let ranges = {};
        let key_text = 'selection';
        let section_counter =1;
        for(let i=1; i<=arr.length; i++){
        let next_val = arr[i];
        let diff = getdatedifference(next_val,first_val); 
         if(diff===1){
                 first_val = next_val;
         }else{
            let key = key_text+section_counter;
            ranges[key] = {startDate: new Date(dateFormat_second(new Date(range_start))), endDate: new Date(dateFormat_second(new Date(arr[i-1]))) };
            first_val = next_val;
            range_start = next_val;
            section_counter++;
         }
  }

  setDaterange(ranges)
    }  



}, [props.filters])



const handelChange = (item) => {
    setSendBockoutDateParms(1);
    if(selctnum===1)
	{
		var key = "selection"+selctnum;
		var obj ={};
		for (const property in item) {			
			 var startDate =  addDays(item[property].startDate, 0);
			 var endDate =  addDays(item[property].endDate, 0);
            
            }
       
		obj[key] ={
		        startDate: startDate,
    		    endDate: endDate
        }
		setDaterange({ ...dateRanges, ...obj })		
	}
	else
	{
			for (const property in item) {
			 var startDate =  addDays(item[property].startDate, 0);
			 var endDate =  addDays(item[property].endDate, 0);
	}
		var key = "selection"+selctnum;
		var obj ={};
		obj[key] ={
		    startDate: startDate,
    		endDate: endDate
		}
		setDaterange({ ...dateRanges, ...obj })
	}
 	setSelctum( selctnum+1);
 }

var ranges =[];
var blockoutdates = [];
for (const property in dateRanges) {			
  ranges.push(dateRanges[property]);
  let start_date = dateRanges[property].startDate;
  let endDate = dateRanges[property].endDate;

  for (var d = new Date(start_date); d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
    blockoutdates.push(new Date(d).getTime());
}
}
    
    const handleFiterSearch = (e) => {
      
    e.preventDefault();

        let blackOutDates = [];
        for (const property in dateRanges) {



            
            let start_date = dateRanges[property].startDate;
            let endDate = dateRanges[property].endDate;
            let start_date_format = dateFormat_second(dateRanges[property].startDate)
            let endDate_format = dateFormat_second(dateRanges[property].endDate)


            var obj ={};
            obj[property] ={
                startDate:  new Date(start_date_format),
                endDate: new Date(endDate_format)
            }
            setDaterange({ ...dateRanges, ...obj })
            
            for (var d = new Date(start_date); d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
                let time = new Date(d);
                time.setUTCDate(time.getDate());
                time.setUTCHours(0);
                time.setUTCMinutes(0);
                time.setUTCSeconds(0);
                time.setUTCMilliseconds(0);    
                let time_stamp = time.getTime();
                blackOutDates.push(time_stamp);  
            }
          }
        blackOutDates = JSON.stringify(blackOutDates);
        
        
        let categoryparam =JSON.stringify(categories);
        let params ='/?isSearched=1';
        if(keywordSearch){params += `&keywordSearch=${keywordSearch}`}
        if(city){params += `&city=${city}`}
        if(lat){params += `&lat=${lat}&long=${long}`}
        if(radius){params += `&radius=${radius}`}
        if(gender){params += `&gender=${gender}`}
        if(ageGroup){params += `&ageGroup=${ageGroup}`}
        if(grade){params += `&grade=${grade}`}
        if(startDate){params += `&startDate=${startDate}`}
        if(endDate){params += `&endDate=${endDate}`}
        if(programType){params += `&programType=${programType}`}
        if(budgetPerProgram){params += `&budgetPerProgram=${budgetPerProgram}`}
        if(categories.length>0){params += `&categories=${categoryparam}`}
        if(blackOutDates && sendBockoutDateParms===1){params += `&blackOutDates=${blackOutDates}`}
        props.SubmitForm(params);

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }

    let { preList } = props;


    const handelCategoryAction = (cat_id) => {
        if(categories.includes(cat_id))
        {
            let cat_item_id = cat_id;
            setCategory(categories.filter((cat_item_id)=>(cat_id !==cat_item_id)));
        }
        else
        {
        setCategory([...categories, cat_id])
        }    
    }

    const clearAllFitler = () =>{
        setKeywordSearch();
        setCity();
        setGender();
        setageGroup();
        setGrade();
        setStartDate();
        setEndDate();
        setProgramType();
        setbudgetPerProgram();
        setCategory([]);
        setRadius();
        setDateRangeVisiblity(false);
        setSendBockoutDateParms(0);
        setlat()
        setLong()
        var key = "selection1";
		var obj ={};
		obj[key] ={
		        startDate: addDays(new Date(),0),
    		    endDate:   addDays(new Date(),0)
        }
        setDaterange(obj)	
        

        let params ="/?isSearched=1&keywordSearch=&city=&gender=&ageGroup=&grad=&startDate=&endDate=&programType=&budgetPerProgram=&category=&lat=&long=&radius=";
        props.SubmitForm(params);  
    }


    const removeDate =(rangeKey) =>{
        var countelemnt =0;
        for (const property in dateRanges) {			
            countelemnt++;
        }
       if(countelemnt===1)
       {
        var key = "selection1";
		var obj ={};		
		obj[key] ={
		        startDate: addDays(new Date(), 0),
    		    endDate: addDays(new Date(), 0)
        }
        setSelctum(1);
        setDaterange(obj);
        setSendBockoutDateParms(0);
       } 
       else
       {
          let date_ranges = {...dateRanges};
         delete date_ranges[rangeKey];
          setDaterange(date_ranges);
       }

    }

    const handleCheckbox = e => {
        if(e.target.checked){
            setGender(parseInt(e.target.value))
        }else{
            setGender()
        }
    }
    const foucrange = [Object.keys(dateRanges).length-1,0];


    const handleClickOutside = e => {
        if (!myRef.current.contains(e.target)) {
            setDateRangeVisiblity(false);
        }
      };
     
      
    const getLatLong =(city) =>{
        geocodeByAddress(city)
        .then(results => {
          
          for (let i = 0; i < results.length; i++) {
              for (let j = 0; j < results[i].address_components.length; j++) {
                  for (let k = 0; k < results[i].address_components[j].types.length; k++) {
                      if (results[i].address_components[j].types[k] === "locality") {
                          let city = results[i].address_components[j].long_name;
                          if(city){
                            setCity(city);
                          }else{
                            setCity();
                          }
                      
                      }
                  }
              }
          }
          return results;
        })


        
        geocodeByAddress(city)
        .then(results => getLatLng(results[0]))
        .then(latLng => setLatLong(latLng))
        .catch(error => console.error('Error', error));
    }   
    const setLatLong =  (latlng) => {
        setlat(latlng.lat);
        setLong(latlng.lng);
    }

    const  disabledDate = (current) => {
        return current && current < moment().startOf('day');
      }

    const checkenddate = (e) =>{
        let startD = new Date(removeTime(e[0]._d));
        let endD = new Date(removeTime(e[1]._d));
        let Difference_In_Time =  endD.getTime() - startD.getTime() ; 
        let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        if(Difference_In_Days>90)
        {
            //setStartDate();
            setEndDate(new Date(removeTime(e[0]._d)).getTime());
            setDaterangeError(true)
        }
        else
        {
        setDaterangeError(false)
        setEndDate(new Date(removeTime(e[1]._d)).getTime())
        }    
    } 
    
    const checkClity =(e) =>{
        if(e==='')
        {
            setlat();
            setLong();
        }
     }    


     const searchOptions = {
        
  types: ['(cities)']
       
      }
    return(
        <div  className={`main_filter ${props.hideCategory ? 'cm_hide_cat' : ''}`}>
            <form className="cm_radius_0" onSubmit={handleFiterSearch}>
                <input type="hidden" name="lat" value={lat}/>
                <input type="hidden" name="long" value={long}/>
                <div className="form-group cat_wrap_wrapper">
                <span className="clear_filters" onClick={() => {clearAllFitler();props.ClearAll()} }>Clear All</span> <h3>Search</h3> 
                    {
                        preList.category.map(item => (
                            <div className="cat_wrap" key={item._id+1}>
                                <input
                                    type="checkbox"
                                    value={item._id}
                                    onChange={() => handelCategoryAction(item._id)}
                                    checked={typeof(categories) !=='undefined' && categories.includes(item._id)}
                                />
                                <div className="cat_bg">
                                    <img src={item.icon} alt={'icon'}/>
                                </div>
                            </div>
                        ))
                    }
                </div>

                <div className={`form-group ${props.hideCategory ? '' : 'auto_scheduler'}`}>
                    {props.hideCategory ? '' : <button className="btn btn-secondary">Auto Scheduler</button>}
                    <label>Keyword Search</label>
                    <input
                        type="text"
                        value={keywordSearch ? keywordSearch: ''}
                        className="form-control"
                        onChange={(e) => { setKeywordSearch(e.target.value) } }
                        placeholder="Enter in Keyword or Program Name"
                      
                    />
                </div>

                <div className="row" style={{position: 'relative'}}>
                <div className="col-sm-8" style={{position: 'static'}}>
                    <div className="form-group" style={{position: 'static'}}>
                        <label>City</label>
                        <PlacesAutocomplete
                            className="form-control"
                            value={city ? city : ''}
                            onChange={(e) => { setCity(e);  checkClity(e) }}  
                            onSelect={getLatLong}
                            searchOptions={searchOptions}
                            
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input
                                {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input form-control',
                                })}
                                />
                                <div className="autocomplete-dropdown-container cm_locations">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                    return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                        className,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                    );
                                })}
                                </div>
                            </div>
                            )}
                        </PlacesAutocomplete>
                       {/* <Select
                            className="form-control"
                            placeholder="Select City"
                            value={city ? city : 'Select City'}
                            onChange={(e) => { setCity(e) }} 
                        >    
                            <Option key="cityselect" value="">Select City</Option> 
                            {preList.city && preList.city.map(item => (<Option key={item+1} value={item}>{item}</Option>))}
                        </Select>
                    */}   
                    </div>
                </div>    

                <div className="col-sm-4 form-group">
                        <label>Radius</label>
                        <InputNumber
                            placeholder="Radius"
                            className="form-control"
                            value ={radius}
                            onChange={e => setRadius(e)} 
                            min={1}
                            max={500}
                        />
                        {/*radius ? <i className="icon-cross field_clear" title="Clear Radius" /> : ''*/}
                    </div>
                    
 
                </div>


                


                <div className="d-flex cm_gendr">
                    {/* <label>Gender</label> */}
                    <ul className="d-flex">
                        {
                            genders3.map(item => (
                                <li className="me-2 check_box_wrapper" key={item.label}>
                                    <input
                                        
                                        type="checkbox"
                                        checked={gender === item.value}
                                        onChange={handleCheckbox}
                                        value={item.value}
                                        name={item.label}
                                    />
                                    <span className="check_box"><span /> {item.label}</span> 
                                </li>
                            ))
                        }

                    </ul>        
                </div>

               

                <div className="row">

                    <div className="form-group col-md-6">
                        <label>Age</label>
                        <Select
                            className="form-control"
                            placeholder="Select"
                            value={ageGroup ? ageGroup : 'Select Age'}
                            onChange={(e) => { setageGroup(e) }} 
                        >   
                            <Option key="ageselect" value="">Select Age</Option> 
                            {ageArr.map(item => <Option key={item} value={item}>{item}</Option>)}
                        </Select>
                    </div>

                    <div className="form-group col-md-6">
                        <label>Grade</label>
                        <Select
                            className="form-control"
                            placeholder="Select"
                            value={grade ? grade : 'Select Grade'}
                            onChange={(e) => { setGrade(e) }} 
                        >   
                            <Option key="gradselect" value="">Select Grade</Option>  
                            {gradeArr.map(item => <Option key={item} value={item}>{item}</Option>)}
                        </Select>
                    </div>

                </div>
   

                <div className="form-group">
                    <label>Select Dates</label>
                    {daterangeError ? <span className="cm_error_msg">Can't select more than 12 weeks</span> : ''}
                    <div>
                    <RangePicker
                            className="form-control"
                            format={dateFormatNew} 
                            disabledDate={disabledDate}
                            onChange={e => { setStartDate(new Date(removeTime(e[0]._d)).getTime()); checkenddate(e)}}
                            value={startDate ? [
                                moment.utc(parseInt(startDate)),
                                moment.utc(parseInt(endDate))
                            ] : ''}
                        />
                    </div>
                </div>

                <div className="form-group blockout_dates_wrapper" ref={myRef}>
                    <label>Blackout Dates</label>               
                    <div className="blockout_dates"  onClick={() => {setDateRangeVisiblity(true)} }>
                    {
                        sendBockoutDateParms ? ( 
                            Object.keys(dateRanges).map((key, index) => (
                                <div key={key+2} >
                                     <span className="icon-delete" onClick={() => removeDate(key)} /> 
                                     {formatDate(dateRanges[key])}
                                </div>
                            ))
                            ) 
                            : 'Select Blackout Dates'
                    
                        } 
                    </div>    
                    { showdateRange &&  
                    <Fragment>
                            <span className="icon-close" onClick={() => {setDateRangeVisiblity(false)}} />     
                            <DateRangePicker
                            showPreview={false}                            
                            focusedRange={foucrange}
                            minDate={new Date()}
                            showDateDisplay={false}
                            rangeColors= {['#F9A720', '#F9A720', '#F9A720']}
                            onChange={(item) => handelChange(item)}
                            ranges={ranges}
                            months={2}
                            direction="horizontal"
                            />
                     </Fragment>         
                    }    
                 </div>
               

                <div className="form-group">
                    <label>Choose Program Type</label>
                    <div>
                    <Select
                            className="form-control"
                            placeholder="Select Program Type"
                            value={programType ? programType : 'Select Program Type'}
                            onChange={(e) => { setProgramType(e) }}  

                        >    <Option key="selectprogram" value="">Select Program Type</Option> 
                            {programTypes && programTypes.map(item => (<Option key={item.label} value={item.value}>{item.label}</Option>))}
                    </Select>
                    </div>
                </div>


                <div className="form-group">
                    <label>Budget per Program</label>
                    <div>
                    <Select
                            className="form-control"
                            placeholder="Select Budget"
                            value={budgetPerProgram ? budgetPerProgram : 'Select Budget'}
                            onChange={(e) => { setbudgetPerProgram(e) }}        
                        >    
                        <Option key="selectbuget" value="">Select Budget</Option> 
                            {PriceArr && PriceArr.map(item => (<Option key={item} value={item}>{item}</Option>))}
                    </Select>
                    </div>
                </div>                                


                <div className="text-center mt-3">
                    <button type="submit" className="btn btn-secondary ps-5 pe-5">Search</button>
                </div>

            </form>
        </div>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {

    let { loggedIn } = state.user;

    let { preList } = state.program;

    return {
        loggedIn,
        preList,
    };
}
  
const mapDispatchToProps = dispatch => ({

});
  
export default connect(mapStateToProps, mapDispatchToProps)(MainFilter);
