import React, { useReducer, useEffect, useState, Fragment } from 'react';
import { Modal, OverlayTrigger } from 'react-bootstrap';
import { connect } from 'react-redux';
import { TimePicker, notification, Tooltip } from 'antd';
import moment from 'moment';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import isURL from 'validator/lib/isURL';
import isEmail from 'validator/lib/isEmail';
import { v4 as uuidv4 } from 'uuid';
import Select from "react-select";


import { dProgramModalAction } from '../../store/ui';
import { counselorlist, getDistrictsService } from '../../services/directorServices';
import { childAction } from '../../store/program';

import {
  CustomDateRange,
  CustomNumber,
  CustomSelect,
  CustomText,
  CustomTextarea,
  InputError,
  CustomDatePicker
} from '../reuse/FormControls';


import {
  resHandle,
  dateFormat,
  programTypes,
  genders3,
  trueFalse,
  trueFalse2,
  facilies,
  mealsArr,
  gradeArr,
  removeTime2,
  experiences,
  addBr,
  getBr,
  removeHtmlTags,
  checkStringContainsNumbers,
  validateZipCode,
  provinceList,
  calMultiDatesPrices,
  Timezones,
  userTimezone
} from '../../helper';

import { dProgramDetail, dProgramEdit, dProgramCreate } from '../../services/directorServices';
import { trim } from 'lodash';
import settings from '../../config/settings';
import { getCampDetailService } from '../../services/userServices';


const amountOpt = [
    {value: 1, label: 'Amount'},
    {value: 2, label: 'Percentage'},
]

const questionTypes = [
    {label: 'Short Answer', value: 1},
    {label: 'Drop-down', value: 2},
    {label: 'Checkboxes', value: 3},
    {label: 'Multiple Choice', value: 4},
    {label: 'Multiple Choice with Discounts', value: 5},
    {label: 'Drop-down with Discounts', value: 6},
];
const operationType = [{label: "All answers Required", value: 2}, {label: "Atleast one answer required", value: 1}]


const getOtherStateValue = (val) => {
    let hasInList = provinceList.find(item => item.value === val);
    if(!hasInList){
        return <option val={val}>{val}</option>
    }else{
        return ''
    }
}


const getQuestionType = i => {
    return questionTypes[i - 1]
}

const findMaxMin = (arr) => {
    let max = arr[0];
    let min = arr[0];
    for(let i = 0; i < arr.length; i++) {
       if(arr[i] > max) {
          max = arr[i];
       }
       else if (arr[i] < min) {
          min = arr[i];
       }
    };
    return {
       min, max
    };
 };

const AddProgramModal = props => {
  const url = window.location.href;

  const [btnLoder, setBtnLoder] = useState(false);
  const [counselorList, setCounselorList] = useState([]);
  const [location, setLocation] = useState('');
  const [tempTime, setTempTime] = useState('');
  const [errorArr, setErrorArr] = useState('');
  const [cmQuestions, setCmQuestions] = useState([]);
  const [cmQuestionsErr, setCmQuestionsErr] = useState('');
  const [selectedSubCat, setSelectedSubCat] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState({});
  const [campId, setCampId] = useState('');
  const [byDates, setByDates] = useState(2);
  const [additionalDates, setAdditionalDates] = useState([{
      startDate: '',
      endDate: '',
      standardPrice: 0,
      slots: 0,
      endDateErr: '',
      standardPriceErr: '',
      slotsErr: '' 
  }])
  const [dependsOnOptions, setDependsOnOptions] = useState([]);

  const [userInput, setUserInput] = useReducer((state, newState) => ({...state, ...newState}),
  {
      programName: '',
      programId: '',
      programCode: '',
      noOfSlots: '',
      registerNonRefundFee: '',
      dateEarlyRegisterOpen: '',
      dateEarlyRegisterClosed: '',
      earlyBirdPrice: 0,
      registerOpenDate: '',
      registerCloseDate: '',
      registrationFee: 0,
      commuterPrice: 0,
      dateLateRegisterOpen: '',
      dateLateRegisterClosed: '',
      lateRegistrationFee: '',
      category: '',
      subCategory: '',
      addressLine1: '',
      city: '',
      state: '',
      zipCode: '',
      gender: '',
      campId: '',
      programType: '',
      minAge: '',
      maxAge: '',
      grade: '',
      expLevel: '',
      startDate: '',
      endDate: '',
      checkInTime: '',
      checkOutTime: '',
      attendanceHour: '',
      meals: '',
      tshirt: '',
      equipment: '',
      facility: '',
      travel: '',
      travelInfo: '',
      scholarships: '',
      discount: 0,
      impNotes: '',
      questions: [],
      counselors: [],
      programIntroduction: '',
      aboutProgram: '',
      programInstruction: '',
      disclaimer: '',
      lat: '',
      long: '',
      scholarshipsUrl: '',
      isfeatured: '',
      isprivate: '',
      discountType: 1,
      isOnline: '',
      tax: '',
      earlyConvenienceFee: '',
      convenienceFee: '',
      lateConvenienceFee: '',
      siblingDiscount: '',
      siblingDiscountType: '',
      multiProgramDiscount: '',
      multiProgramDiscountType: '',
      programTimezone: userTimezone,
  }
);

const [error, setError] = useReducer((state, newState) => ({...state, ...newState}),
  {
      programName: '',
      programCode: '',
      noOfSlots: '',
      registerOpenDate: '',
      registerCloseDate: '',
      registrationFee: '',
      category: '',
      subCategory: '',
      addressLine1: '',
      city: '',
      state: '',
      gender: '',
      campId: '',
      programType: '',
      minAge: '',
      maxAge: '',
      grade: '',
      expLevel: '',
      startDate: '',
      endDate: '',
      checkInTime: '',
      checkOutTime: '',
      tshirt: '',
      equipment: '',
      facility: '',
      travel: '',
      travelInfo: '',
      scholarships: '',
      impNotes: '',
      questions: '',
      programIntroduction: '',
      aboutProgram: '',
      programInstruction: '',
      disclaimer: '',
      lat: '',
      long: '',
      location: '',
      tax: '',
      counselors: '',
      siblingDiscount: '',
      siblingDiscountType: '',
      multiProgramDiscount: '',
      multiProgramDiscountType: '',
      zipCode: '',
      scholarshipsUrl: '',
      programTimezone:''
  }
);


const getCampDetail = () => {
    getCampDetailService(`?campCode=${props?.userProfile?.campCode}`).then(res => {
        let {status, data} = resHandle(res);
        if(res?.data?.statusCode){
            setCampId(res.data.responseData.result?._id);
        }
    })
}

useEffect(() => {
    getCampDetail();
}, [props?.userProfile?.campCode])


const closeModal = () => {
  setLocation('');
  setErrorArr('');
  setCmQuestions([]);
  setCmQuestionsErr('');
  setDistrict({});
  setByDates(2);
    setAdditionalDates([{
        startDate: '',
        endDate: '',
        standardPrice: 0,
        slots: 0,
        endDateErr: '',
        standardPriceErr: '',
        slotsErr: '' 
    }])
  setUserInput(
    {
      programName: '',
      programId: '',
      programCode: '',
      noOfSlots: '',
      registerNonRefundFee: '',
      dateEarlyRegisterOpen: '',
      dateEarlyRegisterClosed: '',
      earlyBirdPrice: 0,
      registerOpenDate: '',
      registerCloseDate: '',
      registrationFee: 0,
      commuterPrice: 0,
      dateLateRegisterOpen: '',
      dateLateRegisterClosed: '',
      lateRegistrationFee: '',
      category: '',
      subCategory: '',
      addressLine1: '',
      city: '',
      state: '',
      zipCode: '',
      gender: '',
      campId: '',
      programType: '',
      minAge: '',
      maxAge: '',
      grade: '',
      expLevel: '',
      startDate: '',
      endDate: '',
      checkInTime: '',
      checkOutTime: '',
      attendanceHour: '',
      meals: '',
      tshirt: '',
      equipment: '',
      facility: '',
      travel: '',
      travelInfo: '',
      scholarships: '',
      discount: 0,
      impNotes: '',
      questions: [],
      programIntroduction: '',
      aboutProgram: '',
      programInstruction: '',
      disclaimer: '',
      lat: '',
      long: '',
      scholarshipsUrl: '',
      discountType: 1,
      isOnline: '',
      tax: '',
      earlyConvenienceFee: '',
      convenienceFee: '',
      lateConvenienceFee: '',
      counselors: [],
      siblingDiscount: '',
      siblingDiscountType: '',
      multiProgramDiscount: '',
      multiProgramDiscountType: '',
    }
  )

  setError(
    {
      programName: '',
      programCode: '',
      noOfSlots: '',
      registerOpenDate: '',
      registerCloseDate: '',
      registrationFee: '',
      category: '',
      subCategory: '',
      addressLine1: '',
      city: '',
      state: '',
      gender: '',
      campId: '',
      programType: '',
      minAge: '',
      maxAge: '',
      grade: '',
      expLevel: '',
      startDate: '',
      endDate: '',
      checkInTime: '',
      checkOutTime: '',
      tshirt: '',
      equipment: '',
      facility: '',
      travel: '',
      travelInfo: '',
      scholarships: '',
      impNotes: '',
      questions: '',
      programIntroduction: '',
      aboutProgram: '',
      programInstruction: '',
      disclaimer: '',
      lat: '',
      long: '',
      location: '',
      isfeatured: '',
      isprivate: '',
      tax: '',
      counselors: '',
      zipCode: '',
      scholarshipsUrl: ''
    }
  )

  props.dProgramModalAction('');
}


const getDistricts = () => {
    getDistrictsService('').then(res => {
        let { status, data } = resHandle(res);
        if(status){
            let _data = data.result.map(i => ({...i, label: i.name, value: i._id}))
            setDistrictList(_data || []);
        }
    })
  }


  useEffect(() => {
    getDistricts();
  }, [])
  
  useEffect(() => {
    
    const _dependsOnOptions =  cmQuestions.map( ques => ({label: ques.title, value: ques?.questionId}))
    setDependsOnOptions(_dependsOnOptions);
  }, [cmQuestions])

useEffect(() => {
  let programCode = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < 7; i++){
      programCode += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  setUserInput({programCode})
}, [props.dProgramModal]);



useEffect(() => {
    counselorlist('').then(res => {
        if(res?.data?.statusCode){
            setCounselorList(res.data.responseData.result.counselor);
        }
    })
}, [])




const handleLocationChange = location => {
  setLocation(location);
  setError({location: ''});
  setErrorArr('');
  setError({
      lat: '',
      addressLine1: '',
      city: '',
      state: '',
  });
};








const handleLocationSelect = location => {
  setLocation(location);
  setErrorArr('');
  geocodeByAddress(location)
    .then(results => {
      let zipCode;
      let city;
      let state;
      let addressLine1;
      for (let i = 0; i < results.length; i++) {
          for (let j = 0; j < results[i].address_components.length; j++) {
              for (let k = 0; k < results[i].address_components[j].types.length; k++) {
                  if (results[i].address_components[j].types[k] === "route") {
                      addressLine1 = results[i].address_components[j].long_name;
                      if(addressLine1){
                          setUserInput({addressLine1})
                      }else{
                          setUserInput({addressLine1: ''})
                      }
      
                  }
                  if (results[i].address_components[j].types[k] === "postal_code") {
                      zipCode = results[i].address_components[j].short_name;
                      if(zipCode){
                          setUserInput({zipCode})
                      }else{
                          setUserInput({zipCode: ''})
                      }
                      
                  }
                  if (results[i].address_components[j].types[k] === "locality") {
                      city = results[i].address_components[j].long_name;
                      if(city){
                          setUserInput({city})
                      }else{
                          setUserInput({city: ''})
                      }
                  
                  }
                  if (results[i].address_components[j].types[k] === "administrative_area_level_1") {
                      state = results[i].address_components[j].long_name;
                      if(state){
                          setUserInput({state})
                      }else{
                          setUserInput({state: ''})
                      }
                  }

              }
          }
      }
      return results;
    })
    .then(results => getLatLng(results[0]))
    .then(latLng => setUserInput({lat: latLng.lat, long: latLng.lng}))
    .catch(error => console.error('Error', error));
};




const handleChange = e => {
  setUserInput({[e.target.name]: e.target.value});
  setError({[e.target.name]: ''});
  setErrorArr('');
}
const handleProgramTimeZoneChange = e => {
  setUserInput({programTimezone: e.value});
  setError({programTimezone: ''});
  setErrorArr('');
}


const handleNumberChange = (e, name) => {
  if(name === 'discount') {
    if (userInput.discountType) {
        if (e < userInput.registrationFee || e < calMultiDatesPrices(additionalDates)) {
            setUserInput({ discount: e });
            setError({ discount: '' });
        } else {
            setUserInput({ discount: 0 });
            setError({ discount: 'Discount amount must be less than or equal to the standard price.' });

        }
    }


    //   if(userInput.discountType){
    //       if (e > userInput.registrationFee){
    //           setUserInput({discount: ''})
    //           setError({discount: 'Discount amount must be less then standard price.'})
    //       }else if(e > calMultiDatesPrices(additionalDates)){
    //         setUserInput({discount: ''})
    //         setError({discount: 'Discount amount must be less then standard price.'})
    //       }else{
    //           setUserInput({discount: e})
    //           setError({discount: ''}) 
    //       }
         
    //   }
      
  }else{
      setUserInput({[name]: e});
      setError({[name]: ''});
      setErrorArr('');
  }

  
}


const handleDateRangeChange = (e, from, to) => {
  setUserInput({ [from]: removeTime2(new Date(e[0]._d)), [to]: removeTime2(new Date(e[1]._d))});
  setError({[from]: '', [to]: ''});
  setErrorArr('');
}


const handleDateChange = (e, key) => {
    setUserInput({ [key]: removeTime2(new Date(e._d)) });
    if(key === 'dateEarlyRegisterClosed'){
        setUserInput({
            registerOpenDate: removeTime2(new Date(e._d)) + 86400000,
            registerCloseDate: ''
        });
        setError({ registerOpenDate: ''});
    }

    if(key === 'registerCloseDate'){
        setUserInput({ dateLateRegisterOpen: removeTime2(new Date(e._d)) + 86400000 });
        setError({ dateLateRegisterOpen: ''});
    }

    setError({[key]: ''});
    setErrorArr('');
}


const handleTimeChange = (e, name) => {
  if(name === 'checkInTime'){
      setTempTime(new Date(e._d).getHours());

      if(userInput.checkOutTime){
          setUserInput({checkOutTime: ''})
          
      }

  }


  setUserInput({[name]: moment(e._d).format('h:mm a')});
  setError({[name]: ''});
  setErrorArr('');
}


const handleSelect = (e, name) => {
  if(name === 'discountType'){
      if(e.value){
          if(userInput.discount > userInput.registrationFee){
              setUserInput({discount: 0})
          }
      }
  }
  setUserInput({[name]: e.value});
  setError({[name]: ''});
  setErrorArr('');
}

const handleMultiSelect = (e, name) => {
  let tempVal = e ? e.map(i => i.value) : '';
  setUserInput({[name]: tempVal});
  setError({[name]: ''});    
  setErrorArr('');
}




const handleCategory = e => {
  setUserInput({category: e.value, subCategory: []})
  const subCatArr = props.category.filter(el => el._id === e.value)[0].subCategory;
  setSelectedSubCat(subCatArr);   
  setError({category: ''});
  setErrorArr('');
}

const handleCheckboxChange = e => {
  setUserInput({isOnline: e.target.checked ? 1 : 0});
  if(e.target.checked){
      setUserInput({
          addressLine1: '',
          city: '',
          state: '',
          zipCode: '',
          lat: '',
          long: ''
      })
      setLocation('');
  }
}


const handleValidate = () => {
  let validate = true;
  let {
      programName,
      noOfSlots,
      registerCloseDate,
      registrationFee,
      category,
      subCategory,
      addressLine1,
      city,
      state,
      gender,
      programType,
      minAge,
      maxAge,
      grade,
      expLevel,
      endDate,
      checkInTime,
      checkOutTime,
      tshirt,
      equipment,
      facility,
      travel,
      travelInfo,
      scholarships,
      scholarshipsUrl,
      impNotes,
      programIntroduction,
      aboutProgram,
      programInstruction,
      disclaimer,
      isOnline,
      counselors,
      tax,
      siblingDiscount,
      siblingDiscountType,
      multiProgramDiscount,
      multiProgramDiscountType,
      zipCode
  } = userInput;
  
  if(programName === '' || programName.trim() === ''){
      setError({programName: 'Program name is required'});
      validate = false;
  }else if(programName.length > 100){
    setError({programName: 'Program name is too large max limit is 100 characters'});
    validate = false
  }

  if(category === ''){
      setError({category: 'Category is required'});
      validate = false
  }

  if(subCategory === ''){
      setError({subCategory: 'Sub category is required'});
      validate = false
  }


//   if(programType === ''){
//       setError({programType: 'Program type is required'});
//       validate = false
//   }


//   if(trim(programIntroduction) === ''){
//       setError({programIntroduction: 'Program introduction is required'});
//       validate = false
//   }



  if(removeHtmlTags(aboutProgram) === '' || removeHtmlTags(aboutProgram).trim() === ''){
      setError({aboutProgram: 'About program is required'});
      validate = false
  }

  if(removeHtmlTags(disclaimer) === '' || removeHtmlTags(disclaimer).trim() === ''){
      setError({disclaimer: 'Disclaimer is required'});
      validate = false
  }

  if(byDates !== 1){
    if(trim(registerCloseDate) === ''){
        setError({registerOpenDate: 'Register close date is required'});
        validate = false
    }

    if(registerCloseDate === ''){
        setError({registerCloseDate: 'Date standard registration is required'});
        validate = false
    }

    if(endDate === ''){
        setError({endDate: 'Start end date is required'});
        validate = false
    }

    if(noOfSlots === ''){
        setError({noOfSlots: 'Number of slots is required'});
        validate = false
    }
  }else{
        let _additionalDates = [...additionalDates];
        let _res = _additionalDates.map(item => {
            return {
                ...item,
                endDateErr: (!item.startDate || !item.endDate) ? 'Date is required' : '', 
            }
        })

        setAdditionalDates(_res);

        let res_err = _res.filter(item => item.endDateErr);

        if(res_err.length){
            validate = false
        }



  }
  

  if(gender === ''){
      setError({gender: 'Gender is required'});
      validate = false
  }

  if(expLevel === ''){
      setError({expLevel: 'Experience level is required'});
      validate = false
  }

  if(minAge === ''){
      setError({minAge: 'Min age is required'});
      validate = false
  }

  if(maxAge === ''){
      setError({maxAge: 'Max age is required'});
      validate = false
  }

  if(grade === ''){
      setError({grade: 'Grade is required'});
      validate = false
  }

  
  if(!isOnline){
      if(location === ''){
          setError({location: 'Location is required'});
          validate = false
      }
      
      if(addressLine1 === ''){
          setError({addressLine1: 'Street address is required'});
          validate = false
      }

      if(city === ''){
          setError({city: 'City is required'});
          validate = false
      }

      if(state === ''){
          setError({state: 'State is required'});
          validate = false
      }

      if(zipCode){
        if(!checkStringContainsNumbers(zipCode)){
            setError({zipCode: 'Zipcode is invalid'});
            validate = false
          }else if(validateZipCode(zipCode)){
            setError({zipCode: 'Zipcode is invalid'});
            validate = false
          }
      }
  }

  

  if(tshirt === '' || tshirt === null || tshirt === 'undefined'){
      setError({tshirt: 'T-shirt is required'});
      validate = false
  }

if(equipment === '' || equipment.trim() === ''){
      setError({equipment: 'Equipment is required'});
      validate = false
  }


  if(facility === ''){
      setError({facility: 'Facility is required'});
      validate = false
  }


    if(scholarships === '' || scholarships === null || scholarships === 'undefined'){
      setError({scholarships: 'Scholarships is required'});
      validate = false
    }

    if(scholarshipsUrl){
    
       if(!isURL(scholarshipsUrl)){
        setError({scholarshipsUrl: 'Scholarships url is not valid'});
        validate = false
       }else if(!scholarshipsUrl.startsWith('http')){
        setError({scholarshipsUrl: 'Scholarships url is not valid please add http:// or https:// Before your URL'});
        validate = false
       }
    }


    if(travel === '' || travel === null || travel === 'undefined'){
      setError({travel: 'Travel Info is required'});
      validate = false
     
  }

  if(travel === 1){
    if(travelInfo === '' || travelInfo.trim() === ''){
          setError({travelInfo: 'Travel Info is required'});
          validate = false
    }
  }



  if(checkInTime === ''){
      setError({checkInTime: 'Check in time is required'});
      validate = false
  }

  if(checkOutTime === ''){
      setError({checkOutTime: 'Check out time is required'});
      validate = false
  }


  return validate;
}


useEffect(() => {
  setErrorArr(Object.values(error).filter(i => i !== ''))
}, [error])



const handleSubmit = e => {
  e.preventDefault();

  

  if(handleValidate()){
    setBtnLoder(true);
      let {
          programName,
          programCode,
          noOfSlots,
          registerNonRefundFee,
          earlyBirdPrice,
          registerOpenDate,
          registerCloseDate,
          registrationFee,
          commuterPrice,
          dateLateRegisterOpen,
          dateLateRegisterClosed,
          lateRegistrationFee,
          category,
          subCategory,
          addressLine1,
          city,
          state,
          zipCode,
          gender,
          programType,
          minAge,
          maxAge,
          grade,
          expLevel,
          startDate,
          endDate,
          checkInTime,
          checkOutTime,
          attendanceHour,
          meals,
          tshirt,
          equipment,
          facility,
          travel,
          travelInfo,
          scholarships,
          discount,
          impNotes,
          question1,
          question2,
          question3,
          programIntroduction,
          aboutProgram,
          programInstruction,
          disclaimer,
          lat,
          long,
          scholarshipsUrl,
          isfeatured,
          isprivate,
          dateEarlyRegisterOpen,
          dateEarlyRegisterClosed,
          discountType,
          isOnline,
          tax,
          earlyConvenienceFee,
          convenienceFee,
          lateConvenienceFee,
          counselors,
          siblingDiscount,
          siblingDiscountType,
          multiProgramDiscount,
          multiProgramDiscountType,
          programTimezone
      } = userInput;



      let params = new FormData();
        params.append('programId', props.dProgramId);
        params.append('campId', campId);
        params.append('programName', programName);
        params.append('programCode', programCode);
        params.append('registerNonRefundFee', (registerNonRefundFee || registerNonRefundFee === 0) ? registerNonRefundFee : '');
        params.append('dateEarlyRegisterOpen', dateEarlyRegisterClosed ? ((dateEarlyRegisterOpen === 'null' || dateEarlyRegisterOpen === '' || dateEarlyRegisterOpen === 'NaN') ? '' : dateEarlyRegisterOpen) : '') ;
        params.append('dateEarlyRegisterClosed', dateEarlyRegisterOpen === '' ? '' : (dateEarlyRegisterClosed === 'null' || dateEarlyRegisterClosed === '' || dateEarlyRegisterClosed === 'NaN') ? '' : dateEarlyRegisterClosed);
        params.append('earlyBirdPrice', (earlyBirdPrice === '' || earlyBirdPrice === 'NaN' || earlyBirdPrice === null) ? '' : earlyBirdPrice);
        params.append('registerOpenDate', registerOpenDate);
        params.append('registerCloseDate', registerCloseDate);
        params.append('registrationFee', registrationFee);
        params.append('commuterPrice', commuterPrice || 0);
        params.append('dateLateRegisterOpen', dateLateRegisterClosed ? ((dateLateRegisterOpen === 'null' || dateLateRegisterOpen === '' || dateLateRegisterOpen === 'NaN') ? '' : dateLateRegisterOpen) : '');
        params.append('dateLateRegisterClosed', (dateLateRegisterClosed === '' || dateLateRegisterClosed === '' || dateLateRegisterClosed === 'NaN') ? '' : dateLateRegisterClosed);
        params.append('lateRegistrationFee', dateLateRegisterOpen === '' ? '' : (lateRegistrationFee === '' || lateRegistrationFee === 'NaN' || lateRegistrationFee === null) ? '' : parseInt(lateRegistrationFee));
        params.append('category', category);
        params.append('subCategory', JSON.stringify(subCategory));
        params.append('addressLine1', addressLine1);
        params.append('city', city);
        params.append('state', state);
        params.append('zipCode', zipCode);
        params.append('gender', gender);
        params.append('campCode', props.userProfile.campCode);
        params.append('programType', programType);
        params.append('minAge', minAge);
        params.append('maxAge', maxAge);
        params.append('grade', JSON.stringify(grade));
        params.append('expLevel', expLevel);
        params.append('checkInTime', checkInTime);
        params.append('checkOutTime', checkOutTime);
        params.append('attendanceHour', attendanceHour);
        params.append('meals', meals ? JSON.stringify(meals) : '[]');
        params.append('tshirt', tshirt);
        params.append('equipment', equipment);
        params.append('facility', parseInt(facility));
        params.append('travel', parseInt(travel));
        params.append('travelInfo', addBr(travelInfo));
        params.append('scholarships', scholarships);
        params.append('discount', discount ? parseInt(discount) : 0);
        params.append('impNotes', impNotes ? addBr(impNotes) : '');
        params.append('questions', JSON.stringify(cmQuestions));
        params.append('programIntroduction', programIntroduction ? addBr(programIntroduction) : '');
        params.append('aboutProgram', addBr(aboutProgram));
        params.append('programInstruction', programInstruction ? addBr(programInstruction) : '');
        params.append('disclaimer', addBr(disclaimer));
        params.append('lat', lat);
        params.append('long', long);
        params.append('scholarshipsUrl', scholarshipsUrl);
        params.append('isfeatured', isfeatured ? parseInt(isfeatured) : 0);
        params.append('isprivate', isprivate ? parseInt(isprivate) : 0);
        params.append('discountType', discountType);
        params.append('isOnline', isOnline);
        params.append('tax', tax ? tax : 0);
        params.append('earlyConvenienceFee', earlyConvenienceFee);
        params.append('convenienceFee', convenienceFee ? convenienceFee : '');
        params.append('lateConvenienceFee', lateConvenienceFee);
        params.append('siblingDiscount', siblingDiscount ? siblingDiscount : 0);
        params.append('siblingDiscountType', siblingDiscountType ? siblingDiscountType : 1);
        params.append('multiProgramDiscount', multiProgramDiscount);
        params.append('multiProgramDiscountType', multiProgramDiscountType);
        params.append('programTimezone', programTimezone);
        if(counselors.length){
            params.append('counselors', JSON.stringify(counselors));
        }else{
            params.append('counselors', JSON.stringify([]));
        }
        if(district.value){
            //params.append('districtId', district.value);
        }


        params.append('additionalDates', JSON.stringify(additionalDates));
        params.append('byDates', byDates);

        if(byDates === 1){
            let all_additionalDates = []
            for (let i = 0; i < additionalDates.length; i++) {
                all_additionalDates.push(additionalDates[i].startDate);
                all_additionalDates.push(additionalDates[i].endDate);
            }
            let {min, max} = findMaxMin(all_additionalDates);
            params.append('startDate', min);
            params.append('endDate', max);
        }else{
            params.append('startDate', startDate);
            params.append('endDate', endDate);
            params.append('noOfSlots', parseInt(noOfSlots));
        }

       


      if(props.dProgramId) {
          
        dProgramEdit(params).then(status => {
            setBtnLoder(false);
              if(status){
                  closeModal();
                  props.childAction();
                  notification.open({
                      message: 'Program',
                      description: `Program updated successfully.`
                  });

              }
          })
      }else{
        dProgramCreate(params).then(status => {
            setBtnLoder(false);
              if(status){
                  closeModal();
                  props.childAction();
                  notification.open({
                      message: 'Program',
                      description: 'Program created successfully.'
                  });

              }
          })
      }
  }
}



useEffect(() => {
  if(props.dProgramId){
    let query = `?programId=${props.dProgramId}`
    dProgramDetail(query).then(res => {
      let { status, data } = resHandle(res);
      if(status){
        setUserInput({
          programId: data.result._id,
          programName: data.result.programName,
          programCode: data.result.programCode,
          noOfSlots: data.result.noOfSlots,
          registerNonRefundFee:  (data.result.registerNonRefundFee || data.result.registerNonRefundFee === 0) ? data.result.registerNonRefundFee : '',
          dateEarlyRegisterOpen: data.result.dateEarlyRegisterOpen ? data.result.dateEarlyRegisterOpen : '',
          dateEarlyRegisterClosed: data.result.dateEarlyRegisterClosed ? data.result.dateEarlyRegisterClosed : '',
          earlyBirdPrice: data.result.earlyBirdPrice ? data.result.earlyBirdPrice : '',
          registerOpenDate: data.result.registerOpenDate,
          registerCloseDate: data.result.registerCloseDate,
          registrationFee: data.result.registrationFee,
          commuterPrice: data.result.commuterPrice,
          dateLateRegisterOpen: data.result.dateLateRegisterOpen ? data.result.dateLateRegisterOpen : '',
          dateLateRegisterClosed: data.result.dateLateRegisterClosed ? data.result.dateLateRegisterClosed : '',
          lateRegistrationFee: data.result.lateRegistrationFee ? data.result.lateRegistrationFee : '',
          category: data.result.categoryName,
          subCategory: data?.result?.subCategory.map(i => i._id),
          addressLine1: data.result.addressLine1,
          city: data.result.city,
          state: data.result.state,
          zipCode: data.result.zipCode,
          gender: data.result.gender,
          programType: data.result.programType,
          minAge: data.result.minAge,
          maxAge: data.result.maxAge,
          grade: data.result.grade,
          expLevel: data.result.expLevel,
          startDate: data.result.startDate,
          endDate: data.result.endDate,
          checkInTime: data.result.checkInTime,
          checkOutTime: data.result.checkOutTime,
          attendanceHour: data.result.attendanceHour,
          meals: data.result.meals,
          tshirt: data.result.tShirt,
          equipment: data.result.equipment,
          facility: data.result.facility,
          travel: data.result.travel,
          travelInfo: data?.result?.travelInfo ? getBr(data.result.travelInfo) : '',
          scholarships: data.result.scholarships,
          scholarshipsUrl: data.result.scholarshipsUrl,
          discount: data.result.discount,
          impNotes: getBr(data.result.impNotes),
          programIntroduction: getBr(data.result.programIntroduction),
          aboutProgram: getBr(data.result.aboutProgram),
          
          programInstruction: getBr(data.result.programInstruction),
          disclaimer: getBr(data.result.disclaimer),
          scholarships: data.result.scholarships,
          lat: data.result.geoLocation ? data.result.geoLocation[1] : '',
          long: data.result.geoLocation ? data.result.geoLocation[0] : '',
          isfeatured: data.result.isfeatured,
          isprivate: data.result.isprivate,
          discountType: data.result.discountType ? data.result.discountType : 1,
          siblingDiscount: data.result.siblingDiscount,
          siblingDiscountType: data.result.siblingDiscountType,
          multiProgramDiscount: data.result.multiProgramDiscount,
          multiProgramDiscountType: data.result.multiProgramDiscountType,
          isOnline: data.result.isOnline,
          tax: data.result.tax,
          counselors: data.result.counselors,
          earlyConvenienceFee: data.result.earlyConvenienceFee ? data.result.earlyConvenienceFee : '',
          convenienceFee: data.result.convenienceFee ? data.result.convenienceFee : '',
          lateConvenienceFee: data.result.lateConvenienceFee ? data.result.lateConvenienceFee : '',
          programTimezone: data.result?.programTimezone ? data.result.programTimezone: userTimezone,
      });


      setByDates(data.result?.byDates === 1 ? 1 : 2);
      setAdditionalDates(data.result?.additionalDates || []);

      setCmQuestions(data?.result?.questions?.length ? data.result.questions.map( ques => { (!ques?.questionId) && (ques.questionId = uuidv4()); return ques}) : [])
      setLocation(`${data.result.addressLine1} ${data.result.city} ${data.result.state} ${data.result.addressLine1}`)


      if(data?.result?.districtId){
        let tempDistrict = districtList.find(i => i._id === data?.result?.districtId);
        setDistrict({...tempDistrict, value: tempDistrict._id, label: tempDistrict.name})
     }

   
    

      }else{
        setUserInput({
            programName: '',
            programCode: '',
            noOfSlots: '',
            registerNonRefundFee: '',
            dateEarlyRegisterOpen: '',
            dateEarlyRegisterClosed: '',
            earlyBirdPrice: '',
            registerOpenDate: '',
            registerCloseDate: '',
            registrationFee: '',
            commuterPrice: 0,
            dateLateRegisterOpen: '',
            dateLateRegisterClosed: '',
            lateRegistrationFee: '',
            category: '',
            subCategory: '',
            addressLine1: '',
            city: '',
            state: '',
            zipCode: '',
            gender: '',
            campId: '',
            programType: '',
            minAge: '',
            maxAge: '',
            grade: '',
            expLevel: '',
            startDate: '',
            endDate: '',
            checkInTime: '',
            checkOutTime: '',
            attendanceHour: '',
            meals: '',
            tshirt: '',
            equipment: '',
            facility: '',
            travel: '',
            travelInfo: '',
            scholarships: '',
            discount: '',
            impNotes: '',
            programIntroduction: '',
            aboutProgram: '',
            programInstruction: '',
            disclaimer: '',
            lat: '',
            long: '',
            scholarshipsUrl: '',
            isfeatured: '',
            isprivate: '',
            discountType: 1,
            isOnline: '',
            tax: '',
            earlyConvenienceFee: '',
            convenienceFee: '',
            lateConvenienceFee: '',
            counselors: []
        })
      }
    })

  
}
}, [props.dProgramId])




const clearDates = () => {

    let _additionalDates = [...additionalDates];
    let clsDate = _additionalDates.map(item => {
        return {
            startDate: '',
            endDate: '',
            standardPrice: 0,
            slots: 0,
            endDateErr: '',
            standardPriceErr: '',
            slotsErr: '' 
        }
    })

    setAdditionalDates(clsDate);

  setUserInput({
      dateEarlyRegisterOpen: '',
      dateEarlyRegisterClosed: '',
      registerOpenDate: '',
      registerCloseDate: '',
      dateLateRegisterOpen: '',
      dateLateRegisterClosed: '',
      startDate: '',
      endDate: ''
  })
}



const handleAddQues = () => {
    let temp = [...cmQuestions];
    let isEmpty = temp[temp.length - 1]?.title === '';

    if(cmQuestions.length === 0){
        temp.push({
            questionId:uuidv4(),
            questionType: '',
            title: '',
            subTitle: '',
            optionVal: '',
            options: [],
            isRequred: 2,
            isOtherOption: 2,
        });
    }else {
        if(!isEmpty){
            temp.push({title: ''});
            setCmQuestionsErr('');
        }else{
            setCmQuestionsErr('last field can not be empty');
        }
    }
    setCmQuestions(temp);//added
    
}


const handleQuestionTypeChange = (e, ind) => {
    let temp = [...cmQuestions];
    temp[ind] = {
        questionId:uuidv4(),
        questionType: e.value,
        title: '',
        subTitle: '',
        optionVal: '',
        options: [],
        isRequred: 2,
        isOtherOption: 2,
    }
    setCmQuestions(temp);
}
const handleDependsOnChange = (e, ind) => {
    let temp = [...cmQuestions];
    const conditionalOn = {
        questionId:e.value, 
    }
    temp[ind] = {
        ...temp[ind],
        conditionalOn: [conditionalOn],
        
    }
    setDependsOnOptions(prev => prev.filter(option => option.value !== e.value));
    setCmQuestions(temp);
}
const handleDependsOnChange2 = (e, ind) => {
    let optionVals = e ? e.map(i => i.value) : '';
    let temp = [...cmQuestions];
    const conditionalOn = {
        ...temp[ind]?.conditionalOn[0],
        optionVals
    }
    temp[ind] = {
        ...temp[ind],
        conditionalOn: [conditionalOn],
        
    }
    setCmQuestions(temp);
}
const handleDependsOnChange3 = (e, ind) => {
    let temp = [...cmQuestions];
    const conditionalOn = {
        ...temp[ind]?.conditionalOn[0],
        optionValOperator:e.value
    }
    temp[ind] = {
        ...temp[ind],
        conditionalOn: [conditionalOn],  
    }
    setCmQuestions(temp);
}


const handleQuestionChange = (e, index) => {
    let temp = [...cmQuestions];
    temp[index].title = e.target.value;
    setCmQuestions(temp);   
    
}
const handleQuestionSubTitleChange = (e, index) => {
    let temp = [...cmQuestions];
    temp[index].subTitle = e.target.value;
    setCmQuestions(temp);   
    
}

const handleAddOption = ind => {
    let temp = [...cmQuestions];

    if(temp[ind].optionVal && temp[ind].optionVal.trim()){

        let isDuplicate = temp[ind].options.filter(i => i.opt === temp[ind].optionVal);
        if(isDuplicate.length){
            temp[ind].optionValErr = 'duplicate value not allowed';
            setCmQuestions(temp);
        }else{
            temp[ind].options.push({opt: temp[ind].optionVal});
            setCmQuestions(temp);
    
            setTimeout(() => {
                let temp = [...cmQuestions];
                temp[ind].optionVal = '';
                setCmQuestions(temp);
            }, 2)
        }        

    }
}

const handleOptionValChange = (e, ind) => {
    let temp = [...cmQuestions];
    temp[ind].optionVal = e.target.value;
    temp[ind].optionValErr = '';
    setCmQuestions(temp); 

}



const handleRemoveQues = index => {
    let temp = [...cmQuestions];
    temp.splice(index, 1)
    setCmQuestions(temp);     
}

const handleIsRequredChnage = i => {
    let temp = [...cmQuestions];
    temp[i].isRequred = temp[i].isRequred === 1 ? 2 : 1
    setCmQuestions(temp);    
}
const handleIsConditionalChange = i => {
    let temp = [...cmQuestions];
    temp[i].conditional = temp[i].conditional === 1 ? 2 : 1;
    temp[i].conditionalOn = [];
    setCmQuestions(temp);    
}


const handleIsOtherOptionChnage = i => {
    let temp = [...cmQuestions];
    temp[i].isOtherOption = temp[i].isOtherOption === 1 ? 2 : 1
    setCmQuestions(temp);    
}


const handleRemoveOption = (i, j) => {
    let temp = [...cmQuestions];
    temp[i].options.splice(j, 1)
    setCmQuestions(temp);     
}

const handleEditerChange = (e, name) => {
    setUserInput({[name]: e});
    setError({[name]: ''});
    setErrorArr('');
}

const handleDiscountChange = (e, ind, cind) => {
    let temp = [...cmQuestions];
    temp[ind].options[cind].discount = e;
    setCmQuestions(temp); 
}

const handleDiscountTypeSelect = (e, ind, cind) => {
    let temp = [...cmQuestions];
    temp[ind].options[cind].discountType = e.value;
    setCmQuestions(temp); 
}

const handleMultiDateChange = (e, i) => {
    let temp = [...additionalDates];
    temp[i].startDate = removeTime2(new Date(e[0]._d));
    temp[i].endDate = removeTime2(new Date(e[1]._d));
    temp[i].endDateErr = '';
    setAdditionalDates(temp);
}


const handleMultiDatePriceChange = (e, i) => {
    let temp = [...additionalDates];
    temp[i].standardPrice = e;
    temp[i].standardPriceErr = '';
    setAdditionalDates(temp);
}

const handleMultiDateSloteChange = (e, i) => {
    let temp = [...additionalDates];
    temp[i].slots = e;
    temp[i].slotsErr = '';
    setAdditionalDates(temp);
}

const handleCommuterChange = (e, i) => {
    let temp = [...additionalDates];
    temp[i].commuterPrice = e;
    setAdditionalDates(temp);
}

const handleRemoveDate = index => {
    let temp = [...additionalDates];
    temp.splice(index, 1)
    setAdditionalDates(temp);     
}

const handleAddDates = () => {
    let temp = [...additionalDates];
    temp.push({
        startDate: '',
        endDate: '',
        standardPrice: 0,
        slots: 0,
        endDateErr: '',
        standardPriceErr: '',
        slotsErr: '' 
    })

    setAdditionalDates(temp)
}
 
 
 const getOptionsValues = (qId) => {
    if(!qId) return [];
    //only valid for checkbox and dropdowns and special cases of multiple choice questions 
    const _selectedConditionalQues = cmQuestions.filter(ques => ques.questionId === qId)[0];
    if(_selectedConditionalQues?.questionType === 1)  return [];
    const newOptions = _selectedConditionalQues?.options.map( opts => ({label:opts.opt, value:opts.opt}))
    return newOptions;
 };

    return (
        <Modal
            size="lg"
            show={props.dProgramModal}
            animation={false}
            dialogClassName="add_prog_modal"
            centered>
      <Modal.Header>
        <Modal.Title> {userInput.programId ? 'Edit' : 'Add'} Program</Modal.Title>
        <span className="modal_close" onClick={closeModal}><i className="icon-cross" /></span>
      </Modal.Header>

      <Modal.Body>
      <form className="input_space_form" onSubmit={handleSubmit}>
            <div className="row">

                <div className="col-12">
                    <h3 className="cm_form_title">Description</h3>
                </div>
                
                

                <div className="col-md-6 form-group">
                    <label>Camp Program*</label>
                    <CustomText
                        name="programName"
                        value={userInput.programName}
                        change={handleChange}
                        pHolder="Camp program name"
                        error={error.programName} />
                </div>
                

                <div className="col-md-6 form-group">
                    <label>Program Code (Auto generated)</label>
                    <input
                        type="text"
                        value={userInput.programCode}
                        readOnly
                        className="form-control" />
                </div>
                

                 <div className="col-md-6 form-group">
                    <label>Program Category*</label>
                    <CustomSelect
                        pHolder="Select a category"
                        change={handleCategory}
                        value={props.category.map(el => el._id === userInput.category && ({value: el._id, label: el.name}))}
                        error={error.category}
                        options={props.category.map(item => ({value: item._id, label: item.name}))} />
                </div>
                

                <div className="col-md-6 form-group">
                    <label>Program Sub Category*</label>
                    <CustomSelect
                        closeMenuOnSelect={false}
                        isMulti
                        error={error.subCategory}
                        pHolder="Select sub category"
                        change={e => handleMultiSelect(e, 'subCategory')}
                        value={userInput.subCategory && props.subcategories.filter(el => userInput.subCategory.find(e => el._id === e)).map(i => ({value: i._id, label: i.name}))}
                        options={selectedSubCat.map(item => ({value: item._id, label: item.name}))} />
                </div>
                

                <div className="col-md-6 form-group">
                    <label>Program Type*</label>
                    <CustomSelect
                        error={error.programType}
                        pHolder="Select program type"
                        change={e => handleSelect(e, 'programType')}
                        value={programTypes.map(el => el.value === userInput.programType && el)}
                        options={programTypes} />
                </div>


                <div className="col-md-6 form-group">
                    <label>Personnel</label>
                    <CustomSelect
                        isMulti
                        error={error.counselors}
                        pHolder="Select Staff personnel"
                        change={e => handleMultiSelect(e, 'counselors')}
                        value={userInput.counselors && counselorList.filter(el => userInput.counselors.find(e => el._id === e)).map(i => ({value: i._id, label: i.name}))}
                        options={counselorList.map(item => ({value: item._id, label: item.name}))} />
                </div>
                

                <div className="col-md-6 form-group my_editer">
                    <label>Program Introduction</label>

                    <ReactQuill
                        className={`${error.programIntroduction ? 'is-invalid' : ''}`}
                        theme="snow"
                        value={userInput.programIntroduction}
                        onChange={e => handleEditerChange(e, 'programIntroduction')} />
                    {error.programIntroduction ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.programIntroduction}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}

                </div>
                

                <div className="col-md-6 form-group my_editer">
                    <label>About Program*</label>

                    <ReactQuill
                        className={`${error.aboutProgram ? 'is-invalid' : ''}`}
                        theme="snow"
                        value={userInput.aboutProgram}
                        onChange={e => handleEditerChange(e, 'aboutProgram')} />
                    {error.aboutProgram ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.aboutProgram}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
                </div>
                

                <div className="col-md-6 form-group my_editer">
                    <label>Disclaimer Statement*</label>

                    <ReactQuill
                        className={`${error.disclaimer ? 'is-invalid' : ''}`}
                        theme="snow"
                        value={userInput.disclaimer}
                        onChange={e => handleEditerChange(e, 'disclaimer')} />
                    {error.disclaimer ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.disclaimer}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
                    
                </div>
                

                <div className="col-md-6 form-group my_editer">
                    <label>Program Instructions</label>

                    <ReactQuill
                        className={`${error.programInstruction ? 'is-invalid' : ''}`}
                        theme="snow"
                        value={userInput.programInstruction}
                        onChange={e => handleEditerChange(e, 'programInstruction')} />
                    {error.programInstruction ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.programInstruction}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
                    
                </div>
                

                <div className="col-md-12 form-group my_editer">
                    <label>Important Information Notes</label>

                    <ReactQuill
                        className={`${error.impNotes ? 'is-invalid' : ''}`}
                        theme="snow"
                        value={userInput.impNotes}
                        onChange={e => handleEditerChange(e, 'impNotes')} />
                    {error.impNotes ? <OverlayTrigger placement="left" overlay={<Tooltip>{error.impNotes}</Tooltip>}><span className="error_tootltip" /></OverlayTrigger> : ''}
                    
                </div>


                <div className="col-12"><h3 className="cm_form_title">Applicable Groups</h3></div>
                

                <div className="col-md-4 form-group" style={{zIndex: '99999'}}>
                    <label>Gender*</label>
                    <CustomSelect
                        pHolder="Select a gender"
                        change={e => handleSelect(e, 'gender')}
                        error={error.gender}
                        value={(userInput.gender === '') ? '' : genders3.map(el => el.value === userInput.gender && el)}
                        options={genders3} />
                </div>
                

                <div className="col-md-4 form-group" style={{zIndex: '99999'}}>
                    <label>Experience Level*</label>
                    <CustomSelect
                        pHolder="Experience Level"
                        change={e => handleSelect(e, 'expLevel')}
                        error={error.expLevel}
                        value={(userInput.expLevel === '') ? '' : experiences.map(el => el.value === userInput.expLevel && el)}
                        options={experiences} />
                </div>
                

                <div className="col-md-3 form-group">
                    <label>Age Group (Min)*</label>
                    <CustomNumber
                        change={e => handleNumberChange(e, 'minAge')}
                        value={userInput.minAge}
                        pHolder="Min age"
                        min={0}
                        precision={0}
                        step={1}
                        error={error.minAge}
                        max={99} />
                </div>

                


                <div className="col-md-3 form-group">
                    <label>Age Group (Max)*</label>
                    {userInput.minAge === '' ? <span className='my_tooltip' title='Please fill the above fields'><img src={require('../../assets/img/info.png')} /></span> : ''}
                    <CustomNumber
                        change={e => handleNumberChange(e, 'maxAge')}
                        value={userInput.maxAge}
                        pHolder="Max age"
                        error={error.maxAge}
                        max={100}
                        precision={0}
                        step={1}
                        disabled={userInput.minAge === ''}
                        min={userInput.minAge + 1} />
                </div>
                

                 <div className="col-md-6 form-group z_index_top">
                    <label>Grade*</label>
                    <CustomSelect
                        isMulti
                        pHolder="Select Grade"
                        change={e => handleMultiSelect(e, 'grade')}
                        error={error.grade}
                        value={userInput.grade && userInput.grade.map(item => ({value: item, label: item}))}
                        options={gradeArr.map(item => ({value: item, label: item}))}
                        closeMenuOnSelect={false} />
                </div>
                

                <div className="col-12"><h3 className="cm_form_title">Price</h3></div>

                <div className="col-12 date_option">
                    <p>Program price is based on?</p>
                    <div className="check_box_wrapper me-4">
                        <input
                            onChange={() => setByDates(2)}
                            name="byDates"
                            type="radio"
                            checked={byDates === 2} />
                            <span className="check_box checkbox_dark">
                            <span />
                            Registration Dates
                            </span>
                        
                    </div>

                    <div className="check_box_wrapper">
                        <input
                            onChange={() => setByDates(1)}
                            name="byDates"
                            type="radio"
                            checked={byDates === 1} />
                            <span className="check_box checkbox_dark">
                            <span />
                            Program Dates
                            </span>
                        
                    </div>
                 
                </div>
                
                <div className="col-md-6 form-group">
                    <label>Non-refundable registration fee</label>
                    <CustomNumber
                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        change={e => handleNumberChange(e, 'registerNonRefundFee')}
                        value={userInput.registerNonRefundFee}
                        min={0}
                        precision={userInput.registerNonRefundFee ? 2 : 0}
                        pHolder="Non-refundable registration fee"  />
                </div>

                {byDates !== 1 ? <div className="col-md-6 form-group">
                    <label>No. of Available slots*</label>
                    <CustomNumber
                        change={e => handleNumberChange(e, 'noOfSlots')}
                        value={userInput.noOfSlots}
                        pHolder="No. of Available slots"
                        min={0}
                        precision={0}
                        step={1}
                        error={error.noOfSlots} />
                </div> : ''}

                <div className="col-12 mb-4">
                    <span onClick={clearDates} className="btn btn-primary btn-sm">Clear Dates</span>
                </div>

                {
                    byDates !== 1 ? <Fragment>
                                    <div className={`col-md-${props.program ? '3' : '4'} form-group`}>
                                        <label>Date Early Bird Registration Start</label>
                                        <CustomDatePicker
                                            disabledDate={d => userInput.registerOpenDate ? d.isAfter(dateFormat(userInput.registerOpenDate + 86400000)) : d.isSameOrBefore(dateFormat(new Date().getTime()))}
                                            value={userInput.dateEarlyRegisterOpen ? moment(dateFormat(parseInt(userInput.dateEarlyRegisterOpen))): ''}
                                            change={e => handleDateChange(e, 'dateEarlyRegisterOpen')} />
                                    </div>


                                    <div className={`col-md-${props.program ? '3' : '4'} form-group`}>
                                        <label>Date Early Bird Registration End</label>
                                        <CustomDatePicker
                                            disabledDate={d => d.isSameOrBefore(dateFormat(userInput.dateEarlyRegisterOpen + 86400000))}
                                            disabled={userInput.dateEarlyRegisterOpen ? false : true}
                                            value={userInput.dateEarlyRegisterClosed ? moment(dateFormat(parseInt(userInput.dateEarlyRegisterClosed))): ''}
                                            change={e => handleDateChange(e, 'dateEarlyRegisterClosed')} />
                                    </div>


                                    <div className={`col-md-${props.program ? '3' : '4'} form-group`}>
                                        <label>Early Bird Price</label>
                                        <CustomNumber
                                            precision={userInput.earlyBirdPrice ? 2 : 0}
                                            formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            change={e => handleNumberChange(e, 'earlyBirdPrice')}
                                            value={userInput.earlyBirdPrice}
                                            disabled={userInput.dateEarlyRegisterOpen ? false : true}
                                            min={0}
                                            pHolder="Early Bird Price" />
                                    </div>

                                    {
                                        props.program ? <div className="col-md-3 form-group">
                                                            <label>Early Convenience Fee</label>
                                                            <CustomNumber
                                                                min={0}
                                                                precision={userInput.earlyConvenienceFee ? 2 : 0}
                                                                formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                change={e => handleNumberChange(e, 'earlyConvenienceFee')}
                                                                value={userInput.earlyConvenienceFee}
                                                                pHolder="Early Convenience Fee" />
                                                        </div> : ''
                                    }


                                    <div className="col-12" />


                                    <div className={`col-md-${props.program ? '3' : '4'} form-group`}>
                                    <label>Date Standard Registration Start*</label>
                                    <CustomDatePicker
                                        disabledDate={d => (userInput.dateEarlyRegisterOpen !== '' && userInput.dateEarlyRegisterClosed) ? d.isSameOrBefore(dateFormat(userInput.dateEarlyRegisterClosed + 86400000)) : d.isSameOrBefore(dateFormat(new Date().getTime()))}
                                        value={userInput.registerOpenDate ? moment(dateFormat(parseInt(userInput.registerOpenDate))): ''}
                                        change={e => handleDateChange(e, 'registerOpenDate')} />
                                    </div>

                                    <div className={`col-md-${props.program ? '3' : '4'} form-group`}>
                                    <label>Date Standard Registration End*</label>
                                    <CustomDatePicker
                                        disabledDate={d => d.isSameOrBefore(dateFormat(userInput.registerOpenDate + 86400000))}
                                        disabled={userInput.registerOpenDate ? false : true}
                                        value={userInput.registerCloseDate ? moment(dateFormat(parseInt(userInput.registerCloseDate))): ''}
                                        change={e => handleDateChange(e, 'registerCloseDate')} />
                                    </div>


                                    <div className={`col-md-${props.program ? '3' : '4'} form-group`}>
                                    <label>Standard Price*</label>
                                    <CustomNumber
                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        change={e => handleNumberChange(e, 'registrationFee')}
                                        value={userInput.registrationFee}
                                        pHolder="Standard Price"
                                        precision={userInput.registrationFee ? 2 : 0}
                                        min={0}
                                        error={error.registrationFee}/>
                                    </div>

                                    {props.program ? <div className="col-md-3 form-group">
                                                    <label>Convenience Fee</label>
                                                    <CustomNumber
                                                        min={0}
                                                        precision={userInput.convenienceFee ? 2 : 0}
                                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        change={e => handleNumberChange(e, 'convenienceFee')}
                                                        value={userInput.convenienceFee}
                                                        pHolder="Convenience Fee" />
                                                </div> : ''
                                    }

                                    <div className="col-12" />

                                    <div className={`col-md-${props.program ? '3' : '4'} form-group`}>
                                    <label>Date Late Registration Start</label>
                                    <CustomDatePicker
                                        disabledDate={d => d.isSameOrBefore(dateFormat(userInput.registerCloseDate + 86400000))}
                                        value={userInput.dateLateRegisterOpen ? moment(dateFormat(parseInt(userInput.dateLateRegisterOpen))): ''}
                                        change={e => handleDateChange(e, 'dateLateRegisterOpen')} />
                                    </div>


                                    <div className={`col-md-${props.program ? '3' : '4'} form-group`}>
                                    <label>Date Late Registration End</label>
                                    <CustomDatePicker
                                        disabledDate={d => d.isSameOrBefore(dateFormat(userInput.dateLateRegisterOpen + 86400000))}
                                        disabled={userInput.dateLateRegisterOpen ? false : true}
                                        value={userInput.dateLateRegisterClosed ? moment(dateFormat(parseInt(userInput.dateLateRegisterClosed))): ''}
                                        change={e => handleDateChange(e, 'dateLateRegisterClosed')} />
                                    </div>


                                    <div className={`col-md-${props.program ? '3' : '4'} form-group`}>
                                    <label>Late Registration Price</label>
                                    <CustomNumber
                                        disabled={userInput.dateLateRegisterOpen ? false : true}
                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        change={e => handleNumberChange(e, 'lateRegistrationFee')}
                                        value={userInput.lateRegistrationFee}
                                        min={0}
                                        precision={userInput.lateRegistrationFee ? 2 : 0}
                                        pHolder="Late Registration Price"
                                    />
                                    </div>


                                    {props.program ? <div className="col-md-3 form-group">
                                                    <label>Late Convenience Fee</label>
                                                    <CustomNumber
                                                        precision={userInput.lateConvenienceFee ? 2 : 0}
                                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        change={e => handleNumberChange(e, 'lateConvenienceFee')}
                                                        value={userInput.lateConvenienceFee}
                                                        min={0}
                                                        pHolder="Late Convenience Fee" />
                                                </div> : ''
                                    }


                                <div className={`col-md-4 form-group`}>
                                    <label>Commuter Price*</label>
                                    <CustomNumber
                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        change={e => handleNumberChange(e, 'commuterPrice')}
                                        value={userInput.commuterPrice}
                                        pHolder="Commuter Price"
                                        precision={userInput.commuterPrice ? 2 : 0}
                                        min={0}
                                        error={''}/>
                                    </div>
                    </Fragment> : <Fragment>

                                    {additionalDates.map((aDate, i) => (
                                        <Fragment key={i}>
                                            <div className="col-md-3 form-group">
                                                <label>Start Date* <span className="end_date_label2">End Date*</span></label>
                                                <CustomDateRange
                                                    error={aDate.endDateErr}
                                                    value={aDate.endDate ? [
                                                        moment(dateFormat(parseInt(aDate.startDate))),
                                                        moment(dateFormat(parseInt(aDate.endDate)))
                                                    ] : ''}
                                                    change={e => handleMultiDateChange(e, i)} />
                                            </div>

                                            <div className='col-md-8'>
                                                <div className='row'>
                                                    <div className="col-md-4 form-group">
                                                        <label>Standard Price*</label>
                                                        <CustomNumber
                                                            formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            change={e => handleMultiDatePriceChange(e, i)}
                                                            value={aDate.standardPrice}
                                                            pHolder="Standard Price"
                                                            min={0}
                                                            precision={userInput.standardPrice ? 2 : 0}
                                                            error={aDate.standardPriceErr}/>
                                                    </div>


                                                    <div className="col-md-4 form-group">
                                                        <label>Available slots*</label>
                                                        <CustomNumber
                                                            change={e => handleMultiDateSloteChange(e, i)}
                                                            value={aDate.slots}
                                                            pHolder="No. of Available slots"
                                                            min={0}
                                                            precision={0}
                                                            step={1}
                                                            error={aDate.slotsErr} />
                                                    </div>

                                                    <div className="col-md-4 form-group">
                                                        <label>Commuter Price</label>
                                                        <CustomNumber
                                                            formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            change={e => handleCommuterChange(e, i)}
                                                            value={aDate.commuterPrice}
                                                            pHolder="Commuter Price"
                                                            min={0}
                                                            error={''} />
                                                    </div>
                                                </div>
                                            </div>


                                            

                                            

                                            <div className='col-md-1 remove_date'>
                                            <i className="icon-close" onClick={() => handleRemoveDate(i)} />
                                            </div>


                                        </Fragment>
                                    ))}


                                    <div className='col-md-12 mb-4'>
                                        <div className="add_question_btn ms-0" onClick={handleAddDates}>
                                            <i className="icon-plus" /><small>Add Dates</small>
                                        </div>
                                    </div>
                    </Fragment>
                }


                

                <div className="col-12"><h3 className="cm_form_title">Location</h3></div>
                

                <div className="col-12">
                    <p>
                        <div className="check_box_wrapper">
                            <input
                                onChange={handleCheckboxChange}
                                type="checkbox"
                                checked={userInput.isOnline} />
                                <span className="check_box checkbox_dark">
                                <span />
                                Online Program?
                                </span>
                           
                        </div></p>
                </div>


                {
                    userInput.isOnline ? '' : <Fragment>

                <div className="col-md-12 form-group">
                    <label>Location*</label>
                    <PlacesAutocomplete
                        value={location}
                        onChange={handleLocationChange}
                        onSelect={handleLocationSelect}>
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <input
                                className={`form-control ${error.location ? 'is-invalid' : ''}`}
                            {...getInputProps({
                                placeholder: 'Enter location'
                            })}
                            />
                            <div className="cm_locations">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                    return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                        className,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                    );
                                })}
                            </div>
                        </div>
                        )}
                    </PlacesAutocomplete>
                    <InputError error={error.location} />
                </div>
                <div className="col-md-6 form-group">
                <label>Program Timezone*</label>
                <Select
                    options={Timezones}
                    value={Timezones.find((tz) => tz.value === userInput.programTimezone)}
                    onChange={handleProgramTimeZoneChange}
                    placeholder="Select a program timezone"
                />
                </div>

                <div className="col-md-6 form-group">
                    <label>Street Address*</label>
                    <CustomText
                        name="addressLine1"
                        value={userInput.addressLine1}
                        change={handleChange}
                        pHolder="Street Address"
                        error={error.addressLine1} />
                </div>


                <div className="col-md-6 form-group">
                    <label>City*</label>
                    <CustomText
                        name="city"
                        value={userInput.city}
                        change={handleChange}
                        pHolder="City"
                        error={error.city} />
                </div>


                {/* <div className="col-md-6 form-group">
                    <label>State*</label>
                    <CustomText
                        name="state"
                        value={userInput.state}
                        change={handleChange}
                        pHolder="State"
                        error={error.state} />
                </div> */}

                <div className="col-md-6 form-group">
                    <label>State*</label>
                    <select
                        name="state"
                        value={userInput.state}
                        defaultValue={userInput.state}
                        onChange={handleChange}
                        placeholder='State'
                        className={`form-control ${error.state ? 'is-invalid' : ''}`}>
                    
                        <option value="">Select State*</option>   
                            {provinceList.map((item, i  )=> <option key={item.value + i} value={item.value}>{item.value} - {item.label}</option>)}
                            {getOtherStateValue(userInput.state)}
                    </select>

                    <InputError error={error.state} />
                </div>


                <div className="col-md-6 form-group">
                    <label>Zip Code</label>
                    <CustomText
                        name="zipCode"
                        value={userInput.zipCode}
                        change={handleChange}
                        error={error.zipCode}
                        pHolder="Zip Code" />
                </div>

                    </Fragment>
                }



                <div className="col-md-6 form-group">
                    <label>Tax</label>
                    <CustomNumber
                        change={e => handleNumberChange(e, 'tax')}
                        value={userInput.tax}
                        min={0}
                        precision={userInput.tax ? 2 : 0}
                        pHolder="State tax"
                        formatter={value => `${value}%`}
                        max={99} />
                </div>
                
                {campId === settings.api.districtCamp ? <div className="col-md-6 form-group">
                    <label>District</label>
                    <CustomSelect
                        pHolder="Select District"
                        change={e => setDistrict(e)}
                        value={district}
                        options={districtList} />
                </div> : ''}
                


                <div className="col-12"><h3 className="cm_form_title">Amenities</h3></div>


                <div className="col-md-6 form-group">
                    <label>Meals</label>
                    <Tooltip placement="right" title="Leave blank if no meals are provided">
                        <i className="icon-information" />
                    </Tooltip>
                    <CustomSelect
                        isMulti
                        pHolder="Meals"
                        change={e => handleMultiSelect(e, 'meals')}
                        value={userInput.meals && userInput.meals.map(item => ({value: item, label: item}))}
                        options={mealsArr.map(item => ({value: item, label: item}))}
                        closeMenuOnSelect={false} />
                </div>


                <div className="col-md-6 form-group">
                            <label>T-shirt*</label>
                    <CustomSelect
                        pHolder="T-shirt"
                        change={e => handleSelect(e, 'tshirt')}
                        value={trueFalse.map(el => el.value === userInput.tshirt && el)}
                        options={trueFalse}
                        error={error.tshirt} />
                </div>


                <div className="col-md-6 form-group">
                    <label>Equipment Needed*</label>
                    <Tooltip placement="right" title="Enter, ‘None’, if no equipment is needed to be brought by camper.">
                        <i className="icon-information" />
                    </Tooltip>
                    <CustomText
                        name="equipment"
                        value={userInput.equipment}
                        change={handleChange}
                        pHolder="Equipment"
                        error={error.equipment} />
                </div>


                <div className="col-md-6 form-group">
                    <label>Facility*</label>
                    <CustomSelect
                        pHolder="Facility"
                        change={e => handleSelect(e, 'facility')}
                        value={facilies.map(el => el.value === userInput.facility && el)}
                        options={facilies}
                        error={error.facility} />
                </div>

                
                <div className="col-12" />


                <div className="col-md-6 form-group">
                    <label>Scholarships*</label>
                    <CustomSelect
                        pHolder="Scholarships"
                        change={e => handleSelect(e, 'scholarships')}
                        value={trueFalse.map(el => el.value === userInput.scholarships && el)}
                        options={trueFalse}
                        error={error.scholarships} />
                </div>


                {
                    (userInput.scholarships === 1) ? (
                        <div className="col-md-6 form-group">
                            <label>Scholarships Url</label>
                            <CustomText
                                name="scholarshipsUrl"
                                type="url"
                                value={userInput.scholarshipsUrl}
                                change={handleChange}
                                pHolder="Scholarships Url"
                                error={error.scholarshipsUrl} />
                        </div>
                    ) : ''
                }


                <div className="col-12" />
                

                <div className="col-md-6 form-group">
                    <label>Travel*</label>
                    <CustomSelect
                        pHolder="Travel"
                        change={e => handleSelect(e, 'travel')}
                        classNamePrefix="cm_select"
                        value={trueFalse.map(el => el.value === userInput.travel && el)}
                        options={trueFalse}
                        error={error.travel} />
                </div>


                {
                    (userInput.travel === 1) ? (
                        <div className="col-md-6 form-group">
                            <label>Travel Info*</label>
                            <CustomTextarea
                                name="travelInfo"
                                value={userInput.travelInfo}
                                change={handleChange}
                                error={error.travelInfo}
                                pHolder="Travel Info" />
                        </div>
                    ) : ''
                }


                <div className="col-12"><h3 className="cm_form_title">Timing</h3></div>
                {byDates !== 1 ? <div className="col-md-6 form-group">
                <label>Start Date* <span className="end_date_label">End Date*</span></label>
                    <CustomDateRange
                        error={error.endDate}
                        disabled={userInput.registerCloseDate === ''}
                        disabledDate={d => userInput.dateEarlyRegisterOpen ? d.isSameOrBefore(dateFormat(userInput.dateEarlyRegisterOpen)) : d.isSameOrBefore(dateFormat(userInput.registerOpenDate))}
                        value={userInput.startDate ? [
                            moment(dateFormat(parseInt(userInput.startDate))),
                            moment(dateFormat(parseInt(userInput.endDate)))
                        ] : ''}
                        change={e => handleDateRangeChange(e, 'startDate', 'endDate')} />
                </div> : '' }

                


                <div className="col-md-6 form-group">
                    <div className="row">

                        <div className="col">
                            
                            <label>Check-In Time*</label>
                            <TimePicker
                                minuteStep={15}
                                use12Hours format="h:mm a"
                                value={userInput.checkInTime ? moment(userInput.checkInTime, 'h:mm a') : ''}
                                onChange={e => handleTimeChange(e, 'checkInTime')}
                                className={`form-control ${error.checkInTime ? 'is-invalid' : ''}`} />
                                <InputError error={error.checkInTime} />
                        </div>


                        <div className="col">
                            <label>Check-Out Time*</label>
                            <TimePicker
                                minuteStep={15}
                                use12Hours format="h:mm a"
                                disabled={userInput.checkInTime ? false : true}
                                value={userInput.checkOutTime ? moment(userInput.checkOutTime, 'h:mm a') : ''}
                                onChange={e => handleTimeChange(e, 'checkOutTime')}
                                className={`form-control ${error.checkOutTime ? 'is-invalid' : ''}`} />
                                <InputError error={error.checkOutTime} />
                        </div>


                    </div>
                </div>

                <div className="col-12" />
                <div className="col-md-4 form-group">
                    <label>Alternate Last Day Check-Out Time</label>
                    <TimePicker
                        minuteStep={15}
                        use12Hours format="h:mm a"
                        value={userInput.attendanceHour ? moment(userInput.attendanceHour, 'h:mm a') : ''}
                        onChange={e => handleTimeChange(e, 'attendanceHour')}
                        className="form-control" />
                </div>


                <div className="col-12"><h3 className="cm_form_title">Extra</h3></div>



                <div className="col-md-6 form-group">
                    <label>Discount type</label>
                    <CustomSelect
                        pHolder="Select a type"
                        change={e => handleSelect(e, 'discountType')}
                        value={amountOpt.map(item => item.value === userInput.discountType && item)}
                        options={amountOpt}/>
                </div>


                <div className="col-md-6 form-group">
                    <label>Discount</label>
                    {userInput.registrationFee === '' ? <span className='my_tooltip' title='Please fill the above fields'><img src={require('../../assets/img/info.png')} /></span> : ''}
                    <CustomNumber
                        disabled={(userInput.registrationFee || calMultiDatesPrices(additionalDates)) ? false : true}
                        change={e => handleNumberChange(e, 'discount')}
                        formatter={value => userInput.discountType === 1 ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : `${value}%`}
                        value={userInput.discount}
                        min={0}
                        precision={userInput.discount ? 2 : 0}
                        pHolder="Discount"
                        max={userInput.discountType === 1 ? Number.MAX_VALUE : 100} />
                </div>



                <div className="col-md-6 form-group">
                    <label>Sibling Discount Type</label>
                    <CustomSelect
                        pHolder="Select a type"
                        change={e => handleSelect(e, 'siblingDiscountType')}
                        value={amountOpt.map(item => item.value === userInput.siblingDiscountType && item)}
                        options={amountOpt}/>
                </div>


                <div className="col-md-6 form-group">
                    <label>Sibling Discount</label>
                    <CustomNumber
                        change={e => handleNumberChange(e, 'siblingDiscount')}
                        formatter={value => (userInput.siblingDiscountType === 1) ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : `${value}%`}
                        value={userInput.siblingDiscount}
                        pHolder="Discount"
                        min={0}
                        precision={userInput.siblingDiscount ? 2 : 0}
                        max={userInput.siblingDiscountType === 1 ? Number.MAX_VALUE : 100} />
                </div>



                <div className="col-md-6 form-group">
                    <label>Multi Program Discount Type</label>
                    <CustomSelect
                        pHolder="Select a type"
                        change={e => handleSelect(e, 'multiProgramDiscountType')}
                        value={amountOpt.map(item => item.value === userInput.multiProgramDiscountType && item)}
                        options={amountOpt}/>
                </div>


                <div className="col-md-6 form-group">
                    <label>Multi Program Discount</label>
                    <CustomNumber
                        change={e => handleNumberChange(e, 'multiProgramDiscount')}
                        formatter={value => (userInput.multiProgramDiscountType === 1) ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : `${value}%`}
                        value={userInput.multiProgramDiscount}
                        pHolder="Discount"
                        min={0}
                        precision={userInput.multiProgramDiscount ? 2 : 0}
                        max={userInput.multiProgramDiscountType === 1 ? Number.MAX_VALUE : 100} />
                </div>


            


                <div className="col-md-6 form-group">
                    <label>Is Private</label>
                    <CustomSelect
                        pHolder="Is Private"
                        change={e => handleSelect(e, 'isprivate')}
                        value={trueFalse2.map(el => el.value === userInput.isprivate && el)}
                        options={trueFalse2} />
                </div>



                <div className="col-12">

                {cmQuestions.map((item, i) => <div className="form-group cm_questions dir_camp_card">
                <i className="icon-close px-1" onClick={() => handleRemoveQues(i)} />
                    <div className='d-flex justify-content-between mb-3'>
                        <label>Question {i + 1}</label>
                        <div className='question_types'>
                        {(item.questionType === 3 || item.questionType === 4) ? <Fragment>
                            <p>Is Other Option ?</p>
                            <div className="cm_swich_wrap">
                                <input type="checkbox"  checked={item.isOtherOption === 1 ? true : false} onChange={e => handleIsOtherOptionChnage(i)} />
                                <span />
                            </div>
                        </Fragment> : ''}

                        {i>0 && 
                            <>
                                <p>Is Conditional ?</p>
                                <div className="cm_swich_wrap">
                                    <input type="checkbox"  checked={item.conditional === 1 ? true : false} onChange={e => handleIsConditionalChange(i)} />
                                    <span />
                                </div>
                            </>
                        }
                        <p>Is Mandatory ?</p>
                        <div className="cm_swich_wrap">
                            <input type="checkbox"  checked={item.isRequred === 1 ? true : false} onChange={e => handleIsRequredChnage(i)} />
                            <span />
                        </div>
                        <CustomSelect
                            pHolder="Select Question Type"
                            value={getQuestionType(cmQuestions[i].questionType)}
                            onChange={e => handleQuestionTypeChange(e, i)}
                            options={questionTypes} />
                        </div>
                    </div>
                        {
                        cmQuestions[i]?.conditional === 1 && 
                        <>
                            <div className="d-flex justify-content-between align-items-center" >
                                <div className="form-group w-100 me-2">
                                    <label>Conditional Question</label>
                                        <CustomSelect
                                            placeholder="Select conditional question"
                                            value={cmQuestions[i]?.conditionalOn[0]?.questionId ? dependsOnOptions.filter( option =>  option.value === cmQuestions[i].conditionalOn[0].questionId):  ''}
                                            onChange={e => handleDependsOnChange(e, i)}
                                            options={dependsOnOptions.filter(option => option.value !== cmQuestions[i]?.questionId)}
                             
                                        />
                                </div>
                            {cmQuestions[i].conditionalOn[0]?.questionId  &&
                            <>
                                <div className="form-group w-100 me-2">
                                <label> Match Answers </label>
                                    <CustomSelect
                                        isMulti
                                        placeholder="Select all required answers"
                                        change={e => handleDependsOnChange2(e, i)}
                                        value={(cmQuestions[i].conditionalOn[0]?.optionVals?.length >0) ? cmQuestions[i].conditionalOn[0]?.optionVals.map(opt => ({label:opt, value:opt})):  ''}
                                        options={getOptionsValues(cmQuestions[i]?.conditionalOn[0]?.questionId)}
                                        closeMenuOnSelect={false} />
                            </div>
                            <div className="form-group w-100 me-2">
                                <label>Match Type</label>
                                    <CustomSelect
                                        placeholder="Match type"
                                        value={cmQuestions[i].conditionalOn[0]?.questionId ? operationType.filter(option => (option.value === cmQuestions[i]?.conditionalOn[0]?.optionValOperator))[0] : ''}
                                        onChange={e => handleDependsOnChange3(e, i)}
                                        options={operationType}      
                                    />   
                            </div>
                            
                            </> 
                            }
                            </div>
                        </>
                        }
                        
                    {                         
                        item.questionType ? <div className='cm_ques'>
                        <CustomTextarea
                            pHolder="Question*"
                            value={cmQuestions[i].title}
                            change={e => handleQuestionChange(e, i)} />
                        
                        <CustomTextarea
                            pHolder="Sub Title"
                            value={cmQuestions[i].subTitle}
                            change={e => handleQuestionSubTitleChange(e, i)} />

                    </div> : ''
                    }



                    {(item.questionType === 5 || item.questionType === 6) ? <div className='cm_options'>

                    {item.options.length ? (
                        <div className='cm_child_options_wrapper'>
                            <ul>
                                {
                                    item.options.map((itm, j) => (
                                        <li>
                                            <p>{itm.opt}</p>
                                            <i className="icon-close" onClick={() => handleRemoveOption(i, j)} />

                                            <div className="form-group me-3 mb-0">
                                                <CustomSelect
                                                    pHolder="Select a type"
                                                    change={e => handleDiscountTypeSelect(e, i, j)}
                                                    value={amountOpt.map(e => e.value === itm.discountType && e)}
                                                    options={amountOpt}/>
                                            </div>

                                            <div className="form-group me-3 mb-0">
                                                <CustomNumber
                                                    change={e => handleDiscountChange(e, i, j)}
                                                    formatter={value => (itm.discountType === 1) ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : `${value}%`}
                                                    value={itm.discount ? itm.discount : ''}
                                                    pHolder="Discount"
                                                    min={0}
                                                    max={itm.discountType === 1 ? Number.MAX_VALUE : 100} />
                                            </div>
                                            </li>
                                    ))
                                }
                            </ul>

                            
                        </div>
                        ) : ''}

                    <div className='d-flex align-items-center'>
                        <input
                            type='text'
                            className={`form-control ${item.optionValErr ? 'is-invalid' : ''}`}
                            placeholder='Add Option'
                            value={item.optionVal}
                            onChange={e => handleOptionValChange(e, i)} />

                    {item.optionValErr ? <h6>{item.optionValErr}</h6> : ''}
                        
                        <div>
                            <span style={{minWidth: '150px', marginLeft: '20px'}} className='btn btn-outline-primary btn-sm' onClick={() => handleAddOption(i)}><i className="icon-plus" /><small>Add</small></span>  
                        </div>
                    </div>
                    </div> : ''}
                    

                    {(item.questionType === 2 || item.questionType === 3 || item.questionType === 4) ? <div className='cm_options'>
                                                {item.options.length ? <ul>{item.options.map((itm, j) => <li>{itm.opt} <i className="icon-close" onClick={() => handleRemoveOption(i, j)} /></li>)}</ul> : ''}
                                                <div className='d-flex align-items-center'>
                                                    <input
                                                        type='text'
                                                        className={`form-control ${item.optionValErr ? 'is-invalid' : ''}`}
                                                        placeholder='Add Option'
                                                        value={item.optionVal}
                                                        onChange={e => handleOptionValChange(e, i)} />
                                                    
                                                    {item.optionValErr ? <h6>{item.optionValErr}</h6> : ''}
                                                    
                                                    <div>
                                                        <span style={{minWidth: '150px', marginLeft: '20px'}} className='btn btn-outline-primary btn-sm' onClick={() => handleAddOption(i)}><i className="icon-plus" /><small>Add</small></span>  
                                                    </div>
                                                </div>
                                            </div> : ''}


                </div>)}

                <div className="add_question">
                    {cmQuestionsErr ? <span className="cm_error">{cmQuestionsErr}</span> : ''}
                    <div className="add_question_btn" onClick={handleAddQues}>
                        <i className="icon-plus" /><small>Add Question</small>
                    </div>
                </div>

                </div>


            </div>

            <div className="cm_feild_err">{errorArr ? errorArr[0] : ''}</div>

            <div className="text-center mt-4">
                <button disabled={btnLoder} type="submit" className="btn btn-primary ps-5 pe-5 mb-3">Save</button> 
            </div>
         
        </form>
      </Modal.Body>
    </Modal>


  )
}



const mapStateToProps = state => {
  let { dProgramModal, dProgramId } = state.ui;
  let { userProfile } = state.user;
  let { preList } = state.program;
  let { category, subcategory } = preList;
  
  return {
    dProgramModal,
    dProgramId,
    category,
    userProfile,
    subcategories: subcategory,
  };
}

const mapDispatchToProps = dispatch => ({
    dProgramModalAction: params => dispatch(dProgramModalAction(params)),
    childAction: () => dispatch(childAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProgramModal);