import React from "react";
import { Row, Col } from "react-bootstrap";

const CampCardSkeleton = () => {
  return (
    <div className="camp_list p-1 border rounded my-2">
    {/* Top Section */}
    <Row className="align-items-center mb-2">
      <Col xs={2}>
        <div
          className="bg-light"
          style={{ height: "120px", width: "120px" }}
        ></div>
      </Col>
      <Col xs={10}>
        <div className="bg-light mb-2" style={{ height: "20px", width: "60%" }}></div>
        <div className="bg-light mb-2" style={{ height: "15px", width: "40%" }}></div>
        <div className="bg-light mb-2" style={{ height: "15px", width: "50%" }}></div>
      </Col>
    </Row>
  </div>
  );
};

export default CampCardSkeleton;