import React from 'react';

import AdvertisingCarousel from '../carousels/AdvertisingCarousel'
import AdvertisingBanner from '../../assets/img/advertising-banner.png';
import YVRLogo from '../../assets/img/YVR-logo.png';
import HotelImg from '../../assets/img/hotel.png';
import PugiliaImg from '../../assets/img/pugilia.png';
import MenWathingProgrss from '../../assets/img/men-wathing-progrss.png';
import TeamLookingAtLappy from '../../assets/img/team-looking-at-lappy.png';


const Advertising = () => {
    return (
        <div className="body_container advertising">

            <div className="container">
                <div className="section_header">
                    <h2>Advertising</h2>
                </div>

                <div className="cm_banner_wrapper">
                    <img src={AdvertisingBanner} />
                    <div className="cm_banner_text">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <h2>Connect With Families Across<br />The Country</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="cm_wrapper" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex justify-content-between">
                                <h3 className="section_title_left">Featured Products</h3>
                                <h5 className="color_red">See all Products</h5>
                            </div>
                            <p>We offer a variety of digital marketing solutions to create custom, targeted, and innovative campaigns with proven results</p>

                        </div>
                    </div>
                </div>
                <div className="camps_carousel_wrap">
                    <AdvertisingCarousel />
                </div>
            </div>


            <div className="cm_wrapper" >

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex justify-content-between">
                                <h3 className="section_title_left">Featured Success Stories</h3>
                                <h5 className="color_red">See all Success Stories</h5>
                            </div>
                            <p>Learn how to use our digital marketing solutions to reach your objectives</p>
                        </div>
                    </div>

                    <div className="row text-center">
                        <div className="col-md-4">
                            <div className="cm_card  mb-md-2">
                                <img className="img-fluid" src={YVRLogo} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="cm_card mb-md-2">
                                <img className="img-fluid" src={HotelImg} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="cm_card">
                                <img className="img-fluid" src={PugiliaImg} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div className="cm_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex justify-content-between">
                                <h3 className="section_title_left">Featured Insights</h3>
                                <h5 className="color_red">See all Insights</h5>
                            </div>
                            <p>Tap into our research, data, and insights to help inform your digital marketing strategy</p>
                        </div>
                    </div>

                    <div className="row card_botom text-left">
                        <div className="col-md-4">
                            <BlurCard data={loop[0]} />
                        </div>
                        <div className="col-md-4">
                            <BlurCard data={loop[1]} />
                        </div>
                        <div className="col-md-4">
                            <BlurCard data={loop[2]} />
                        </div>
                    </div>
                </div>
            </div>






        </div>
    )
}

export default Advertising;


const BlurCard = (props) => (
    <div className="cm_card">
        <div className="img_wrap text-center">
            <img className="img-fluid" src={props.data.img} />
            <div className="text_wrapper mb-3 ps-3">
                <h6 className="title" >{props.data.title}</h6>
                <span className="white" ><small>{props.data.body}</small></span>
            </div>
        </div>
    </div>
)

const loop = [
    {
        index: 1,
        title: "Research",
        body: "Page Under Construction",
        img: MenWathingProgrss
    },

    {
        index: 2,
        title: "Research",
        body: "Page Under Construction",
        img: MenWathingProgrss
    },

    {
        index: 3,
        title: "Research",
        body: "Page Under Construction",
        img: TeamLookingAtLappy
    }

]
