import React from 'react'
const SubProductInfo = ({subItem}) => {
    
  return (
    <>
        <div className="d-flex flex-column flex-sm-row flex-wrap align-content-start align-items-stretch">
                <div className='product_card p-3 me-3' >
                    <div style={{ width: '120px'}}>
                        <h5>{subItem.title}</h5>
                    </div>
                    {
                        subItem?.image?.length && <img src={subItem.image[0]} className="img-fluid" style={{ width: '120px', height: "120px" }} />

                    }
                    <div className='product_text'>
                        <div className=''>
                            <div className="form-group mb-0">
                                <label>Size: </label>
                                <b> {subItem.sizes || 'N/A'}</b>
                            </div>

                            <div className={`form-group mb-0`}>
                                <label>Color: </label>
                                <b> {subItem.color || 'N/A'}</b>
                            </div>
                            <div className="form-group mb-0">
                                <label>Item Count: </label>
                                <b> {subItem.count || 0}</b>
                            </div>
                            <div className={`form-group mb-0`}>
                                <label>Price:</label>
                                <b> ${subItem.price || 'N/A'}</b>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </>
  )

}




export default SubProductInfo