import React, { useState, useEffect, useReducer } from 'react';
import { getUserHistroyService } from '../../services/campersServices';
import { ChargeAmountReducer, convertTimeToCurrentTimeZone, initialChargeAmountModalState, resHandle } from '../../helper';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { isOnlySpaces, trimSpace } from '../bookings/bookingHelper';
import ChargeCamperModal from '../reuse/ChargeCamper/ChargeCamperModal';
import { Modal } from 'react-bootstrap';
import PaginatedComponent from '../common/Pagination/PaginationComponent';


const limit =10
const baseClass = `d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row mb-2 m_box` 



const UserHistory = props => {

    const [list, setList] = useState([]);

    const [modalState, dispatch] = useReducer(ChargeAmountReducer, initialChargeAmountModalState);
    const [chargeModal, setChargeModal] = useState(false)
    const [refundModal, setRefundModal] = useState(false)
    const [refundData, setRefundData] = useState([])
    const[page, setPage] = useState(1);
    const[totalCount, setTotalCount] = useState(0);

    const handleChargeAmountReason = (newReason) => {
        if (isOnlySpaces(newReason)) {
            handleReasonError('Please Enter a valid reason.')
        } else {
            const _reason = trimSpace(newReason)
            dispatch({ type: 'SET_REASON', payload: _reason });
            handleReasonError('')
        }
    };
    const handleReasonError = (error) => {
        dispatch({ type: 'SET_REASON_ERROR', payload: error });
    };
    const handleChargeAmountChange = (amount) => {
        dispatch({ type: 'SET_AMOUNT', payload: amount });
    }
    const handleCamperList = (list) => {
        dispatch({ type: 'SET_CAMPERLIST', payload: list });
    }
    const handleCamperChange = (camper) => {
        dispatch({ type: 'SET_CAMPER_CHANGE', payload: camper });
    }
    const handleMetaDataChange = (b_id, b_no, p_id) => {
        dispatch({ type: 'SET_META_DATA', payload: { b_id, b_no, p_id } });
    }

    const getUserHistroy = () => {
        let query = `?page=${page}&limit=${limit}&userId=${props.match.params.id}&campCode=${props.userProfile.campCode}`;

        getUserHistroyService(query).then(res => {
            let { status, data } = resHandle(res);
            if (status) {
                setList(data.result);
                setTotalCount(data.count)
            }
        })
    }


    useEffect(() => {
        if (props?.match?.params?.id) {
            getUserHistroy();
        }

    }, [props, page])

    const handleChargeCustomer = (campers, bookingNo, bookingId, programId) => {
        handleMetaDataChange(bookingId, bookingNo, programId)
        setChargeModal(true)
        const _camperList = campers.map(camper => ({ label: camper.camperName, value: camper.camperId }))
        handleCamperList(_camperList)
    }

    const handleCloseChargeModal = (type = 1) => {
        dispatch({ type: 'RESET', payload: initialChargeAmountModalState })
        setChargeModal(false)
        if (type === 1) {
            getUserHistroy()
        }
    }

    const handleCloseRefundModal = () => {
        setRefundModal(false)
        setRefundData([])
    }
    const handleOpenRefundModal = (e, refunds) => {
        e.preventDefault();
        setRefundData(refunds)
        setRefundModal(true)
    }



    return (
        <div className="mt-3 ms-3 me-3">
            <div className='d-flex align-items-center justify-content-between mb-4'>
                <h4>History</h4>
            </div>

            <ul className='user_timeline'>
                {
                    list.map(item => (
                        <li key={item._id}>
                            <time>{moment(item.created).format('DD MMM, YYYY')}</time>
                            <span className='histroy_type'>{item.productPrice ? 'Products' : 'Programs'}</span>


                            {
                                item.productPrice ? (
                                    <div className='timeline_inner'>
                                        <h3>Camp Name: {item?.campName}</h3>
                                        <div className='time_line_programs'>
                                            <h5 className='me-3'>Products: </h5>
                                            {item.itemName.map((p) => <h5 key={p} className='m_box'> {p}</h5>)}
                                        </div>

                                        <h2>Amount: {item.productPrice ? `$${item.productPrice.toFixed(2)}` : 'Free'}</h2>
                                    </div>
                                ) : (
                                    <div className={`timeline_inner ${item?.bookingStatus === 2 ? 'is_canceled' : 'not_canceled'}`}>
                                        {<div className='has_canceled_program_text'>
                                            {item?.bookingStatus === 2 && <span>Canceled</span>}
                                            {item?.bookingStatus === 3 && <span className='text-primary'>Refunded</span>}
                                            {item?.bookingStatus === 4 && <> <span>Canceled</span><span className='text-dark'> & </span><span className='text-primary'> Refunded</span></>}
                                        </div>
                                        }
                                        <h3>Camp Name: {item.campName}</h3>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <small className='m_box'>Booking Number: {item?.bookingNumber}</small>
                                            <h2>Amount: {item.amount ? `$${item.amount.toFixed(2)}` : 'Free'}</h2>
                                        </div>
                                        <div className=''>
                                            <h5 className='me-3'>Programs: </h5>
                                            {item?.programs?.length ? item.programs.map((p, i) => <div key ={p?._id}>
                                                <h5 className='ps-5' key={i}>{p?.programName}</h5>
                                                <div className='d-flex justify-content-end bg-red'>
                                                    <button className='btn btn-primary' onClick={() => handleChargeCustomer(p.campers, item.bookingNumber, item.bookingId, p.programId)}>Charge camper</button>
                                                </div>
                                                <div className='mb-5 ps-5 ms-5'>
                                                    <small className='me-3'>participants: </small>
                                                    {p.campers.map((cp) =>
                                                        <Link
                                                            key ={cp?._id}
                                                            to={`/bookings/${item.bookingId}/${cp.camperId}/${p.programId}`}>
                                                            <div
                                                                className={`${baseClass} ${(cp.status === 2 || cp.status ===4 )  && 'border border-danger'} ${(cp.status === 3) && 'border border-primary'}`}>
                                                                <small>
                                                                    Name: {cp.camperName}
                                                                </small>

                                                                <small>Booking Date : 
                                                                {convertTimeToCurrentTimeZone(item?.bookingDate, props?.userProfile?.timezone,'MMMM DD, YYYY : hh:mm a')}
                                                                </small>
                                                                {
                                                                    cp?.refunds?.length > 0 &&
                                                                    <span className='btn btn-outline-secondary btn-sm' onClick={(e) => handleOpenRefundModal(e, cp.refunds)}>
                                                                        Refunds
                                                                    </span>
                                                                }

                                                            </div>
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>) : ''}
                                        </div>

                                    </div>

                                )
                            }
                        </li>
                    ))
                }
            </ul>

            {
                list.length ? 
                <div className="d-flex justify-content-center mt-5">
                    <PaginatedComponent
                        activePage={page}
                        itemsCountPerPage={limit}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={4}
                        onPageChange={setPage}/>
                </div>  : ''

            }
            <Modal
                show={refundModal}
                onHide={handleCloseRefundModal}
                centered
            >
                 <Modal.Header
                 className='d-flex justify-content-center align-items-center'
            >
                <Modal.Title >Refund History</Modal.Title>
                <span className="modal_close" onClick={handleCloseRefundModal}><i className="icon-cross" /></span>
            </Modal.Header>
                <Modal.Body>
                    {
                        refundData.map(item =>
                            <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row mb-2 m_box'>
                                <small>Refund Amount : <b>${item.refundAmount}</b></small>
                                <small>Refund Date :{moment.utc(item?.refundDate).format('MMMM DD, YYYY : hh:mm a')}</small>
                            </div>
                        )
                    }
                </Modal.Body>
            </Modal>
            <ChargeCamperModal
                modalState={modalState}
                chargeModal={chargeModal}
                handleCloseChargeModal={handleCloseChargeModal}
                handleCamperChange={handleCamperChange}
                handleChargeAmountReason={handleChargeAmountReason}
                handleChargeAmountChange={handleChargeAmountChange}
                userId={props.match.params.id}
            />

        </div>
    )
}


const mapStateToProps = state => {
    let userProfile = state.user.userProfile;
    return {
        userProfile,
    };
}


export default connect(mapStateToProps)(UserHistory);