import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { newsListService } from '../../services/otherService';
import { Link } from 'react-router-dom';
import { ProgramLoader } from '../reuse/SVG';
import PaginatedComponent from '../common/Pagination/PaginationComponent';


const removeHtmlTags = e => {
    return e.replace(/(<([^>]+)>)/gi, " ");
}



const NewsAndEvents = () => {
    const [loader, setLoader] = useState(true);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [list, setList] = useState([]);


    const getNewsList = () => {
        let query = `?page=${page - 1}&limit=15&isActive=0`;

        newsListService(query).then(res => {
            setLoader(false);
            if(res?.data?.statusCode){
                setList(res.data.responseData.result);
                setCount(res.data.responseData.count);
            }
        })
    }



    useEffect(() => {
        getNewsList();
    }, [page])

    return(
        <div className="body_container">
            {loader ? <div className="page_loader"><ProgramLoader /></div> : ''}
            <div className="container">
                <div className="section_header mb-3">
                    <h3>Recent News & Events</h3>
                </div>

                <div className="row g-0">
                {
                    list.map(item => (
                        <div className="col-lg-4 col-md-6 mb-4" key={item._id}>
                            <div className="cm_card">
                                <div className="card_img_wrap">
                                    <img src={item.image} />

                                    <div className="d-flex justify-content-between align-items-center camp_card_name">
                                        <small><i className="white">{moment(item.created).format('MMM,DD YYYY')}</i></small>
                                    </div>
                                </div>

                                <div className="card_body cm_min_height">
                                <p><strong className="cm_no_breck">{item.title}</strong></p>
                                    <p><small><span className="three_dots">{removeHtmlTags(item.desc)}</span> <Link to={`/news-and-events/${item._id}`}>[Read more]</Link></small></p>

                                </div>
                            </div>
                            
                        </div>
                    ))
                }
                </div>
                
                <div className="text-center">
                <div className="d-flex justify-content-center mt-5">
                    <PaginatedComponent
                        activePage={page}
                        itemsCountPerPage={15}
                        totalItemsCount={count}
                        pageRangeDisplayed={4}
                        onPageChange={setPage}/>
                </div>
                </div>

            </div>
        </div>
    )
}

export default NewsAndEvents;

