import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { FolderIcon } from '../reuse/SVG';
import { Link } from 'react-router-dom';
import moment from 'moment';

const FoldersList = (props) => {
    const { folders, setDeleteFolder, setDeleteFolderId } = props
    return (
        <Row className="p-1">
            {
                folders?.length > 0 && folders.map((folder) => (
                    <Col
                        xs={12} sm={6} md={3}
                        className="d-flex align-items-center counselor_card folder_card m-3" key={folder._id}
                    >
                        <div>
                            <div className="folder_icon"> <Link to={`/bookings/folder/${folder._id}`}><FolderIcon /></Link></div>
                            <h4><Link to={`/bookings/folder/${folder._id}`}>{folder.folderName}</Link></h4>
                            <small>{moment(folder.created).format('MM/DD/YYYY hh:mm')}</small>
                        </div>
                        <div className="remove_counselor"><i onClick={() => { setDeleteFolder(true); setDeleteFolderId(folder._id) }} className="icon-delete" /></div>
                    </Col>
                ))
            }
        </Row>
    )
}

export default FoldersList
