import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import OtpInput from 'react-otp-input';

import { toggleOtpModal } from '../../store/ui';
import { verifyemailotpAction, resendOtpAction } from '../../store/user';


const OtpVerificationModal = props => {
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes ] = useState(5);
    const [isActive, setIsActive] = useState(false);

    const userId = localStorage.getItem('userId');



    const handleChane = otp => {
        setOtp(otp);
        setOtpError('');
    };


    const handleValidate = () => {
        let validate = true;


        if(otp === ''){
            setOtpError('otp is required');
            validate = false
        }
        if(otp.toString().length !== 4){
            setOtpError('otp is require length');
            validate = false
        }

        return validate;
    }



    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            let params = {
                userId,
                otp
            }

            props.verifyemailotpAction(params);
        }
    }



    
      function reset() {
        setSeconds(0);
        setMinutes(5)
      }
    
      useEffect(() => {
        let interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (!isActive && seconds === 0) {
                if (!isActive && minutes === 0) {
                    clearInterval(interval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
          }, 1000);

        return () => clearInterval(interval);
      });



      const reSendOTP = () => {
        setOtp('');
        props.resendOtpAction({email: props.resendotpEmail})
        reset()
      }
    

    return(
        <Modal
            dialogClassName="text-center otp_modal"
            show={props.otpModal}
            animation={false}
            centered
        >
        <Modal.Body>
            <form className="p-5" onSubmit={handleSubmit}>
                <h3>Please Enter OTP</h3>
                <p>We have sent a One Time Password (OTP) to your Email</p>
                <div className="color-primary otp_timer">0{minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</div>

                <div className="otp_inputs">
                    <OtpInput
                        onChange={handleChane}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        name="otp"
                        value={otp}
                        numInputs={4}
                        separator={''}
                    />
                    <div className="invalid-feedback">{otpError}</div>
                </div>

                <p>Didn’t receive the OTP? <span onClick={reSendOTP} className="pointer color-primary">Resend</span></p>
                {props.resendotpEmailLoader ? 'loading' : ''}
                <button
                    type="submit"
                    disabled={props.loading}
                    className={`btn btn-primary rounded-0 w-100 mt-3 btn_anim ${props.loading ? 'show_anim' : ''}`}>{props.loading ? 'Loading...' : 'Verify'}</button>

                {props.error && <p className="error_msg">{props.error}</p>}
            </form>
        </Modal.Body>
    </Modal>
    )
}


/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { otpModal, resendotpEmailLoader, resendotpEmail } = state.ui;
    let { error, loading } = state.user;
    return {
        otpModal,
        error,
        loading,
        resendotpEmail,
        resendotpEmailLoader
    };
}
  
const mapDispatchToProps = dispatch => ({
    toggleOtpModal: params => dispatch(toggleOtpModal(params)),
    verifyemailotpAction: params => dispatch(verifyemailotpAction(params)),
    resendOtpAction: params => dispatch(resendOtpAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(OtpVerificationModal);