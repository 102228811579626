import React from 'react';
import { Modal } from 'react-bootstrap';

const ConfirmModal = ({show, hideModal, msg, action}) => (
    <Modal show={show} centered dialogClassName="remove_header_line">
        <Modal.Header>
        <span className="modal_close" onClick={hideModal}><i className="icon-cross" /></span>
        </Modal.Header>
        <Modal.Body>
            <p className="text-center">{msg}</p>
            <div className="d-flex justify-content-center mt-5 mb-4">
                <button onClick={hideModal} className="btn btn-light me-4 ps-5 pe-5">No</button>
                <button className="btn btn-primary ps-5 pe-5" onClick={action}>Yes</button>
            </div>
        </Modal.Body>
    </Modal>
)

export default ConfirmModal;