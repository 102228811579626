import React, {useState, useEffect}  from 'react';
import {associatePrograms,followcamps, addToCart, programFavourite} from '../../services/programService';
import { ProgramLoader } from '../reuse/SVG';
import { Empty } from 'antd';
import { connect } from 'react-redux';
import {campDates, getProgramType,getGender,getgendersdisplay, gerFavpropgramdate} from '../../helper';
import { Link, useLocation } from 'react-router-dom';
import {CardLoader} from '../reuse/SVG';
import { LogoWhite } from '../reuse/SVG';
import { Modal, Dropdown } from 'react-bootstrap';
import Share from '../static/Share';


const AssociatePrograms = props => {
    const [loader,  setLoader]                          = useState(true);
    const [assocaitePrograms, setAssociatePrograms]     = useState([]);
    const [addtocartModal, setaddtocartModal]           = useState(false);
    const [cartLoder, setCartLoder]                     = useState(false);
    let location                                        = useLocation();





    useEffect(() =>{
        if(props.userID)
        {
        var params = `/?campId=${location.state.campId}&userId=${props.userID}`;
        }else
        {
        var  params = `/?campId=${location.state.campId}`; 
        }
        const getassociateprograms = associatePrograms(params)
        getassociateprograms.then((res)=>{
            setLoader(false);
            setAssociatePrograms(res.data.responseData.result.data);
        }).catch((err)=>{
            setLoader(false);
        })
    },[])

    useEffect(() =>{
        if(props.userID)
        {
        var params = `/?campId=${location.state.campId}&userId=${props.userID}`;
        }else
        {
        var  params = `/?campId=${location.state.campId}`; 
        }
        const getassociateprograms = associatePrograms(params)
        getassociateprograms.then((res)=>{
            setLoader(false);
            setAssociatePrograms(res.data.responseData.result.data);
        }).catch((err)=>{
            setLoader(false);
        })
    },[cartLoder])


    // const followCamps = async (campId,follow) => {
    //     setCartLoder(true);
    //     const followCamp = await followcamps({campId,follow});
    //     try{
    //         if(follow)
    //         {
    //             let campid = campId;
    //             let newassociate = [...assocaitePrograms];
    //             newassociate.map((campes, index)=>{
    //                 if(campes.campId == campId)
    //                 {
    //                     newassociate[index].isFollowed = false;
    //                 }
    //             })
    //             setAssociatePrograms(newassociate);
    //             setCartLoder(false);
    //         }
    //         else
    //         {
    //             let campid = campId;
    //             let newassociate = [...assocaitePrograms];
    //             newassociate.map((campes, index)=>{
    //                 if(campes.campId == campId)
    //                 {
    //                     newassociate[index].isFollowed = true;
    //                 }
    //             })
    //             setAssociatePrograms(newassociate);
    //             setCartLoder(false);
    //         }
            
    //     }
    //     catch(err)
    //     {
    //         setCartLoder(false);
    //     } 
    // }

    // const addtocartAction = (programid) =>
    // {
    //     let programId = programid;
    //     let numberOfBooking = 1;
    //     let additional_exp_cart = [];
    //     let params = {programId, numberOfBooking}  
    //     addToCart(params).then(res=>{
    //         if(res?.data?.statusCode)
    //         {
    //             setaddtocartModal(true);     
    //         }
    //     });
    // } 
    
 
    const handleFavourite = (programId, favourite) => {
        setCartLoder(true);
        let params = {
            programId: programId,
            favourite: favourite
        }

        programFavourite(params).then(res => {
            setCartLoder(false);
            
        })
    }


    return(
        <div className="body_container">

                <Modal
                    dialogClassName="h_auto"
                    show={addtocartModal}
                    animation={false}
                    centered
                >
                <Modal.Body>
                    <div className="p-5">
                        <h3 className="text-center mb-4">Success!</h3>
                        <p className="text-center">Successfully added to cart </p>
                        <button onClick={()=>setaddtocartModal(false)}className="btn btn-primary w-100 mt-2">Ok</button>
                    </div>
                </Modal.Body>
                </Modal>


            <div className="container">
                <div className="d-flex align-items-center justify-content-between section_header">
                    <h3>Associated programs</h3>
                </div>
            </div>
            <div className="container">
            
            {
                 loader ?  <div className="program_loader"><ProgramLoader /></div> : 
                 (
                    <div className="row">
                        {cartLoder ? <div className="favloder_wrapper"><CardLoader/></div> : ''}
                      {
                        assocaitePrograms.length > 0 ?
                        (
                            assocaitePrograms.map((item, i) => (
                        <div key={i} className="col-lg-4 col-md-6 mb-5">
                            <div className="cm_card fav_card assoc_card">
                                <div className="card_img_wrap">     
                                { props.userID ?  item.isFavourite ? <span className="btn_like active" onClick={()=>{handleFavourite(item._id, 0)}}><i className="icon-heart"></i></span> :<span className="btn_like" onClick={()=>{handleFavourite(item._id, 1)}}><i className="icon-heart"></i></span> : ''}

                                    {
                                      item.programImage ? (<img src={item.programImage} />) : (<div className="no_img"><LogoWhite /></div>)   
                                    }
                                    
                                    <h4>{item.camps}</h4>
                                    <div className="camp_card_name">
                                        <h3>{gerFavpropgramdate(item.startDate, item.endDate)} <Share pageUrl={`ProgramDetail=${item._id}`} /></h3>
                                    </div>
                                </div>
                                <div className="card_body">
                                    <div className="d-flex justify-content-between">
                                        <h3>{item.programName}</h3>
                                            <div className={`text-right dark__ ${item.isSpecialDeal  ? 'cm_spacial_price' : ''}`}>
                                            <h3><span className="under_red"> {item.registrationFee ? `Price : $${item.registrationFee.toFixed(2)}` : 'Free'}   </span></h3>
                                            {item.isSpecialDeal ? <h2>${item.specialDealsPrice.toFixed(2)}</h2>  : ''} 
                                        </div>  

                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p>{getProgramType(item.programType)}, <span className="text-nowrap">{`${item.checkInTime}-${item.checkOutTime}`}</span></p>
                                            <p>Age : {item.ageGroup}</p>
                                        </div>
                                        <div className="text-nowrap">
                                            <p>Gender : {getgendersdisplay(item.gender)}</p>
                                            <p className="text-right">EZPro <span className="EZPro_rating">{item.ezProRating} <i className="icon-star" /></span></p>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        {/* <button onClick={() => {addtocartAction(item._id)}} className="btn btn-primary w-100 me-1 rounded-0">Add to cart</button> */}
                                        <Link className="btn btn-primary w-100 me-1 rounded-0" to={`/camps/${item._id}`}>Details</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))) :
                        (
                            <div className="col-12 mb-5"><div className="cm_empty"><Empty description="No Associate Programs Found" /></div></div>
                        )
                    }                    
                    </div> 
                 )
            }
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    let { loggedIn } = state.user;
    let userID =false;

    if(state.user.userProfile!== undefined)
    {
        userID = state.user.userProfile._id;
    }
    return {
        loggedIn,
        userID
    };
}
  

  
export default connect(mapStateToProps, null)(AssociatePrograms);

