import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input/input';
import { notification } from 'antd';

import { becomePartnerService } from '../../services/otherService';
import { isValidEmail } from '../../helper';
import { ProgramLoader } from '../reuse/SVG';
import PatnerBanner from '../../assets/img/patner-banner.png';
import ContactBg from '../../assets/img/contact-bg.png';

const Partner = () => {
    const [loader, setLoader] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [state, setState] = useState('');
    const [comment, setComment] = useState('');

    const [nameErr, setNameErr] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [companyNameErr, setCompanyNameErr] = useState('');
    const [phoneNoErr, setPhoneNoErr] = useState('');
    const [stateErr, setStateErr] = useState('');




    const handleValidate = () => {
        let validate = true;

        if(name === ''){
            setNameErr('Name is required');
            validate = false
        }

        if(email === ''){
            setEmailErr('Email is required');
            validate = false
        }else if(!isValidEmail(email)){
            setEmailErr('Email is not valid');
            validate = false
        }

        if(companyName === ''){
            setCompanyNameErr('Company name is required');
            validate = false
        }

        if(phoneNo === ''){
            setPhoneNoErr('Phone number is required');
            validate = false
        }

        if(state === ''){
            setStateErr('State is required');
            validate = false
        }

        return validate;
    }



    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            setLoader(true);

            let params = {
                name,
                email,
                companyName,
                phoneNo,
                state,
                comment
            }

            becomePartnerService(params).then(res => {
                setLoader(false);
                if(res?.data?.statusCode){
                    setName('');
                    setEmail('');
                    setCompanyName('');
                    setPhoneNo('');
                    setState('');
                    setComment('');

                    notification.open({
                        message: 'Become a Partner',
                        description:
                          'Partner added successfully',
                      });
                }
            })

        }
    }

    return(
        <div className="body_container">

        {loader ? <div className="page_loader"><ProgramLoader /></div> : ''}

            <div className="cm_banner_wrapper cm_partner_banner">
                <img src={PatnerBanner} />
                <div className="cm_banner_text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h2>Become A Partner</h2>
                                <p>If you are not a program or activity provider but you would like to partner with us for rewards point campaigns, sweepstakes, giveaways, community projects, or other promotional activities or partnerships, send us a message here.  We would love to hear about your ideas and possible collaborations, especially ones in completely novel industries.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div className="contact_us_wrapper p-0">

                <div className="row g-0">
                    <div className="col-lg-6 contact_bg" style={{backgroundImage: `url(${ContactBg})`}}>
                        {/* <div className="cm_address_card">
                            <p>Golden Lake,6 MT 5971</p>
                            <p>800.548.4486 Or 406.995.5000</p>
                            <p>P.O Box 16001</p>
                            <p>1 Lone Mountain Trail</p>
                        </div> */}
                    </div>
                    <div className="col-lg-6 partner-form">
                        <form onSubmit={handleSubmit}>
                            <h3>Tell us About You</h3>

                            <div className="form-group">
                                <input
                                    type="text"
                                    value={name}
                                    onChange={e => (setName(e.target.value), setNameErr(''))}
                                    className={`form-control form-control-lg ${nameErr ? 'is-invalid' : ''}`}
                                    placeholder="Name" />

                                {nameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{nameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>


                            <div className="form-group">
                                <input
                                    type="text"
                                    value={companyName}
                                    onChange={e => (setCompanyName(e.target.value), setCompanyNameErr(''))}
                                    className={`form-control form-control-lg ${companyNameErr ? 'is-invalid' : ''}`}
                                    placeholder="Company Name" />

                                {companyNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{companyNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>


                            <div className="form-group">
                                <input
                                    type="email"
                                    value={email}
                                    onChange={e => (setEmail(e.target.value), setEmailErr(''))}
                                    className={`form-control form-control-lg ${companyNameErr ? 'is-invalid' : ''}`}
                                    placeholder="Email" />

                                {emailErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{emailErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>


                            <div className="form-group">
                                <PhoneInput
                                    country="US"
                                    placeholder="Phone"
                                    className={`form-control form-control-lg ${phoneNoErr ? 'is-invalid' : ''}`}
                                    onChange={e => (setPhoneNo(e), setPhoneNoErr(''))}
                                    maxLength={16}
                                    value={phoneNo} />
                                {phoneNoErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{phoneNoErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>

                            <div className="form-group">
                                <input
                                    type="text"
                                    value={state}
                                    onChange={e => (setState(e.target.value), setStateErr(''))}
                                    className={`form-control form-control-lg ${stateErr ? 'is-invalid' : ''}`}
                                    placeholder="State" />

                                {stateErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{stateErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>


                            <div className="form-group">
                                <textarea
                                    rows="3"
                                    value={comment}
                                    onChange={e => setComment(e.target.value)}
                                    className="form-control form-control-lg"
                                    placeholder="Comment" />

                            </div>


                            <div className="text-center">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>









        </div>
    )
}

export default Partner;

