import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { getOrdersService } from '../../services/onlineStoreService';
import moment from 'moment';
import { Empty } from 'antd';
import PaginatedComponent from '../common/Pagination/PaginationComponent';


const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const Orders = props => {
    const [loader, setLoader] = useState(false);
    const [ordersList, setOrdersList] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [search, setSearch] = useState('');


    const getOrderList = () => {
        let query = `?page=${page - 1}&limit=${limit}&search=${search}` 
        getOrdersService(query).then(res => {
            if(res?.data?.statusCode){
                setOrdersList(res.data.responseData.result);
                setTotalCount(res.data.responseData.totalCount);
            }
        })
    }


    useEffect(() => {
        getOrderList();
    }, [search, page])

    
  const getTotalpriceOfItem = (item) => {
        const _ItemPrice = (+item.itemPrice * item.quantity);
        const _totalSubItemsPrice = (item?.subItems?.length === 0) ? 0 : item?.subItems?.reduce((total, si) => total + (+si.price * +si.count), 0);
        return (_ItemPrice + _totalSubItemsPrice);
  }



    return(
        <div className="content_wrapper booking_page">
            <div className="">
                <h2>Products</h2>
                
            </div>

            <div className="partner-form search_wrap" style={{ maxWidth: '100%' }}>
                <i className="icon-search" />
                <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Search"
                    value={search}
                    onChange={e => setSearch(e.target.value)} />
            </div>

            {/* {loader ? <div className="page_sm_loader"><ProgramLoader /></div> : ''} */}


            {ordersList.length ?
                ordersList.map(item => (
                                    <div className="product_card_xl" key={item._id}>

                                        <div className='row'>
                                            <div className='col-lg-4 pb-5'>

                                            {
                                                item?.image?.length ? <Slider {...settings}>
                                                                        {item?.image.map((img, i) => (
                                                                                <img src={img} key={i} className="img-fluid" />
                                                                        ))}
                                                                    </Slider> : ''
                                            }
                                            </div>
                                            <div className='col-lg-8'>
                                                <h3>{item.name}</h3>
                                                <div className=''>
                                                    <div  className="form-group mb-0">
                                                        <label>Size: </label>
                                                        <b className='ms-2'>{item.sizes}</b>
                                                    </div> 

                                                    <div className={`form-group mb-0`}>
                                                        <label>Color: </label>
                                                        <b  className='ms-2'>{item.color}</b>
                                                    </div>

                                                    <div className={`form-group mb-0`}>
                                                        <label>Item Count: </label>
                                                        <b className='ms-2'>{item.quantity}</b>
                                                    </div>

                                                    <div className={`form-group mb-0`}>
                                                        <label>Order Date: </label>
                                                        <b  className='ms-2'>{moment(item.orderedDate).format('DD MMM, YYYY')}</b>
                                                    </div>
                                                </div>



                                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                                    <div className="product_price">
                                                        <h2><b>Total Price: ${getTotalpriceOfItem(item)?.toFixed(2)}</b></h2>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                                {
                                                            item?.paymentStatus ===3 ? 
                                                            <>
                                                            <span className='p_cat_text' style={{background:'red'}}>Failed Payment</span> 
                                                            </>
                                                            : ''

                                                }  
                                                </div>
                        
                                                
                                            </div>
                                        </div>
                                        {item?.subItems?.length > 0 && <h4>Add-ons</h4>}
                                                <div className = "d-flex flex-column flex-sm-row flex-wrap align-content-start ">

                                                        { item?.subItems?.length > 0 && 
                                                            item?.subItems?.map ( (subItem, si) => 
                                                                <div className='product_card p-3 mx-3' key = {subItem._id}>
                                                                    <div style= {{width: '120px'}}>
                                                                        <h5>{subItem.title}</h5>  
                                                                    </div>
                                                                    {
                                                                        subItem?.image?.length && <img src={subItem.image[0]} className="img-fluid" style= {{width: '120px', height:"120px"}}/>
                                                                        
                                                                    }
                                                                    <div className='product_text'>
                                                                    <div className=''>
                                                                            <div  className="form-group mb-0">
                                                                                <label>Size: </label>
                                                                                <b> {subItem.sizes || 'N/A'}</b>
                                                                            </div> 

                                                                            <div className={`form-group mb-0`}>
                                                                                <label>Color: </label>
                                                                                <b> {subItem.color || 'N/A'}</b>
                                                                            </div>
                                                                            <div  className="form-group mb-0">
                                                                                <label>Item Count: </label>
                                                                                <b> {subItem.count || 0}</b>
                                                                            </div> 
                                                                            <div className={`form-group mb-0`}>
                                                                                <label>Price:</label>
                                                                                <b> ${subItem.price || 'N/A'}</b>
                                                                            </div>
                                                                        </div>   
                                                                    </div>   
                                                                </div>
                                                            )
                                                        } 
         
                                                </div> 
                                    
                                    </div>
                )) : <div className="cm_empty"><Empty description="No Data" /></div>
            }

            {
                ordersList.length ? <div className="d-flex justify-content-center mt-5">
                <PaginatedComponent
                    activePage={page}
                    itemsCountPerPage={limit}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={4}
                    onPageChange={setPage}/>
                </div>  : ''
            }

        </div>
    )
}

export default Orders;
