import { getProgramPriceByDates } from "../cart/helper";


export const sliderSetting = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

export const addZero = num => {
    if( num.toString().length < 2 )
    return "0" + num;
    return num.toString();
}



export const getWithTaxPrice = (obj, amount) => {
    let withTax = 0
    if(obj.percentage){
        withTax = amount + ((amount * obj.percentage) / 100) + obj.taxRate;
    }else{
        withTax = amount + obj.taxRate;
    }
    return withTax.toFixed(2);
}


export const getSizes = arr => {
    let filterArr = [];
    if(arr?.length){
        let isHasSize = arr.filter(i => (i.name !== 'No Size'));
        if(isHasSize.length){
            let onlyName = isHasSize.map(item => item.name);
            let uniqArr =  onlyName.filter((item, index) => onlyName.indexOf(item) === index);
            filterArr = uniqArr.map(i => ({value: i, label: i}))
        }
    }
    return filterArr;
}




export const getColors2 = (arr, val) => {
    let filterArr = [];

    if(val){
        if(arr?.length){
            let isHasColor = arr.filter(i => i.name === val);
            let isHasColor2 = isHasColor.filter(i => i.color !== 'No Color');
            if(isHasColor2.length){
                let onlyName = isHasColor2.map(item => item.color);
                let uniqArr =  onlyName.filter((item, index) => onlyName.indexOf(item) === index);
                filterArr = uniqArr.map(i => ({value: i, label: i}))
            }
        }
    }else{
        if(arr?.length){
            let isHasColor = arr.filter(i => i.color !== 'No Color');
            if(isHasColor.length){
                let onlyName = isHasColor.map(item => item.color);
                let uniqArr =  onlyName.filter((item, index) => onlyName.indexOf(item) === index);
                filterArr = uniqArr.map(i => ({value: i, label: i}))
            }
        }
    }
    return filterArr;
}

export const cancellationOptions = [
    {
        label: "CANCEL ONLY", //CANCEL WITHOUT REFUNDS
        value:2
    },
    {
        label: "CANCEL AND REFUND", //CANCEL WITH REFUND
        value:3
    },
    {
        label: "REFUND ONLY", //REFUND WITHOUT CANCELLATION
        value:4
    },
]

export const camperCancellationOptions = [
    {
        label: "CANCEL ONLY", //CANCEL WITHOUT REFUNDS
        value:2
    },
    {
        label: "REFUND ONLY", //REFUND WITHOUT CANCELLATION
        value:3
    },
    {
        label: "CANCEL AND REFUND", //CANCEL WITH REFUND
        value:4
    },
    {
        label:"CHARGE CAMPER",
        value:5,
    }
]

export const prepareQuoteData = (quoteDetails, offer ) => {
    const quoteData = {
        expiration_date: quoteDetails?.quote?.expiration_date,
        effective_date: quoteDetails?.quote?.effective_date,
        quote_date: quoteDetails?.quote?.quote_date,
        product: {
            id: quoteDetails?.quote?.product?.id,
            name: quoteDetails?.quote?.product?.name,
            friendly_name: quoteDetails?.quote?.product?.friendly_name,
        },
        checkout_url: quoteDetails?.quote?.checkout_url,
        premium_amount: quoteDetails?.quote?.premium_amount,
        currency: quoteDetails?.quote?.currency,
        total: quoteDetails?.quote?.total,
        quote_id: quoteDetails?.quote?.quote_id,
        offer_id: quoteDetails?.quote?.offer_id,
        insuranceStatus: 2,
        type: offer?.product,
        camperId: quoteDetails?.quote?.metadata?.context_name_id,
        programId: quoteDetails?.quote?.metadata?.context_event_id,
        eventName: `${quoteDetails?.quote?.metadata?.context_name} - ${quoteDetails?.quote?.metadata?.context_event}`
    };
    return quoteData
}


export const totalPriceSum = (...numbers) =>  {
    return numbers.reduce((acc, curr) => acc + curr, 0);
  }

export const  isOnlySpaces = (str) =>  {
    return str.trim().length === 0;
}
export const trimSpace = (str) =>  {
    return str.replace(/^\s+/, '');
  }


export const getInsuranceWithTaxPrice = (obj, amount) => {
    let withTax = 0
    if(obj.percentage){
        withTax = amount + ((amount * obj.percentage) / 100);
    }else{
        withTax = amount;
    }
    return withTax.toFixed(2);
}


export const getSplitPrice = (obj, amount) => {
    let _amount = amount / obj.noOfTimesPayment;
    let withTax = 0
    if(obj.percentage){
        withTax = _amount + ((_amount * obj.percentage) / 100) + obj.taxRate;
    }else{
        withTax = _amount;
    }
    return Number(withTax.toFixed(2));
}

export const getSplitPrice2 = (obj, amount) => {
    let _amount = amount / (obj.noOfTimesPayment || 1);
    return Number(_amount.toFixed(2));
}

export const getTaxForPrice = (obj, amount) => {
    let _taxAmount = 0
    if(obj.percentage){
        _taxAmount = ((amount * obj.percentage) / 100) + obj.taxRate;
    }else{
        _taxAmount = obj.taxRate;
    }
    return Number(_taxAmount.toFixed(2));
}
export const getTaxForSplitPrice = (obj, amount) => {
    let _amount = amount / obj.noOfTimesPayment;
    let _taxAmount = 0
    if(obj.percentage){
        _taxAmount = ((_amount * obj.percentage) / 100) + obj.taxRate;
    }else{
        _taxAmount = obj.taxRate;
    }
    return Number(_taxAmount.toFixed(2));
}

export const getSplitPriceNew = (obj, amount) => {
    let _amount = amount / obj.noOfTimesPayment;
    return Number(_amount.toFixed(2));
}

export const removeItemByKeyMatch = (arr1,  key1='_id',arr2 =[], key2= '_id') => {
    if(!arr2.length){
        return arr1;
    }
    const idsToRemove = new Set(arr2.map(item => item[key2]));
    return arr1.filter(item => !idsToRemove.has(item[key1]));
  };


export const calculateTotalMoney = (items, isAllFree) => {
    let  additionalDiscount= 0
    let _additionalExperiencePrice= 0
    const _associatedCartPrice= calAssociateCartPrice(items)
    items.campers.forEach(camper => {
        if (camper?.additionalExperience?.length) {
            _additionalExperiencePrice = calculateAdditionalExpPrice(camper)
        }
        if (camper.answers !== undefined) {
            additionalDiscount = calculateAdditionalDisc(camper, items)
        }
    })
    let totalMoney = 0
    totalMoney += (items.registrationFee * items.campers.length) + isAllFree;


    if (additionalDiscount) {
        totalMoney += totalMoney - additionalDiscount;
    }

    if (_additionalExperiencePrice) {
        totalMoney += _additionalExperiencePrice;
    }

    if (_associatedCartPrice) {
        totalMoney += _associatedCartPrice;
    }

    if (items.byDates === 1) {
        totalMoney += getProgramPriceByDates(items.additionalDates, items.selectedDates) + isAllFree;
    }
    return totalMoney
}

export const calculateAdditionalDisc = (items) => {
    let additionalDiscount = 0
    if (items?.camper?.answers) {
        items.camper.answers.forEach(answ => {
            if (answ?.options) {
                answ.options.forEach(optio => {
                    if (optio.opt === answ?.answer) {
                        if (optio.discountType && optio.discount) {
                            additionalDiscount = (optio.discountType === 1 ? optio.discount : ((items.registrationFee / 100) * optio.discount));
                        }
                    }
                })
            }
        })
    }
    return additionalDiscount
}

export const calculateAdditionalExpPrice = (camper) => {
    let _additionalExperiencePrice = 0
    for (let i = 0; i < camper.additionalExperience.length; i++) {
        _additionalExperiencePrice = _additionalExperiencePrice + (camper.additionalExperience[i].price * camper.additionalExperience[i].numberOfBooking)
    }
    return _additionalExperiencePrice
}

export const calAssociateCartPrice = (items) => {
    let _associatedCartPrice = 0
    for (let i = 0; i < items.associatedCartInfo.length; i++) {
        _associatedCartPrice = _associatedCartPrice + (items.associatedCartInfo[i].price * items.associatedCartInfo[i].count)
    }
    return _associatedCartPrice;
}