


export const getProgramPriceByDates = (allPrices, selectedDateByProgram) => {
    const _res = allPrices.filter(el => selectedDateByProgram.find((id) => el._id === id));
    let _totalPrice = 0;
    if(_res.length){
        _res.map(i => _totalPrice += i.standardPrice)
    }
    return _totalPrice > 0 ? `$${_totalPrice.toFixed(2)}` : 'Free';
}


export const getProductPriceFun = _list => {
    let _all_product_price = 0;
    if(_list.length){
        for (let i = 0; i < _list.length; i++) {
            _all_product_price = _all_product_price +  calculateTotalPriceForItem(_list[i].items);
        }
    }

    return _all_product_price;
}

export const calculateTotalPriceForItem  = _item => {
    let totallPrice = Number(_item.price) * Number(_item.count)
     if(_item?.subItems?.length > 0){
        totallPrice = _item.subItems.reduce( (tp, si) => (tp + (Number(si.price) * Number(si.count)) ), totallPrice);
     }
     return totallPrice

}

export const getProgramPriceByDates2 = (allPrices, selectedDateByProgram, numbderofCampers, _product_price) => {
    const _res = allPrices.filter(el => selectedDateByProgram.find((id) => el._id === id));
    let _totalPrice = 0;
    if(_res.length){
        _res.map(i => _totalPrice += i.standardPrice)
    }

    return _totalPrice ? ((_totalPrice * numbderofCampers) + getProductPriceFun(_product_price)).toFixed(2) : 'Free';
}

 


export  const getTotalAmount = data => {
    if(data.byDates === 1){

        const _res = data.additionalDates.filter(el => data.selectedDates.find((id) => el._id === id));
        let _totalPrice = 0;

        for (let i = 0; i < data.campers.length; i++) {
            if(data.campers[i].isCommuter){
                for (let j = 0; j < _res.length; j++) {
                    _totalPrice = _totalPrice + _res[j].commuterPrice;
                }
            }else{
                for (let j = 0; j < _res.length; j++) {
                    _totalPrice = _totalPrice + _res[j].standardPrice;
                }
            }
        }

        return _totalPrice;

    }else{
        let _total_amount = 0;

        for (let i = 0; i < data.campers.length; i++) {
            if(data.campers[i].isCommuter){
                _total_amount = _total_amount + data.commuterPrice
            }else{
                _total_amount = _total_amount + data.campRegistrationFee
            }
        }

        return _total_amount ? _total_amount : 0 

    }
}

export   const getTotalAmount2 = (data, numberOfCampers )=> {
    if(data.byDates === 1){
        const _res = data.additionalDates.filter(el => data.selectedDates.find((id) => el._id === id));
        let _totalPrice = 0;

        for (let j = 0; j < _res.length; j++) {
            _totalPrice = _totalPrice + _res[j].standardPrice;
        }

        return _totalPrice ? _totalPrice * numberOfCampers : 0;

    }else{
        return data.campRegistrationFee;
    }
}
