import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/react";
// import "antd/dist/reset.css";
import 'draft-js/dist/Draft.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import store from './store';
import App from './App';



Sentry.init({
  dsn: "https://87e4d747fb8d0463ed675016897ff9ab@o1073574.ingest.sentry.io/4506505683795968",
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/campsez.com\.io\/api/],
});


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>)
