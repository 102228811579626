import React, { useState } from 'react'
import { usLacrosseService } from '../../services/campersServices';
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { LacroseStatusMessage, USLMembershipExpMsg, uslMemSecCheck } from './helper';
import HtmlParser from '../HTMLParser';

const getStatusClass = (status) => {
    const className = {
        Active: 'success',
        Pending: 'primary',
        Suspended: 'danger'
    }
    return className[status];
}

const LacroseCheck = ({ camperId, Email, USLResponseSuccess, setUSLResponseSuccess, programEndDate }) => {
    const [USLMemberID, setUSLMemberID] = useState('');
    const [USLMemberIDErr, setUSLMemberIDErr] = useState('');
    const [USLResponseMsg, setUSLResponseMsg] = useState('');
    const [lastName, setLastName] = useState('');
    const [lastNameErr, setLastNameErr] = useState('');
    const [secCheckFail, setSecCheckFail] = useState(false)

    const uslValidate = () => {
        let validate = true;
        if (USLMemberID === '') {
            setUSLMemberIDErr('USAL member ID is required');
            validate = false;
        }
        return validate;
    }
    const resetData = () => {
        setLastNameErr('')
        setSecCheckFail(false)
        setUSLResponseMsg('')
        setUSLResponseSuccess('')
    }


    const checkUsLacrosse = () => {
        resetData();
        if (uslValidate()) {

            let params = {
                USLMemberID: USLMemberID,
                LastName: lastName,
                Email,
                camperId
            }

            usLacrosseService(params).then(res => {
                if (!res?.data?.statusCode) {
                    if (res?.data?.error?.errors?.message) {
                        setUSLResponseMsg(res?.data?.error?.errors?.message.split(':')[0])
                    } else {
                        setUSLResponseMsg(res?.data?.error?.responseMessage)
                    }
                    setUSLResponseSuccess('');
                }
                if (res?.data?.statusCode) {
                    const respDate = res?.data?.responseData?.output;
                    setUSLResponseSuccess(respDate);
                    respDate.mem_status === 'Active' && setSecCheckFail(uslMemSecCheck(respDate.exp_date, programEndDate))
                }
            })
        }
    }
    const handleUSLIDChange = (e) => {
        setUSLMemberID(e.target.value)
        setUSLMemberIDErr('')
    }
    return (
        <Container>
            <Row><label className="ms-3"><b>Verification Required <small>*</small></b></label></Row>
            <Row>
                <Col className="col-md-5 form-group cm_top_info_space">
                    <label className="with_a_link">USAL Member ID <a href="https://membership.usalacrosse.com/login" target="blank">(https://membership.usalacrosse.com/login)</a></label>
                    <input
                        value={USLMemberID}
                        onChange={e => handleUSLIDChange(e)}
                        className={`form-control ${USLMemberIDErr ? 'is-invalid' : ''}`}
                        type="text" />

                    {USLMemberIDErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{USLMemberIDErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                </Col>

                <Col className="col-md-5 form-group cm_top_info_space">
                    <label className="with_a_link">Last Name</label>
                    <input
                        value={lastName}
                        onChange={e => (setLastName(e.target.value), setLastNameErr(''))}
                        className={`form-control ${lastNameErr ? 'is-invalid' : ''}`}
                        type="text" />

                    {lastNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{lastNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                </Col>

                <Col className="col-md-2">
                    <label className="with_a_link mb-0" />
                    <span onClick={checkUsLacrosse} className="btn btn-secondary w-100">Check</span>
                </Col>
            </Row>
            <Row className="card col-md-12 mb-2">
                {USLResponseSuccess?.mem_status ?

                    <Col className="card-body d-flex flex-column col-md-12">
                        <small>Membership Status: <b className={`text-${getStatusClass(USLResponseSuccess.mem_status)}`}>{USLResponseSuccess.mem_status}</b></small>
                        <small>Expiration Date: {moment(USLResponseSuccess.exp_date).format('MM/DD/YYYY')}</small>
                    </Col>
                    : ""
                }
                {USLResponseMsg &&
                    <Col className="card-body col-md-12">
                        <small className='text-danger'>{USLResponseMsg}</small><br />
                    </Col>
                }
                <Col>
                    <span className='text-danger'>
                        {
                            secCheckFail ?
                                <HtmlParser className='text-danger' htmlString={USLMembershipExpMsg} />
                                :
                                <HtmlParser className='text-danger' htmlString={LacroseStatusMessage[USLResponseSuccess?.mem_status?.toUpperCase()]} />
                        }
                    </span>
                </Col>
            </Row>
        </Container>
    )
}

export default LacroseCheck
