import React, { Fragment, memo,} from 'react'
import { getHtml } from '../../helper'
import Select from 'react-select';

const AdditionalExpComp = memo(({ additionalExperience, setAdditionalExperience, handleAddAdditionalExp }) => {
    const handleAdditionalExperienceQuantity = (index, type) => {
        let temp = [...additionalExperience];
        temp[index].additionalExperienceId = additionalExperience[index]._id;
        if (typeof temp[index].quantity === 'undefined') {
            temp[index].quantity = 0;
            temp[index].numberOfBooking = 0;
        }
        if (type) {
            const qnt = (temp[index].quantity || 0) + 1;
            temp[index].quantity = qnt;
            temp[index].numberOfBooking = qnt;
        } else {
            if (temp[index].quantity > 0) {
                const qnt = (temp[index].quantity || 0) - 1;
                temp[index].quantity = qnt
                temp[index].numberOfBooking = qnt;
            }
        }
        setAdditionalExperience([...temp]);
    };
    const handleAdditionalExperienceAnsChange = (val, index) => {
        let temp = [...additionalExperience];
        temp[index].question.answer = val;
        temp[index].question.answerErr = '';
        setAdditionalExperience([...temp]);
    }
    const handleAdditionalExperienceChecked = index => {
        let temp = [...additionalExperience];
        if (!temp[index].quantity) {
            // setAlertModal(true);
        } else {
            temp[index].quantity = 0;
        }
        setAdditionalExperience([...temp]);
    }

    const handleAdditionalExperienceAnsCheckboxChange = (val, index) => {
        let temp = [...additionalExperience];
        temp[index].question.answer = val;
        temp[index].question.answerErr = '';
        temp[index].question.isOtherOptionSelected = 0;
        setAdditionalExperience([...temp]);
    }

    const handleIsOtherOptionClick = (index) => {
        let temp = [...additionalExperience];
        temp[index].question.answer = '';
        temp[index].question.answerErr = '';
        temp[index].question.isOtherOptionSelected = temp[index]?.question?.isOtherOptionSelected ? 0 : 1;
        setAdditionalExperience([...temp]);
    }
    const handleAdd = (index) => {
        let temp = [...additionalExperience];
        if(temp[index].question.isRequred && !temp[index].question.answer){
            temp[index].question.answerErr = "Error: This field is required. Please provide an answer.";
            setAdditionalExperience([...temp]);
            return
        }
        handleAddAdditionalExp(index)
    }

    return (
        <Fragment>
            {
                additionalExperience.length > 0 ? (
                    <Fragment>
                        <label>Additional Experiences</label>
                        <ul className="campers_add_exp">
                            {
                                additionalExperience.map((item, index) => (
                                    <li key={index} id={item._id}>
                                        <div style={{ color: item.color }}>
                                            <p><div><b>Title</b></div> {getHtml(item.title)}</p>
                                            <p><b>Price</b>: ${item.price}</p>
                                            <p><div><b>Description </b></div> {getHtml(item.description)}</p>
                                            <div className="quantity mb-3">
                                                <button onClick={() => handleAdditionalExperienceQuantity(index, 0)} className="inc" />
                                                <b>{item.quantity || 0}</b>
                                                <button onClick={() => handleAdditionalExperienceQuantity(index, 1)} className="dec" />
                                            </div>
                                            <div className='d-flex justify-content-end col-12'>
                                                <span className='btn btn-sm btn-primary px-4' onClick={() => handleAdd(index)}>Add</span>
                                            </div>
                                            <div className="check_box_wrapper ms-auto">
                                                <input checked={item.quantity ? true : false} onChange={() => handleAdditionalExperienceChecked(index)} type="checkbox" />
                                                <span className="check_box"><span /></span>
                                            </div>
                                        </div>



                                        {
                                            (item.quantity && item.question) ? (
                                                <Fragment>
                                                    <div className="form-group mb-3 mt-3">
                                                        {item.question?.title ? <p className='m-0'>Q. {getHtml(item.question.title)} {item?.question?.isRequred && <span className='text-danger'>*</span>}</p> : ''}
                                                        {item.question.subTitle ? <p><small>{getHtml(item.question.subTitle)}</small></p> : ''}
                                                    </div>

                                                    {
                                                        item.question.questionType === 1 ? (
                                                            <Fragment>
                                                                <textarea
                                                                    value={item?.question?.answer}
                                                                    onChange={e => handleAdditionalExperienceAnsChange(e.target.value, index)}
                                                                    className="form-control" />
                                                            </Fragment>
                                                        ) : ''
                                                    }


                                                    {
                                                        item.question.questionType === 2 ? (
                                                            <Fragment>
                                                                <Select
                                                                    className="form-control"
                                                                    placeholder="Select"
                                                                    onChange={e => handleAdditionalExperienceAnsChange(e.value, index)}
                                                                    classNamePrefix="cm_select"
                                                                    options={item.question.options.map(im => ({ label: im.opt, value: im.opt }))} />
                                                            </Fragment>
                                                        ) : ''
                                                    }


                                                    {
                                                        item.question.questionType === 4 ? (
                                                            <Fragment>
                                                                {item.question.options.length ? item.question.options.map((im, ind) => (
                                                                    <div className="check_box_wrapper cm_dark_check cm_dark_check_2" key={ind}>
                                                                        <input
                                                                            checked={im.opt === item.question.answer ? true : false}
                                                                            onChange={() => handleAdditionalExperienceAnsCheckboxChange(im.opt, index)}
                                                                            type="checkbox" />
                                                                        <span className="check_box"><span></span></span>
                                                                        <p>{im.opt}</p>
                                                                    </div>
                                                                )) : ''}

                                                                {
                                                                    item?.question?.isOtherOption === 1 ? (
                                                                        <div className="check_box_wrapper cm_dark_check cm_dark_check_2">
                                                                            <input
                                                                                checked={item?.question?.isOtherOptionSelected ? true : false}
                                                                                onChange={() => handleIsOtherOptionClick(index)}
                                                                                type="checkbox" />
                                                                            <span className="check_box"><span></span></span>
                                                                            <p>Other</p>
                                                                        </div>
                                                                    ) : ''
                                                                }

                                                            </Fragment>
                                                        ) : ''
                                                    }


                                                    {
                                                        item?.question?.isOtherOptionSelected ? (
                                                            <div className='pt-3 ps-4'>
                                                                <textarea
                                                                    value={item?.question?.answer}
                                                                    onChange={e => handleAdditionalExperienceAnsChange(e.target.value, index)}
                                                                    className="form-control" />
                                                            </div>
                                                        ) : ''
                                                    }

                                                    {
                                                        item?.question?.answerErr ? <h6 className='cm_err mt-2'>{item.question.answerErr}</h6> : ''
                                                    }

                                                </Fragment>
                                            ) : ''

                                        }

                                    </li>
                                ))
                            }
                        </ul>
                    </Fragment>
                ) : ''

            }
        </Fragment>
    )
})

export default AdditionalExpComp
