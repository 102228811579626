import React, {useState, useEffect} from 'react';
import { connect } from "react-redux";
import LoginUserCart from './loginUserCart';
import NotloginUserCart from './notloginUserCart';

const Cart = (props) => {

    return(
        <div className="body_container">
            <div className="container p-0 mt-4 mb-5">
                <div className="row g-0">
                    <div className="col-lg-10 mb-5">
                        {
                            props.loggedIn ? <LoginUserCart/> : <NotloginUserCart/>
                        }              
                    </div>
                </div>
            </div>    
        </div>   
    )
}

const mapStateToProps = state => {
    let {cartitems} = state.cartReducer.cartitems;
    let { loggedIn } = state.user;
    return {
        cartitems:cartitems,
        loggedIn
    };
}
  
const mapDispatchToProps = dispatch => ({
    
    
});

export default connect(mapStateToProps,mapDispatchToProps)(Cart);
