import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Empty } from 'antd';

import { getPersonnelActivityListService } from '../../services/directorServices';
import { ProgramLoader } from '../reuse/SVG';


const ActivityViewList = props => {
    const { _id } = props.userProfile;
    const [loader, setLoader] = useState(true);
    const[pAList, setPAList] = useState([]);
    
    
    useEffect(()=>{
        getPersonnelActivityListService(`/?personnelid=${_id}`).then(res => {
            setLoader(false);
            if(res?.data?.statusCode){
                setPAList(res.data.responseData.result || []);   
            }
        })
    },[])

    return(
        <div className="card mb-4 pro_wrapper">
            {loader ? <div className="section_loader"><ProgramLoader /></div> : ''}
            <h4 className="text-center mb-5">Activity View List</h4>
                
            {pAList.length ?
                pAList.map(item => (
                    <div className="d-flex align-items-center counselor_card cm_mob_pad_0" key={item._id}>

                        
                        <div>
                        <p><b>Name</b> : {item.activityName}</p>
                        <p><b>Equipment</b>: {item.equipment}</p>
                        <p><b>Cost</b>: {item.cost ? item.cost : '0'}</p>
                        <p><b>Time needed</b>: {item.timeNeeded}</p>
                        </div>
                        <div className="ms-auto">
                            <Link to={`/activity/edit/${item._id}`} className="btn btn-primary">View</Link>
                        </div> 
                    </div>
                ))
                :
                <div className="col-12 mb-5"><div className="cm_empty"><Empty description="No Activity Found" /></div></div>
            }
        </div>
    )
}


const mapStateToProps = state => {
    let { userProfile } = state.user; 
    return{
        userProfile
    }
}

export default connect(mapStateToProps, null)(ActivityViewList);