import React, { useState } from 'react'
import { getUsersDetailById } from '../../../services/directorServices';
import { Modal } from 'react-bootstrap';
import { convertTimeToCurrentTimeZone, resHandle } from '../../../helper';
const tableHeaders = ['Title', 'Price', "Value", "Updated At", 'Updated By']


const AdditionalExpAudit = ({ show, setShow, history, timezone, additionalExpData }) => {
    const expData = new Map(additionalExpData.map(item => [item._id, { title: item.title, price: item.price }]));
    const [historyData, setHistoryData] = useState(history ?? []);

    const fetchUserdata = async () => {
        const params = { userIds: [...new Set(historyData.map(item => item.auditUser))] };
        try {
            const res = await getUsersDetailById(params);
            let { status, data: { result } } = resHandle(res);
            if (status) {
                const nameMap = new Map(result.map(item => [item._id, `${item.name} ${item?.lastName ?? ''}`]))
                const mappedName = historyData.map(item => ({
                    ...item,
                    name: nameMap.get(item.auditUser) || '-/-'
                }));
                setHistoryData(mappedName)
            }
        } catch (error) {
            console.log(error);
        }

    }
    return (
        <Modal
            dialogClassName="h_auto"
            show={show}
            size='lg'
            animation={false}
            centered
        >
            <Modal.Header>
                <Modal.Title>Additional Experience Audits</Modal.Title>
                <div className='d-flex justify-content-end'>
                    <button className='btn btn-sm btn-primary ms-5' onClick={fetchUserdata}>
                        Show users
                    </button>
                </div>
                <span className="modal_close" onClick={() => setShow(false)}><i className="icon-cross" /></span>
            </Modal.Header>
            <Modal.Body className='d-flex flex-column justify-content-center'>

                <div className="overflow-x-auto justify-content-center">
                    <table className="min-w-full border border-gray-300">
                        <thead>
                            <tr className="bg-gray-100">
                                {tableHeaders.map((key) => (
                                    <th key={key} className="px-4 py-2 border"><small><b>{key}</b></small></th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {historyData?.length &&  historyData.map((row) => (
                                <tr key={row?.auditDate} className="border">
                                    <td className="px-4 py-2 border"><small>{expData.get(row.additionalExperienceId)?.title}</small></td>
                                    <td className="px-4 py-2 border"><small>$ {expData.get(row.additionalExperienceId)?.price}</small></td>
                                    <td className="px-4 py-2 border"><small>{row.auditType === 1 ? "ADDED" : "REMOVED"}</small></td>
                                    <td className="px-4 py-2 border"><small>{convertTimeToCurrentTimeZone(row.auditDate, timezone, 'MM/DD/YY hh:mm a')}</small></td>
                                    <td className="px-4 py-2 border"><small>{row?.name ? row?.name : "-/-"}</small></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AdditionalExpAudit
