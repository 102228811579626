import React, { useState } from 'react'
import LoginBg from '../../assets/img/login-bg.jpg';
import OtpInput from 'react-otp-input';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { verifyFastEmailotpAction } from '../../store/user';

const AdminLogin = (props) => {
    let { userId, isAdmin } = props.match.params;
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [loader, setLoader] = useState(false);

    const handleOtpChange = otp => {
        setOtp(otp);
        setOtpError('');
    };
    const handleValidate = () => {
        let validate = true;
        if (otp === '') {
            setOtpError('otp is required');
            validate = false
        }
        if (otp.toString().length !== 4) {
            setOtpError('otp is require length');
            validate = false
        }
        return validate;
    }
    const handleOtpSubmit = e => {
        e.preventDefault();

        if (handleValidate()) {
            setLoader(true);
            let params = {
                userId,
                otp,
                isAdmin,
            }
            props.verifyFastEmailotpAction(params).then(res => {
                setLoader(false);                
            });
        }
    }
    return (
        <Container className='p-3'>
            <Card>
                <Row>
                    <Col className="col-lg-6 hide_in_mob">
                        <img src={LoginBg} loading='lazy' alt='login' />
                    </Col>
                    <Col className='d-flex align-items-center'>
                        <form onSubmit={handleOtpSubmit}>
                            <h3 className="text-center mb-4">Sign In</h3>
                            <>
                                <div className="otp_inputs">
                                    <OtpInput
                                        onChange={handleOtpChange}
                                        isInputNum={true}
                                        shouldAutoFocus={true}
                                        name="otp"
                                        value={otp}
                                        numInputs={4}
                                        separator={''}
                                    />
                                    {otpError ? <div className="invalid-feedback">{otpError}</div> : ''}
                                </div>
                            </>
                            <button
                                type="submit"
                                disabled={loader}
                                className={`btn btn-primary rounded-0 w-100 mt-3 btn_anim ${loader ? 'show_anim' : ''}`}>{loader ? 'Loading...' : 'Confirm OTP'}</button>
                            {props.error && <p className="error_msg">{props.error}</p>}

                        </form>
                    </Col>
                </Row>
            </Card>

        </Container>

    )
}

const mapStateToProps = state => {
    let { loading, error, loggedIn } = state.user;
    let { isDirecterAuth } = state.ui;
    return {
        loading,
        error,
        isDirecterAuth,
        loggedIn
    };
}

const mapDispatchToProps = dispatch => ({
    verifyFastEmailotpAction: params => dispatch(verifyFastEmailotpAction(params)),
});
  
export default connect(mapStateToProps,  mapDispatchToProps)(AdminLogin);