import React, {useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { compose, withProps } from 'recompose';
import { Modal, Dropdown, OverlayTrigger, Tooltip, Container, Row, Col } from 'react-bootstrap';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import {notification, Rate, Empty} from 'antd';
import { Redirect, Link, useHistory } from 'react-router-dom';
import Scholarships_bg from '../../assets/img/scholarships_bg.jpg';
import CampsUser from '../../assets/img/camps-user.png';
import setting from '../../config/settings';

import {
    viewProgramDetail,
    getCartItemsNonLoginUser,
    getCartItems,
    addToCart,
    programFavourite,
    followcamps,
    userguest,
    addToCartNonLoginUser,
    getReview
} from '../../services/programService';
import { gradeArr2, getExperience, getProgramType, getFacility, gerFavpropgramdate, getHtml, urlify } from '../../helper';
import { LogoWhite, ProgramLoader,CardLoader } from '../reuse/SVG';
import { campContectAction, toggleMapViewModal, viewLoginModalAction } from '../../store/ui';
import AssociatedCard from './AssociatedCard';
import {getitemsincart} from '../../store/cart'
import {askQuestionService} from '../../services/otherService';
import Share from '../static/Share';
import moment from 'moment';

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};


const MapComponent = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCdDH9dkoLecpVX7gNRSkYMMV1eUfQ7VE0&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `180px` }} />,
      mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )((props) =>
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{lng: props.geoLocation ? props.geoLocation[0] : 0, lat: props.geoLocation ? props.geoLocation[1] : 0 }}
      >
            <Marker
              position={{lng: props.geoLocation ? props.geoLocation[0] : 0, lat: props.geoLocation ? props.geoLocation[1] : 0 }}
              icon={{
                url: require('../../assets/img/mapIcon.svg')
                }}
            >
            </Marker>
    </GoogleMap> 
  );


const CampDetail = props => {
    const todayDate2 = new Date().getTime();
    let history                                             = useHistory();
    const[loader, setLoader]                                = useState(true);
    const[programactionloader, setProgramActionLoader]      = useState(false);
    const[numberofCampers, setNumberOfCampers]              = useState(1);
    const[addtocartModal, setaddtocartModal]                = useState(false);
    const[campDetails, setCampDetails]                      = useState([]);
    const[campPrograms, setCampPrograms]                    = useState([]);
    const[additionalExp, setAdditionalExp]                  = useState();
    const[redirect, setRedirect]                            = useState(false);
    const[isFavouritecamp, setIsFavourite]                  = useState();
    const[isFollowed, setIsFollowed]                        = useState();
    const[btnloder, setBtnLoader]                           = useState(false);
    const[isAddedToCart, setIsAddedToCart]                  = useState(false);
    const[isConfirmed, setIsConfirmed]                      = useState(false);
    const[question, setQuestion]                            = useState('');
    const[btnDisable, setBtnDisable]                        = useState(false);
    const[reviews, setReviews]                              = useState([]);
    const[averageCampRating, setAverageCampRating]          = useState(0);
    const[viewAllModal, setViewAllModal]                    = useState(false);
    const[scholarships, setScholarships]                    = useState('');
    const[scholarshipsUrl, setScholarshipsUrl]              = useState('');
    const[others, setOthers]                                = useState([]);
    const[additionalDates, setAdditionalDates]              = useState([]);
    const[selectedDates, setSelectedDates]                  = useState([]);
    const[byDates, setByDates]                              = useState(2);
    const[showSelectDateModal, setShowSelectDateModal]      = useState(false);
    const [maxSlots, setMaxSlots]                           = useState(1);
    const [showError, setShowError]                         = useState(false);
 

    const onChangeSelectedDates = item => {
        let temp = [...selectedDates];
        if(temp.includes(item)){
            let new_temp = temp.filter(i => i !== item) 
            setSelectedDates(new_temp)
            const total_slots = Math.min(...new_temp.map((i) => i.slots));
            setMaxSlots(total_slots);
        }else{
            temp.push(item);
            setSelectedDates(temp);
            const total_slots = Math.min(...temp.map((i) => i.slots));
            setMaxSlots(total_slots);
        }
    } 


    useEffect(() => {
       setNumberOfCampers(1);
    }, [selectedDates])


    useEffect(() => {
        if(props.mapViewModal){
            props.toggleMapViewModal()
        }
        
    }, [])


    const getTotalPrice = items => {
        let temp = 0;
        if(items.length){
            items.map(i => temp += i.standardPrice);
            if(temp > 0){
                return `Price : $${temp.toFixed(2)}`
            }else {
                return 'Free'
            }
        }else {
            return ''
        }
        
    }


    useEffect(() => {
        if(campDetails.campId){
            let query =`?campId=${campDetails.campId}`
            getReview(query).then(res => {
                if(res?.data?.statusCode){
                    setReviews(res.data.responseData.result);
                    setAverageCampRating(res.data.responseData.averageCampRating);
                }
            })
        }
        
    }, [campDetails])


    useEffect(() => {
        let userId = props.userID;
        let params ='';
       if(userId)
       {
          params = `?userId=${userId}&programId=${props.match.params.id}`;    
       }
       else
       {
        params = `?programId=${props.match.params.id}`; 
       }

        let program_details = viewProgramDetail(params);
        program_details.then((res)=>{
            setLoader(false)  
            if(res?.data?.statusCode){
                
                setCampDetails(res.data.responseData.result.programData);
                setCampPrograms(res.data.responseData.result.campPrograms);
                setIsFavourite(res.data.responseData.result.programData.isFavourite)
                setIsFollowed(res.data.responseData.result.programData.isFollowed)
                setScholarships(res.data.responseData.result.programData.scholarships);
                setScholarshipsUrl(res.data.responseData.result.programData.scholarshipsUrl);
                setOthers([...res.data.responseData.result.programData.personnel, ...res.data.responseData.result.programData.counselor]);
                setByDates(res.data.responseData.result.programData.byDates);
                res.data.responseData.result.programData.byDates === 2 && setMaxSlots(res.data.responseData.result.programData?.availableSlots);
                setNumberOfCampers(res.data.responseData.result.programData.numberOfBooking || 1);
                if(res.data.responseData.result.programData.byDates === 1){
                    let _additionalDates = res.data.responseData.result.programData.additionalDates.filter(mi => mi.slots > 0 && new Date() < mi.endDate);
                    let _selectedDate = res.data.responseData.result.programData.selectedDates;
                    setAdditionalDates(_additionalDates || []);
                    let _selectedDate2 = [];
                    if(_selectedDate?.length){
                        for (let i = 0; i < _additionalDates.length; i++) {
                            if(_selectedDate.includes(_additionalDates[i]._id)){
                                _selectedDate2.push(_additionalDates[i]);
                            }
                          }

                          setSelectedDates(_selectedDate2);
                    }
                    
                }
             
                let registrationStataus = 'booking';
                
                let registerOpenDate = res.data.responseData.result.programData.registerOpenDate
                let registrationCloseDate = res.data.responseData.result.programData.registerCloseDate 
                
                let dateEarlyRegisterOpen = res.data.responseData.result.programData.dateEarlyRegisterOpen;
                let dateEarlyRegisterClosed = res.data.responseData.result.programData.dateEarlyRegisterClosed;
                
                let dateLateRegisterOpen = res.data.responseData.result.programData.dateLateRegisterOpen;
                let dateLateRegisterClosed = res.data.responseData.result.programData.dateLateRegisterClosed;
                
                let time = new Date();
                let todaydate = time.getTime();

                if(dateEarlyRegisterOpen!==undefined && todaydate<dateEarlyRegisterOpen)
                {
                    registrationStataus = 'commingsoon';
                }else{
                    if(todaydate<dateEarlyRegisterOpen)
                    {
                        registrationStataus = 'commingsoon'; 
                    }
                    if(todaydate > dateEarlyRegisterClosed && todaydate<registerOpenDate)
                    {
                        registrationStataus = 'commingsoon';  
                    }
                }

                if(dateLateRegisterClosed!==undefined && todaydate > dateLateRegisterClosed)
                {
                    registrationStataus = 'closed';  
                }
                else
                {
                    if(todaydate > dateLateRegisterClosed)
                    {
                        registrationStataus = 'closed'; 
                    }
                    if(todaydate < dateLateRegisterOpen && todaydate > registrationCloseDate)
                    {
                        registrationStataus = 'commingsoon';    
                    }  
                }
            


                let tax = res.data.responseData.result.programData.tax;
                let registrationfee = res.data.responseData.result.programData.registrationFee;
                let convenienceFee = res.data.responseData.result.programData.convenienceFee;
                let discounttype = res.data.responseData.result.programData.discountType;
                let discountamount = res.data.responseData.result.programData.discount;
                let campamount = '';
                let taxamont  = res.data.responseData.result.programData.taxPrice;
                if(discounttype===1)
                {
                    campamount = registrationfee + discountamount  - taxamont - convenienceFee;
                    
                }
                else{
                    campamount = registrationfee + discountamount  - taxamont - convenienceFee;
                    discountamount = registrationfee*discountamount/100;
                }
                
               
               
                if(res.data.responseData.result.programData.isCartConfirmed){
                    setIsConfirmed(true)   
                }
                if(res.data.responseData.result.programData.isAddedToCart){
                    setIsAddedToCart(true)
                }
                let additionexp = res.data.responseData.result.programData.additionalExp;
                
                let additionalobj ={}
                if(additionexp !== undefined)
                {
                additionexp.map((item,index) => {
                    let key = index;
                    additionalobj[key] = {"_id": item._id, "quantity":0, "price":item.price,"title": item.title}
                    if(item.description !== undefined)
                    {
                        additionalobj[key].description = item.description;   
                    }
                })
                setAdditionalExp(additionalobj);
                }

                let _campContect = {
                    campPhone: res.data.responseData.result.programData.campPhone, 
                    campEmail: res.data.responseData.result.programData.campEmail
                }

                props.campContectAction(_campContect)


                }else{
                    history.push("/camps")
                }         
        })


        return () => {
            props.campContectAction({})
        }

       
       
    }, [])



     /*******Add to cart for non login users***** */
     const addtocartAction = async(programid, numberofcampers, buttontype) =>
     {
        if(numberofCampers > maxSlots){
            setShowError(true)
            return;
        } 
        let service = props.loggedIn ? getCartItems : getCartItemsNonLoginUser
        setBtnLoader(true);
        if(props.loggedIn){
            let programId = programid;
            let numberOfBooking = numberofcampers;
            let params = {programId, numberOfBooking, fromProgramDetail: 1};
            if(byDates === 1){
                let _selectedDatesIds = selectedDates.map(i => i._id);
                params.selectedDates = JSON.stringify(_selectedDatesIds);
            }
            

            addToCart(params).then(res => {
                if(res.data.statusCode === 1){
                    service().then(res=>{
                        
                        if(res?.data?.statusCode){
                            props.getitemsincart(res.data.responseData.result.length)
                        }
                    })

                    if(buttontype === 'addtocart2'){
                        history.push({
                            pathname: '/profile/information',
                            state: {
                                "numbderofCampers": 1,
                                "cart_id": res.data.responseData.cartId
                            }
                        })
                    }

                    if(buttontype === 'addtocart'){
                            setaddtocartModal(true);
                            setBtnLoader(false);
                            setIsAddedToCart(true)
                        }else{
                            setRedirect(true);
                        }
                }else{
                    if(res.data.error.errorCode === 25){
                        history.push("/cart")
                    }
                }
            });  
        }
        else
        {
        let nonLoginaccessToken = localStorage.getItem('NonLoginaccessToken'); 
           if(!nonLoginaccessToken)
           {
            let guestuser = await userguest()
            try{
                if(guestuser.data.statusCode===1)
                {
                    localStorage.setItem('NonLoginaccessToken', guestuser.data.responseData.accessToken);
                    localStorage.setItem('NonLoginUserID', guestuser.data.responseData.userProfile._id);
                    let programId = programid;
                    let numberOfBooking = numberofcampers;
                    let params = {programId, numberOfBooking}  
                    addToCartNonLoginUser(params).then(res=>{
                        if(res?.data?.statusCode)
                        {
                            if(buttontype==='addtocart')
                                {
                                    setaddtocartModal(true);
                                    setBtnLoader(false);
                                    setIsAddedToCart(true)
                                }    
                                else
                                {
                                    setRedirect(true);
                                }
                                service().then(res=>{
                                    if(res?.data?.statusCode)
                                    {
                                        props.getitemsincart(res.data.responseData.result.length)
                                    }
                                })
                        }
                    });
                }
            }catch(error){}            
           } 
           else
           {
               let programId = programid;
               let numberOfBooking = numberofcampers;
               let params = {programId, numberOfBooking}  
               addToCartNonLoginUser(params).then(res=>{
                if(res?.data?.statusCode)
                {
                    if(buttontype==='addtocart')
                        {
                            setaddtocartModal(true);
                            setBtnLoader(false);
                        }    
                        else
                        {
                            setRedirect(true);
                        }
                        service().then(res=>{
                            if(res?.data?.statusCode)
                            {
                                props.getitemsincart(res.data.responseData.result.length)
                            }
                        })
                }
            });
           }

            const oldproduct = localStorage.getItem('cartitems') ? localStorage.getItem('cartitems') : "[]";
            const arrayproduct =  JSON.parse(oldproduct);  
            if(arrayproduct.length>0)
            {
                let prevaddproducts = arrayproduct.filter(cartitems => cartitems.programId ===programid);
                if(prevaddproducts.length>0)
                {
                    let prevaddproductquantity = prevaddproducts[0].numberOfBooking+1;
                    let index = arrayproduct.findIndex(cartitems => cartitems.programId ===programid);
                    arrayproduct[index].numberOfBooking = prevaddproductquantity;
                    localStorage.setItem('cartitems', JSON.stringify(arrayproduct)); 
                }
                else
                { 
                    let additional_exp_cart = [];
                
                    if(additionalExp)
                    {
                        for (const property in additionalExp) { 
                                let addition_exp = {"_id" : additionalExp[property]._id,"price": additionalExp[property].price, "title":additionalExp[property].title }
                                if( additionalExp[property].description !== undefined)
                                {
                                    addition_exp.description = additionalExp[property].description;   
                                }
                                additional_exp_cart.push(addition_exp);
                        }
                    }                    
                    
                let cartdata ={"programId":programid,"numberOfBooking":numberofCampers, "additionalExperience": additional_exp_cart, "registrationFee": campDetails.registrationFee, "convenienceFee": campDetails.convenienceFee, "programName": campDetails.programName, "startDate": campDetails.startDate, "endDate":campDetails.endDate}
                arrayproduct.push(cartdata);
                localStorage.setItem('cartitems', JSON.stringify(arrayproduct));  
                }
                setBtnLoader(false);
            }
            else
            {
                let additional_exp_cart = [];
                
                if(additionalExp)
                {
                    for (const property in additionalExp) { 
                            let addition_exp = {"_id" : additionalExp[property]._id,"price": additionalExp[property].price, "title":additionalExp[property].title }
                            if( additionalExp[property].description !== undefined)
                            {
                                addition_exp.description = additionalExp[property].description;   
                            }
                            additional_exp_cart.push(addition_exp);
                    }
                }

               // let cartdata ={"programID":programid,"numberOfBooking":numberofCampers, "additionalExperience": additional_exp_cart}
                

                let cartdata ={"programId":programid,"numberOfBooking":numberofCampers, "additionalExperience": additional_exp_cart, "registrationFee": campDetails.registrationFee, "convenienceFee": campDetails.convenienceFee }

                arrayproduct.push(cartdata);
                localStorage.setItem('cartitems', JSON.stringify(arrayproduct)); 
                setBtnLoader(false);
            }
        }
        
       



    }


    const handleFavourite = (programId, favourite) => {
        setProgramActionLoader(true);
        let params = {
            programId: programId,
            favourite: favourite
        }

        programFavourite(params).then(res => {
            setProgramActionLoader(false);
            setIsFavourite(favourite);
        })
    }
    const followCamps = async(campId,follow) => {
        setProgramActionLoader(true);
        const followCamp = await followcamps({campId,follow});
        try{
            if(follow){
                setIsFollowed(true);
                setProgramActionLoader(false);
                notification.open({
                    description: 'You started following this camp ',
                });
            }else{
                setIsFollowed(false);
                setProgramActionLoader(false);
                notification.open({
                    description: 'You started unfollow this camp ',
                });

            }
        }
        catch(err){
           
        } 
    }  
    
    


    const handleQuestionSubmit = () => {

        if(question !== ''){
            setBtnDisable(true);
            let params = {
                question, 
                programId: campDetails._id,
                email: props.userProfile.email
            }
            askQuestionService(params).then(res => {
                setBtnDisable(false);
                if(res?.data?.statusCode){
                    setQuestion('');
                    notification.open({
                        description:
                          'Question Submitted',
                      });
                }
            })
        }
    }
  
    const onNumberOfCampersUpdate = (type) => {
        setShowError(false);
        switch (type) {
            case 'add':
                setNumberOfCampers(numberofCampers < maxSlots ? numberofCampers + 1 : maxSlots);
                break;
            case 'remove':
                setNumberOfCampers(numberofCampers > 1 ? numberofCampers - 1 : 1);
                break;
            case 'default':
                console.log('error in the updating counts')
                break;
        }
        let errorCount = numberofCampers + 1;
        if (errorCount > maxSlots) {
            setShowError(true);
        }
        setIsAddedToCart(false)

    }


    if (redirect)
    return (<Redirect to='/cart'  />)
  

    return(
        
        <div className="body_container program_page">

            <div className="container">
                <Modal
                    dialogClassName="h_auto"
                    show={addtocartModal}
                    animation={false}
                    centered
                >
                <Modal.Body>
                    <div className="p-5">
                        <h3 className="text-center mb-4">Success!</h3>
                        <p className="text-center">Successfully added to cart </p>
                        <button onClick={()=>setaddtocartModal(false)}className="btn btn-primary w-100 mt-2">Ok</button>
                    </div>
                </Modal.Body>
                </Modal>



                <Modal
                    dialogClassName="h_auto"
                    show={showSelectDateModal}
                    animation={false}
                    centered
                >
                <Modal.Body>
                    <div className="p-5">
                        <h3 className="text-center mb-4">Warning!</h3>
                        <p className="text-center">Please Select Program Date</p>
                        <button onClick={()=> setShowSelectDateModal(false)}className="btn btn-primary w-100 mt-2">Close</button>
                    </div>
                </Modal.Body>
                </Modal>


                <div className="d-flex justify-content-between program_head">
                    <div>
                    <div className='d-flex justify-content-between' >
                        <img src={campDetails.campLogo} height={"40px"} width={'50px'}  alt="camplogo"/>
                        <h2 className='ms-1'>{campDetails.campName} - {campDetails.programName}</h2>
                    </div>
                    </div>
                    <div className="text-center">
                        <div className="ratings">
                            <span>EZPro: </span>
                            <span className="icon-star" />
                            <span className="icon-star" />
                            <span className="icon-star" />
                            <span className="icon-star" />
                            <span className="icon-star" />
                        </div>
                        <small>({campDetails.ratingCount} reviews)</small>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-8 prog_main">

                        {/* <div className="prog_media">
                        {campDetails.isSpecialDeal ? <span className="SD_tag">Special Offers</span> :''}
                        {programactionloader ? <CardLoader/>: ''}
                            {campDetails.programImages && campDetails.programImages.length ? <Slider {...settings}>
                                                    {campDetails.programImages.map(item => (
                                                        <div className="prog_slide_img" key={item._id}>
                                                            <img src={item.url} className="img-fluid" />
                                                        </div>
                                                    ))}
                                                </Slider> : <div className="no_img"><LogoWhite /></div>
                            }

                            {
                            props.userID ? isFollowed ? 
                            <i onClick={()=> {followCamps(campDetails.campId,0)}} className="cm_follow icon-check"/> : 
                            <i onClick={()=> {followCamps(campDetails.campId,1)}} className="cm_follow icon-plus" />:
                            ''
                            }

                        <div className="prog_action">
                            {
                              props.userID ? isFavouritecamp ? 
                              <i  onClick={()=>handleFavourite(campDetails._id, 0)} className="icon-heart active" /> : 
                              <i onClick={()=>handleFavourite(campDetails._id, 1)} className="icon-heart" /> : ''    
                            }
                            <Share pageUrl={`ProgramDetail=${props.match.params.id}`} />    
                            
                                       
                          
                        </div>                      
                        </div> */}






                        <Container 
                         style={{height:"400", width:'660'}}
                        
                        className="prog_media prog_media_desk">
                        {campDetails.isSpecialDeal ? <span className="SD_tag">Special Offers</span> :''}
                        {programactionloader && <CardLoader/> }
                            {campDetails.programImages && campDetails.programImages.filter(i => !i.viewType).length ? <Slider {...settings}>
                                                    {campDetails.programImages.filter(i => !i.viewType).map(item => (
                                                        <div className="prog_slide_img" key={item._id}>
                                                            <img src={item.url} className="img-fluid" />
                                                        </div>
                                                    ))}
                                                </Slider> : campDetails.programImage ? <img src={campDetails.programImage} className="img-fluid" /> : <div className="no_img"><LogoWhite /></div>
                            }

                            {
                            props.userID ? isFollowed ? 
                            <i onClick={()=> {followCamps(campDetails.campId,0)}} className="cm_follow icon-check"/> : 
                            <i onClick={()=> {followCamps(campDetails.campId,1)}} className="cm_follow icon-plus" />:
                            ''
                            }

                        <div className="prog_action">
                            {
                              props.userID ? isFavouritecamp ? 
                              <i  onClick={()=>handleFavourite(campDetails._id, 0)} className="icon-heart active" /> : 
                              <i onClick={()=>handleFavourite(campDetails._id, 1)} className="icon-heart" /> : ''    
                            }
                            <Share pageUrl={`ProgramDetail=${props.match.params.id}`} />    
                         
                        </div>                      
                        </Container>




                        <div className="prog_media prog_media_mob">
                        {campDetails.isSpecialDeal ? <span className="SD_tag">Special Offers</span> :''}
                        {programactionloader ? <CardLoader/>: ''}
                            {campDetails.programImages && campDetails.programImages.filter(i => i.viewType).length ? <Slider {...settings}>
                                                    {campDetails.programImages.filter(i => i.viewType).map(item => (
                                                        <div className="prog_slide_img" key={item._id}>
                                                            <img src={item.url} className="img-fluid" />
                                                        </div>
                                                    ))}
                                                </Slider> : campDetails.programImage ? <img src={campDetails.programImage} className="img-fluid" /> : <div className="no_img"><LogoWhite /></div>
                            }

                            {
                            props.userID ? isFollowed ? 
                            <i onClick={()=> {followCamps(campDetails.campId,0)}} className="cm_follow icon-check"/> : 
                            <i onClick={()=> {followCamps(campDetails.campId,1)}} className="cm_follow icon-plus" />:
                            ''
                            }

                        <div className="prog_action">
                            {
                              props.userID ? isFavouritecamp ? 
                              <i  onClick={()=>handleFavourite(campDetails._id, 0)} className="icon-heart active" /> : 
                              <i onClick={()=>handleFavourite(campDetails._id, 1)} className="icon-heart" /> : ''    
                            }
                            <Share pageUrl={`ProgramDetail=${props.match.params.id}`} />    
                            
                                       
                          
                        </div>                      
                        </div>

                        {byDates === 1 ?
                        <div className='multi_dates hide_on_desktop'>
                            <ul>
                                {additionalDates.map((item, i) => (
                                    <li key={i}>
                                        <div className="check_box_wrapper cm_dark_check">
                                            <input
                                                onChange={e => onChangeSelectedDates(item)}
                                                checked={selectedDates.includes(item)}
                                                type="checkbox"
                                                name="selectedDate" />
                                            <span className="check_box"><span></span> {gerFavpropgramdate(item.startDate, item.endDate)} <b className='ms-3'>${item.standardPrice.toFixed(2)}</b></span>
                                        </div>
                                         <small className='cm_a_slots'>available slots: <b>{item?.slots}</b></small>                                         
                                    </li>
                                ))}
                            </ul>
                            
                        </div> : ''}


                        <div className="d-flex justify-content-between mt-3 mb-2 prog_d">
                        {byDates === 1 ? '' : <h3>{props.match.params.id === "621d3620c9cc80756fe0a171" ? "TBA" : gerFavpropgramdate(campDetails.startDate, campDetails.endDate)}</h3>}
                        
                        
                        

                            

                    {

                    byDates === 1 ? getTotalPrice(selectedDates) ? <div className="text-center">
                        {
                            isConfirmed ? <Link to="/cart" className="btn btn-primary btn-sm ms-1 rounded-0">Proceed to checkout</Link> : (
                                <React.Fragment>
                                    <button onClick={() => props.loggedIn ? addtocartAction(campDetails._id, numberofCampers, "addtocart") : props.viewLoginModalAction()} className={`btn btn-info  btn-sm me-1 rounded-0 ${btnloder ? 'btnLoader' : ''}`}>Add to Cart</button>
                                    <button onClick={() => props.loggedIn ? addtocartAction(campDetails._id,numberofCampers, "proceedTocheckout") : props.viewLoginModalAction()} className="btn btn-primary btn-sm ms-1 rounded-0">Check out</button>
                                </React.Fragment>
                            )
                        }
                </div> : <div className="text-center">
                                                <button onClick={() => setShowSelectDateModal(true)} className={`btn btn-info  btn-sm me-1 rounded-0`}>Add to Cart</button>
                                                <button onClick={() => setShowSelectDateModal(true)} className="btn btn-primary btn-sm ms-1 rounded-0">Check out</button>
                           
                                            </div> : (todayDate2 < campDetails.dateLateRegisterClosed && campDetails.status === 1)? 
                    <div className="text-center">
                        {
                            isConfirmed ? <Link to="/cart" className="btn btn-primary btn-sm ms-1 rounded-0">Proceed to checkout</Link> : (
                                <React.Fragment>
                                    <button onClick={() => props.loggedIn ? addtocartAction(campDetails._id, numberofCampers, "addtocart") : props.viewLoginModalAction()} className={`btn btn-info  btn-sm me-1 rounded-0 ${btnloder ? 'btnLoader' : ''}`}>Add to Cart</button>
                                    {isAddedToCart ? <Link to="/cart" className="btn btn-primary btn-sm ms-1 rounded-0">Check out</Link> : <button onClick={() => props.loggedIn ? addtocartAction(campDetails._id,numberofCampers, "proceedTocheckout") : props.viewLoginModalAction()} className="btn btn-primary btn-sm ms-1 rounded-0">Check out</button>}
                                </React.Fragment>
                            )
                        }
                    </div> : todayDate2 < campDetails.registerCloseDate ? 
                    <div className="text-center">         
                        {
                            isConfirmed ? <Link to="/cart" className="btn btn-primary btn-sm ms-1 rounded-0">Proceed to checkout</Link> : (
                                campDetails.status === 1 && campDetails.availableSlots ?
                                <React.Fragment>       
                                    <button onClick={() => props?.loggedIn ? addtocartAction(campDetails._id, numberofCampers, "addtocart") : props.viewLoginModalAction()} className={`btn btn-info  btn-sm me-1 rounded-0 ${btnloder ? 'btnLoader' : ''}`}>Add to Cart</button>
                                    {isAddedToCart ? <Link to="/cart" className="btn btn-primary btn-sm ms-1 rounded-0">Check out</Link> : (props?.loggedIn && campDetails.isApplicable === 1 && (!props.userProfile?.lastName || props.userProfile?.lastName === 'undefined') && (!props.userProfile?.phone || props.userProfile?.phone === 'undefined') && !props.userProfile?.gender && !props.userProfile?.dob && !props.userProfile?.address ) ? <button className="btn btn-primary btn-sm ms-1 rounded-0" onClick={() => addtocartAction(campDetails._id, 1, "addtocart2")}>Check out</button> : <button onClick={() => props.loggedIn ? addtocartAction(campDetails._id,numberofCampers, "proceedTocheckout") : props.viewLoginModalAction()} className="btn btn-primary btn-sm ms-1 rounded-0">Check out</button>}
                                </React.Fragment> : ''
                            )
                        }
                        </div> : ''
                }

                            


                            
                            <div className="prog_price">

                                {byDates === 1 ? <h3><b>{getTotalPrice(selectedDates)}</b></h3> : <Fragment>
                                    <h3><b>{campDetails?.registrationFee ? `Price : $${campDetails?.registrationFee?.toFixed(2)}` : 'Free'}</b></h3>

                                    <Dropdown className="price_detail_popover">
                                    <Dropdown.Toggle><small>Price detail</small></Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="d-flex justify-content-between">
                                            <span>Camp Registration Fee :</span>
                                            <b className="color_primary">${campDetails.campRegistrationFee && (campDetails.campRegistrationFee.toFixed(2) - (campDetails.registerNonRefundFee || 0))}</b>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span>Sales Tax :</span>
                                            <b className="color_primary">${campDetails.taxPrice && campDetails.taxPrice.toFixed(2)}</b>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span>Convenience Fee :</span>
                                            <b className="color_primary">${campDetails?.convenienceFee?.toFixed(2)}</b>
                                        </div>

                                        {
                                            campDetails.registerNonRefundFee ? <div className="d-flex justify-content-between">
                                            <span>Camp Non Registration Fee :</span>
                                            <b className="color_primary">${campDetails?.registerNonRefundFee?.toFixed(2)}</b>
                                        </div> : ''
                                        }

                                        
                                        {
                                            campDetails.discountPrice ? 
                                            <div className="d-flex justify-content-between">
                                            <span>Discount :</span>
                                            <b className="color_primary">-${campDetails.discountPrice.toFixed(2)}</b>
                                        </div>
                                        :'' 
                                        }
                                        <div className="d-flex justify-content-between gt_total">
                                            <span>Grand Total : </span>
                                            <b>${campDetails.registrationFee && campDetails.registrationFee.toFixed(2)}</b>
                                        </div>

                                        {campDetails.status === 1 && campDetails.availableSlots ?
                                            todayDate2 < campDetails.dateLateRegisterClosed
                                                ? <div className="text-center">
                                            {
                                            isConfirmed ? <Link to="/cart" className="btn btn-primary w-100 rounded-0">Proceed to checkout</Link> :  
                                            isAddedToCart ?  <Link to="/cart" className="btn btn-primary w-100 rounded-0">Book</Link> : <button onClick={() => props.loggedIn ? addtocartAction(campDetails._id,numberofCampers, "proceedTocheckout") : props.viewLoginModalAction()} className="btn btn-primary w-100 rounded-0">Book</button>
                                        }</div> : todayDate2 < campDetails.registerCloseDate ? <div className="text-center">
                                        {
                                            isConfirmed ? <Link to="/cart" className="btn btn-primary w-100 rounded-0">Proceed to checkout</Link> :  
                                            isAddedToCart ?  <Link to="/cart" className="btn btn-primary w-100 rounded-0">Book</Link> : <button onClick={() => props.loggedIn ? addtocartAction(campDetails._id,numberofCampers, "proceedTocheckout") : props.viewLoginModalAction()} className="btn btn-primary w-100 rounded-0">Book</button>
                                        }</div> : '' : ''
                                        }

                                    </Dropdown.Menu>
                                </Dropdown>
                                    </Fragment>}

                               
                                
                            </div>
                        </div>
                        <div className="quantity mb-3">
                            <button className="inc" onClick={() => onNumberOfCampersUpdate('remove')} />
                            <b>{numberofCampers}</b>
                            <button className="dec" onClick={() => onNumberOfCampersUpdate('add')} />
                        </div>
                        {(showError) && <span style={{ color: 'red' }}>Not enough slots available</span>}
                        
                        <p>{getHtml(campDetails.aboutProgram)}</p>



                    </div>
                    <Container className="col-lg-4">
                        <Row>
                            <Col>
                            
                            {campDetails.isOnline ? 
                                (<h2 className="cm_on_line">Online Program</h2>)
                                :
                                (<div className="camp_detail_map">
                                    { campDetails.geoLocation ? <MapComponent geoLocation={campDetails.geoLocation} {...props} /> : ''}
                                    
                                    <div className="camp_detail_address">
                                        {campDetails.city ? <p className="m-0">{`${campDetails.addressLine1} ${campDetails.city} ${campDetails.state}`}</p> : ''}
                                    </div>
                                </div>)}
                            </Col>
                        </Row>  
                        <Row>
                            <h3>Sample Schedule</h3>
                        </Row>  
                        <Row>
                            <Col className="daily_schedule">
                                <ul>{campDetails.schedule && campDetails.schedule.length ? campDetails.schedule.map(item => (
                                    <li key={item.time}><b>{item.time}</b> {item.activity}</li> 
                                )) : <li>Not updated</li>}
                                </ul>
                            </Col>
                        </Row>
                     
                        <Row>
                        {byDates === 1 ?
                        <Col className='multi_dates hide_on_mobile'>
                            <ul>
                                {additionalDates.map((item, i) => (
                                    <li key={i}>
                                        <div className="check_box_wrapper cm_dark_check">
                                            <input
                                                onChange={e => onChangeSelectedDates(item)}
                                                checked={selectedDates.includes(item)}
                                                type="checkbox"
                                                name="selectedDate" />
                                            <span className="check_box"><span></span> {gerFavpropgramdate(item.startDate, item.endDate)} <b className='ms-3'>${item.standardPrice.toFixed(2)}</b></span>
                                        </div>
                                        
                                        <small className='cm_a_slots'>available slots: <b>{item.slots}</b></small>
                                        
                                        
                                    </li>
                                ))}
                            </ul>
                            
                        </Col> : ''}
                        </Row>


                    </Container>
                </div>


                <div className="mt-4 mb-4 program_detail">
                    <h3>Program Details</h3>
                    <div className="row mt-3">
                        <div className="col-lg-4">
                            {campDetails.districtName ? <p><b>District Name: </b>{campDetails.districtName}</p> : ''}
                            <p><b>Age Group: </b>{campDetails.minAge} - {campDetails.maxAge}</p>
                            <p><b>Sex: </b> 
                                        {campDetails.gender === 1 ? 'Male' : ''}
                                        {campDetails.gender === 2 ? 'Female' : ''}
                                        {campDetails.gender === 3 ? 'Co-Ed' : ''}</p>
                            <p className="last_dot"><b>Grade: </b> {campDetails.grade && gradeArr2.filter(el => campDetails.grade.find(e => el.value === e )).sort((a, b) => a.count - b.count).map(item => <Fragment key={item.value}>{item.value}<span>,</span> </Fragment>)}</p>
                            <p className="last_dot"><b>Meals Provided:</b> {campDetails.meals && campDetails.meals.length ? campDetails.meals.map(item => <Fragment key={item}>{item ? item : 'None'}<span>,</span> </Fragment>) : 'None'}</p>
                        </div>
                        <div className="col-lg-4 cm_bordered">
                            <p><b>Check in Time: </b>{campDetails.checkInTime}</p>
                            <p><b>Check out Time: </b>{campDetails.checkOutTime}</p>
                            <p><b>Type: </b>{campDetails.programType ? getProgramType(campDetails.programType) : ''}</p>
                            {/* <p className="last_dot"><b>Camp Personnel: </b> {campDetails.personnel && campDetails.personnel.map(item => <Fragment key={item._id}>{item.name}<span>,</span> </Fragment>)}</p> */}
                            <p><b>Program Code: </b>{campDetails.programCode}</p>
                         
                            
                        </div>
                        <div className="col-lg-4">
                            <p><b>Experience: </b>{campDetails.expLevel ? getExperience(campDetails.expLevel) : ''}</p>
                            <p><b>Category: </b>{campDetails.categoryName}</p>
                            <p className="last_dot"><b>Sub Category: </b> {campDetails.subCategory && campDetails.subCategory.map(item => <Fragment key={item._id}>{item.name}<span>,</span> </Fragment>)}</p>
                            <p><b>Travel: </b>{campDetails.travel === 1 ? '' : 'Not'} Included</p>
                            <p><b>T-Shirt Included: </b>{campDetails.tShirt === 1 ? 'Yes' : 'No'}</p>
                            
                        </div>
                    </div>
                    <p>{getHtml(campDetails.programIntroduction)}</p>
                  

                </div>




                <div className="sm_section mt-5">
                    <h3>Instruction, Equipment & Facility</h3>
                    <ul>

                        {campDetails.programInstruction ? <li>Instruction :<p className="ps-4">{getHtml(campDetails.programInstruction)}</p></li> : ''}
                        {campDetails.equipment ? <li>Equipment Needed : <p className="ps-4">{campDetails.equipment}</p></li> : ''}
                        {campDetails.facility ? <li>Facility : <p className="ps-4">{campDetails.facility ? getFacility(campDetails.facility) : ''}</p></li> : ''}
                        
                        
                    </ul>
                </div>

                {
                    campDetails.impNotes ? <div className="sm_section">
                    <h3 className="mb-3">Important Notes</h3>
                    <p>{getHtml(campDetails.impNotes)}</p>
                </div> : ''
                }
                

                {campDetails.travel === 1 ?
                    <div className="sm_section">
                        <h3 className="mb-3">Travel Info </h3>
                        <p>{campDetails.travelInfo ? getHtml(campDetails.travelInfo) : ''}</p>
                    </div>:''
                }




                {campDetails.additionalExp !== undefined && campDetails.additionalExp.length > 0 ?
                <div className="sm_section">
                    <h3>Additional Experience <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 50, hide: 250 }}
                                                overlay={<Tooltip>Additional Experiences can be added in checkout.</Tooltip>}>
                                                <i style={{verticalAlign: 'middle'}} className="icon-information" />
                                            </OverlayTrigger>
                    </h3>
                    {
                        campDetails.additionalExp ? campDetails.additionalExp.map((item, index) => (
                            <div style={{color: item.color}} className="additionalExp" key={item._id}>
                                <h5 style={{color: item.color}}>{getHtml(item.title)}</h5>
                                <p style={{color: item.color}} className="mb-2"><b style={{color: item.color}}>Price :</b> ${item.price}</p>
                                {/* <p style={{color: item.color}} className="mb-2"><b style={{color: item.color}}>Available Slots :</b> {item.remainingAdditionalExpCount}</p> */}
                                <p><div><b style={{color: item.color}}>Description</b></div> {getHtml(urlify(item.description))}</p>
                            </div>
                        )) : ''
                    }
                </div>:''
                }

                
                    
                {/* {
                    (todayDate2 < campDetails.dateLateRegisterClosed && campDetails.status === 1)
                        ? <div className="mt-5 text-center">
               
                        {
                                            isConfirmed ? <Link to="/cart" className="btn btn-primary btn-sm ms-1 rounded-0">Proceed to checkout</Link> : (
                                                <React.Fragment>
                                                    <button
                                                        onClick={() => props.loggedIn ? addtocartAction(campDetails._id, numberofCampers, "addtocart") : props.viewLoginModalAction()}
                                                        className={`btn btn-info  btn-sm me-1 rounded-0 ${btnloder ? 'btnLoader' : ''}`}>Add to Cart</button>
                                                    {isAddedToCart ? <Link to="/cart" className="btn btn-primary btn-sm ms-1 rounded-0">Check out</Link> : <button onClick={() => props.loggedIn ? addtocartAction(campDetails._id,numberofCampers, "proceedTocheckout") : props.viewLoginModalAction()} className="btn btn-primary btn-sm ms-1 rounded-0">Check out</button>}
                                                </React.Fragment>
                                            )
                                        }
                        </div> : (todayDate2 < campDetails.registerCloseDate && campDetails.status === 1) ? <div className="mt-5 text-center">
               
               {
                                   isConfirmed ? <Link to="/cart" className="btn btn-primary btn-sm ms-1 rounded-0">Proceed to checkout</Link> : (
                                       <React.Fragment>
                                           <button
                                               onClick={() => props.loggedIn ? addtocartAction(campDetails._id, numberofCampers, "addtocart") : props.viewLoginModalAction()}
                                               className={`btn btn-info  btn-sm me-1 rounded-0 ${btnloder ? 'btnLoader' : ''}`}>Add to Cart</button>
                                           {isAddedToCart ? <Link to="/cart" className="btn btn-primary btn-sm ms-1 rounded-0">Check out</Link> : <button onClick={() => props.loggedIn ? addtocartAction(campDetails._id,numberofCampers, "proceedTocheckout") : props.viewLoginModalAction()} className="btn btn-primary btn-sm ms-1 rounded-0">Check out</button>}
                                       </React.Fragment>
                                   )
                               }
               </div> : ''
                } */}

                


            </div>


            <div className="associated_wrapper">
                <div className="container">
                    <div className="d-flex justify-content-between">
                        <h2>Associated Programs</h2>
                        <Link  to={
                        {     
                            pathname: '/associateprograms',
                            state:{"campId":campDetails.campId}
                        }
                    } className="cm_see_All"><h2>See all</h2></Link>
                    </div>

                    <div className="row">
                        {campPrograms.length ? campPrograms.slice(0, 2).map(item => (
                            <div className="col-lg-6" key={item._id}>
                                <AssociatedCard  UserID={props.userID} data={item} />
                            </div>
                        )) : ''}
                        
                    </div>
                </div>
            </div>




            <div className="other_detail">
                <div className="container">

                    <div className="row">

                        {/* <div className="col-lg-12 cm_border_right pe-5"> */}
                        <div className="col-lg-6 pe-5">
                            <h3 className="mb-4">Staff Personnel</h3>
                            <ul>
                                {
                                    others.map(item => (
                                        <li key={item._id}>
                                            <img src={item.profileImage ? item.profileImage : CampsUser} />
                                            <h6>{item.UserType === 4 ? item.userTitle ? item.userTitle : 'Counselor' : 'Camp Director'}</h6>
                                            <h4 className="mb-2">{item.name}</h4>
                                            <p>{item.userDescription}</p>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>


                        <div className="col-lg-6 ps-5 ">
                            <div className="mb-4 d-flex justify-content-between">
                            <h3>Reviews</h3>
                            <h3 className="color_black cm_cursor" onClick={() => setViewAllModal(true)}>See All</h3>
                            </div>
                            {reviews.length ? <Fragment>
                                <h4>Total average score : <span className="view_rating"><Rate value={averageCampRating} disabled={true} /></span></h4>
                            
                                {reviews.slice(0, 3).map(item => (
                                    <div className="review_sm_card">
                                        <img src={item.profileImage ? item.profileImage : CampsUser} />
                                        <h6>{item.username}</h6>
                                        <p>{item.reviews}</p>
                                        <div className="ratings">
                                        <span className="view_rating"><Rate value={item.rating} disabled={true} /></span>
                                        </div> {parseFloat(item.rating).toFixed(1)}
                                    </div>
                                ))}
                            </Fragment> : <div className="cm_empty"><Empty description="No Reviews Found" /></div>}
                            


                        </div>




                    </div>
                </div>
            </div>


            {
                props.loggedIn ?  <div className="ask_quition">
                <div className="container">
                        <div className="form-group">
                            <label>Ask a question?</label>
                            <textarea
                                value={question}
                                onChange={e => setQuestion(e.target.value)}
                                className="form-control" />
                        </div>

                        <div className="text-center mb-5">
                            <span onClick={handleQuestionSubmit} className={`btn btn-primary rounded-0 ps-5 pe-5 ${btnDisable ? 'disabled' : ''}`}>Submit</span>
                        </div>
                </div>
            </div> : ''
            }

           






            <div className="donate_sec container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Make a Donation</h2>
                            <p>These donations go to the specified program provider. Every donation helps, and can allow organizations to fill unmet needs and support the community.  We all, would like to thank you for your generosity!</p>
                            
                            <Link to={`/donate/${campDetails._id}`} className="btn btn_white">Donate</Link>
                        </div>
                        
                    </div>

                    </div>
                    
                            {scholarships === 1 ? <div className="scholarships_bg" style={{backgroundImage: `url(${Scholarships_bg})`}}><div className="mt-4">
                                <a href={scholarshipsUrl} className="btn btn-secondary ps-5 pe-5 rounded-0" target="_blank">Apply for scholarship</a>
                                <p>To apply to need-based financial aid for this program click the Apply button. This financial aid can apply to some, or all, of the registration fee for this program, and acceptance is based solely on the program provider's discretion.</p>
                            </div></div> : ''}
                        
                    
                    
                    
                
            </div>




            <Modal
                show={viewAllModal}
                animation={false}
                size="lg"
                centered
                >
                <Modal.Body>
                    <span className="modal_close" onClick={() => setViewAllModal(false)}><i className="icon-cross" /></span>
                    

                    <div className="mb-4">
                        <h3>Review</h3>
                        </div>
                        
                            {reviews.length ? <Fragment>
                                <h4>Total average score : <span className="view_rating"><Rate value={averageCampRating} disabled={true} /></span></h4>
                                <div className="review_max_height">
                                {reviews.map(item => (
                                    <div className="review_sm_card">
                                        <img src={item.profileImage ? item.profileImage : CampsUser} />
                                        <h6>{item.username}</h6>
                                        <p>{item.reviews}</p>
                                        <div className="ratings">
                                        <span className="view_rating"><Rate value={item.rating} disabled={true} /></span>
                                        </div> {parseFloat(item.rating).toFixed(1)}
                                    </div>
                                ))}
                                </div>
                            </Fragment> : <div className="cm_empty"><Empty description="No Reviews Found" /></div>}
                        
                </Modal.Body>
                </Modal>



                {
                  setting.api.sportInternationalCamp === campDetails.campId ? <a className='providesupport' href="https://vm.providesupport.com/0c628irp4d8370wooul2pwrdfy" target="_blank"><img src="https://image.providesupport.com/image/0c628irp4d8370wooul2pwrdfy/current"  alt="Live chat" title="Click to chat"/></a> : ''
                }


        </div>

   )
}




/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let { programDetail, preList } = state.program;
    let { mapViewModal } = state.ui;
    let { loggedIn, userProfile } = state.user;
    let userID =false;

    if(state.user.userProfile!== undefined)
    {
        userID = state.user.userProfile._id;
    }
    return {
        mapViewModal,
        programDetail,
        preList,
        loggedIn,
        userID,
        userProfile
    };
}


const mapDispatchToProps = dispatch => ({
    toggleMapViewModal: () => dispatch(toggleMapViewModal()),
    viewLoginModalAction: () => dispatch(viewLoginModalAction()),
    getitemsincart: (cartitems) => dispatch(getitemsincart(cartitems)),
    campContectAction: data => dispatch(campContectAction(data)),
});

  
export default connect(mapStateToProps, mapDispatchToProps)(CampDetail);
