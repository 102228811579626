import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Empty } from 'antd';
import { toggleMapViewModal } from '../../store/ui';
import { filterProgramsAction, programLoaderActiveAction } from '../../store/program';
import { fFilterTypeAction, fPageNoAction } from '../../store/pFilter';
import CampCard from './CampCard';
import MainFilter from '../home/MainFilter';
import Filters from './Filters';
import { ProgramLoader } from '../reuse/SVG';
import PaginatedComponent from '../common/Pagination/PaginationComponent';


const Camps = props => {
    const [page, setPage] = useState(1);

    useEffect(() => {
        props.programLoaderActiveAction()
    }, [])

    useEffect(() => {
        let { 
            keywordSearch,
            gender,
            ageGroup,
            grade,
            startDate,
            endDate,
            programType,
            city,
            radius,
            priceRange,
            ezProRating,
            categories,
            flat,
            flong,            
            lat,
            long,
            pageNo,
            limit,
            weekDays,
            campers,
            subCategories,
            filterType,
            maxDistance,
            isOnline
        } = props;
    
        let params = { 
            userId: props.userProfile ? props.userProfile._id : '',
            keywordSearch,
            gender,
            ageGroup,
            grade,
            startDate,
            endDate,
            programType,
            city,
            radius,
            priceRange,
            ezProRating,
            flat,
            flong,            
            lat,
            long,
            pageNo,
            limit,
            filterType,
            maxDistance,
            weekDays: weekDays.length ? JSON.stringify(weekDays) : '',
            categories: categories.length ? JSON.stringify(categories) : '',
            subCategories: subCategories.length ? JSON.stringify(subCategories) : '',
            campers: campers.length ? JSON.stringify(campers) : '',
            isOnline
        }

        props.filterProgramsAction(params);
    }, [props.pageNo, props.subCategories, props.filterType, props.maxDistance])


    const handleFilterType = val => {
        props.fFilterTypeAction(val);
    }

    const handlePageChange = page => {
        props.fPageNoAction(page);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }


    return(
        <div className="body_container">



            <div className="container">
                <div className="d-flex justify-content-end section_header">
                    <button disabled={props.programs.length ? false : true} title={props.programs.length ? '' : 'No program available'} onClick={props.toggleMapViewModal} className="btn btn-secondary ps-5 pe-5"><i className="icon-map" /> Map View</button>
                </div>
            </div>




            <div className="container">
                <div className="row">


                    <div className="col-lg-4">
                        <MainFilter hideCategory={true} />
                        <Filters />
                    </div>


                    <div className="col-lg-8">
                        <div className="cat_btn_group">
                            <span onClick={() => handleFilterType('')} className={`me-2 btn btn-outline-dark ${props.filterType === '' ? 'active' : ''}`}>All</span>
                            <span onClick={() => handleFilterType(0)} className={`me-2 btn btn-outline-dark ${props.filterType === 0 ? 'active' : ''}`}>Popular</span>
                            <span onClick={() => handleFilterType(1)} className={`me-2 btn btn-outline-dark ${props.filterType === 1 ? 'active' : ''}`}>Nearby</span>
                            <span onClick={() => handleFilterType(2)} className={`me-2 btn btn-outline-dark ${props.filterType === 2 ? 'active' : ''}`}>EZ Pro</span>
                            <span onClick={() => handleFilterType(3)} className={`me-2 btn btn-outline-dark ${props.filterType === 3 ? 'active' : ''}`}>Featured</span>
                            {/* <span onClick={() => handleFilterType(4)} className={`me-2 btn btn-outline-dark ${props.filterType === 4 ? 'active' : ''}`}>Special deals</span> */}
                        </div>


                        <div className="camps_list" id="camps_lists">
                            {
                                props.loader ? 
                                    <div className="program_loader"><ProgramLoader /></div> 
                                    :
                                    props.programs.length ? props.programs.map(program => <CampCard key={program._id} program={program} />) : <div className="cm_empty"><Empty description="Please broaden your search by entering fewer fields in the search box." /> </div>
                            }
                        </div>

                        {
                            props.programs.length ? 
                            <div className="d-flex justify-content-center mt-5">
                                <PaginatedComponent
                                    activePage={props.pageNo}
                                    itemsCountPerPage={props.limit}
                                    totalItemsCount={props.totalCount}
                                    pageRangeDisplayed={4}
                                    onPageChange={handlePageChange}/>
                            </div> 
                                                     : ''
                        }

                        
                        



                    </div>
                </div>
            </div>




        </div>
    )
}

/*******************************
    Store Connect
********************************/

const mapStateToProps = state => {
    let {programs, loader, totalCount} = state.program;
    let {userProfile} = state.user;

    let { 
        keywordSearch,
        gender,
        ageGroup,
        grade,
        startDate,
        endDate,
        programType,
        weekDay,
        city,
        radius,
        priceRange,
        ezProRating,
        categories,
        flat,
        flong,
        lat,
        long,        
        pageNo,
        limit,
        weekDays,
        subCategories,
        advanceSearch,
        filterType,
        campers,
        maxDistance,
        isOnline
    } = state.pFilter;

    return {
        loader,
        programs,
        keywordSearch,
        gender,
        ageGroup,
        grade,
        startDate,
        endDate,
        programType,
        weekDay,
        city,
        radius,
        priceRange,
        ezProRating,
        categories,
        flat,
        flong,        
        lat,
        long,
        pageNo,
        limit,
        weekDays,
        subCategories,
        advanceSearch,
        filterType,
        campers,
        totalCount,
        maxDistance,
        userProfile,
        isOnline
    };
}
  
const mapDispatchToProps = dispatch => ({
    toggleMapViewModal: () => dispatch(toggleMapViewModal()),
    programLoaderActiveAction: () => dispatch(programLoaderActiveAction()),
    filterProgramsAction: params => dispatch(filterProgramsAction(params)),
    fPageNoAction: params => dispatch(fPageNoAction(params)),
    fFilterTypeAction: params => dispatch(fFilterTypeAction(params)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Camps);
